import common from './common.json'
import title from './title.json'
import login from './login.json'
import toolbar from './toolbar.json'
import user from './user.json'
import settings from './settings.json'
import project from './project.json'
import planning from './planning.json'
import organisation from './organisation.json'
import header from './header.json'
import tools from './tools.json'

const index = {
  common,
  title,
  login,
  toolbar,
  user,
  settings,
  project,
  planning,
  organisation,
  header,
  tools
}

export default index
