import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import location from '../../location.js'
import './user.css'
import { Trans as T, withTranslation } from 'react-i18next'
import { xmlGetRequest, hasPermissionFor } from '../../tools.jsx'
const $ = window.$

const makeANiceLookingLabelForMe = function (role, t) {
  const roles = {
    'ADMIN+': {
      color: 'red',
      name: t('user:admin_plus')
    },
    ADMIN: {
      color: 'red',
      name: t('user:admin')
    },
    EMPLOYEE: {
      color: 'purple',
      name: t('user:employee')
    },
    FREELANCER: {
      color: 'green',
      name: t('user:freelancer')
    },
    PARTNER: {
      color: 'blue',
      name: t('user:partner')
    },
    ONEPROJECTER: {
      color: 'orange',
      name: t('user:inspector')
    },
    USER: {
      color: 'green',
      name: t('user:user')
    }
  }
  roles[role] = roles[role] || {} // in case the role is incorrect this'll make sure the page still loads
  return (
    <span className={`label ${roles[role].color}`}>{roles[role].name}</span>
  )
}

const fetchUser = function (props, callback) {
  let url = `${location.origin}/v1/user/`
  if (props.user._id === props.match.params.id) {
    url += 'me'
  } else {
    url += props.match.params.id
  }
  const settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: 'GET',
    headers: {
      authorization: `Bearer ${props.token.token}`
    }
  }
  const promise = $.ajax(settings)
  promise.done(response => {
    callback(null, response)
  })
  promise.fail(error => {
    callback(error)
  })
}

class UserView extends Component {
  constructor (props) {
    super(props)

    let hasCRUDPermissions
    /* eslint-disable */
    switch (this.props.user.role) {
      case 'ADMIN+':
      case 'ADMIN':
      case 'EMPLOYEE':
      case 'FREELANCER': hasCRUDPermissions = true
        break
      default: hasCRUDPermissions = false
    }
    /* eslint-enable */
    this.state = {
      user: {
        _id: '',
        afas_id: null,
        afas_updated: null,
        customer: null,
        name: null,
        initials: null,
        gender: null,
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        date_of_birth: null,
        address: {
        },
        phone: null,
        phone_work: null,
        website: null,
        organisation: {
          organisation_id: '',
          name: null
        },
        ss_role: null,
        role: 'USER',
        projects: [],
        created_at: null,
        updated_at: null,
        ss_updated_at: null,
        ss_id: null,
        profile_picture_id: null,
        profile_picture_icon_url: null
      },
      error: {},
      loading: true,
      hasCRUDPermissions
    }
  }

  componentWillMount () {
    document.title = this.props.t('title:user')
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentWillUpdate (nextProps, nextState) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      fetchUser(nextProps, (error, response) => {
        if (!this.isUnmounting) {
          if (!error) {
            if (!this.isUnmounting) {
              this.setState({
                user: response,
                loading: false
              })
            }
          } else {
            this.setState({
              error,
              loading: false
            })
          }
        }
      })
    }
  }

  componentDidMount () {
    fetchUser(this.props, (error, response) => {
      if (!this.isUnmounting) {
        if (!error) {
          document.title = this.props.t('title:user') + ` - ${response.name || ''}`
          this.setState({
            user: response,
            loading: false
          })
        } else {
          this.setState({
            error,
            loading: false
          })
        }
      }
    })
  }

  render () {
    if (this.state.loading) {
      return (
        <section>
          <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
        </section>
      )
    }
    const t = this.props.t

    const error = this.state.error
    if (error && error.status === 403) {
      return <h1><T i18nKey="user:no_access">Geen toegang</T></h1>
    }

    const user = this.state.user
    let userRole = this.props.user.role
    if (this.props.user._id === this.props.match.params.id) {
      userRole = 'me'
    }
    const ssRoleName = user.ss_role || ''
    const imgUrl = user.profile_picture_thumbnail_url || '/images/default_user_thumbnail.png'
    const img = <img alt="icon" className="profile_pic" src={`${location.origin}${imgUrl}`} />
    user.organisation = user.organisation || {}
    const organisationId = user.organisation.organisation_id

    const canSeeProjects = hasPermissionFor(this.props.user.role, 'FREELANCER')

    return (
      <section className="container content">
        <section className="user_block">
          <div className="user_profile_pic">
            <div className="big profile_pic_round">
              {img}
            </div>
            {userRole.match('ADMIN') || userRole === 'me'
              ? <Link to={`/gebruiker_afbeelding/${user._id}`} className="edit_profile_pic"><T i18nKey="user:edit">Aanpassen</T></Link>
              : ''
            }
          </div>
          <div className="user_info">
            <h3>
              {user.name}
              {this.state.hasCRUDPermissions
                ? <span>&nbsp;{makeANiceLookingLabelForMe(user.role, t)}</span>
                : null
              }
            </h3>
            <div>
              {ssRoleName}
              <br />
              {organisationId
                ? <Link to={`/organisatie/${organisationId}?from=user`}>{user.organisation.name}</Link>
                : ''
              }
            </div>
            <div className="user_contact">
              <div><span className="glyphicons glyphicons-envelope"></span> : <a href={`mailto:${user.email}`}>{user.email}</a></div>
              <div><span className="glyphicons glyphicons-earphone"></span> : <a href={`tel:${user.phone}`}>{user.phone}</a></div>
            </div>
            <div className="User-info-container">
              <span><T i18nKey="user:address">Adres</T></span>
              <span>{user.ss_address || ''}</span>
            </div>
            <div className="User-info-container">
              <span><T i18nKey="user:extra_information">Extra informatie</T></span>
              <span>{user.additional_info || ''}</span>
            </div>
            <div className="User-info-container">
              <span><T i18nKey="user:safetysnapper_subrole">Safetysnapper subrol</T></span>
              <span>{user.ss_role_secondary || ''}</span>
            </div>
            <div className="User-info-container">
              <span><T i18nKey="user:fax">Fax</T></span>
              <span>{user.fax_number || ''}</span>
            </div>
          </div>
          {userRole.match('ADMIN') || userRole === 'me'
            ? <Link className="edit" to={`/gebruiker_aanpassen/${user._id || ''}`}><span className="glyphicons glyphicons-pencil"></span></Link>
            : null
          }
          <div className="clear"></div>
        </section>
        {canSeeProjects && <Projects user={user} token={this.props.token} t={t} />}
      </section>
    )
  }
}

const User = withTranslation()(UserView)
export { User }

const Projects = ({ user, token, t }) => {
  const [projects, setProjects] = useState({ results: [] })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!user) return
    let isUnmounting = false
    async function getProjects () {
      setLoading(true)
      const url = `${location.origin}/v1/project/getv2?status=execution,waiting,design&contact=${user._id}`
      const projects = await getAll(url, token.token)
      const vcUrl = `${location.origin}/v1/project/getv2?status=execution,waiting,design&user=${user._id}`
      const vcProjects = await getAll(vcUrl, token.token)
      if (isUnmounting) return
      setLoading(false)
      const groupedProjects = new Map([...projects.results, ...vcProjects.results].map(p => [p._id, p]))
      setProjects({ results: [...groupedProjects.values()] })
    }
    getProjects()
    return () => {
      isUnmounting = true
    }
  }, [user, token])

  return (
    <div className="User-project">
      <div className="User-project-row User-project-row-title">
        <div className="User-project-row-id"><T i18nKey="project:project_number_short">Project nr.</T></div>
        <div className="User-project-row-title"><T i18nKey="project:title">Titel</T></div>
      </div>
      {projects.results.map(project => <Project key={project._id} project={project} t={t} />)}
      {projects.results.length === 0 && !loading && <div className="User-project-row"><T i18nKey="user:no_projects_found">Geen projecten gevonden</T></div>}
      {loading && <div className="User-project-row"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></div>}
    </div>
  )
}

const Project = ({ project, t }) => {
  return (
    <Link to={`/werf/${project._id}`} className="User-project-row">
      <div className="User-project-row-id">{project.afas_id}</div>
      <div className="User-project-row-title">{project.ss_name || project.project_name || t('project:nameless_project')}</div>
    </Link>
  )
}

async function getAll (url, token, results = [], skip = 0) {
  const pageSize = 100
  const projects = await xmlGetRequest(url + `&skip=${skip}&limit=${pageSize}`, token)
  if (projects.results.length < pageSize) {
    return { results: [...results, ...projects.results] }
  } else {
    return getAll(url, token, [...results, ...projects.results], skip + pageSize)
  }
}
