import React, { Component } from 'react'

import { VGPMainView } from './vgp/vgpMain.jsx'
import { CDView } from './cd.jsx'
import { PIDView } from './pid.jsx'
import { ContactsView } from './contacts/contacts.jsx'
// import { MessagesView } from './messages.jsx'
import { LogsView } from './logs/logs.jsx'
import RAView from './RAView.js'

import './ProjectInfo.css'
import { Trans as T } from 'react-i18next'

const $ = window.$

export class SecondaryInfo extends Component {
  constructor (props) {
    super(props)

    this.state = {
      route: 'CD'
    }

    this.changeTab = this.changeTab.bind(this)
  }

  changeTab (event) {
    event.preventDefault()
    this.setState({ route: event.currentTarget.id })
    const $target = $(event.currentTarget)
    $target.closest('section').find('.active').removeClass('active')
    $target.closest('li').addClass('active')
  }

  render () {
    let rightContent
    switch (this.state.route) {
      case 'CD': rightContent = <CDView {...this.props} reloadProject={this.props.reloadProject} />; break
      case 'PID': rightContent = <PIDView {...this.props} reloadProject={this.props.reloadProject} />; break
      case 'contacts': rightContent = <ContactsView {...this.props} {...this.state} reloadProject={this.props.reloadProject} />; break
      // case 'messages': rightContent = <MessagesView {...this.props}/>; break
      case 'logs': rightContent = <LogsView {...this.props} />; break
      case 'RA': rightContent = <section className="right_content"><RAView {...this.props} reloadProject={this.props.reloadProject} /></section>; break
      default: rightContent = <VGPMainView {...this.props} reloadProject={this.props.reloadProject} />
    }
    const role = this.props.user.role

    let logsButton = null
    if (role.match('ADMIN')) {
      logsButton = (
        <ul>
          <li>
            <a href="" onClick={this.changeTab} id="logs"><T i18nKey="project:logs">Logs</T></a>
          </li>
        </ul>
      )
    }
    /*
            let messagesButton = null;
            if ( role !== 'PARTNER' ) {
                messagesButton = (
                    <li>
                        <a href="" onClick={this.changeTab} id="messages">Berichten</a>
                    </li>
                );
            } */
    return (
      <div className="SecondaryInfo">
        <div>
          <section className="left_menu">
            <ul>
              <li>
                <a href="" onClick={this.changeTab} id="VGP"><T i18nKey="project:vgp">VGP</T></a>
              </li>
              <li className="Project-info-cd">
                <a><T i18nKey="project:cd">CD</T></a>
                <ul className="Project-info-cd-menu">
                  <li className="active Project-info-cd-menu-button">
                    <div className="Project-info-cd-menu-indent"><div className="Project-info-cd-menu-indent-lines"></div></div>
                    <a href="" onClick={this.changeTab} id="CD"><T i18nKey="project:diary">Dagboek</T></a>
                  </li>
                  <li className="Project-info-cd-menu-button">
                    <div className="Project-info-cd-menu-indent"><div className="Project-info-cd-menu-indent-lines"></div></div>
                    <a href="" onClick={this.changeTab} id="RA"><T i18nKey="project:risk_and_safety_plans">RA's & Veiligheidsplannen</T></a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="" onClick={this.changeTab} id="PID"><T i18nKey="project:pid">PID</T></a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="" onClick={this.changeTab} id="contacts"><T i18nKey="project:contacts">Contacten</T></a>
              </li>
              {/* messagesButton */}
            </ul>
            {logsButton}
          </section>
        </div>
        {rightContent}
        <div className="clear"></div>
      </div>
    )
  }
}
