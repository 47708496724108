import React, { Component } from 'react'
import moment from 'moment'
import { formatInterval } from '../../projectTypeFunctions.js'
import { xmlGetRequest, hasPermissionFor, getAdministration, getSystem } from '../../tools.jsx'
import location from '../../location'
import { withRouter } from 'react-router'
import './filter.css'
import { getProgressValue } from '../project/projectPage/ProjectNotes.js'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

const pageSize = 30

function getPidMailDate (pidMailDates = {}, name) {
  const date = (pidMailDates[name] || {}).date || ''
  if (date) return moment(date).format('YYYY-MM-DD')
  return ''
}

class FilterView extends Component {
  constructor (props) {
    super(props)

    const previousParamState = this.getPreviousState(props.location)
    this.state = Object.assign(previousParamState, {
      projectsStore: {},
      loadingCsv: false,
      ownProjectsChecked: this.props.location.pathname === '/planning'
    })

    this.isFreelancer = props.user.role === 'FREELANCER'

    this.toggleOwnProjects = this.toggleOwnProjects.bind(this)
    this.toggleStaleProjects = this.toggleStaleProjects.bind(this)
    this.fetchProjects = this.fetchProjects.bind(this)
    this.handleVCChange = this.handleVCChange.bind(this)
    this.getNextPage = this.getNextPage.bind(this)
    this.createCSV = this.createCSV.bind(this)
    this.refreshProjects = this.refreshProjects.bind(this)
    this.updatePath = this.updatePath.bind(this)
    this.clearProjects = this.clearProjects.bind(this)
    this.getABunchOfCounts = this.getABunchOfCounts.bind(this)
  }

  componentDidMount () {
    if (this.props.type === 'project') {
      this.fetchProjects()
    } else if (this.props.type === 'planning') {
      this.fetchPlannings()
    }

    window.addEventListener('getNextPage', this.getNextPage)
    window.addEventListener('filterReloadProjects', this.refreshProjects)
    window.addEventListener('filterClearProjects', this.clearProjects)
  }

  componentWillUnmount () {
    this.isUnmounting = true
    window.removeEventListener('getNextPage', this.getNextPage)
    window.removeEventListener('filterReloadProjects', this.refreshProjects)
    window.removeEventListener('filterClearProjects', this.clearProjects)
  }

  getNextPage () {
    this.fetchProjects()
  }

  refreshProjects () {
    this.fetchProjects()
  }

  getPreviousState (location) {
    const searchParams = new URLSearchParams(location.search || '')

    const state = {
      searchInput: searchParams.get('search') || '',
      vc: (location.state || {}).vc || [],
      vcInput: searchParams.get('vc') || '',
      clientInput: searchParams.get('client') || '',
      ownProjectsChecked: searchParams.get('ownProjects') === 'true',
      staleProjectsChecked: searchParams.get('stale') === 'true'
    }

    return state
  }

  clearProjects () {
    this.setState(state => ({
      projectsStore: {}
    }), this.refreshProjects)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      return this.setState(
        state => Object.assign(
          this.getPreviousState(nextProps.location),
          { projectsStore: {} }
        ),
        () => { this.fetchProjects(nextProps) }
      )
    } else if (nextProps.type === 'project') {
      let update = false

      if (nextProps.type !== this.props.type || this.props.sort !== nextProps.sort) {
        update = true
      }

      if (update) {
        return this.setState(state => ({
          projectsStore: {},
          ownProjectsChecked: this.props.location.pathname === '/planning'
        }), () => { this.fetchProjects(nextProps) })
      }

      if (this.props.status !== nextProps.status && !this.state.projectsStore[nextProps.status]) {
        return this.fetchProjects(nextProps)
      }
    } else if (nextProps.type === 'planning') {
      if (nextProps.type !== this.props.type) {
        return this.setState(state => ({ projectsStore: {} }), () => { this.fetchPlannings(nextProps) })
      }

      if (this.props.status !== nextProps.status && !this.state.projectsStore[nextProps.status]) {
        return this.fetchPlannings(nextProps)
      }
    }
  }

  updatePathThrottled () {
    clearTimeout(this.updatePathTimeout)
    this.updatePathTimeout = setTimeout(this.updatePath, 250)
  }

  updatePath () {
    const {
      clientInput,
      ownProjectsChecked,
      searchInput,
      staleProjectsChecked,
      vc,
      vcInput
    } = this.state

    const pathname = this.props.planningPage ? '/planning' : '/'

    const names = [
      {
        name: 'client',
        value: clientInput
      },
      {
        name: 'ownProjects',
        value: ownProjectsChecked
      },
      {
        name: 'search',
        value: searchInput
      },
      {
        name: 'stale',
        value: staleProjectsChecked
      },
      {
        name: 'vc',
        value: vcInput
      }
    ]
    const searchParams = new window.URLSearchParams(this.props.location.search || '')
    names.forEach(query => {
      searchParams.set(query.name, query.value)
    })

    this.props.history.replace({
      pathname,
      search: '?' + searchParams.toString(),
      state: {
        vc: vcInput ? vc : []
      }
    })
  }

  toggleOwnProjects () {
    this.setState(state => ({
      ownProjectsChecked: !state.ownProjectsChecked
    }), this.updatePathThrottled)
  }

  toggleStaleProjects () {
    this.setState(state => ({
      staleProjectsChecked: !state.staleProjectsChecked
    }), this.updatePathThrottled)
  }

  handleChange (name, value) {
    this.setState(state => ({
      [name]: value
    }), this.updatePathThrottled)
  }

  handleVCChange (value) { // throttleVCChange
    clearTimeout(this.vcTimeout)
    this.setState(state => ({ vcInput: value }))
    this.vcTimeout = setTimeout(() => { this._handleVCChange(value) }, 250)
  }

  _handleVCChange (value) {
    if (this.isUnmounting) {
      return
    }

    if (!value) {
      return this.setState(state => ({
        vc: [],
        vcInput: ''
      }), this.updatePathThrottled)
    }

    const url = `${location.origin}/v1/user?vc=true&search=${value}`
    xmlGetRequest(url, this.props.token.token)
      .then(users => {
        if (users.length) {
          this.setState(state => ({
            vc: users.map(user => user._id)
          }), this.updatePathThrottled)
        } else {
          this.setState(state => ({
            vc: []
          }), this.updatePathThrottled)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  createQueryParameters ({ status = 'execution', sort, queryOverride, queryRemove, user, vcType } = {}) {
    const { searchInput, clientInput, ownProjectsChecked, staleProjectsChecked } = this.state

    const select = [
      'project_name',
      'ss_name',
      'sortField',
      'status',
      'afas_id',
      'users',
      'client_id',
      'client_name',
      'planning',
      'boosted',
      'muted',
      'start_date',
      'finished_date',
      'createPidStartDate',
      'type',
      'next_visit',
      'last_visit',
      'planning_disabled',
      'project_vc_passed_status',
      'files',
      'progress',
      'contracted_visits',
      'contracted_duration',
      'administration',
      'site_id',
      'address',
      'pidMailDates',
      'comment',
      'pidComment',
      'fCode',
      'onHoldComment',
      'onHoldUntilAtLeastDate'
    ].join(',')

    const queryParameters = {
      status,
      limit: pageSize,
      select
    }

    // status
    if (status === 'execution') { // dont load planning_disabled and muted because we show these in the 'waiting' projects list
      queryParameters.planning_disabled = false
      queryParameters.include_muted = false
      // } else if (status === 'waiting') {
      // queryParameters.limit = Math.floor(pageSize / 3)
    }

    // sort
    if (sort) {
      queryParameters.sort = sort
    }

    // index
    queryParameters.skip = (this.state.projectsStore[status] || {}).index || 0

    // title
    if (searchInput) {
      queryParameters.search = searchInput
    }

    // client
    if (clientInput) {
      queryParameters.client = clientInput
    }

    // user
    const users = ownProjectsChecked ? [user._id] : this.state.vc.slice()
    if (users.length) {
      queryParameters.user = users.join(',')
    }
    if (this.isFreelancer) {
      queryParameters.user = user._id
    }

    if (vcType) {
      queryParameters.vc_type = vcType
    }

    // age
    if (staleProjectsChecked) {
      queryParameters.lastVisitOlderThan = moment().subtract(6, 'weeks').format('x')
    }

    if (queryOverride) {
      Object.assign(queryParameters, queryOverride)
    }
    if (queryRemove) {
      Object.keys(queryRemove).forEach(key => { delete queryParameters[key] })
    }

    return queryParameters
  }

  async getABunchOfCounts (props) {
    const parameterArguments = {
      status: 'new',
      sort: props.sort,
      queryOverride: props.queryOverride,
      queryRemove: props.queryRemove,
      user: props.user
    }
    const newQueryParameters = this.createQueryParameters(parameterArguments)
    parameterArguments.status = 'design'
    const designQueryParameters = this.createQueryParameters(parameterArguments)
    parameterArguments.status = 'execution'
    const executionQueryParameters = this.createQueryParameters(parameterArguments)
    parameterArguments.status = 'createPid'
    const createPidQueryParameters = this.createQueryParameters(parameterArguments)
    parameterArguments.status = 'onHold'
    const onHoldQueryParameters = this.createQueryParameters(parameterArguments)
    parameterArguments.status = 'finished'
    const finishedQueryParameters = this.createQueryParameters(parameterArguments)

    /* project status: waiting */
    parameterArguments.vcType = 'vcExecution'
    parameterArguments.status = 'waiting'
    const waitingQueryParameters = this.createQueryParameters(parameterArguments)
    parameterArguments.status = 'execution'
    const waitingQueryParametersDisabled = this.createQueryParameters(parameterArguments)
    waitingQueryParametersDisabled.planning_disabled = true
    waitingQueryParametersDisabled.include_muted = false
    const waitingQueryParametersMuted = this.createQueryParameters(parameterArguments)
    waitingQueryParametersMuted.planning_disabled = false
    waitingQueryParametersMuted.include_muted = true
    /* project status: waiting */

    let results
    if (this.state.vcInput && !this.state.vc.length && !this.state.ownProjectsChecked) {
      results = [{ amount: 0 }, { amount: 0 }, { amount: 0 }, { amount: 0 }, { amount: 0 }, { amount: 0 }, { amount: 0 }, { amount: 0 }, { amount: 0 }]
    } else {
      await Promise.all([
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(newQueryParameters).map(key => `${key}=${encodeURI(newQueryParameters[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(designQueryParameters).map(key => `${key}=${encodeURI(designQueryParameters[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(executionQueryParameters).map(key => `${key}=${encodeURI(executionQueryParameters[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(createPidQueryParameters).map(key => `${key}=${encodeURI(createPidQueryParameters[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(waitingQueryParameters).map(key => `${key}=${encodeURI(waitingQueryParameters[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(waitingQueryParametersDisabled).map(key => `${key}=${encodeURI(waitingQueryParametersDisabled[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(waitingQueryParametersMuted).map(key => `${key}=${encodeURI(waitingQueryParametersMuted[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(onHoldQueryParameters).map(key => `${key}=${encodeURI(onHoldQueryParameters[key])}`).join('&')}`, props.token.token),
        xmlGetRequest(`${location.origin}/v1/project/getv2?countOnly=true&${Object.keys(finishedQueryParameters).map(key => `${key}=${encodeURI(finishedQueryParameters[key])}`).join('&')}`, props.token.token)
      ])
        .then(responses => {
          results = responses
        })
        .catch(error => {
          console.error(error)
        })
    }

    props.setFilterCount({
      new: results[0].amount,
      design: results[1].amount,
      execution: results[2].amount,
      createPid: results[3].amount,
      waiting: (results[4].amount || 0) + (results[5].amount || 0) + (results[6].amount || 0),
      onHold: results[7].amount,
      finished: results[8].amount
    })
  }

  fetchProjects (props = this.props) {
    if (props.type === 'planning') {
      return this.fetchPlannings(props)
    }

    const myRequestId = new Date()
    this.latestRequestId = myRequestId

    if (props.setFilterCount) {
      this.getABunchOfCounts(props)
    }

    if (this.state.vcInput && !this.state.vc.length && !this.state.ownProjectsChecked) {
      this.props.updateProjects(null, true)
      setTimeout(() => {
        this.props.updateProjects({})
      }, 0)
      return
    }

    if (this.state.projectsStore[props.status]) {
      props.updateProjects(null, true)
    } else {
      props.updateProjects()
    }

    const status = props.status || 'execution'
    const token = props.token.token

    const queryParameterOptions = {
      status: props.status,
      sort: props.sort,
      queryOverride: props.queryOverride,
      queryRemove: props.queryRemove,
      user: props.user
    }
    if (status === 'waiting') {
      queryParameterOptions.vcType = 'vcExecution'
    }
    const queryParameters = this.createQueryParameters(queryParameterOptions)

    let url = `${location.origin}/v1/project/getv2?${Object.keys(queryParameters).map(key => `${key}=${encodeURI(queryParameters[key])}`).join('&')}`

    xmlGetRequest(url, token).then(response => {
      if (this.isUnmounting) {
        return
      }

      if (myRequestId !== this.latestRequestId) {
        return
      }

      if (status !== 'waiting') {
        this.setState(state => {
          if (!response.results || !response.results.length) { // if there are no more results -> turn off the scroll listener
            $(window).off('.projectsScroll')
            window.dispatchEvent(new window.CustomEvent('removeScrollListener'))
          }

          const newProjectsArray = ((state.projectsStore[status] || {}).projects || []).concat(response.results || [])

          const newProjectsResponse = {
            amount: response.amount,
            index: response.indexStop,
            projects: newProjectsArray
          }

          return {
            projectsStore: Object.assign(
              {},
              state.projectsStore,
              { [status]: newProjectsResponse }
            )
          }
        }, () => {
          props.updateProjects(this.state.projectsStore)
        })
      } else {
        queryParameters.status = 'execution'
        queryParameters.planning_disabled = true
        queryParameters.include_muted = false
        url = `${location.origin}/v1/project/getv2?${Object.keys(queryParameters).map(key => `${key}=${encodeURI(queryParameters[key])}`).join('&')}`
        xmlGetRequest(url, token).then(planningDisabledResponse => {
          if (myRequestId !== this.latestRequestId) {
            return
          }

          queryParameters.planning_disabled = false
          queryParameters.include_muted = true
          url = `${location.origin}/v1/project/getv2?${Object.keys(queryParameters).map(key => `${key}=${encodeURI(queryParameters[key])}`).join('&')}`
          xmlGetRequest(url, token).then(includeMutedResponse => {
            if (myRequestId !== this.latestRequestId) {
              return
            }

            this.setState(state => {
              const combinedProjects = ((state.projectsStore.waiting || {}).projects || []).concat(response.results || [], planningDisabledResponse.results || [], includeMutedResponse.results || [])
              const combinedResponses = {
                amount: response.amount + planningDisabledResponse.amount + includeMutedResponse.amount,
                index: response.indexStop,
                projects: combinedProjects
              }

              if (((state.projectsStore.waiting || {}).projects || []).length === combinedResponses.projects.length) { // if there are no more results -> turn off the scroll listener
                $(window).off('.projectsScroll')
                window.dispatchEvent(new window.CustomEvent('removeScrollListener'))
              }

              return {
                projectsStore: Object.assign(
                  {},
                  state.projectsStore,
                  { waiting: combinedResponses }
                )
              }
            }, () => {
              props.updateProjects(this.state.projectsStore)
            })
          }).catch(error => {
            console.error(error)
          })
        }).catch(error => {
          console.error(error)
        })
      }
    }).catch(error => {
      console.error(error)
    })
  }

  fetchPlannings (props = this.props) {
    if (props.type === 'project') {
      return this.fetchProjects(props)
    }

    props.updateProjects()

    const status = props.status

    const queryParameters = {}

    if (status === 'my') {
      queryParameters.user = props.user._id
    } else if (status === 'planned') {
      if (this.state.vc.length) {
        queryParameters.user = this.state.vc.join(',')
      } else {
        queryParameters.all = true
      }
    }

    const url = `${location.origin}/v1/planning?${Object.keys(queryParameters).map(key => `${key}=${encodeURI(queryParameters[key])}`).join('&')}`

    xmlGetRequest(url, props.token.token).then(plannings => {
      if (this.isUnmounting) {
        return
      }

      this.setState(state => {
        const newPlannings = {
          [status]: plannings
        }
        if (status === 'my') {
          newPlannings.planned = null
        } else if (status === 'planned') {
          newPlannings.my = null
        }
        return {
          projectsStore: Object.assign(
            {},
            state.projectsStore,
            newPlannings
          )
        }
      }, () => {
        props.updateProjects(this.state.projectsStore)
      })
    }).catch(error => {
      console.error(error)
    })
  }

  async createCSV () {
    const props = this.props
    const t = props.t

    this.setState(state => ({
      loadingCsv: true
    }))

    const projectsStore = this.state.projectsStore[props.status] || {}
    let projects = projectsStore.projects
    const token = props.token.token

    // fetch remaining projects with current query
    if (projects.length !== projectsStore.amount) {
      if (props.status !== 'waiting') {
        const lastIndex = projectsStore.index || 0
        const pagesLeft = Math.ceil((projectsStore.amount - projects.length) / pageSize)
        const queryParameters = this.createQueryParameters({
          status: props.status,
          sort: props.sort,
          queryOverride: props.queryOverride,
          queryRemove: props.queryRemove,
          user: props.user
        })

        let i = 0
        let url
        const promises = []
        while (i < pagesLeft) {
          queryParameters.skip = lastIndex + (i * pageSize)
          url = `${location.origin}/v1/project/getv2?${Object.keys(queryParameters).map(key => `${key}=${encodeURI(queryParameters[key])}`).join('&')}`
          promises.push(xmlGetRequest(url, token))
          i++
        }
        try {
          const results = await Promise.all(promises)
          projects = results.reduce((accumulator, currentValue) => accumulator.concat(currentValue.results || []), projects)
        } catch (error) {
          console.error(error)
          return
        }
      } else { // queries for projects with waiting status are different
        const queryParameters = this.createQueryParameters({
          status: props.status,
          sort: props.sort,
          queryOverride: props.queryOverride,
          queryRemove: props.queryRemove,
          user: props.user,
          vcType: 'vcExecution'
        })
        const queryParametersDisabled = Object.assign({}, queryParameters, {
          status: 'execution',
          planning_disabled: true,
          include_muted: false
        })
        const queryParametersMuted = Object.assign({}, queryParameters, {
          status: 'execution',
          planning_disabled: false,
          include_muted: true
        })

        const myPageSize = pageSize / 3

        const lastIndex = projectsStore.index || 0
        const pagesLeft = Math.ceil((projectsStore.amount - projects.length) / myPageSize)

        const promises = []
        const promisesDisabled = []
        const promisesMuted = []

        let i = 0
        let url, urlDisabled, urlMuted
        // const promises = []
        while (i < pagesLeft) {
          queryParameters.skip = lastIndex + (i * myPageSize)
          queryParametersDisabled.skip = lastIndex + (i * myPageSize)
          queryParametersMuted.skip = lastIndex + (i * myPageSize)
          url = `${location.origin}/v1/project/getv2?${Object.keys(queryParameters).map(key => `${key}=${encodeURI(queryParameters[key])}`).join('&')}`
          urlDisabled = `${location.origin}/v1/project/getv2?${Object.keys(queryParametersDisabled).map(key => `${key}=${encodeURI(queryParametersDisabled[key])}`).join('&')}`
          urlMuted = `${location.origin}/v1/project/getv2?${Object.keys(queryParametersMuted).map(key => `${key}=${encodeURI(queryParametersMuted[key])}`).join('&')}`
          promises.push(xmlGetRequest(url, token))
          promisesDisabled.push(xmlGetRequest(urlDisabled, token))
          promisesMuted.push(xmlGetRequest(urlMuted, token))
          i++
        }
        try {
          const results = await Promise.all(promises)
          const resultsDisabled = await Promise.all(promisesDisabled)
          const resultsMuted = await Promise.all(promisesMuted)
          projects = results.reduce((accumulator, currentValue) => accumulator.concat(currentValue.results || []), projects)
          projects = resultsDisabled.reduce((accumulator, currentValue) => accumulator.concat(currentValue.results || []), projects)
          projects = resultsMuted.reduce((accumulator, currentValue) => accumulator.concat(currentValue.results || []), projects)
        } catch (error) {
          console.error(error)
          return
        }
      }
    }

    if (window.Map) { // remove any duplicates
      const uniqueProjects = new Map(projects.map(project => [project._id, project]))
      projects = [...uniqueProjects.values()]
    }

    let csvKeys
    if (!this.props.planningPage) {
      csvKeys = [
        t('project:project_number_short'),
        t('project:title'),
        t('project:type_frequency'),
        t('project:safety_coordinator_design'),
        t('project:safety_coordinator_execution'),
        t('project:client'),
        t('project:street'),
        t('project:housenumber'),
        t('project:postalcode'),
        t('project:placename'),
        t('project:start_date'),
        t('project:latest_visit'),
        t('project:next_visit'),
        t('project:next_planning'),
        t('project:delayed_until'),
        t('project:boost'),
        t('project:progress'),
        t('project:progress_description'),
        t('project:max_visits'),
        t('project:max_months'),
        t('project:vgp_saved'),
        t('project:administration'),
        t('project:system'),
        t('project:notes'),
        t('project:fCode')
      ]
    } else {
      csvKeys = [
        t('project:project_number_short'),
        t('project:title'),
        t('project:type_frequency'),
        t('project:safety_coordinator_design'),
        t('project:safety_coordinator_execution'),
        t('project:start_date'),
        t('project:next_visit'),
        t('project:delayed_until')
      ]
    }

    if (props.status === 'createPid') {
      csvKeys = [
        t('project:project_number_short'),
        t('project:title'),
        t('project:safety_coordinator_design'),
        t('project:safety_coordinator_execution'),
        t('project:start_date'),
        t('project:create_pid_start_date'),
        t('project:1st_mail_pid'),
        t('project:2nd_mail_pid'),
        t('project:3rd_mail_pid'),
        t('project:pid_sent'),
        t('project:notes'),
        t('project:pidnotes')
      ]
    }

    const csvArray = [csvKeys.join(';')]

    let project, mappedObject, value, address, vgpFile
    const getValue = key => {
      value = mappedObject[key]
      if (value && value.indexOf && ~value.indexOf(';')) {
        return `"${value}"`
      }
      return value
    }

    for (let i = 0, l = projects.length; i < l; i++) {
      project = projects[i]
      address = project.address || {}

      vgpFile = (project.files.find(file => !file.deleted && file.file_type === 'vgp') || {}).file

      mappedObject = {
        [t('project:project_number_short')]: project.afas_id,
        [t('project:boost')]: project.boosted,
        [t('project:client')]: project.client_name,
        [t('project:street')]: address.street || '',
        [t('project:housenumber')]: address.housenumber || '',
        [t('project:postalcode')]: address.zipcode || '',
        [t('project:placename')]: address.city || '',
        [t('project:latest_visit')]: project.last_visit ? moment(project.last_visit).format('YYYY-MM-DD') : '',
        [t('project:next_visit')]: project.next_visit ? moment(project.next_visit).format('YYYY-MM-DD') : '',
        [t('project:title')]: (project.ss_name || project.project_name).replace(/\n/g, ''),
        [t('project:start_date')]: project.start_date,
        [t('project:type_frequency')]: formatInterval(project.type),
        [t('project:next_planning')]: project.planning ? moment(project.planning.planned_date).format('YYYY-MM-DD') : '',
        [t('project:delayed_until')]: project.muted ? moment(project.muted).format('YYYY-MM-DD') : '',
        [t('project:progress')]: getProgressValue('percent', project.progress || '1'),
        [t('project:progress_description')]: getProgressValue('name', project.progress || '1'),
        [t('project:max_visits')]: project.contracted_visits,
        [t('project:max_months')]: project.contracted_duration,
        [t('project:vgp_saved')]: vgpFile ? moment(vgpFile.updated_at).format('YYYY-MM-DD') : '',
        [t('project:administration')]: getAdministration(project.administration),
        [t('project:system')]: getSystem(project.site_id),
        [t('project:notes')]: '"' + (project.comment || '').replace(/\n/g, '\\n') + '"',
        [t('project:fCode')]: project.fCode || ''
      }

      if (props.status === 'createPid') {
        mappedObject[t('project:1st_mail_pid')] = getPidMailDate(project.pidMailDates, 'pidDate1')
        mappedObject[t('project:2nd_mail_pid')] = getPidMailDate(project.pidMailDates, 'pidDate2')
        mappedObject[t('project:3rd_mail_pid')] = getPidMailDate(project.pidMailDates, 'pidDate3')
        mappedObject[t('project:pid_sent')] = getPidMailDate(project.pidMailDates, 'pidDateSent')
        mappedObject[t('project:create_pid_start_date')] = project.createPidStartDate ? moment(project.createPidStartDate).format('YYYY-MM-DD') : ''
        mappedObject[t('project:notes')] = '"' + (project.comment || '').replace(/\n/g, '\\n') + '"'
        mappedObject[t('project:pidnotes')] = '"' + (project.pidComment || '').replace(/\n/g, '\\n') + '"'
      }

      const vcExecution = (project.users || []).find(user => user.type === 'vcExecution')
      const vcDesign = (project.users || []).find(user => user.type === 'vcDesign')
      if (vcExecution) {
        mappedObject[t('project:safety_coordinator_execution')] = vcExecution.user.name
      }
      if (vcDesign) {
        mappedObject[t('project:safety_coordinator_design')] = vcDesign.user.name
      }

      csvArray.push(
        csvKeys.map(getValue).join(';')
      )
    }

    const csvString = csvArray.join('\n')

    const csvLink = document.createElement('a')
    csvLink.href = 'data:text/csvcharset=utf-8,' + encodeURI(csvString)
    csvLink.download = `${moment().format('DD_MM_YYYY_HH_mm_')}projecten_export.csv`
    document.body.appendChild(csvLink)
    csvLink.click()
    document.body.removeChild(csvLink)

    this.setState(state => ({
      loadingCsv: false
    }))
  }

  render () {
    const { state, props } = this

    const currentFilterCount = (state.projectsStore[props.status] || {}).amount || 0
    let maxCount
    if (props.showProjectCount !== false) {
      maxCount = props.projectsCount[props.status] || 0
    }

    const filterConfiguration = new Set()
    if (!props.planningPage) {
      ['name', 'vc', 'client', 'own', 'visit', 'export'].forEach(item => filterConfiguration.add(item)) // we're not using new Set(iterable) because ie11 doesn't support it (according to mdn, even though it does appear to work in a virtual windows with ie11)
    } else {
      /* eslint-disable */
      switch (props.status) {
        default:
        case 'main':
          ['name', 'own', 'vc', 'visit', 'export'].forEach(item => filterConfiguration.add(item))
          break

        case 'my':
          [].forEach(item => filterConfiguration.add(item))
          break

        case 'planned':
          ['vc'].forEach(item => filterConfiguration.add(item))
          break
      }
      /* eslint-enable */
    }
    if (this.isFreelancer) {
      filterConfiguration.delete('vc')
      filterConfiguration.delete('visit')
    }

    if (props.planningPage && props.status === 'my') {
      return <div></div>
    }

    return (
      <div className="filter-container">
        <div className="filter-title-container">
          <span className="filter-title"><T i18nKey="project:filter">Filter</T></span>
          {props.showProjectCount !== false &&
            <span className="amount">{currentFilterCount}/{maxCount}</span>
          }
        </div>

        { filterConfiguration.has('name') &&
          <div className="filter-input-container">
            <label className="filter-label" htmlFor="filterTitleInput"><T i18nKey="project:number_or_title">Nr. of Titel</T></label>
            <input id="filterTitleInput" className={state.searchInput ? 'filter-input filter-input-with-content' : 'filter-input'} onChange={e => this.handleChange('searchInput', e.target.value)} value={state.searchInput} />
            {state.searchInput && <span onClick={() => this.handleChange('searchInput', '')} className="filter-input-close"><span className="glyphicons glyphicons-remove"></span></span>}
          </div>
        }

        { filterConfiguration.has('vc') &&
          <div className="filter-input-container">
            <label className="filter-label" htmlFor="filterVcInput"><T i18nKey="project:vc">VC</T></label>
            <input id="filterVcInput" className={state.vcInput ? 'filter-input filter-input-with-content' : 'filter-input'} onChange={e => this.handleVCChange(e.target.value)} value={state.vcInput} />
            {state.vcInput && <span onClick={() => this.handleChange('vcInput', '')} className="filter-input-close"><span className="glyphicons glyphicons-remove"></span></span>}
          </div>
        }

        { filterConfiguration.has('client') &&
          <div className="filter-input-container">
            <label className="filter-label" htmlFor="filterClientInput"><T i18nKey="project:client">Klant</T></label>
            <input id="filterClientInput" className={state.clientInput ? 'filter-input filter-input-with-content' : 'filter-input'} onChange={e => this.handleChange('clientInput', e.target.value)} value={state.clientInput} />
            {state.clientInput && <span onClick={() => this.handleChange('clientInput', '')} className="filter-input-close"><span className="glyphicons glyphicons-remove"></span></span>}
          </div>
        }

        { filterConfiguration.has('own') && hasPermissionFor(props.user.role, 'EMPLOYEE') &&
          <div className="filter-input-container">
            <div className="toggle_switch">
              <div className="filter-label"><T i18nKey="project:own_projects">Eigen Werven</T></div>
              <input className="toggle" id="filterOwnProjects" type="checkbox" checked={state.ownProjectsChecked} onChange={this.toggleOwnProjects} />
              <label htmlFor="filterOwnProjects"></label>
            </div>
          </div>
        }

        { filterConfiguration.has('visit') &&
          <div className="filter-input-container">
            <div className="toggle_switch">
              <div className="filter-label"><T i18nKey="project:last_visited_more_than_6_weeks_ago">Laatst bezocht ouder dan 6 weken</T></div>
              <input className="toggle" id="filterStaleProjects" type="checkbox" checked={state.staleProjectsChecked} onChange={this.toggleStaleProjects} />
              <label htmlFor="filterStaleProjects"></label>
            </div>
          </div>
        }

        { filterConfiguration.has('export') &&
          <button className="filter-button" onClick={this.createCSV}>
            {state.loadingCsv
              ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
              : <T i18nKey="project:csv_export">CSV Export</T>
            }
          </button>
        }
      </div>
    )
  }
}
const Filter = withTranslation()(FilterView)

export default withRouter(Filter)
