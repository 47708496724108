import React, { Component } from 'react'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class Dropbox extends Component {
  constructor (props) {
    super(props)

    this.droppableAreaRef = React.createRef()
  }

  componentDidMount () {
    const $dropbox = $(this.droppableAreaRef.current)
    // project droppable
    $dropbox.droppable({
      // accept: `.draggableProject_${this.props.due}`,
      tolerance: 'pointer',
      activate: (event, ui) => {
        $dropbox.addClass('dropbox_droppable')
      },
      deactivate: (event, ui) => {
        $dropbox.removeClass('dropbox_droppable')
      }
    })
  }

  render () {
    return (
      <div className="dropbox">
        <div ref={this.droppableAreaRef} id="planning_dropbox">
          <p><T i18nKey="planning:my_planning">Mijn planning</T></p>
        </div>
      </div>
    )
  }
}
