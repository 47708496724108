import React, { Component } from 'react'
import { QuickActions } from '../quickactions.js'
import _ from 'underscore'
import moment from 'moment'
import { createLabel } from '../../reactUtils/labelFactory.jsx'
import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class ProjectsTableRowView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      displayBoost: this.props.project.boosted
    }

    this.updateBoostStatus = this.updateBoostStatus.bind(this)
    this.toggleBoostProject = this.toggleBoostProject.bind(this)
    this.updateProject = this.updateProject.bind(this)
    this.gotoProject = this.gotoProject.bind(this)
    this.gotoProjectWizard = this.gotoProjectWizard.bind(this)
  }

  componentWillMount () {
    this.initUpdateBoostListener()
  }

  componentWillUnmount () {
    $(window).off('updateBoostStatus')
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (nextState.displayBoost !== this.state.displayBoost) {
      return true
    }
    return false
  }

  gotoProject (event) {
    this.props.history.push(`/werf/${event.currentTarget.id}`)
  }

  gotoProjectWizard (event) {
    this.props.history.push(`/werf_wizard/${event.currentTarget.id}?new=true`)
  }

  initUpdateBoostListener () {
    $(window).on('updateBoostStatus', (event, id) => {
      this.updateBoostStatus(id)
    })
  }

  updateBoostStatus (id) {
    const boosted = this.props.project.boosted
    this.setState({
      displayBoost: !boosted
    })
  }

  toggleBoostProject (event) {
    const projectBoostStatus = !this.state.displayBoost
    this.updateProject({ boosted: projectBoostStatus })
      .done((response) => {
        this.setState({
          displayBoost: projectBoostStatus
        })
      })
      .fail(function (error) {
        console.error(error)
      })
  }

  updateProject (newData) {
    const projectId = this.props.project._id
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${projectId}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(newData)
    }
    return $.ajax(settings)
  }

  prepareForOther (projectStatus) {
    const project = this.props.project
    const t = this.props.t

    const organisationImgSrc = `/v1/file/image/${project.client_id}_icon`
    const vcExecution = _.find(project.users, function (user) {
      return user.type === 'vcExecution'
    })
    let vc1Image
    if (vcExecution && typeof vcExecution.user === 'object') {
      vc1Image = vcExecution.user.profile_picture_icon_url || '/images/default_user_icon.png'
    } else {
      vc1Image = '/images/default_user_icon.png'
    }

    let dynamicDateField
    if (projectStatus === 'execution') {
      // laatst bezocht
      let lastVisit = project.last_visit
      if (!lastVisit) {
        lastVisit = t('project:not_visited')
      } else {
        lastVisit = moment(lastVisit).startOf('day')
        const today = moment().startOf('day')
        lastVisit = today.diff(lastVisit, 'days')
        if (lastVisit === 0) {
          lastVisit = t('project:today')
        } else if (lastVisit !== 1) {
          lastVisit = t('project:amount_days_ago', { days: lastVisit })
        } else {
          lastVisit = t('project:1_day_ago')
        }
      }
      dynamicDateField = lastVisit
    } else if (projectStatus === 'waiting') {
      dynamicDateField = project.start_date
      if (!dynamicDateField) {
        dynamicDateField = t('project:no_start_date')
      }
    } else if (projectStatus === 'finished') {
      dynamicDateField = project.finished_date
      if (!dynamicDateField) {
        dynamicDateField = t('project:no_end_date')
      }
    } else if (projectStatus === 'onHold') {
      dynamicDateField = project.onHoldUntilAtLeastDate
      if (!dynamicDateField) {
        dynamicDateField = t('project:no_date')
      }
    }

    let projectName = project.ss_name || project.project_name || t('project:nameless_project')
    if (projectName.length > 60) {
      projectName = projectName.slice(0, 55) + '...'
    }

    let projectSpecialStatusIcon = null
    if (project.planning) {
      let labelColor
      let extraGlypicon = null
      const plannedDate = moment(project.planning.planned_date).startOf('d')
      const nextDate = moment(project.muted || project.next_visit).startOf('d')
      if (plannedDate.isAfter(nextDate)) {
        labelColor = 'red'
        extraGlypicon = <span className="glyphicons glyphicons-exclamation-sign"></span>
      } else {
        labelColor = 'green'
      }
      if (moment().startOf('d').isAfter(nextDate)) {
        labelColor = 'red'
        extraGlypicon = <span className="glyphicons glyphicons-exclamation-sign"></span>
      }
      projectSpecialStatusIcon = <span className={`label ${labelColor}`}><T i18nKey="project:planned">Ingepland</T> {extraGlypicon}</span>
    } else if (this.state.displayBoost) {
      projectSpecialStatusIcon = <span className="glyphicons glyphicons-warning-sign red"></span>
    } else if (project.muted) {
      projectSpecialStatusIcon = <span className="label blue"><T i18nKey="project:delayed">Uitgesteld</T></span>
    }

    if (project.planning_disabled) {
      projectSpecialStatusIcon = <span className="label orange"><span className="glyphicons glyphicons-turtle"></span></span>
    }

    return (
      <tr className="projects_table_row" onClick={this.gotoProject} id={project._id}>
        <td>{project.afas_id}</td>
        <td>
          {projectSpecialStatusIcon} {projectName}
        </td>
        <td style={{ textAlign: 'right', paddingRight: '0' }}>{this.props.userCanSeeVisitLabel ? createLabel(project.type) : null}</td>
        <td>{project.client_name
          ? <div onMouseEnter={this.props.expandPicture} onMouseLeave={this.props.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${organisationImgSrc}`} className="profile_pic" alt={project.client_name || '-'} />
          </div>
          : '-'}
        </td>
        <td>{(vcExecution && typeof vcExecution.user === 'object')
          ? <div onMouseEnter={this.props.expandPicture} onMouseLeave={this.props.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${vc1Image}`} className="profile_pic" alt={vcExecution.user.name || '-'} />
          </div>
          : '-'}
        </td>
        <td>{dynamicDateField}</td>
        <QuickActions token={this.props.token} user={this.props.user} project={project} updateProject={this.updateProject} toggleBoostProject={this.toggleBoostProject} boosted={this.state.displayBoost} mute={true} />
      </tr>
    )
  }

  prepareForDesign () {
    const project = this.props.project
    const t = this.props.t

    const organisationImgSrc = `/v1/file/image/${project.client_id}_icon`
    const vcDesign = _.find(project.users, function (user) {
      return user.type === 'vcDesign'
    })
    let vc1Image
    if (vcDesign && typeof vcDesign.user === 'object') {
      vc1Image = vcDesign.user.profile_picture_icon_url || '/images/default_user_icon.png'
    } else {
      vc1Image = '/images/default_user_icon.png'
    }

    let projectName = project.ss_name || project.project_name || t('project:nameless_project')
    if (projectName.length > 60) {
      projectName = projectName.slice(0, 55) + '...'
    }

    return (
      <tr className="projects_table_row" onClick={this.gotoProject} id={project._id}>
        <td>{project.afas_id}</td>
        <td>{projectName}</td>
        <td style={{ textAlign: 'right', paddingRight: '0' }}>{this.props.userCanSeeVisitLabel ? createLabel(project.type) : null}</td>
        <td>{project.client_name
          ? <div onMouseEnter={this.props.expandPicture} onMouseLeave={this.props.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${organisationImgSrc}`} className="profile_pic" alt={project.client_name || '-'} />
          </div>
          : '-'}
        </td>
        <td>{(vcDesign && typeof vcDesign.user === 'object')
          ? <div onMouseEnter={this.props.expandPicture} onMouseLeave={this.props.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${vc1Image}`} className="profile_pic" alt={vcDesign.user.name || '-'} />
          </div>
          : '-'}
        </td>
        <QuickActions token={this.props.token} user={this.props.user} project={project} updateProject={this.updateProject} toggleBoostProject={this.toggleBoostProject} boosted={this.state.displayBoost} mute={true} />
      </tr>
    )
  }

  prepareForNew () {
    const project = this.props.project
    const t = this.props.t
    let projectName = project.ss_name || project.project_name || t('project:nameless_project')
    if (projectName.length > 120) {
      projectName = projectName.slice(0, 115) + '...'
    }
    return (
      <tr onClick={this.gotoProjectWizard} id={project._id}>
        <td>{project.afas_id}</td>
        <td>
          {projectName}
        </td>
      </tr>
    )
  }

  render () {
    const projectStatus = this.props.projectStatus
    let projectRow
    if (this.props.projectStatus === 'new') {
      projectRow = this.prepareForNew()
    } else if (this.props.projectStatus === 'design') {
      projectRow = this.prepareForDesign(projectStatus)
    } else {
      projectRow = this.prepareForOther(projectStatus)
    }
    return projectRow
  }
}

const ProjectsTableRow = withTranslation()(ProjectsTableRowView)
export { ProjectsTableRow }
