import React, { Component } from 'react'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class RecoverPasswordView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      result: 0,
      error: false,
      email: null,
      loading: false
    }

    this.submitForm = this.submitForm.bind(this)
  }

  componentWillMount () {
    document.title = this.props.t('title:recover_password')
  }

  submitForm (event) {
    event.preventDefault()
    this.setState({
      loading: true
    })

    const email = $('#email').val().toLowerCase()

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/auth/recover_password?email=${email}`,
      method: 'GET'
    }

    $.ajax(settings).done(response => {
      this.setState({
        result: response.result,
        email: email,
        loading: false
      })
    }).fail(error => {
      console.error(error)
      this.setState({
        loading: false,
        error: error.responseJSON.message
      })
    })
  }

  render () {
    return (
      <section className="container form">
        {this.state.result === 1
          ? <div className="message success">
            <div className="message_icon">
              <span className="glyphicons glyphicons-ok-sign"></span>
            </div>
            <T i18nKey="login:recovery_message" values={{ email: this.state.email }}>Een email werd verstuurd naar <strong>{this.state.email}</strong> met verdere instructies.</T>
          </div>
          : <div>
            {this.state.result === 2
              ? <div className="message warning">
                <div className="message_icon">
                  <span className="glyphicons glyphicons-exclamation-sign"></span>
                </div>
                {this.state.result === 2 ? 'Er werd geen gebruiker gevonden met dit email adres, kijk na of u geen typfouten heeft gemaakt of neem contact op met de webmaster.' : 'Multiple users with this email found, please inform an administrator about this.'}
              </div>
              : null}
            <form onSubmit={this.submitForm}>
              <fieldset className="form-group">
                <label htmlFor="email"><T i18nKey="login:recovery_email">Verstuur je Email. Je zal daarna een link ontvangen in je mailbox, welke je toelaat je paswoord aan te maken of te veranderen.</T></label>
                <input type="email" className="form-control" id="email" placeholder={this.props.t('common:email')} />
              </fieldset>
              <fieldset className="form-group">
                <button type="submit" className="form-submit">
                  {this.state.loading
                    ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                    : null}
                            &nbsp;<T i18nKey="login:send">Versturen</T>
                </button>
              </fieldset>
            </form>
          </div>}
      </section>
    )
  }
}

const RecoverPassword = withTranslation()(RecoverPasswordView)
export { RecoverPassword }
