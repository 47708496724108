import React, { Component } from 'react'
import { ProjectsTableRow } from './rows.jsx'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class ProjectsTable extends Component {
  expandPicture (event) {
    const $target = $(event.currentTarget)
    $target.addClass('active')
    const alt = $target.find('img')[0].alt
    const newDiv = $('<div>').html(alt).addClass('profile_pic_name')
    $(event.currentTarget).before(newDiv)
  }

  withdrawPicture (event) {
    const $target = $(event.currentTarget)
    $target.removeClass('active')
    $target.prev('.profile_pic_name').remove()
  }

  sortList (value) {
    $(window).trigger('getSortedData', value)
  }

  showSortIcon (sortType) {
    const sortState = this.props.sortState
    if (sortType === sortState) {
      return (
        <span className="glyphicons glyphicons-sort-by-order"></span>
      )
    }
    if (sortType === sortState.slice(1)) {
      return (
        <span className="glyphicons glyphicons-sort-by-order-alt"></span>
      )
    }
  }

  render () {
    const projects = this.props.projects || []
    const loadingRow = (
      <tr>
        <td colSpan="10"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
      </tr>
    )
    const tableRows = projects.map((project) => {
      return <ProjectsTableRow history={this.props.history} key={project._id} user={this.props.user} token={this.props.token} project={project} expandPicture={this.expandPicture} withdrawPicture={this.withdrawPicture} />
    })

    return (
      <section className='projects_table list_block'>
        <h3><T i18nKey="project:projects">Projecten</T> <span className="amount">{this.props.projectsCount}</span></h3>
        <table className="list_table">
          <thead>
            <tr>
              <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables'><T i18nKey="project:project_number_short">Project nr.</T> {this.showSortIcon('afas_id')}</th>
              <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
              <th style={{ width: '30px' }}><T i18nKey="project:client">Klant</T></th>
              <th style={{ width: '40px' }}><T i18nKey="project:vc">VC</T></th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
            {this.props.loading
              ? loadingRow
              : null
            }
          </tbody>
        </table>
      </section>
    )
  }
}
