import React, { Component } from 'react'
import { MyPlanning } from './myPlanning/myPlanning.jsx'
import { MainPlanning } from './toPlan/planning.jsx'
import { PlannedProjectsOverview } from './planned/overviewPlanning.jsx'
import Filter from '../filter/filter.js'
import { Trans as T, withTranslation } from 'react-i18next'

class PlanningView extends Component {
  componentWillMount () {
    document.title = this.props.t('title:planning')
  }

  constructor (props) {
    super(props)

    this.state = {
      pageState: (props.location.state || {}).activeTab || 'main',
      projects: [],
      plannings: []
    }

    this.updateProjects = this.updateProjects.bind(this)
    this.updatePlannings = this.updatePlannings.bind(this)
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  changePageState (newState) {
    if (!this.isUnmounting) {
      this.setState({
        pageState: newState
      })
      this.props.history.replace({
        pathname: '/planning',
        state: {
          activeTab: newState
        }
      })
    }
  }

  addActiveClass (type) {
    const pageState = this.state.pageState
    if (pageState === type) {
      return 'active'
    } else {
      return ''
    }
  }

  updateProjects (projects, onlyLoading) {
    if (this.isUnmounting) { return }

    if (onlyLoading) {
      return this.setState(state => ({
        loading: true
      }))
    }

    if (!projects) {
      return this.setState(state => ({
        projects: [],
        loading: true
      }))
    }

    this.setState(state => ({
      projects: (projects.execution || {}).projects || [],
      loading: false
    }))
  }

  updatePlannings (plannings, onlyLoading) {
    if (this.isUnmounting) { return }

    if (onlyLoading) {
      return this.setState(state => ({
        loading: true
      }))
    }

    if (!plannings) {
      return this.setState(state => ({
        plannings: [],
        loading: true
      }))
    }

    this.setState(state => ({
      plannings: plannings || [],
      loading: false
    }))
  }

  render () {
    let planningView
    if (this.state.pageState === 'main') {
      planningView = <MainPlanning {...this.props} loading={this.state.loading} projects={this.state.projects} />
    } else if (this.state.pageState === 'my') {
      planningView = <MyPlanning {...this.props} projects={this.state.plannings.my || []} loading={this.state.loading} />
    } else {
      planningView = <PlannedProjectsOverview {...this.props} projects={this.state.plannings.planned || []} loading={this.state.loading} />
    }
    let adminOverviewButton = null
    if (this.props.user.role.match('ADMIN')) {
      adminOverviewButton = <button onClick={this.changePageState.bind(this, 'planned')} className={this.addActiveClass('planned')}><T i18nKey="planning:planning_projects">Geplande werven</T></button>
    }
    return (
      <section className="container content projects_toplevel">
        <div className="top_tab top_tab_planning">
          <button onClick={this.changePageState.bind(this, 'main')} className={this.addActiveClass('main')}><T i18nKey="planning:projects_to_plan">In te plannen werven</T></button>
          <button onClick={this.changePageState.bind(this, 'my')} className={this.addActiveClass('my')}><T i18nKey="planning:my_planning">Mijn planning</T></button>
          {adminOverviewButton}
        </div>
        <div className="planning-container">
          {this.state.pageState === 'main'
            ? <Filter
              token={this.props.token}
              status="execution"
              queryOverride={{
                planned: false,
                planning_disabled: false,
                limit: 0,
                select: ['ss_name', 'project_name', 'users', 'project_vc_passed_status', 'boosted', 'muted', 'next_visit', 'last_visit', 'start_date', 'type', 'project_id', 'afas_id', 'planning']
              }}
              queryRemove={{
                include_muted: true
              }}
              user={this.props.user}
              type="project"
              sort="next_visit"
              updateProjects={this.updateProjects}
              showProjectCount={false}
              planningPage={true}
            />
            : <Filter
              token={this.props.token}
              status={this.state.pageState}
              planningPage={true}
              user={this.props.user}
              type="planning"
              updateProjects={this.updatePlannings}
              showProjectCount={false}
            />
          }
          {planningView}
        </div>
      </section>
    )
  }
}

const Planning = withTranslation()(PlanningView)
export { Planning }
