import React, { Component } from 'react'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$
let autocomplete

class EditOrganisationView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      organisation: {
        _id: '',
        afas_id: null,
        name: null,
        address: {},
        updated_at: null,
        customer: null,
        website: null,
        phone_work: null,
        email_work: null,
        phone_mobile: null,
        ondernemersnummer: null,
        profile_picture_id: null,
        profile_picture_url: null,
        profile_picture_icon_url: null,
        profile_picture_thumbnail_url: null,
        profile_picture_large_url: null
      }
    }

    this.updateLocation = this.updateLocation.bind(this)
    this.changeValue = this.changeValue.bind(this)

    this.locationRef = React.createRef()
  }

  componentWillMount () {
    document.title = this.props.t('title:edit_organisation')
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/organisation/${this.props.match.params.id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    $.ajax(settings).done((response) => {
      if (!this.isUnmounting) {
        document.title = this.props.t('title:edit_organisation') + ` - ${response.name}`

        const address = response.address || {}
        let location = null
        if (address) {
          location = `${address.street ? address.street + ' ' : ''}${address.housenumber ? address.housenumber + ', ' : ''}${address.city ? address.city + ', ' : ''}${address.country || ''}`
          if (location.length < 10) {
            location = null
          }
        }
        this.locationRef.current.value = location

        this.setState({
          organisation: response,
          location
        })
      }
    })

    autocomplete = new window.google.maps.places.Autocomplete(
      this.locationRef.current,
      { types: ['geocode'] }
    )
    autocomplete.addListener('place_changed', this.updateLocation)
  }

  updateLocation (event) {
    const place = autocomplete.getPlace()
    const organisation = this.state.organisation
    const address = {}

    place.address_components.forEach(component => {
      component.types.forEach(type => {
        switch (type) {
          case 'route': address.street = component.long_name; break
          case 'postal_code': address.zipcode = component.long_name; break
          case 'country': address.country = component.long_name
            address.country_short = component.short_name; break
          case 'locality': address.city = component.long_name; break
          case 'street_number': address.housenumber = component.long_name; break
          default:
        };
      })
    })
    organisation.address = address
    this.setState({
      location: place.formatted_address,
      organisation
    })
  }

  changeValue (event) {
    const organisation = this.state.organisation
    organisation[event.currentTarget.id] = event.currentTarget.value

    this.setState({
      organisation
    })
  }

  submitChanges (event) {
    event.preventDefault()
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/organisation/${this.props.match.params.id}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      },
      data: this.state.organisation
    }
    const promise = $.ajax(settings)
    promise.done((response) => {
      delete window.dataCache.organisationsState
      this.props.history.goBack()
    })
    promise.fail((error) => {
      console.error(error)
      this.setState({
        error: error
      })
    })
  }

  render () {
    const organisation = this.state.organisation

    return (
      <section className="container form">
        {this.state.error
          ? <p>{this.state.error.responseText}</p>
          : ''}
        <form onSubmit={this.submitChanges}>
          <h3><T i18nKey="organisation:edit_information">Gegevens aanpassen</T></h3>
          <fieldset className="form-group">
            <label htmlFor="name"><T i18nKey="organisation:name">Naam</T></label>
            <input type="text" className="form-control" id="name" onChange={this.changeValue} value={organisation.name || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="location"><T i18nKey="organisation:address">Adres</T></label>
            <input ref={this.locationRef} type="text" className="form-control" id="locatie" />
            <div className="form-info">
              <h4><T i18nKey="organisation:address">Adres</T></h4>
              {this.state.location}<br /><br />
            </div>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="ondernemersnummer"><T i18nKey="organisation:vat">VAT</T></label>
            <input type="text" className="form-control" id="ondernemersnummer" onChange={this.changeValue} value={organisation.vat} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="email_work"><T i18nKey="organisation:email">Email</T></label>
            <input type="email" className="form-control" id="email_work" onChange={this.changeValue} value={organisation.email_work || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="website"><T i18nKey="organisation:website">Website</T></label>
            <input type="url" className="form-control" id="website" onChange={this.changeValue} value={organisation.website || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="phone_mobile"><T i18nKey="organisation:mobile">GSM nr</T></label>
            <input type="text" className="form-control" id="phone_mobile" onChange={this.changeValue} value={organisation.phone_mobile || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="phone_work"><T i18nKey="organisation:phone_work">Telefoon werk</T></label>
            <input type="text" className="form-control" id="phone_work" onChange={this.changeValue} value={organisation.phone_work || ''} />
          </fieldset>
          <fieldset className="form-group">
            <button type="submit" className="form-submit"><T i18nKey="common:save">Opslaan</T></button>
          </fieldset>
        </form>
      </section>
    )
  }
}

const EditOrganisation = withTranslation()(EditOrganisationView)
export { EditOrganisation }
