import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import DatePicker from 'react-datepicker'
import { getIntervalDays, calcNextDateByType, getIntervalOptions } from '../../projectTypeFunctions.js'
import location from '../../location'
import './projectWizard.css'
import { xmlRequest, hasPermissionFor } from '../../tools.jsx'
import { Trans as T, withTranslation, Translation as Translate } from 'react-i18next'
const $ = window.$
const place = window.place
const google = window.google

const formatResult = function (client) {
  if (client.loading) return client.text
  const markup = client.name
  return markup
}

const formatSelection = function (client) {
  return client.text
}

const initiateClientDropdown = function () {
  const token = localStorage.getItem('authToken')
  $('.js-remote-client-data').select2({
    ajax: {
      async: true,
      crossDomain: true,
      url: function (params) {
        return `${location.origin}/v1/organisation?search=${encodeURIComponent(params.term)}`
      },
      headers: {
        authorization: `Bearer ${token}`
      },
      delay: 250,
      processResults: function (data) {
        data = $.map(data, function (object) {
          object.id = object.afas_id
          object.text = object.name
          return object
        })
        return {
          results: data
        }
      },
      cache: true
    },
    escapeMarkup: function (markup) {
      return markup
    },
    minimumInputLength: 3,
    templateResult: formatResult,
    templateSelection: formatSelection
  })
}
const initiateVCDropdown = function () {
  const token = localStorage.getItem('authToken')
  $('.veiligheidsCoordinator').select2({
    ajax: {
      async: true,
      crossDomain: true,
      url: function (params) {
        return `${location.origin}/v1/user?search=${encodeURIComponent(params.term)}&vc=true`
      },
      headers: {
        authorization: `Bearer ${token}`
      },
      delay: 250,
      processResults: function (data) {
        data = $.map(data, function (object) {
          object.id = object._id
          object.text = object.name
          return object
        })
        return {
          results: data
        }
      },
      cache: true
    },
    escapeMarkup: function (markup) {
      return markup
    },
    minimumInputLength: 3,
    templateResult: formatResult,
    templateSelection: formatSelection
  })
}

const getClient = function (clientInfo, token, callback) {
  getClientAjax(clientInfo, 'organisation', token, function (error, client) {
    if (!error) {
      if (client) {
        return callback(client)
      } else {
        getClientAjax(clientInfo, 'user', token, function (error, client) {
          if (!error) {
            return callback(client)
          } else {
            return callback()
          }
        })
      }
    } else {
      return callback()
    }
  })
}

const getClientAjax = function (info, type, token, callback) {
  $.ajax({
    async: true,
    crossDomain: true,
    method: 'GET',
    url: `${location.origin}/v1/${type}?afasId=${info}`,
    headers: {
      authorization: `Bearer ${token}`
    }
  }).done(function (clientResponse) {
    return callback(null, clientResponse)
  }).fail(function (error) {
    return callback(error)
  })
}

const sendProjectPutRequest = function (component, project, callback) {
  const data = {
    ss_name: project.ss_name,
    address: project.address,
    client_id: project.client_id,
    client_name: project.client_name,
    start_date: project.start_date,
    // end_date: project.end_date,
    status: project.status,
    contracted_visits: project.contracted_visits,
    type: project.type,
    '3x8Dates': project['3x8Dates'],
    users: project.users,
    unique_id: project.afas_id,
    site_id: project.site_id,
    geo: project.geo,
    ownPlatform: project.ownPlatform,
    next_visit: moment(project.next_visit).format('YYYY-MM-DD'),
    oldVGPType: project.oldVGPType,
    description: project.description,
    hasInvoicedVisits: project.hasInvoicedVisits
  }

  if (data.users) {
    data.users = data.users.filter(function (item) {
      return !!item.user
    }).map(function (user) {
      if (typeof user.user === 'object' && user.user !== null) {
        user.user = user.user._id
      }
      return user
    })
  }

  const settings = {
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/project/${component.props.match.params.id}`,
    method: 'PUT',
    headers: {
      authorization: `Bearer ${component.props.token.token}`,
      'content-type': 'application/json'
    },
    data: JSON.stringify(data)
  }

  const promise = $.ajax(settings)
  promise.done((response) => {
    callback(null, response)
  })
  promise.fail((error) => {
    callback(error)
  })
}

export class ProjectWizardView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      project: {
        afas_id: null,
        client_id: null,
        project_name: null,
        ss_name: null,
        phone_work: null,
        email_work: null,
        start_date: null,
        type: null,
        status: null,
        address: {},
        geo: {},
        users: [],
        '3x8Dates': {},
        ownPlatform: false,
        contracted_visits: null,
        initialStatus: 'design'
      },
      client: {
        _id: null,
        afas_id: null,
        name: null,
        address: {},
        updated_at: null,
        customer: null,
        phone_work: null,
        email_work: null
      },
      location: '',
      newVCDesign: '',
      newVCExecution: '',
      showExtraDates: false,
      error: false,
      postStatus: null,
      newDateChanged: false,
      loading: true,
      afas_id: '',
      afasIdChanged: false,
      afasIdChangeSuccess: false,
      afasIdChangeError: false
    }

    this.updateLocation = this.updateLocation.bind(this)
    this.changeCheckboxValue = this.changeCheckboxValue.bind(this)
    this.changeStartDate = this.changeStartDate.bind(this)
    this.change3x8Date = this.change3x8Date.bind(this)
    this.changeType = this.changeType.bind(this)
    this.changeName = this.changeName.bind(this)
    this.saveProject = this.saveProject.bind(this)
    this.getAddress = this.getAddress.bind(this)
    this.changeContractedVisits = this.changeContractedVisits.bind(this)
    this.findGeoLocatie = this.findGeoLocatie.bind(this)
    this.deleteProject = this.deleteProject.bind(this)
    this.changeAfasId = this.changeAfasId.bind(this)
    this.updateAfasId = this.updateAfasId.bind(this)
    this.changeInitialStatus = this.changeInitialStatus.bind(this)
    this.changeDescription = this.changeDescription.bind(this)

    this.streetRef = React.createRef()
    this.housenumberRef = React.createRef()
    this.zipcodeRef = React.createRef()
    this.cityRef = React.createRef()
    this.countryRef = React.createRef()
    this.delButtonRef = React.createRef()
    this.designButtonRef = React.createRef()
    this.executeButtonRef = React.createRef()
    this.createPidButtonRef = React.createRef()
    this.finishButtonRef = React.createRef()
  }

  componentWillMount () {
    document.title = this.props.t('title:edit_project')
  }

  componentDidMount () {
    $('#instantie').on('change', (event) => {
      setTimeout(() => {
        const project = this.state.project
        const newClientId = event.target.value
        project.client_id = newClientId
        getClient(newClientId, this.props.token.token, (response) => {
          if (response) {
            project.client_name = response.name
            if (!this.isUnmounting) {
              this.setState({
                client: response,
                project
              })
            }
          }
        })
      }, 500)
    })
    $('.veiligheidsCoordinator').on('change', (event) => {
      const id = event.currentTarget.id
      setTimeout(() => {
        const newVC = event.currentTarget.value
        if (id === 'veiligheidsCoordinatorDesign') {
          this.setState({
            newVCDesign: newVC
          })
        } else {
          this.setState({
            newVCExecution: newVC
          })
        }
      }, 500)
    })

    // Get the project
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.match.params.id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    $.ajax(settings).done((projectResponse) => {
      document.title = this.props.t('title:edit_project') + ` - ${projectResponse.ss_name || ''}`

      const veiligheidsCoordinatorSelectDesign = $('#select2-veiligheidsCoordinatorDesign-container')
      const veiligheidsCoordinatorSelectExecution = $('#select2-veiligheidsCoordinatorExecution-container')
      if (veiligheidsCoordinatorSelectDesign.length) {
        const vcDesign = _.find(projectResponse.users, function (user) {
          return user.type === 'vcDesign'
        })
        const vcExecution = _.find(projectResponse.users, function (user) {
          return user.type === 'vcExecution'
        })
        if (vcDesign) {
          veiligheidsCoordinatorSelectDesign.append(vcDesign.user.name)
        }
        if (vcExecution) {
          veiligheidsCoordinatorSelectExecution.append(vcExecution.user.name)
        }
      }

      const startDate = projectResponse.start_date
      if (!startDate) {
        const visits = projectResponse.visits
        if (visits.length) {
          projectResponse.start_date = moment(moment(visits.slice(-1)[0].date).valueOf()).format('YYYY-MM-DD')
        }
      }

      const address = projectResponse.address
      let location = null
      if (address) {
        location = `${address.street ? address.street + ' ' : ''}${address.housenumber ? address.housenumber + ', ' : ''}${address.city ? address.city + ', ' : ''}${address.country || ''}`
        if (location.length < 10) {
          location = null
        }
      }

      let showExtraDates = false
      if (projectResponse.type === 'interval_3x8months') {
        showExtraDates = true
      }

      let projectClient = null
      if (projectResponse.client_id) {
        projectClient = projectResponse.client_id
      }

      projectResponse.initialStatus = projectResponse.status === 'new' ? 'design' : 'execution'
      const newState = {
        project: projectResponse,
        location,
        showExtraDates,
        loading: false,
        afas_id: projectResponse.afas_id
      }
      if (projectClient) {
        getClient(projectClient, this.props.token.token, (clientResponse) => {
          const clientSelect = $('#select2-instantie-container')
          if (clientSelect.length && clientResponse) {
            clientSelect.append(clientResponse.name)
            projectResponse.client_id = clientResponse.afas_id
          }
          if (!this.isUnmounting) {
            newState.client = clientResponse
            this.setState(newState)
          }
        })
      } else {
        if (!this.isUnmounting) {
          this.setState(newState)
        }
      }
    })

    initiateClientDropdown()
    initiateVCDropdown()
  }

  componentWillUnmount () {
    $('#instantie').off('change')
    this.isUnmounting = true
  }

  updateLocation (event) {
    const project = this.state.project
    const address = {}
    place.address_components.forEach(component => {
      component.types.forEach(type => {
        /* eslint-disable */
        switch (type) {
          case 'route': address.street = component.long_name || ''; break
          case 'postal_code': address.zipcode = component.long_name || ''; break
          case 'country': address.country = component.long_name || ''
            address.country_short = component.short_name || ''; break
          case 'locality': address.city = component.long_name || ''; break
          case 'street_number': address.housenumber = component.long_name || ''; break
          default:
        }
        /* eslint-enable */
      })
    })
    project.address = address

    const geo = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
    project.geo = geo

    this.setState({
      location: place.formatted_address,
      project
    })
  }

  changeCheckboxValue (event) {
    const target = event.currentTarget
    const project = this.state.project
    const newVal = target.checked
    project[target.id] = newVal
    this.setState({ project })
  }

  changeStartDate (date) {
    const project = this.state.project
    if (date === null) {
      project.start_date = null
    } else {
      project.start_date = moment(date).format('YYYY-MM-DD')
    }
    this.setState({
      project,
      newDateChanged: true
    })
  }

  change3x8Date (date, target) {
    const project = this.state.project
    if (date === null) {
      project['3x8Dates'] = project['3x8Dates'] || {}
      project['3x8Dates'][target] = null
    } else {
      project['3x8Dates'] = project['3x8Dates'] || {}
      project['3x8Dates'][target] = date
    }
    this.setState({
      project
    })
  }

  changeType (event) {
    const project = _.clone(this.state.project)
    project.type = event.target.value
    let showExtraDates = false
    if (project.type === 'interval_3x8months') {
      showExtraDates = true
    } else {
      delete project['3x8Dates']
    }
    this.setState({
      project,
      showExtraDates,
      typeChanged: true
    })
  }

  changeName (event) {
    const project = _.clone(this.state.project)
    project.ss_name = event.target.value
    this.setState({ project })
  }

  async saveProject (event) {
    event.preventDefault()
    const t = this.props.t
    const params = new URLSearchParams(this.props.location.search)
    const projectIsNew = params.get('new') === 'true'

    if (this.state.postStatus === 'working') {
      return
    }

    this.setState({
      loading: true
    })

    const project = _.clone(this.state.project)

    // validate required fields
    let projectTypeValid = false
    if (project.type === 'interval_3x8months') {
      const dates = project['3x8Dates'] || {}
      let x = 1
      let stillValid = true
      while (x < 4 && stillValid) {
        if (!dates[`date${x}`]) {
          stillValid = false
        }
        x++
      }
      projectTypeValid = stillValid
    } else if (project.type) {
      projectTypeValid = true
    }

    const newVCDesign = this.state.newVCDesign
    const newVCExecution = this.state.newVCExecution
    if (newVCDesign) {
      project.users = _.filter(project.users, function (user) {
        return user.type !== 'vcDesign'
      })
      project.users.push({
        user: newVCDesign,
        type: 'vcDesign'
      })
    }
    if (newVCExecution) {
      project.users = _.filter(project.users, function (user) {
        return user.type !== 'vcExecution'
      })
      project.users.push({
        user: newVCExecution,
        type: 'vcExecution'
      })
    }
    let vcExists = false
    if (project.initialStatus === 'design') {
      vcExists = _.any(project.users, function (user) {
        return user.type === 'vcDesign'
      })
    } else {
      vcExists = _.any(project.users, function (user) {
        return user.type === 'vcExecution'
      })
    }

    if (project.project_name && project.start_date && projectTypeValid && project.address && vcExists) {
      const startDate = project.start_date
      if (projectIsNew || (this.state.newDateChanged && !project.visits.length)) {
        const visits = project.visits
        let latestVisit
        if (visits.length) {
          latestVisit = moment(moment(visits[0].date).valueOf())
        } else {
          latestVisit = moment(startDate)
        }
        project.next_visit = latestVisit.add(getIntervalDays(project.type), 'days')
      }

      if (project.status === 'new') {
        if (project.initialStatus === 'design' || moment(startDate).isBefore(moment().endOf('day'))) {
          project.status = project.initialStatus
        } else {
          project.status = 'waiting'
        }
      } else if (this.state.newDateChanged && project.status !== 'design') {
        // if the startdate was changed AND status !== design or new
        const startDateMoment = moment(startDate)
        const endOfToday = moment().endOf('day')
        if (startDateMoment.isBefore(endOfToday)) {
          project.status = 'execution'
        } else if (startDateMoment.isAfter(endOfToday)) {
          project.status = 'waiting'
        }
      }

      if (this.state.typeChanged) {
        const projectType = project.type
        const visit = Object.prototype.toString.apply(project.visits).slice(8, -1) === 'Array' ? project.visits[0] : false
        let prevDate
        if (visit) {
          prevDate = moment() // calculate next visit based on today and not last visit
        } else {
          prevDate = moment(project.start_date, 'YYYY-MM-DD')
        }

        project.next_visit = calcNextDateByType(projectType, prevDate)
      }

      this.setState({
        postStatus: 'working'
      })

      if (!project.unique_id) {
        console.log('create ss')
        project.start_date = moment(project.start_date).format('YYYY-MM-DD')

        const url = `${location.origin}/v1/project/createSSProject`
        const body = project
        let response
        try {
          response = await xmlRequest(url, this.props.token.token, body, 'POST')
        } catch (error) {
          console.error('Received unexpected response after POST')
          console.error(error)
          this.setState({
            loading: false,
            error: {
              status: error.status || error,
              statusText: error.message || t('project:generic_error')
            }
          })
        }

        if (response.site_id) {
          project.unique_id = project.afas_id
          project.site_id = `https://app3.safetysnapper.com/sites/${response.site_id}/edit`

          sendProjectPutRequest(this, project, (error, response) => {
            if (!error) {
              if (projectIsNew) {
                this.props.history.push(`/werf/${this.props.match.params.id}?new=true`)
              } else {
                this.props.history.goBack()
              }
            } else {
              console.error(error)
              this.setState({
                loading: false,
                postStatus: 'error',
                error
              })
            }
          })
        } else {
          console.error('Received unexpected response after POST')
          console.error(response)
          this.setState({
            loading: false,
            error: {
              status: response,
              statusText: response
            }
          })
        }
      } else {
        console.log('ss exists')
        sendProjectPutRequest(this, project, (error, response) => {
          if (!error) {
            if (projectIsNew) {
              this.props.history.push(`/werf/${this.props.match.params.id}?new=true`)
            } else {
              this.props.history.goBack()
            }
          } else {
            console.error(error)
            this.setState({
              postStatus: 'error',
              error,
              loading: false
            })
          }
        })
      }
    } else {
      this.setState({
        postStatus: 'missing info', // TODO show wrong fields
        loading: false
      })
    }
  }

  getAddress (event) {
    event.preventDefault()
    const project = this.state.project
    const address = {
      street: this.streetRef.current.value,
      housenumber: this.housenumberRef.current.value,
      zipcode: this.zipcodeRef.current.value,
      city: this.cityRef.current.value,
      country: this.countryRef.current.value
    }
    project.address = address
    this.setState({
      project
    })
  }

  changeContractedVisits (e) {
    this.setState({ project: Object.assign({}, this.state.project, { contracted_visits: e.currentTarget.value }) })
  }

  findGeoLocatie (event) {
    event.preventDefault()
    const project = this.state.project
    if (project.address) {
      this.setState({
        findingGeoLocation: true
      })
      const address = project.address
      const geocoder = new google.maps.Geocoder()
      const that = this
      geocoder.geocode({ address: `${address.street}, ${address.housenumber}, ${address.zipcode}, ${address.city}, ${address.country}` }, function (results, status) {
        if (status === 'OK' && results.length > 0) {
          const place = results[0]
          place.address_components.forEach(component => {
            component.types.forEach(type => {
              /* eslint-disable */
              switch (type) {
                case 'route': break
                case 'postal_code':
                  if (!address.zipcode && address.city) {
                    address.zipcode = component.long_name || ''
                  }
                  break
                case 'country': address.country = component.long_name || ''
                  address.country_short = component.short_name || ''; break
                case 'locality':
                  if (!address.city && address.zipcode) {
                    address.city = component.long_name || ''
                  }
                  break
                case 'street_number': break
                default:
              }
              /* eslint-enable */
            })
          })
          project.address = address

          const geo = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          }
          project.geo = geo

          that.setState({
            location: place.formatted_address,
            findingGeoLocation: false,
            project
          })
        }
      })
    }
  }

  disableButtons () {
    const buttons = [
      this.delButtonRef,
      this.designButtonRef,
      this.executeButtonRef,
      this.createPidButtonRef,
      this.finishButtonRef
    ]
    buttons.forEach(buttonRef => {
      buttonRef.current && buttonRef.current.setAttribute('disabled', true)
    })
  }

  deleteProject () {
    const confirmDelete = window.confirm(this.props.t('project:delete_confirm'))
    if (confirmDelete) {
      this.disableButtons()
      $.ajax({
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/project?project=${this.state.project._id}`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${this.props.token.token}`
        }
      }).done((response) => {
        this.props.history.push('/')
      }).fail(function (error) {
        console.error(error)
      })
    }
  }

  changeDescription (e) {
    const description = e.target.value
    const newState = {
      project: Object.assign({}, this.state.project, { description })
    }
    this.setState(newState)
  }

  changeAfasId (e) {
    const newValue = e.target.value
    const newState = {
      afasIdChanged: newValue !== this.state.project.afas_id,
      afas_id: newValue
    }
    this.setState(newState)
  }

  updateAfasId (e) {
    const afasId = this.state.afas_id
    if (this.state.afasIdChanged) {
      const payload = {
        afas_id: afasId
      }

      $.ajax({
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/project/${this.state.project._id}/editAfasId`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.props.token.token}`
        },
        processData: false,
        data: JSON.stringify(payload)
      }).done(response => {
        this.setState({
          afasIdChangeSuccess: true,
          afasIdChangeError: false,
          project: Object.assign({}, this.state.project, { afas_id: afasId }),
          afasIdChanged: false
        })
      }).fail(error => {
        console.error(error)
        this.setState({
          afasIdChangeError: error.responseJSON,
          afasIdChangeSuccess: false
        })
      })
    }
  }

  changeInitialStatus (e) {
    const newStatus = e.target.value
    // also clear vcDesign input
    $('#select2-veiligheidsCoordinatorDesign-container').text('')
    this.setState(state => ({
      newVCDesign: '',
      project: Object.assign({}, state.project, {
        initialStatus: newStatus
      })
    }))
  }

  async changeProjectStatus (status) {
    const confirm = window.confirm(this.props.t('project:edit_status_confirm'))
    if (!confirm) {
      return
    }

    const project = this.state.project
    const url = `${location.origin}/v1/project/${project._id}`
    const body = {
      $set: {
        status
      }
    }

    const currentStatus = project.status
    if (currentStatus === 'finished') {
      // pidzip remove
      body.$pull = {
        files: {
          file_type: 'bundledZip'
        }
      }
    }

    if (project.progress === '8') {
      body.$set.progress = '7'
      body.$set.createPidStartDate = ''
    }

    try {
      await xmlRequest(url, this.props.token.token, body, 'PUT')
    } catch (error) {
      return this.setState(state => ({ error }))
    }

    this.props.history.push(`/werf/${project._id}`)
  }

  render () {
    const params = new URLSearchParams(this.props.location.search)
    const projectIsNew = params.get('new') === 'true'
    const project = this.state.project
    const t = this.props.t

    const userIsAdminPlus = this.props.user.role === 'ADMIN+'
    const isAdmin = hasPermissionFor(this.props.user.role, 'ADMIN')

    let startDate = project.start_date ? moment(project.start_date) : null
    if (startDate && !startDate.isValid()) {
      startDate = null
    }
    if (startDate) startDate = startDate.toDate()

    const extraDates = {
      date1: null,
      date2: null,
      date3: null
    }
    if (project['3x8Dates']) {
      extraDates.date1 = (project['3x8Dates'].date1 === undefined) ? null : new Date(project['3x8Dates'].date1)
      extraDates.date2 = (project['3x8Dates'].date2 === undefined) ? null : new Date(project['3x8Dates'].date2)
      extraDates.date3 = (project['3x8Dates'].date3 === undefined) ? null : new Date(project['3x8Dates'].date3)
    }

    const address = project.address ? project.address : {}
    const geo = project.geo ? `${project.geo.lat}, ${project.geo.lng}` : t('project:generate_geolocation')

    let feedback
    const postStatus = this.state.postStatus
    /* eslint-disable */
    switch (postStatus) {
      case 'working': feedback = <div className="message"><T i18nKey="project:saving_message">Aan het opslaan...</T></div>; break
      case 'missing info': feedback = (
        <div id="insufficientWarning" className="message warning">
          <div className="message_icon">
            <span className="glyphicons glyphicons-exclamation-sign"></span>
          </div>
          <T i18nKey="project:empty_fields_message">Niet alle vereiste velden zijn ingevuld.</T>
        </div>
      ); break
      case 'error': feedback = <div className="message warning"><T i18nKey="project:generic_error">Er is iets misgelopen.</T></div>
        break
      default: feedback = null
    }
    /* eslint-enable */

    const projectName = project.ss_name || project.project_name

    const initialStatus = project.initialStatus

    const canGoBackToDesign = project.status === 'finished' || project.status === 'execution' || project.status === 'createPid' || project.status === 'waiting'
    const canGoBackToExecution = project.status === 'finished' || project.status === 'createPid'
    const canGoBackToCreatePid = project.status === 'finished'
    const isNew = project.status === 'new'

    return (
      <section className="container form">
        { feedback}
        <h2>
          {projectIsNew
            ? <><T i18nKey="project:start_project">Werf starten</T>&nbsp;</>
            : <><T i18nKey="project:edit_project">Werf aanpassen</T>&nbsp;</>
          }
          {this.state.loading
            ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
            : null
          }
        </h2>
        <form onSubmit={this.saveProject}>
          <fieldset className="form-group">
            <label htmlFor="werfNaam"><T i18nKey="project:project_name">Werf naam</T> <span className="required"></span></label>
            <textarea className="form-control" id="werfNaam" onChange={this.changeName} value={projectName || ''}></textarea>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="afas_id"><T i18nKey="project:project_number">Project-nummer</T></label>
            <input disabled={!userIsAdminPlus} className="form-control" id="afas_id" onChange={this.changeAfasId} onBlur={this.updateAfasId} value={this.state.afas_id || ''} type="text" />
          </fieldset>
          {this.state.afasIdChangeSuccess
            ? <div className="alert alert-success"><T i18nKey="common:saved">Opgeslagen</T></div>
            : null
          }
          {(this.state.afasIdChanged || this.state.afasIdChangeSuccess) && project.site_id
            ? <div className="alert alert-warning"><T i18nKey="project:edit_on_safetysnapper_warning">Waarschuwing, dit moet ook aangepast worden op <a href={project.site_id} target="_blank" rel="noopener noreferrer">safetysnapper</a>.</T></div>
            : null
          }
          {this.state.afasIdChangeError
            ? <div className="alert alert-danger">
              {this.state.afasIdChangeError.message || t('project:saving_failed')}
            </div>
            : null
          }
          <fieldset className="form-group">
            <label htmlFor="project_notes"><T i18nKey="project:project_extra_info">Korte beschrijving project</T></label>
            <textarea className="form-control" id="project_notes" onChange={this.changeDescription} value={project.description || ''}></textarea>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="instantie"><T i18nKey="project:client">Klant</T> <span className="required"></span></label>
            <select className="form-control js-remote-client-data" id="instantie">
            </select>
          </fieldset>
          <h3><T i18nKey="project:project_location">Werf locatie</T></h3>
          <fieldset className="form-group" style={{ width: '60%', float: 'left', marginRight: '4%' }}>
            <label htmlFor="street"><T i18nKey="project:street">Straat</T></label>
            <input id="street" ref={this.streetRef} value={address.street || ''} type="text" className="form-control" onChange={this.getAddress} />
          </fieldset>
          <fieldset className="form-group" style={{ width: '36%', float: 'left' }}>
            <label htmlFor="housenumber"><T i18nKey="project:number">nr</T></label>
            <input id="housenumber" ref={this.housenumberRef} value={address.housenumber || ''} type="text" className="form-control" onChange={this.getAddress} />
          </fieldset>
          <fieldset className="form-group" style={{ width: '30%', float: 'left', marginRight: '4%' }}>
            <label htmlFor="country"><T i18nKey="project:country">Land</T> <span className="required"></span></label>
            <input id="country" ref={this.countryRef} value={address.country || ''} type="text" className="form-control" onChange={this.getAddress} />
          </fieldset>
          <fieldset className="form-group" style={{ width: '15%', float: 'left', marginRight: '4%' }}>
            <label htmlFor="zipcode"><T i18nKey="project:postalcode">Postcode</T> <span className="required"></span></label>
            <input id="zipcode" ref={this.zipcodeRef} value={address.zipcode || ''} type="text" className="form-control" onChange={this.getAddress} />
          </fieldset>
          <fieldset className="form-group" style={{ width: '47%', float: 'left' }}>
            <label htmlFor="city"><T i18nKey="project:city">Stad/gemeente</T> <span className="required"></span></label>
            <input id="city" ref={this.cityRef} value={address.city || ''} type="text" className="form-control" onChange={this.getAddress} />
          </fieldset>
          <div className="clear"></div>
          <fieldset className="form-group">
            <label style={{ display: 'block' }} ><T i18nKey="project:geolocation">Geo locatie</T>
              <button className="btn-green btn-low right" style={{ marginTop: -5 }} onClick={this.findGeoLocatie}>
                {this.state.findingGeoLocation
                  ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                  : <span className="glyphicons glyphicons-cadastral-map"></span>
                }
                                &nbsp;<T i18nKey="project:find_geolocation">Zoek geo locatie</T>
              </button>
            </label>
            <input value={geo} type="text" className="form-control" disabled={true} />
          </fieldset>

          <fieldset className="form-group form-group-date-picker">
            <label htmlFor="startDate"><T i18nKey="project:start_date">Start datum</T> <span className="required"></span></label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={this.changeStartDate}
              className="form-control"
              placeholderText="Klik om een datum te selecteren"
              isClearable={true}
            />
            <div className="form-info">
              <h4><T i18nKey="project:start_date">Start datum</T></h4>
              <T i18nKey="project:date_message_pt1">Datum wanneer deze werf actief moet worden.</T>
              <br />
              <T i18nKey="project:date_message_pt2">Intussentijd blijft deze in de wachtrij staan</T>
            </div>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="project_contracted_visits"><T i18nKey="project:contractual_visits">Contractu&euml;le bezoeken</T></label>
            <input disabled={!userIsAdminPlus} type="number" className="form-control" id="project_contracted_visits" onChange={this.changeContractedVisits} value={project.contracted_visits || ''} />
          </fieldset>
          {projectIsNew &&
            <fieldset className="form-group">
              <label htmlFor="project_next_status"><T i18nKey="project:next_status">Volgende status</T></label>
              <select className="form-control" id="project_next_status" onChange={this.changeInitialStatus} value={initialStatus}>
                <option value="design">{t('project:vc_design')}</option>
                <option value="execution">{t('project:vc_execution')}</option>
              </select>
            </fieldset>
          }
          <fieldset className="form-group">
            <label htmlFor="veiligheidsCoordinatorDesign"><T i18nKey="project:safety_coordinator_design">Veiligheidsco&ouml;rdinator ontwerp</T> <span className={(initialStatus === 'design') || (project.status === 'design') ? 'required' : ''}></span></label>
            <select disabled={!((initialStatus === 'design') || (project.status === 'design'))} className="form-control veiligheidsCoordinator" id="veiligheidsCoordinatorDesign"></select>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="veiligheidsCoordinatorExecution"><T i18nKey="project:safety_coordinator_execution">Veiligheidsco&ouml;rdinator uitvoer</T> <span className={(initialStatus === 'execution') || (project.status === 'execution') ? 'required' : ''}></span></label>
            <select className="form-control veiligheidsCoordinator" id="veiligheidsCoordinatorExecution"></select>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="type"><T i18nKey="project:type">Type</T> <span className="required"></span></label>
            <select value={project.type || ''} onChange={this.changeType} className="form-control" id="type">
              <option>{t('project:choose')}</option>
              {getIntervalOptions()}
            </select>
          </fieldset>
          {this.state.showExtraDates
            ? [
              <ExtraDates key="3x8Date1" index="1" displayDate={extraDates.date1} change3x8Date={this.change3x8Date} />,
              <ExtraDates key="3x8Date2" index="2" displayDate={extraDates.date2} change3x8Date={this.change3x8Date} />,
              <ExtraDates key="3x8Date3" index="3" displayDate={extraDates.date3} change3x8Date={this.change3x8Date} />
              ]
            : ''
          }
          <fieldset className="form-group">
            <div className="toggle_switch">
              <label htmlFor="ownPlatform"><T i18nKey="project:own_online_platform">Eigen online platform</T></label>
              <input className="toggle" type="checkbox" id="ownPlatform" checked={project.ownPlatform || false} onChange={this.changeCheckboxValue} />
              <label htmlFor="ownPlatform"></label>
            </div>
          </fieldset>
          <fieldset className="form-group">
            <div className="toggle_switch">
              <label htmlFor="oldVGPType"><T i18nKey="project:old_vgp_structure">Oude vgp structuur</T></label>
              <input className="toggle" type="checkbox" id="oldVGPType" checked={project.oldVGPType || false} onChange={this.changeCheckboxValue} />
              <label htmlFor="oldVGPType"></label>
            </div>
          </fieldset>
          {userIsAdminPlus &&
            <fieldset className="form-group">
              <div className="toggle_switch">
                <label htmlFor="hasInvoicedVisits"><T i18nKey="project:individually_invoiced_visits">Individueel gefactureerde werfbezoeken</T></label>
                <input className="toggle" type="checkbox" id="hasInvoicedVisits" checked={project.hasInvoicedVisits || false} onChange={this.changeCheckboxValue} />
                <label htmlFor="hasInvoicedVisits"></label>
              </div>
            </fieldset>
          }
          <fieldset className="form-group">
            <button type="submit" className="form-submit">
              {this.state.loading
                ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                : null
              }
                            &nbsp;<T i18nKey="common:save">Opslaan</T>
            </button>
          </fieldset>
        </form>

        <hr />

        <div className="ProjectWizard-status-buttons-group">
          <button ref={this.delButtonRef} className="btn-red" onClick={this.deleteProject}><T i18nKey="project:delete_project">Werf verwijderen</T></button>
          {canGoBackToDesign && <button ref={this.designButtonRef} className="btn-red projectWizard-button" onClick={() => this.changeProjectStatus('design')}><T i18nKey="project:back_to_design">Terug naar ontwerp</T></button>}
          {canGoBackToExecution && <button ref={this.executeButtonRef} className="btn-red projectWizard-button" onClick={() => this.changeProjectStatus('execution')}><T i18nKey="project:back_to_execution">Terug naar uitvoer</T></button>}
          {canGoBackToCreatePid && <button ref={this.createPidButtonRef} className="btn-red projectWizard-button" onClick={() => this.changeProjectStatus('createPid')}><T i18nKey="project:back_to_create_pid">Terug naar PID aanmaken</T></button>}
          {isNew && isAdmin && <button ref={this.finishButtonRef} className="btn-red projectWizard-button" onClick={() => this.changeProjectStatus('finished')}><T i18nKey="project:finish_immediately">Onmiddelijk afronden</T></button>}
        </div>
      </section>
    )
  }
}

class ExtraDates extends Component {
  constructor (props) {
    super(props)

    this.change3x8Date = this.change3x8Date.bind(this)
  }

  change3x8Date (date) {
    this.props.change3x8Date(date, `date${this.props.index}`)
  }

  render () {
    return (
      <fieldset className="form-group form-group-date-picker">
        <label><T i18nKey="project:visit">Bezoek</T> {this.props.index} <span className="required"></span></label>
        <Translate>{t =>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={this.props.displayDate}
            onChange={this.change3x8Date}
            className="form-control"
            id={`date${this.props.index}`}
            placeholderText={t('common:click_to_select_date')}
            isClearable={true}
          />
        }</Translate>
      </fieldset>
    )
  }
}

const ProjectWizard = withTranslation()(ProjectWizardView)
export { ProjectWizard }
