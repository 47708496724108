import React, { Component } from 'react'
import _ from 'underscore'
import moment from 'moment'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
// let autocomplete;
const $ = window.$

const formatResult = function (client) {
  if (client.loading) return client.text
  const markup = client.name
  return markup
}
const formatSelection = function (client) {
  return client.text
}

const initiateOrganisationDropdown = function () {
  const token = localStorage.getItem('authToken')
  $('.js-remote-organisation-data').select2({
    ajax: {
      async: true,
      crossDomain: true,
      url: function (params) {
        return `${location.origin}/v1/organisation?search=${encodeURIComponent(params.term)}`
      },
      headers: {
        authorization: `Bearer ${token}`
      },
      delay: 250,
      processResults: function (data) {
        data = $.map(data, function (object) {
          object.id = object._id
          object.text = object.name
          return object
        })
        return {
          results: data
        }
      },
      cache: true
    },
    escapeMarkup: function (markup) {
      return markup
    },
    minimumInputLength: 3,
    templateResult: formatResult,
    templateSelection: formatSelection
  })
}

const getClient = function (id, token, callback) {
  const settings = {
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/organisation/${id}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  $.ajax(settings).done((clientResponse) => {
    callback(clientResponse)
  })
}

class NewUserView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        customer: null,
        name: null,
        initials: null,
        gender: null,
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        date_of_birth: null,
        address: {},
        phone: null,
        phone_work: null,
        website: null,
        organisation: {},
        ss_role: '',
        role: 'USER',
        projects: null,
        fax_number: '',
        ss_role_secondary: '',
        additional_info: '',
        ss_address: ''
      },
      location: null,
      passwordComplexity: {
        valid: null,
        complexity: null
      },
      displayPhone: null
    }

    // this.updateLocation = this.updateLocation.bind(this)
    this.changePhoneValue = this.changePhoneValue.bind(this)
    this.changeValue = this.changeValue.bind(this)
    this.changeBirthdate = this.changeBirthdate.bind(this)
    this.createUser = this.createUser.bind(this)
    this.changeValueBetter = this.changeValueBetter.bind(this)

    // this.locationRef = React.createRef()
  }

  componentWillMount () {
    document.title = this.props.t('title:create_user')
  }

  componentDidMount () {
    $('#organisation').on('change', (event) => {
      setTimeout(() => {
        const user = _.clone(this.state.user)
        const organisation = user.organisation || {}
        const newClientId = event.target.value
        organisation.organisation_id = newClientId
        getClient(newClientId, this.props.token.token, (response) => {
          organisation.name = response.name
          user.organisation = organisation
          this.setState({
            user
          })
        })
      }, 500)
    })
    // autocomplete = new window.google.maps.places.Autocomplete(
    //     this.locationRef.current,
    //     {types: ['geocode']}
    // );
    // autocomplete.addListener('place_changed', this.updateLocation);

    initiateOrganisationDropdown()

    $('#password').complexify({}, (valid, complexity) => {
      this.setState({
        passwordComplexity: {
          valid,
          complexity
        }
      })
    })
  }

  // updateLocation(event) {
  //     const place = autocomplete.getPlace();
  //     const user = _.clone(this.state.user);
  //     const address = {};
  //     place.address_components.forEach(component => {
  //         component.types.forEach(type => {
  //             switch (type) {
  //                 case 'route': address.street = component.long_name; break;
  //                 case 'postal_code': address.zipcode = component.long_name; break;
  //                 case 'country': address.country = component.long_name;
  //                                 address.country_short = component.short_name; break;
  //                 case 'locality': address.city = component.long_name; break;
  //                 case 'street_number': address.housenumber = component.long_name; break;
  //                 default:
  //             };
  //         });
  //     });
  //     user.address = address;
  //     this.setState({
  //         location: place.formatted_address,
  //         user
  //     });
  // }
  changePhoneValue (event) {
    const newValue = event.target.value
    this.setState(state => ({
      user: Object.assign({}, state.user, { phone: newValue })
    }))
  }

  changeValue (event) {
    const user = _.clone(this.state.user)
    let inputName = event.currentTarget.id
    inputName = inputName.split('-')
    if (inputName.length > 1) {
      user[inputName[0]][inputName[1]] = event.currentTarget.value // TODO function/ss_role is different
    } else {
      user[inputName] = event.currentTarget.value
    }

    if (inputName[0] === 'lastname' || inputName[0] === 'firstname') {
      user.name = `${user.firstname || ''} ${user.lastname || ''}`
    }

    this.setState({
      user
    })
  }

  changeValueBetter (name, value) {
    this.setState(state => ({
      user: Object.assign({}, state.user, { [name]: value })
    }))
  }

  changeBirthdate (event) {
    const user = this.state.user
    const newDate = event.currentTarget.value
    if (newDate) {
      user.date_of_birth = moment(event.currentTarget.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
    } else {
      user.date_of_birth = ''
    }
    this.setState({ user })
  }

  createUser (event) {
    event.preventDefault()
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/user`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      },
      data: this.state.user
    }
    const promise = $.ajax(settings)
    promise.done((response) => {
      window.dataCache.usersState = null
      this.props.history.push(`/gebruiker/${response._id}`)
    })
    promise.fail((error) => {
      console.error(error)
      this.setState({
        error
      })
    })
  }

  render () {
    const user = this.state.user
    const t = this.props.t

    return (
      <section className="container form">
        <form onSubmit={this.createUser}>
          <fieldset className="form-group">
            <label htmlFor="role"><T i18nKey="user:role">Rol</T></label>
            <select value={user.role} onChange={this.changeValue} className="form-control" id="role">
              <option>--</option>
              <option value="EMPLOYEE">{t('user:employee')}</option>
              <option value="ADMIN">{t('user:admin')}</option>
              <option value="FREELANCER">{t('user:freelancer')}</option>
              <option value="PARTNER">{t('user:partner')}</option>
              <option value="ONEPROJECTER">{t('user:inspector')}</option>
            </select>
          </fieldset>
          <h3><T i18nKey="user:personal_information">Persoonlijke gegevens</T></h3>
          <fieldset className="form-group">
            <label htmlFor="lastname"><T i18nKey="user:surname">Naam</T></label>
            <input type="text" className="form-control" id="lastname" onChange={this.changeValue} value={user.lastname || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="firstname"><T i18nKey="user:first_name">Voornaam</T></label>
            <input type="text" className="form-control" id="firstname" onChange={this.changeValue} value={user.firstname || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="gender"><T i18nKey="user:gender">Geslacht</T></label>
            <select value={user.gender || ''} onChange={this.changeValue} className="form-control" id="gender">
              <option>--</option>
              <option value="M">{t('user:male')}</option>
              <option value="V">{t('user:female')}</option>
            </select>
          </fieldset>
          <h3><T i18nKey="user:company">Bedrijf</T></h3>
          <fieldset className="form-group">
            <select className="form-control js-remote-organisation-data" id="organisation">
            </select>
            <div className="form-info">
              <h4><T i18nKey="user:select_company">Select je bedrijf</T></h4>
            </div>
          </fieldset>
          <h3><T i18nKey="user:contact_information">Contact gegevens</T></h3>
          {/* <fieldset className="form-group">
                        <label htmlFor="location">Adres</label>
                        <input ref={this.locationRef} type="text" className="form-control" id="locatie"/>
                        <div className="form-info">
                            <h4>Adres</h4>
                            {this.state.location}<br/><br/>
                        </div>
                    </fieldset> */}
          <fieldset className="form-group">
            <label htmlFor="addressInput"><T i18nKey="user:address">Adres</T></label>
            <input type="string" className="form-control" id="addressInput" onChange={e => this.changeValueBetter('ss_address', e.target.value)} value={user.ss_address || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="email"><T i18nKey="common:email">Email</T> <span className="required"></span></label>
            <input type="email" className="form-control" id="email" onChange={this.changeValue} value={user.email || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="phone"><T i18nKey="user:mobile">GSM nr</T></label><br />
            <input type="test" className="form-control" id="phone" onChange={this.changePhoneValue} value={user.phone || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="ssRoleInput"><T i18nKey="user:safetysnapper_role">Safetysnapper rol</T></label>
            <input type="text" className="form-control" id="ssRoleInput" onChange={e => this.changeValueBetter('ss_role', e.target.value)} value={user.ss_role || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="ssSubroleInput"><T i18nKey="user:safetysnapper_subrole">Safetysnapper subrol</T></label>
            <input type="text" className="form-control" id="ssSubroleInput" onChange={e => this.changeValueBetter('ss_role_secondary', e.target.value)} value={user.ss_role_secondary || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="faxInput"><T i18nKey="user:fax">Faxnummer</T></label>
            <input type="text" className="form-control" id="faxInput" onChange={e => this.changeValueBetter('fax_number', e.target.value)} value={user.fax_number || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="extraInput"><T i18nKey="user:extra_information">Extra informatie</T></label>
            <textarea className="form-control" id="extraInput" onChange={e => this.changeValueBetter('additional_info', e.target.value)} value={user.additional_info}></textarea>
          </fieldset>
          <div className="clear"></div>
          <h3><T i18nKey="common:password">Wachtwoord</T></h3>
          <fieldset className="form-group">
            <label htmlFor="password"><T i18nKey="common:password">Wachtwoord</T></label>
            <input type="password" className="form-control" id="password" placeholder={t('common:password')} onChange={this.changeValue} />
            <div className="form-info">
              <h4><T i18nKey="common:password">Wachtwoord</T></h4>
              <T i18nKey="user:password_description">Een wachtwoord bevat minimum 12 karakters met letters, cijfers, hoofdletters en leestekens.</T><br /><br />
            </div>
          </fieldset>
          <div className="pass-strenght">
            <div className="pass-strenght-color" style={{ width: `${Math.round(this.state.passwordComplexity.complexity)}%` }}></div>
            <div className="pass-strenght-percent">{`${Math.round(this.state.passwordComplexity.complexity)}%`}</div>
          </div>
          {this.state.error
            ? <div className="message warning">
              {this.state.error.responseText}
            </div>
            : null}
          <fieldset className="form-group">
            <button type="submit" className="form-submit"><T i18nKey="common:save">Opslaan</T></button>
          </fieldset>
        </form>
      </section>
    )
  }
}

const NewUser = withTranslation()(NewUserView)
export { NewUser }
