import React, { Component } from 'react'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

const fetchUsers = function (options, callback) {
  const component = options.component
  const search = options.filter || ''
  const skip = options.skip || ''
  const settings = {
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/user/small?sortBy=${options.sortType || 'name'}&search=${encodeURIComponent(search)}&skip=${skip}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${component.props.token.token}`
    }
  }
  const promise = $.ajax(settings)
  promise.done((data, textStatus, jqXHR) => {
    if (!component.isUnmounting) {
      $(window).trigger('searchFinished')
      callback(data)
    }
  })
  promise.fail((jqXHR, textStatus, errorThrown) => {
    console.error(jqXHR)
    if (jqXHR.status === 401) {
      console.warn('unauthorized token, expired?')
      component.triggerLogout()
    }
  })
}

let working = false

const initScrollLoad = function (component) {
  $(window).off('.scrollHandler') // lets just make sure it's off first

  const $window = $(window)
  const $organisationsSection = $('section.users')
  const sectionTop = $organisationsSection.position().top
  let sectionHeight
  let innerHeight
  let scrollTop
  let working = false
  $(window).on('scroll.scrollHandler', (event) => {
    if (!working) {
      innerHeight = window.innerHeight
      sectionHeight = $organisationsSection.height()
      scrollTop = $window.scrollTop()
      if (scrollTop + innerHeight > sectionTop + sectionHeight - 300) {
        working = true
        console.log('fetching 100 more users')
        // load next 100 and append to current list
        component.setState({
          loading: true
        })
        fetchUsers({
          component: component,
          sortType: component.state.sortStatus,
          filter: component.state.filterStatus,
          skip: component.state.timesLoaded * 100
        }, (users, usersCount) => {
          const newUserList = component.state.users.concat(users)
          const newPageCount = component.state.timesLoaded + 1
          const state = component.state
          state.users = newUserList
          state.timesLoaded = newPageCount
          window.dataCache.usersState = state
          component.setState({
            users: newUserList,
            timesLoaded: newPageCount,
            loading: false
          })
          if (users.length !== 0) {
            working = false
          } else {
            console.log('no more users to fetch')
            $(window).off('.scrollHandler')
          }
        })
      }
    }
  })
}

const createLabel = function (component, role, t) {
  const roles = {
    'ADMIN+': {
      color: 'red',
      name: t('user:admin_plus')
    },
    ADMIN: {
      color: 'red',
      name: t('user:admin')
    },
    EMPLOYEE: {
      color: 'purple',
      name: t('user:employee')
    },
    FREELANCER: {
      color: 'green',
      name: t('user:freelancer')
    },
    PARTNER: {
      color: 'blue',
      name: t('user:partner')
    },
    ONEPROJECTER: {
      color: 'orange',
      name: t('user:inspector')
    },
    USER: {
      color: 'green',
      name: t('user:user')
    }
  }
  roles[role] = roles[role] || {} // in case the role is incorrect this'll make sure the page still loads
  const currentUserRole = component.props.user.role // role of logged in user
  return (
    <span onClick={currentUserRole.match('ADMIN') ? e => component.openQuickactions(e, role) : null} className={`label ${roles[role].color}`}>{roles[role].name}</span>
  )
}

class UsersView extends Component {
  constructor (props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search || '')
    const intialFilter = searchParams.get('filter') || ''
    const intialSort = searchParams.get('sort') || ''

    this.state = {
      users: [],
      filterStatus: intialFilter,
      sortStatus: intialSort,
      timesLoaded: 1,
      loading: false
    }

    this.getNewList = this.getNewList.bind(this)
    this.getFilteredList = this.getFilteredList.bind(this)
    this.gotoUser = this.gotoUser.bind(this)
    this.triggerLogout = this.triggerLogout.bind(this)
    this.openQuickactions = this.openQuickactions.bind(this)
    this.updateURL = this.updateURL.bind(this)
  }

  componentWillMount () {
    document.title = this.props.t('title:users')
  }

  componentDidMount () {
    if (this.state.users.length === 0) {
      this.setState({
        loading: true
      })
      fetchUsers({
        component: this,
        sortType: this.state.sortStatus,
        filter: this.state.filterStatus
      }, (users, usersElements) => {
        const state = this.state
        state.users = users
        window.dataCache.usersState = state
        this.setState({
          users: users,
          loading: false
        })
      })
    }

    setTimeout(function (comp) {
      initScrollLoad(comp)
    }, 100, this)

    $(window).on('filterTrigger', (event, filter) => {
      this.getFilteredList(filter)
    })

    $(window).on('getSortedData', (event, sortType) => {
      const sort = (this.state.sortStatus === sortType) ? `-${sortType}` : sortType
      this.getNewList(sort)
    })
  }

  componentWillUnmount () {
    $(window).trigger('resetSearchbar')
    $(window).off('filterTrigger')
    $(window).off('.scrollHandler')
    this.isUnmounting = true
  }

  getNewList (sortType) {
    this.setState({
      loading: true
    })
    fetchUsers({
      component: this,
      sortType: sortType,
      filter: this.state.filterStatus
    }, (users, usersElements) => {
      const state = this.state
      state.users = users
      state.sortStatus = sortType
      state.timesLoaded = 1
      window.dataCache.usersState = state
      this.updateURL('sort', sortType)
      this.setState({
        state,
        loading: false
      })
    })
  }

  getFilteredList (filter) {
    this.setState({
      loading: true
    })
    fetchUsers({
      component: this,
      sortType: this.state.sortStatus,
      filter: filter
    }, (users) => {
      const state = this.state
      state.users = users
      state.filterStatus = filter
      state.timesLoaded = 1
      window.dataCache.usersState = state
      this.updateURL('filter', filter)
      this.setState({
        state,
        loading: false
      })
      if (filter === '') {
        initScrollLoad(this)
      }
    })
  }

  updateURL (name, value) {
    const newParams = new window.URLSearchParams(this.props.location.search)
    newParams.set(name, value)
    this.props.history.replace({
      search: '?' + newParams.toString()
    })
  }

  gotoUser (event) {
    this.props.history.push(`/gebruiker/${event.currentTarget.id}`)
  }

  triggerLogout () {
    localStorage.removeItem('authToken')
    $(window).trigger('loggedOut')
    this.props.history.push('/login')
  }

  openQuickactions (event, role) {
    event.stopPropagation()
    const t = this.props.t
    if (!working) {
      working = true
      const $theTarget = $(event.currentTarget)
      $(window).off('.quickActionsDisabler')
      $('.quickAction_tip').remove()
      const theQuickActionThing = (`<div class="quickAction_tip" style="display:none;"><div class="quickAction_tip_arrow"></div><div class="quickAction_tip_inner"><form><fieldset class="form-group"><label for="rol"><T i18nKey="user:role">Rol</T></label><select value="werknemer" class="form-control" id="rol"><option value="ADMIN">${t('user:admin')}</option><option value="EMPLOYEE">${t('user:employee')}</option><option value="FREELANCER">${t('user:freelancer')}</option><option value="PARTNER">${t('user:partner')}</option><option value="ONEPROJECTER">${t('user:inspector')}</option></select></fieldset><fieldset class="form-group"><button type="submit" class="form-submit"><T i18nKey="common:save">Opslaan</T></button></fieldset></form></div></div>`)
      $theTarget.after(theQuickActionThing)

      if (role.match('ADMIN') || role.match('ADMIN+')) {
        $theTarget.next().find('select').append(`<option value="ADMIN+">${t('user:admin_plus')}</option>`)
      }

      setTimeout(function (component) {
        const quickActionMenu = $theTarget.next()
        quickActionMenu.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          quickActionMenu.fadeOut(200, () => {
            working = false
            quickActionMenu.remove()
          })
        })

        quickActionMenu.on('click', function (event) {
          event.stopPropagation()
        })
        quickActionMenu.find('form').on('submit', function (event) {
          event.preventDefault()

          $(window).off('.quickActionsDisabler')
          quickActionMenu.fadeOut(200, () => {
            working = false
            quickActionMenu.remove()
          })

          const userId = $(event.currentTarget).closest('tr')[0].id
          const newValue = quickActionMenu.find('select').val()

          const form = new FormData()
          form.append('role', newValue)

          const settings = {
            async: true,
            crossDomain: true,
            url: `${location.origin}/v1/user/${userId}`,
            method: 'PUT',
            headers: {
              authorization: `Bearer ${component.props.token.token}`
            },
            processData: false,
            contentType: false,
            mimeType: 'multipart/form-data',
            data: form
          }

          if (component.props.token.decodedToken.content.userId === userId) {
            component.setState({
              loginAgain: true
            })
          }

          const promise = $.ajax(settings)
          promise.done(function (response) {
            component.getNewList(component.state.sortStatus) // TODO could probably do this better, might not need it
          })
          promise.fail(function (error) {
            console.error(error)
          })
        })
      }, 10, this)
    }
  }

  showSortIcon (value) {
    const sortStatus = this.state.sortStatus || 'name'
    if (value === sortStatus) {
      return (
        <span className="glyphicons glyphicons-sort-by-order"></span>
      )
    }
    if (value === sortStatus.slice(1)) {
      return (
        <span className="glyphicons glyphicons-sort-by-order-alt"></span>
      )
    }
  }

  sortList (value) {
    $(window).trigger('getSortedData', value)
  }

  render () {
    const users = this.state.users
    const t = this.props.t
    const loadingRow = (
      <tr>
        <td colSpan="4"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
      </tr>
    )

    let list = []
    if (users) {
      let img
      let imgUrl
      users.forEach((user) => {
        user.organisation = user.organisation || {}
        imgUrl = user.profile_picture_icon_url || '/images/default_user_icon.png'
        img = <img className="profile_pic" alt={user.name} src={`${location.origin}${imgUrl}`} />
        list.push(
          <tr onClick={this.gotoUser} id={user._id} key={user._id}>
            <td><div className="micro profile_pic_round">{img}</div></td>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>
              {createLabel(this, user.role, t)}
            </td>
            <td>{user.organisation.name}</td>
          </tr>
        )
      })
      if (list.length === 0 && !this.state.loading) {
        list.push(<tr key="noResults"><td></td><td><T i18nKey="user:no_results">Geen resultaten voor je zoekopdracht</T></td><td></td><td></td></tr>)
      }
    } else {
      list = null
    }
    return (
      <section className="container content users">
        {this.state.loginAgain ? <p><T i18nKey="user:logout_message">Log uit en terug in om uw rol te vernieuwen.</T></p> : ''}
        <section className="list_block">
          <table className="list_table">
            <thead>
              <tr>
                <th style={{ width: '35px' }}></th>
                <th onClick={this.sortList.bind(null, 'name')} className="sortables"><T i18nKey="user:name">Naam</T> {this.showSortIcon('name')}</th>
                <th onClick={this.sortList.bind(null, 'email')} className="sortables"><T i18nKey="user:email">Email</T> {this.showSortIcon('email')}</th>
                <th onClick={this.sortList.bind(null, 'role')} className="sortables" style={{ width: '120px' }}><T i18nKey="user:role">Rol</T> {this.showSortIcon('role')}</th>
                <th onClick={this.sortList.bind(null, 'organisation')} className="sortables" style={{ width: '400px' }}><T i18nKey="user:organisation">Organisatie</T> {this.showSortIcon('organisation')}</th>
              </tr>
            </thead>
            <tbody>
              {list}
              {this.state.loading && loadingRow}
            </tbody>
          </table>
        </section>
      </section>
    )
  }
}

const Users = withTranslation()(UsersView)
export { Users }
