import React from 'react'

export const createLabel = function (type) {
  const types = {
    interval_1week: {
      color: 'blue',
      name: '1w'
    },
    interval_2weeks: {
      color: 'purple',
      name: '2w'
    },
    interval_3weeks: {
      color: 'darkred',
      name: '3w'
    },
    interval_1month: {
      color: 'green',
      name: '1m'
    },
    interval_6weeks: {
      color: 'orange',
      name: '6w'
    },
    interval_3x8months: {
      color: 'red',
      name: '3x8m'
    }
  }
  types[type] = types[type] || {} // in case the type is incorrect this'll make sure the page still loads
  return (
    <span className={`label ${types[type].color}`}>{types[type].name}</span>
  )
}

export const createProjectNumLabel = function (num) {
  return <span className="amount">{num}</span>
}

export const showSortIcon = function (value) {
  const sortStatus = window.dataCache.sortStatus || 'name'
  if (value === sortStatus) {
    return (
      <span className="glyphicons glyphicons-sort-by-order"></span>
    )
  }
  if (value === sortStatus.slice(1)) {
    return (
      <span className="glyphicons glyphicons-sort-by-order-alt"></span>
    )
  }
}
