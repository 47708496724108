import React, { Component } from 'react'

import { ToolUserCsvExport } from './toolUsersCsvExport.jsx'
import { withTranslation } from 'react-i18next'

class AdminToolsPageView extends Component {
  componentDidMount () {
    document.title = this.props.t('title:tools')
  }

  render () {
    return (
      <section className="container tools">
        <ToolUserCsvExport token={this.props.token.token} />
      </section>
    )
  }
}

const AdminToolsPage = withTranslation()(AdminToolsPageView)
export { AdminToolsPage }
