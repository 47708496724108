import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import { ProjectRow } from './projectRow.jsx'
import location from '../../../location'
const $ = window.$

export class ProjectsMain extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sortedProjects: []
    }
  }

  componentWillMount () {
    this.sortProjects()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.loading !== this.props.loading) { this.sortProjects(nextProps.planningObjects) }
  }

  sortProjects (planningObjects) {
    planningObjects = planningObjects || this.props.planningObjects || []

    const sortedPlanning = {}
    let x = 14
    const someDay = moment().startOf('d')
    let formattedDay
    while (x !== 0) {
      x--
      // if not sunday
      if (someDay.day() !== 0) {
        formattedDay = someDay.format('YYYY-MM-DD')
        sortedPlanning[formattedDay] = []
      }

      someDay.add(1, 'd')
    }

    let planningObject
    for (let i = 0, l = planningObjects.length; i < l; i++) {
      planningObject = planningObjects[i]
      // planningObjects.forEach(planningObject => {
      // const project = planningObject.project;

      let projectPlannedDate = moment(planningObject.planned_date).startOf('d')
      const today = moment().startOf('d')
      if (projectPlannedDate.isBefore(today)) { // if the project was planned in the past move it to today
        projectPlannedDate = today.format('YYYY-MM-DD')
      } else {
        projectPlannedDate = projectPlannedDate.format('YYYY-MM-DD')
      }

      sortedPlanning[projectPlannedDate] = sortedPlanning[projectPlannedDate] || []
      sortedPlanning[projectPlannedDate].push(planningObject)

      // });
    }

    this.setState({
      sortedProjects: sortedPlanning
    })
  }

  render () {
    const userIsAdminPlus = this.props.user.role === 'ADMIN+'
    const days = _.map(this.state.sortedProjects, (plannings, key, list) => {
      const tableRows = []
      tableRows.push(
        <DateTR
          key={key + '_head'}
          userIsAdminPlus={userIsAdminPlus}
          refreshProjects={this.getProjects}
          token={this.props.token}
          date={key}
        />
      )
      plannings.forEach((planning) => {
        tableRows.push(
          <ProjectRow
            history={this.props.history}
            date={key}
            token={this.props.token}
            planning={planning}
            key={planning._id}
            userIsAdminPlus={userIsAdminPlus}
          />
        )
      })
      return tableRows
    })

    return (
      <section className="planning-table planned-table">
        <table className="list_table">
          <tbody>
            {days}
          </tbody>
        </table>
      </section>
    )
  }
}

class DateTR extends Component {
  constructor (props) {
    super(props)

    this.rowRef = React.createRef()

    this.dragChange = this.dragChange.bind(this)
  }

  componentDidMount () {
    if (this.props.userIsAdminPlus) {
      const $planningRow = $(this.rowRef.current)
      // planning droppable
      $planningRow.droppable({
        tolerance: 'pointer',
        deactivate: (event, ui) => {
          $('.project_dragger').removeClass('ok')
          $('.project_dragger').removeClass('notok')
        },
        over: (event, ui) => {
          $('.project_dragger').removeClass('ok')
          $('.project_dragger').removeClass('notok')
          const myDate = moment(this.props.date, 'YYYY-MM-DD')
          const yourDate = moment($(ui.draggable).data('date'), 'YYYY-MM-DD')
          const isBeforeMyDate = yourDate.isBefore(myDate)

          let newClass = ''
          if (!isBeforeMyDate) {
            newClass = 'ok'
          } else {
            newClass = 'notok'
          }
          $('.project_dragger').addClass(newClass)
        },
        drop: (event, ui) => {
          this.dragChange($(ui.draggable).data('planning-id'))
        },
        hoverClass: 'dropOnMe'
      })
    }
  }

  dragChange (planningId) {
    const newDate = {
      planned_date: new Date(this.props.date)
    }

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning?id=${planningId}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(newDate)
    }

    $.ajax(settings).done(response => {
      // this.props.refreshProjects();
      // trigger reload of planning in de planning slider
      $(window).trigger('updatePlanningSlider')
      const refreshProjectsEvent = new CustomEvent('filterReloadProjects')
      window.dispatchEvent(refreshProjectsEvent)
    }).fail(error => {
      console.error(error)
    })
  }

  render () {
    const date = moment(this.props.date).format('dd, D MMM')
    return (
      <tr ref={this.rowRef} className="planning_date">
        <td colSpan={this.props.userIsAdminPlus ? 4 : 3}>
          {date}
        </td>
      </tr>
    )
  }
}
