import React from 'react'

import { VgpView as VgpOldView } from './old/vgp.jsx'
import { VgpView } from './vgp.jsx'

export const VGPMainView = props =>
  <div className="vgpMain">
    {props.project.oldVGPType
      ? <VgpOldView {...props} />
      : <VgpView {...props} />
    }
  </div>
