import React, { Component } from 'react'
import { FilesView } from './fileManager.jsx'
import { TemplatesView } from './templateManager.jsx'
import { PidMailView } from './pidMailManager.jsx'
import { Trans as T, withTranslation } from 'react-i18next'

class SettingsPageView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showTab: 'files'
    }

    this.changeTab = this.changeTab.bind(this)
    this.activeTab = this.activeTab.bind(this)
  }

  componentWillMount () {
    document.title = this.props.t('title:settings')
  }

  changeTab (newTab) {
    this.setState({
      showTab: newTab
    })
  }

  activeTab (tabName) {
    return tabName === this.state.showTab ? 'active' : ''
  }

  render () {
    let rightSection
    /* eslint-disable */
    switch (this.state.showTab) {
      case 'files': rightSection = <FilesView {...this.props} />; break
      case 'templates': rightSection = <TemplatesView {...this.props} />; break
      case 'pidMail': rightSection = <PidMailView {...this.props} />; break
      default: rightSection = <h1>nothing</h1>
    }
    /* eslint-enable */
    return (
      <section className="container content">
        <section className="left_menu">
          <ul>
            <li className={this.activeTab.bind(this, 'files')()}>
              <a href='#' onClick={this.changeTab.bind(this, 'files')}><T i18nKey="settings:files">Bestanden</T></a>
            </li>
            <li className={this.activeTab.bind(this, 'templates')()}>
              <a href='#' onClick={this.changeTab.bind(this, 'templates')} ><T i18nKey="settings:mail_templates">Mail templates</T></a>
            </li>
            <li className={this.activeTab.bind(this, 'pidMail')()}>
              <a href='#' onClick={this.changeTab.bind(this, 'pidMail')} ><T i18nKey="settings:pid_mail">Pid email</T></a>
            </li>
          </ul>
        </section>
        {rightSection}
      </section>
    )
  }
}

const SettingsPage = withTranslation()(SettingsPageView)
export { SettingsPage }
