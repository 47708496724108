import React, { Component } from 'react'
import { ProjectsTableRow } from './rows.jsx'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class ProjectsTableView extends Component {
  expandPicture (event) {
    const $target = $(event.currentTarget)
    $target.addClass('active')
    const alt = $target.find('img')[0].alt
    const newDiv = $('<div>').html(alt).addClass('profile_pic_name')
    $(event.currentTarget).before(newDiv)
  }

  withdrawPicture (event) {
    const $target = $(event.currentTarget)
    $target.removeClass('active')
    $target.prev('.profile_pic_name').remove()
  }

  sortList (value) {
    window.dispatchEvent(new CustomEvent('getSortedData', { detail: value }))
  }

  showSortIcon (sortType) {
    const sortState = this.props.sort
    if (sortType === sortState) {
      return (
        <span className="glyphicons glyphicons-sort-by-order"></span>
      )
    }
    if (sortType === sortState.slice(1)) {
      return (
        <span className="glyphicons glyphicons-sort-by-order-alt"></span>
      )
    }
  }

  createHeaders () {
    const projectStatus = this.props.projectStatus
    switch (projectStatus) {
      case 'design': return (
        <tr>
          <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables'><T i18nKey="project:project_number_short">Project nr.</T> {this.showSortIcon('afas_id')}</th>
          <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
          <th style={{ width: '25px' }}></th>
          <th style={{ width: '30px' }}><T i18nKey="project:client">Klant</T></th>
          <th style={{ width: '30px' }}><T i18nKey="project:vc">VC</T></th>
          <th style={{ width: '26px' }}></th>
        </tr>
      )
      case 'execution': return (
        <tr>
          <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables'><T i18nKey="project:project_number_short">Project nr.</T> {this.showSortIcon('afas_id')}</th>
          <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
          <th style={{ width: '25px' }}></th>
          <th style={{ width: '30px' }}><T i18nKey="project:client">Klant</T></th>
          <th style={{ width: '30px' }}><T i18nKey="project:vc">VC</T></th>
          <th style={{ width: '150px' }} onClick={this.sortList.bind(this, 'last_visit')} className='sortables'><T i18nKey="project:latest_visit">Laatst bezocht</T> {this.showSortIcon('last_visit')}</th>
          <th style={{ width: '26px' }}></th>
        </tr>
      )
      case 'createPid': return (
        <tr>
          <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables'><T i18nKey="project:project_number_short">Project nr.</T> {this.showSortIcon('afas_id')}</th>
          <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
          <th style={{ width: '25px' }}></th>
          <th style={{ width: '30px' }}><T i18nKey="project:client">Klant</T></th>
          <th style={{ width: '30px' }}><T i18nKey="project:vc">VC</T></th>
          <th style={{ width: '150px' }} onClick={this.sortList.bind(this, 'last_visit')} className='sortables'><T i18nKey="project:latest_visit">Laatst bezocht</T> {this.showSortIcon('last_visit')}</th>
          <th style={{ width: '26px' }}></th>
        </tr>
      )
      case 'finished': return (
        <tr>
          <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables'><T i18nKey="project:project_number_short">Project nr.</T>  {this.showSortIcon('afas_id')}</th>
          <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
          <th style={{ width: '25px' }}></th>
          <th style={{ width: '30px' }}><T i18nKey="project:client">Klant</T> </th>
          <th style={{ width: '30px' }}><T i18nKey="project:vc">VC</T></th>
          <th style={{ width: '150px' }} onClick={this.sortList.bind(this, 'finished_date')} className='sortables'><T i18nKey="project:end_date">Eind datum</T> {this.showSortIcon('finished_date')}</th>
          <th style={{ width: '26px' }}></th>
        </tr>
      )
      case 'waiting': return (
        <tr>
          <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables' ><T i18nKey="project:project_number_short">Project nr.</T> {this.showSortIcon('afas_id')}</th>
          <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
          <th style={{ width: '25px' }}></th>
          <th style={{ width: '30px' }}><T i18nKey="project:client">Klant</T></th>
          <th style={{ width: '30px' }}><T i18nKey="project:vc">VC</T></th>
          <th style={{ width: '150px' }} onClick={this.sortList.bind(this, 'start_date')} className='sortables'><T i18nKey="project:start_date">Start datum</T> {this.showSortIcon('start_date')}</th>
          <th style={{ width: '26px' }}></th>
        </tr>
      )
      case 'new': return (
        <tr>
          <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables' ><T i18nKey="project:project_number_short">Project nr.</T> {this.showSortIcon('afas_id')}</th>
          <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
        </tr>
      )
      case 'onHold': return (
        <tr>
          <th style={{ width: '140px' }} onClick={this.sortList.bind(this, 'afas_id')} className='sortables' ><T i18nKey="project:project_number_short">Project nr.</T> {this.showSortIcon('afas_id')}</th>
          <th onClick={this.sortList.bind(this, 'project_name')} className='sortables'><T i18nKey="project:title">Titel</T> {this.showSortIcon('project_name')}</th>
          <th style={{ width: '25px' }}></th>
          <th style={{ width: '30px' }}><T i18nKey="project:client">Klant</T></th>
          <th style={{ width: '30px' }}><T i18nKey="project:vc">VC</T></th>
          <th style={{ width: '150px' }} onClick={this.sortList.bind(this, 'onHoldUntilAtLeastDate')} className='sortables'><T i18nKey="project:until_at_least">Tot minstens</T> {this.showSortIcon('onHoldUntilAtLeastDate')}</th>
          <th style={{ width: '26px' }}></th>
        </tr>
      )
      default: return (<tr><th>error</th></tr>)
    }
  }

  render () {
    const projectStatus = this.props.projectStatus
    const projects = this.props.projects || []
    const loadingRow = (
      <tr>
        <td colSpan="10" className="table-loader"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
      </tr>
    )
    const tableRows = projects.map((project) => {
      return <ProjectsTableRow history={this.props.history} key={project._id} user={this.props.user} token={this.props.token} projectStatus={projectStatus} project={project} userCanSeeVisitLabel={this.props.userCanSeeVisitLabel} expandPicture={this.expandPicture} withdrawPicture={this.withdrawPicture} />
    })

    let extraClasses = ''
    if (projectStatus === 'new') {
      extraClasses = 'warning'
    }
    return (
      <section className={`projects_table list_block ${extraClasses}`}>
        <table className="list_table">
          <thead>
            {this.createHeaders()}
          </thead>
          <tbody>
            {tableRows}
            {this.props.loading && loadingRow}
          </tbody>
        </table>
      </section>
    )
  }
}

const ProjectsTable = withTranslation()(ProjectsTableView)
export { ProjectsTable }
