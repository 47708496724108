import React, { Component } from 'react'
import _ from 'underscore'
// const location = window.location;
import location from '../../../../location'
import { Trans as T } from 'react-i18next'
import i18n from '../../../../i18n.js'
const $ = window.$

const formatResult = function (user) {
  if (user.loading) return user.text
  let markup = user.name
  const organisationName = (user.organisation || {}).name || i18n.t('project:no_organisation')
  markup = markup + ' - ' + organisationName
  return markup
}

const formatSelection = function (user) {
  return user.text
}

export class AddContact extends Component {
  constructor (props) {
    super(props)

    this.state = {
      role: '',
      ss_role_secondary: '',
      user: '',
      contacts: [],
      loading: false,
      buttonDisabled: false
    }

    this.initiateFindUsersDropdown = this.initiateFindUsersDropdown.bind(this)
    this.changeField = this.changeField.bind(this)
    this.changeSSRole = this.changeSSRole.bind(this)
    this.submitContact = this.submitContact.bind(this)
    this.changeSecondary = this.changeSecondary.bind(this)

    this.usersSelectRef = React.createRef()
    this.formRef = React.createRef()
  }

  componentDidMount () {
    this.initiateFindUsersDropdown()

    $(this.usersSelectRef.current).on('change', (event) => {
      this.changeField('user', event)
    })
  }

  componentWillUnmount () {
    // delete window.dataCache.contacts;
    $('.select2').remove()
    this.isUnmounting = true
  }

  componentWillReceiveProps (nextProps) {
    if (!nextProps.loading) {
      this.setState({ buttonDisabled: false })
    }
  }

  initiateFindUsersDropdown () {
    const token = this.props.token.token
    $('#findUsers').select2({
      ajax: {
        async: true,
        crossDomain: true,
        url: function (params) {
          return `${location.origin}/v1/user?search=${encodeURIComponent(params.term)}`
        },
        headers: {
          authorization: `Bearer ${token}`
        },
        delay: 250,
        processResults: (data) => {
          this.setState({
            contacts: data
          })
          // window.dataCache.contacts = data;
          data = $.map(data, function (object) {
            object.id = object._id
            object.text = object.name
            return object
          })

          return {
            results: data
          }
        },
        cache: true
      },
      escapeMarkup: function (markup) {
        return markup
      },
      minimumInputLength: 3,
      templateResult: formatResult,
      templateSelection: formatSelection
    })
  }

  changeField (fieldName, event) {
    const state = _.clone(this.state)
    state[fieldName] = event.currentTarget.value
    if (!this.isUnmounting) {
      this.setState(
        state
      )
    }
  }

  changeSSRole (event) {
    if (!this.isUnmounting) {
      this.setState({
        role: event.currentTarget.value || ''
      })
    }
  }

  submitContact (event) {
    if (!this.state.loading) {
      this.props.submitContact(event, this)
    }
  }

  changeSecondary (e) {
    const newVal = e.target.value
    this.setState(state => ({
      ss_role_secondary: newVal
    }))
  }

  render () {
    return (
      <div>
        <h3><T i18nKey="project:add_exisiting_user">Voeg bestaande gebruiker toe</T></h3>
        <form ref={this.formRef} onSubmit={this.submitContact}>
          <fieldset className="form-group">
            <label htmlFor="findUsers"><T i18nKey="project:contact">Contactpersoon</T> <span className="required"></span></label>
            <select ref={this.usersSelectRef} className="form-control" id="findUsers">
            </select>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="roleInput"><T i18nKey="project:role_function">Rol (Functie)</T> <span className="required"></span></label>
            <select value={this.state.role} onChange={this.changeSSRole} className="form-control" id="roleInput">
              <option>--</option>
              {this.props.ssRoles.roleOptionElements}
            </select>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="secondaryRoleInput"><T i18nKey="project:subrole">Subrol</T></label>
            <input type="text" className="form-control" id="secondaryRoleInput" value={this.state.ss_role_secondary} onChange={this.changeSecondary} />
          </fieldset>
          {this.state.error
            ? <span className="message warning">
              {this.state.error}
            </span>
            : null}
          <fieldset className="form-group">
            <button type="submit" className="form-submit" disabled={this.state.buttonDisabled}>
              {this.state.loading
                ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                : null}
                            &nbsp;<T i18nKey="project:add">Toevoegen</T></button>
          </fieldset>
        </form>
      </div>
    )
  }
}
