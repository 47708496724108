import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import { hasPermissionFor, deleteFileFromProject } from './../../../tools.jsx'
import { FileUploadForm } from '../../reactUtils/fileUploadForm.jsx'
import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

let working = false

class PIDViewComp extends Component {
  constructor (props) {
    super(props)

    const files = _.filter(this.props.project.files, function (file) {
      return !file.isDeleted
    })
    this.state = {
      loading: false,
      files: files,
      fileLength: files.length
    }

    this.updateFiles = this.updateFiles.bind(this)
  }

  componentDidMount () {
    $(window).on('HerlaadFiles', $.proxy(function () {
      this.updateFiles()
    }, this))
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  updateFiles () {
    this.setState({
      loading: true
    })
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.match.params.id}?select=files`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    $.ajax(settings).done((projectResponse) => {
      if (!this.isUnmounting) {
        const files = _.filter(projectResponse.files, function (file) {
          return !file.isDeleted
        })
        this.setState({
          files: files,
          fileLength: files.length,
          loading: false
        })
        this.props.reloadProject()
      }
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      this.setState({
        error: responseMessage,
        loading: false
      })
    })
  }

  render () {
    const files = _.filter(this.state.files, function (file) {
      return !file.isDeleted
    })
    const t = this.props.t

    const bundledZip = _.find(files, file => {
      return file.file_type === 'bundledZip'
    })

    // filter files per folder
    const fileCollections = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      9: []
    }
    files.forEach(file => {
      /* eslint-disable */
            switch (file.file_type) {
                case 'pid_type1': fileCollections[1].push(file); break
                case 'pid_type2': fileCollections[2].push(file); break
                case 'pid_type3': fileCollections[3].push(file); break
                case 'pid_type4': fileCollections[4].push(file); break
                case 'pid_type5': fileCollections[5].push(file); break
                case 'pid_type6': fileCollections[6].push(file); break
                case 'pid_type7': fileCollections[7].push(file); break
                case 'pid_type9': fileCollections[9].push(file); break
                default:
            }
            /* eslint-enable */
    })
    return (
            <section className="right_content">
                <h1><T i18nKey="project:intervention_file">Postinterventiedossier</T></h1>
                <FinaliseProjectView reloadProject={this.props.reloadProject} user={this.props.user} bundledZip={bundledZip} project={this.props.project} token={this.props.token} updateFiles={this.updateFiles}/>
                <PIDSubTypeView key="folder1" index="1" file_type="pid_type1" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[1]} token={this.props.token} loading={this.state.loading} title={t('project:administrative_part')}/>
                <PIDSubTypeView key="folder2" index="2" file_type="pid_type2" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[2]} token={this.props.token} loading={this.state.loading} title={t('project:received_administrative_information')}/>
                <PIDSubTypeView key="folder3" index="3" file_type="pid_type3" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[3]} token={this.props.token} loading={this.state.loading} title={t('project:specifications')}/>
                <PIDSubTypeView key="folder4" index="4" file_type="pid_type4" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[4]} token={this.props.token} loading={this.state.loading} title={t('project:plans')}/>
                <PIDSubTypeView key="folder5" index="5" file_type="pid_type5" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[5]} token={this.props.token} loading={this.state.loading} title={t('project:structural_features')}/>
                <PIDSubTypeView key="folder6" index="6" file_type="pid_type6" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[6]} token={this.props.token} loading={this.state.loading} title={t('project:info_on_technical_installations')}/>
                <PIDSubTypeView key="folder7" index="7" file_type="pid_type7" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[7]} token={this.props.token} loading={this.state.loading} title={t('project:technical_token')}/>
                <CDFiles key="folder8" updateFiles={this.updateFiles} project={this.props.project} user={this.props.user} token={this.props.token}/>
                <PIDSubTypeView key="folder9" index="9" file_type="pid_type9" user={this.props.user} updateFiles={this.updateFiles} project={this.props.project} files={fileCollections[9]} token={this.props.token} loading={this.state.loading} title={t('project:pv_of_instrument_transfer')}/>
            </section>
    )
  }
}

const PIDView = withTranslation()(PIDViewComp)
export { PIDView }

class FinaliseProjectViewComp extends Component {
  constructor (props) {
    super(props)

    const isAdminPlus = hasPermissionFor(this.props.user.role, 'ADMIN+')
    const isAdmin = hasPermissionFor(this.props.user.role, 'ADMIN')

    this.state = {
      loading: false,
      hasCloseProjectPermission: isAdminPlus || (isAdmin && props.project.status === 'createPid')
    }

    this.closeProject = this.closeProject.bind(this)
  }

  closeProject () {
    const t = this.props.t
    if (this.props.project.status !== 'finished') {
      const prompt = window.confirm(t('project:finish_confirm'))
      if (prompt) {
        this.setState({
          loading: true
        })
        const settings = {
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/project/finalise?project=${this.props.project._id}`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${this.props.token.token}`
          }
        }
        $.ajax(settings)
          .done(oldProject => {
            this.props.updateFiles()
            this.props.reloadProject()
          })
          .fail(error => {
            console.error(error)
            let responseMessage
            try {
              responseMessage = JSON.parse(error.responseText).message
            } catch (error) {
              console.error(error)
            }
            this.setState({
              error: responseMessage
            })
          })
      }
    }
  }

  render () {
    if (this.state.hasCloseProjectPermission) {
      const bundledZip = this.props.bundledZip
      if (bundledZip) {
        const zipFile = bundledZip.file
        return (
                    <div>
                        <table className="list_table">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th style={{ width: 110 }}>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="no-pointer">
                                    <td>{zipFile.fileName}</td>
                                    <td>
                                        <a href={`${location.origin}/v1/file/${zipFile._id}/-1/${encodeURIComponent(zipFile.fileName)}`}>
                                            <span className="glyphicons glyphicons-download-alt"></span> <T i18nKey="common:download">Download</T>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
        )
      } else {
        return (
                    <div>
                        <button className="btn-green btn-low right" onClick={this.closeProject}>
                            {this.state.loading
                              ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                              : null}
                            &nbsp;<T i18nKey="project:close_pid">PID afsluiten</T>
                        </button>
                        <div className="clear"></div>
                    </div>
        )
      }
    } else {
      return (null)
    }
  }
}
const FinaliseProjectView = withTranslation()(FinaliseProjectViewComp)

class CDFilesView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showFileForm: false
    }

    this.deleteFile = this.deleteFile.bind(this)
    this.showNewVersionFileForm = this.showNewVersionFileForm.bind(this)
    this.closeFileForm = this.closeFileForm.bind(this)
  }

  componentWillUpdate (nextProps, nextState) {
    const $tables = $('.list_table')
    $tables.hide(0, function () {
      $tables.show()
    })
  }

  deleteFile (event) {
    event.preventDefault()
    event.stopPropagation()
    const t = this.props.t

    const confirmed = window.confirm(t('project:confirm_delete'))
    if (!confirmed) {
      return
    }

    const fileId = $(event.currentTarget).data('id')
    const projectId = this.props.project._id
    const token = this.props.token.token

    deleteFileFromProject(fileId, token, projectId).done((response) => {
      console.log('done')
      this.props.updateFiles()
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      this.setState({
        error: responseMessage
      })
    })
  }

  showNewVersionFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: false,
      oldFileId: $(event.currentTarget).data('id')
    })
  }

  closeFileForm (event) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileForm: false
    })
  }

  render () {
    const t = this.props.t
    const project = this.props.project
    const files = _.filter(project.files, function (file) {
      return !file.isDeleted
    })
    const reportsList = project.visits.map((report, index, array) => {
      let reportHasBeenSent = report.report_mailings || []
      reportHasBeenSent = reportHasBeenSent.slice(-1)[0]
      let url
      let fileName = t('project:no_filename')
      if (reportHasBeenSent) {
        let file = _.find(files, function (file) {
          return parseInt(file.visit_id) === parseInt(report.visit_id)
        })
        if (file) {
          try {
            file = file.file
            fileName = file.fileName
            url = <td><a target="_blank" rel="noopener noreferrer" href={`${location.origin}/v1/file/${file._id}/-1/${fileName}`}><T i18nKey="common:download">Download</T></a></td>
          } catch (error) {
            url = <td><T i18nKey="project:not_available_yet">Nog niet beschikbaar</T></td>
          }
        } else {
          url = <td><T i18nKey="project:not_available_yet">Nog niet beschikbaar</T></td>
        }
      } else {
        url = <td><T i18nKey="project:no_file_available_yet">Nog geen bestand beschikbaar</T></td>
      }
      // format date
      let visitDate = report.date
      if (visitDate) {
        visitDate = moment(visitDate).format('DD-MM-YYYY')
      }
      let ss = '(SS)'
      if (report.__source === 'safetysnapper') {
        ss = '(SS)'
      } else if (report.__source === 'archisnapper') {
        ss = '(AS)'
      }
      return ({
        date: moment(report.date),
        row:
                    <tr key={report.visit_id}>
                        <td>{fileName}</td>
                        <td>{visitDate}</td>
                        <td>
                            <T i18nKey="project:safety_coordinator">Veiligheidscoördinator</T> {ss}
                        </td>
                        {url}
                        <td></td>
                    </tr>
      })
    })

    // Get a list of all user uploaded reports
    const remainingFiles = _.filter(files, function (file) {
      return file.file_type === 'pid_type8_userupload'
    })

    // Combine reports and uploaded user reports in one array and sort on date
    const reportsAndFilesList = _.sortBy(reportsList.concat(remainingFiles), function (item) {
      const date = (item.date) ? item.date.valueOf() : item.file.customDate
      return date * -1
    })

    // Generate the react components for all the files en reports
    const completeFilesList = []
    const that = this
    _.each(reportsAndFilesList, function (item) {
      if (item.row) {
        completeFilesList.push(item.row)
      } else {
        const file = item
        completeFilesList.push(<FileView key={file._id} showNewVersionFileForm={that.showNewVersionFileForm} user={that.props.user} deleteFile={that.deleteFile} file={file} token={that.props.token}/>)
      }
    })

    return (
            <span>
                <h4>8 <T i18nKey="project:coordination_diary">Coördinatiedagboek</T></h4>
                {this.state.showFileForm
                  ? <FileUploadForm closeFileForm={this.closeFileForm} token={this.props.token} user={this.props.user} fileType={{ type: 'pid', index: this.props.index }} project={this.props.project} newFile={this.state.newFileVersion} oldFileId={this.state.oldFileId} updateParentView={this.props.updateFiles}/>
                  : null
                }
                <table className="list_table">
                    {completeFilesList.length !== 0
                      ? [
                            <thead key="thead">
                                <tr>
                                    <th><T i18nKey="project:filename">Bestandsnaam</T></th>
                                    <th style={{ width: 100 }}><T i18nKey="project:date">Datum</T></th>
                                    <th><T i18nKey="project:uploader">Uploader</T></th>
                                    <th><T i18nKey="common:download">Download</T></th>
                                    <th style={{ width: 26 }} ></th>
                                </tr>
                            </thead>,
                            <tbody key="tbody">
                                {completeFilesList}
                            </tbody>
                        ]
                      : <tbody>
                            <tr className="no-hover">
                                <td><T i18nKey="project:there_are_no_reports_yet">Er zijn nog geen verslagen.</T></td>
                            </tr>
                        </tbody>
                    }
                </table>
            </span>
    )
  }
}
const CDFiles = withTranslation()(CDFilesView)

class PIDSubTypeViewComp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: null,
      loading: this.props.loading,
      uploading: false,
      emailing: false,
      finished: this.props.project.status === 'finished',
      showFileForm: false
    }

    this.deleteFile = this.deleteFile.bind(this)
    this.showFileForm = this.showFileForm.bind(this)
    this.closeFileForm = this.closeFileForm.bind(this)
    this.showNewVersionFileForm = this.showNewVersionFileForm.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return true
  }

  componentDidUpdate (prevProps, prevState) {
    const $tables = $('.list_table')
    $tables.hide(0, function () {
      $tables.show()
    })
  }

  deleteFile (event) {
    event.preventDefault()
    event.stopPropagation()
    const t = this.props.t

    const confirmed = window.confirm(t('project:confirm_delete'))
    if (!confirmed) {
      return
    }

    const fileId = $(event.currentTarget).data('id')
    const projectId = this.props.project._id
    const token = this.props.token.token

    deleteFileFromProject(fileId, token, projectId).done((response) => {
      console.log('done')
      this.props.updateFiles()
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      this.setState({
        error: responseMessage
      })
    })
  }

  showFileForm () {
    this.setState({
      showFileForm: true,
      newFileVersion: true
    })
  }

  showNewVersionFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: false,
      oldFileId: $(event.currentTarget).data('id')
    })
  }

  closeFileForm (event) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileForm: false
    })
  }

  render () {
    const loading = this.props.loading || this.state.loading
    const uploadButton = !((this.props.index === '9' && this.props.user.role === 'PARTNER') || this.state.finished)
    const files = this.props.files.map(file => {
      return (
                <FileView
                    key={file._id}
                    user={this.props.user}
                    file_type={this.props.index}
                    project={this.props.project}
                    updateFiles={this.props.updateFiles}
                    showNewVersionFileForm={this.showNewVersionFileForm}
                    showFileDetailView={this.props.showFileDetailView}
                    deleteFile={this.deleteFile}
                    file={file}
                    token={this.props.token}
                    finished={this.state.finished}
                />
      )
    })

    return (
            <div>
                <h4>
                    {this.props.index}&nbsp;
                    {this.props.title}&nbsp;
                    {this.state.emailing
                      ? <span className="glyphicons glyphicons-message-out"></span>
                      : null
                    }
                    {uploadButton
                      ? <button className="btn-green btn-low right" onClick={this.showFileForm}>
                            {this.state.uploading
                              ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                              : <span className="glyphicons glyphicons-cloud-upload"></span>
                            }
                            &nbsp;&nbsp;&nbsp; <T i18nKey="project:add_file">Voeg bestand toe</T>
                        </button>
                      : null
                    }
                </h4>

                {this.state.showFileForm
                  ? <FileUploadForm closeFileForm={this.closeFileForm} token={this.props.token} user={this.props.user} fileType={{ type: 'pid', index: this.props.index }} project={this.props.project} newFile={this.state.newFileVersion} oldFileId={this.state.oldFileId} updateParentView={this.props.updateFiles}/>
                  : null
                }

                {this.state.error
                  ? <div className="message warning">
                        {this.state.error}
                    </div>
                  : null
                }
                <table className="list_table">
                    {files.length !== 0 && !loading
                      ? [
                            <thead key="thead">
                                <tr>
                                    <th><T i18nKey="project:filename">Bestandsnaam</T></th>
                                    <th><T i18nKey="project:date">Datum</T></th>
                                    <th><T i18nKey="project:uploader">Uploader</T></th>
                                    <th><T i18nKey="common:download">Download</T></th>
                                    <th style={{ width: 26 }} ></th>
                                </tr>
                            </thead>,
                            <tbody key="tbody">
                                {files}
                            </tbody>
                        ]
                      : <tbody>
                            <tr className="no-hover">
                                {loading
                                  ? <td><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
                                  : <td><T i18nKey="project:there_are_no_files_yet">Er zijn nog geen bestanden geüpload.</T></td>
                                }
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
    )
  }
}
const PIDSubTypeView = withTranslation()(PIDSubTypeViewComp)

class FileView extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    return true
  }

  toggleQuickactions (event) {
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  stopPropagation (event) {
    event.stopPropagation()
  }

  render () {
    const file = this.props.file.file
    const fileDate = file.customDate || file.updated_at
    let filename
    try {
      filename = window.decodeURIComponent(file.fileName)
    } catch (error) {
      filename = file.fileName
    }
    const visitDate = moment(fileDate).format('DD-MM-YYYY')
    const uploader = file.uploaded_by || {}
    const isUploader = uploader._id === this.props.user._id
    let quickactions = <td></td>
    if ((isUploader || this.props.user.role.match('ADMIN')) && !this.props.finished) {
      quickactions = (
                <td onClick={this.stopPropagation} className="quickAction_cel">
                    <a onClick={this.toggleQuickactions} href="#" className="quickAction">
                        <span className="glyphicons glyphicons-more"></span>
                    </a>
                    <div className="quickAction_tip" style={{ display: 'none' }}>
                        <div className="quickAction_tip_arrow"></div>
                        <div className="quickAction_tip_inner">
                            <ul>
                                <li>
                                    <a data-id={file._id} href="#" onClick={this.props.showNewVersionFileForm}><T i18nKey="project:upload_new_version">Nieuwe versie uploaden</T></a>
                                </li>
                                <li>
                                    <a data-id={this.props.file._id} href="#" onClick={this.props.deleteFile}><T i18nKey="project:delete_file">Bestand verwijderen</T></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </td>
      )
    }
    return (
            <tr className="no-pointer">
                <td>{filename}</td>
                <td>{visitDate}</td>
                <td>{uploader.name}</td>
                <td><a target="_blank" rel="noopener noreferrer" href={`${location.origin}/v1/file/${file._id}/-1/${file.fileName}`}><T i18nKey="common:download">Download</T></a></td>
                {quickactions}
            </tr>
    )
  }
}
