import React, { Component } from 'react'
import _ from 'underscore'
import { AddContact } from './add_contact.jsx'
import { CreateContact } from './create_contact.jsx'
import { hasPermissionFor } from './../../../../tools.jsx'
import location from '../../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class ContactsViewComp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      contactViewState: null,
      error: false,
      ssRoles: {
        roles: {},
        roleOptionElements: [],
        roleOptionsString: ''
      },
      hasCRUDPermissions: hasPermissionFor(this.props.user.role, 'FREELANCER')
    }

    this.updateProject = this.updateProject.bind(this)
    this.showContactChild = this.showContactChild.bind(this)
    this.goBackToListView = this.goBackToListView.bind(this)
    this.deleteContact = this.deleteContact.bind(this)
    this.submitContact = this.submitContact.bind(this)
    this.sentInvitationMail = this.sentInvitationMail.bind(this)
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    if (!this.state.hasCRUDPermissions) {
      return
    }
    // get ss roles
    const ssRolesAjaxSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/user/SSRoles`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }
    $.ajax(ssRolesAjaxSettings).done((response) => {
      if (!this.isUnmounting) {
        // create roles list (only need to do this once, so lets no do it in render())
        const roleOptionElements = []
        let roleOptionsString = ''
        const roles = {}
        let name
        response.forEach(role => {
          name = role.name
          roles[name] = name
          roleOptionElements.push(
                        <option key={role._id} value={name}>{name}</option>
          )
          roleOptionsString += `<option value=${name}>${name}</option>`
        })

        this.setState({
          ssRoles: {
            roles,
            roleOptionElements,
            roleOptionsString
          }
        })
      }
    }).fail(function (error) {
      console.error(error)
    })
  }

  updateProject (response, role) {
    const form = new FormData()
    form.append('id', response._id)
    form.append('role', role)
    form.append('type', 'contact')

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.project._id}/addUser`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      },
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
      data: form
    }

    return $.ajax(settings)
  }

  showContactChild (childName, event) {
    event.preventDefault()
    this.setState({
      contactViewState: childName
    })
  }

  goBackToListView (e) {
    e && e.preventDefault && e.preventDefault()
    this.setState({
      contactViewState: null
    })
  }

  deleteContact (contactId, ssId, userId) {
    const t = this.props.t
    this.setState(state => ({ error: null }))
    const token = this.props.token.token
    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.project._id}/removeUser`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify({
        _id: contactId,
        user_id: userId
      })
    }).done(response => {
      $(`#${contactId}`).hide()
    }).fail(error => {
      console.error(error)
      this.setState(state => ({
        error: t('project:delete_contact_error') + ': ' + error.responseJSON ? error.responseJSON.message : error.responseText
      }))
    })

    const siteUrl = (this.props.project.site_id || '').split('/')
    const host = siteUrl[2].split('.')[1]
    const siteId = parseInt(siteUrl[4])

    /* remove project from user */
    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/user/remove_project`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify({
        ss_id: ssId,
        user_id: userId,
        site_id: siteId,
        archi: host === 'archisnapper'
      })
    }).done(function (response) {
      console.info(response)
    }).fail(error => {
      console.error(error)
      this.setState(state => ({
        error: t('project:delete_contact_error') + ': ' + error.responseJSON ? error.responseJSON.message : error.responseText
      }))
    })
  }

  submitContact (event, component, user) {
    console.info('submitContact')
    const t = this.props.t
    if (event) {
      event.preventDefault()
    }

    if (user || (component.state.role && component.state.user)) {
      component.setState({
        buttonDisabled: true,
        loading: true,
        error: null
      })
      const token = this.props.token.token

      const siteUrl = (this.props.project.site_id || '').split('/')
      const host = (siteUrl[2] || '').split('.')[1] || ''
      const siteId = siteUrl[4] ? parseInt(siteUrl[4]) : false

      let contact
      if (user) {
        contact = user
      } else {
        contact = _.find(component.state.contacts, (contact) => {
          return contact._id === component.state.user
        })
      }
      let userAddProjectPromise

      if (!siteId) {
        this.setState(state => ({
          error: t('project:add_contact_error_unsaved_project')
        }))
        return
      }

      if (contact.ss_id) {
        // if the contact exists on ss

        const siteIds = []
        contact.projects.forEach(project => {
          if (project && project.site_id) {
            siteIds.push(parseInt(project.site_id))
          }
        })
        siteIds.push(siteId)

        const settings = {
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/user/add_project/${contact._id}`,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify({
            site_id: siteId,
            site_ids: siteIds,
            archi: host === 'archisnapper'
          })
        }

        userAddProjectPromise = $.ajax(settings)
      } else {
        // if the contact does not exist on ss
        const requestData = {
          user_id: contact._id,
          name: contact.name,
          ss_role: contact.ss_role,
          ss_role_secondary: contact.ss_role_secondary,
          email: contact.email,
          site_id: siteId,
          phone: contact.phone,
          fax_number: contact.fax_number,
          additional_info: contact.additional_info,
          address: contact.ss_address,
          company_name: (contact.organisation || {}).name || '',
          archi: host === 'archisnapper'
        }

        const settings = {
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/user/create_ss_contact`,
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify(requestData)
        }

        userAddProjectPromise = $.ajax(settings)
      }

      // also add user to project.contacts
      userAddProjectPromise.done((response) => {
        this.updateProject(response, user ? component.state.contactData.ss_role : component.state.role).done(response => {
          component.props.reloadProject()
          component.formRef.current.reset()
          $('#findUsers').select2('val', '')

          this.goBackToListView()

          /* send invitation/welcome mail */
          this.sentInvitationMail(contact)
        }).fail(error => {
          console.error(error)
          component.setState({
            buttonDisabled: false,
            loading: false,
            error: error.responseJSON ? error.responseJSON.message : error.responseText
          })
        })
      }).fail((error) => {
        console.error(error)
        component.setState({
          buttonDisabled: false,
          loading: false,
          error: error.responseJSON ? error.responseJSON.message : error.responseText
        })
      })
    } else {
      component.setState({
        buttonDisabled: false,
        loading: false,
        error: t('project:add_contact_error_empty_fields')
      })
    }
  }

  sentInvitationMail (contact) {
    const t = this.props.t
    const mailUser = window.confirm(t('project:send_mail_to_user'))
    if (mailUser) {
      const payload = {
        contactId: contact._id,
        template: 'nieuwepartner',
        to: [
          {
            email: contact.email,
            name: contact.name,
            type: 'to'
          }
        ],
        global_merge_vars: [{
          name: 'afas_id',
          content: this.props.project.afas_id
        },
        {
          name: 'project_name',
          content: this.props.project.ss_name || this.props.project.project_name
        }]
      }

      $.ajax({
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/message/send_mail`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${this.props.token.token}`,
          'content-type': 'application/json'
        },
        processData: false,
        data: JSON.stringify(payload)
      }).done(function (response) {
        console.log(response)
      })
    }
  }

  contactMailsToString () {
    const contacts = this.props.project.users
    let emailString = ''
    contacts.forEach(function (contact) {
      if (Object.prototype.toString.apply(contact).slice(8, -1) === 'Object' && contact.type === 'contact' && Object.prototype.toString.apply(contact.user).slice(8, -1) === 'Object') {
        emailString += contact.user.email + ','
      }
    })
    emailString = emailString.slice(0, -1)
    return emailString
  }

  render () {
    const contactViewState = this.state.contactViewState
    const hasCRUDPermissions = this.state.hasCRUDPermissions
    const filteredContacts = this.props.project.users.filter(user => user.user && user.type === 'contact')
    const contactList = filteredContacts.map(user =>
      <Contact
        history={this.props.history}
        key={user._id}
        token={this.props.token}
        sentInvitationMail={this.sentInvitationMail}
        deleteContact={this.deleteContact}
        projectId={this.props.project._id}
        user={this.props.user}
        contact={user}
        ssRoles={this.state.ssRoles}
      />
    )

    return (
            <section className="right_content">
                <h1><T i18nKey="project:contacts">Contacten</T>
                    {!contactViewState && hasCRUDPermissions
                      ? <a className="btn-green btn-low right" href="#" onClick={this.showContactChild.bind(this, 'add')}><span className="glyphicons glyphicons-user-add"></span>&nbsp;&nbsp;<T i18nKey="project:add_contact">Voeg contact toe</T></a>
                      : ''}
                    {!contactViewState && hasPermissionFor(this.props.user.role, 'EMPLOYEE')
                      ? <a className="btn-green btn-low right" href={'mailto:' + this.contactMailsToString()}><span className="glyphicons glyphicons-envelope"></span>&nbsp;&nbsp;<T i18nKey="project:mail_all_contacts">Mail alle contacten</T></a>
                      : null}
                </h1>
                {this.state.error
                  ? <span className="message warning">
                        {this.state.error}
                    </span>
                  : null}
                {contactViewState && hasCRUDPermissions
                  ? <div className="info_block">
                        <div>
                            <a href="#" onClick={this.goBackToListView} className="btn-close">
                                <span className="glyphicons glyphicons-remove"></span> <T i18nKey="common:close">Sluiten</T>
                            </a>
                        </div>
                        <div className="column_50p">
                            <AddContact submitContact={this.submitContact} updateProject={this.updateProject} ssRoles={this.state.ssRoles} token={this.props.token} project={this.props.project} reloadProject={this.props.reloadProject}/>
                        </div>
                        <div className="column_50p">
                            <CreateContact goBackToListView={this.goBackToListView} submitContact={this.submitContact} updateProject={this.updateProject} ssRoles={this.state.ssRoles} token={this.props.token} project={this.props.project} reloadProject={this.props.reloadProject}/>
                        </div>
                        <div className="clear"></div>
                    </div>
                  : ''
                }
                <table className="list_table">
                    {contactList.length !== 0
                      ? <>
                        <thead>
                            <tr>
                                <th><T i18nKey="project:name">Naam</T></th>
                                <th><T i18nKey="project:email">Email</T></th>
                                <th><T i18nKey="project:function_company">Functie [Bedrijf]</T></th>
                                {hasCRUDPermissions && <th style={{ width: 26 }}></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {contactList}
                        </tbody>
                    </>
                      : <>
                        <tbody>
                            <tr>
                                <td><T i18nKey="project:there_are_no_contacts_yet">Er zijn nog geen contacten toegevoegd.</T></td>
                            </tr>
                        </tbody>
                    </>
                    }
                </table>
            </section>
    )
  }
}

const ContactsView = withTranslation()(ContactsViewComp)
export { ContactsView }

class ContactView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      role: (this.props.contact.user || {}).ss_role || '',
      hasCRUDPermissions: hasPermissionFor(this.props.user.role, 'FREELANCER')
    }

    this.working = false

    this.gotoUserPage = this.gotoUserPage.bind(this)
    this.toggleQuickactions = this.toggleQuickactions.bind(this)
    this.deleteContact = this.deleteContact.bind(this)
    this.changeContactFunction = this.changeContactFunction.bind(this)
    this.mailInvite = this.mailInvite.bind(this)
  }

  gotoUserPage (userId) {
    this.props.history.push(`/gebruiker/${userId}`)
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).find('.quickAction_tip')
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  deleteContact () {
    const contact = this.props.contact
    const user = contact.user
    this.props.deleteContact(contact._id, user.ss_id, user._id)
  }

  changeContactFunction (event) {
    event.stopPropagation()

    if (!this.state.hasCRUDPermissions) {
      return
    }
    const t = this.props.t

    this.working = true
    const $theTarget = $(event.currentTarget)
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
    const theQuickActionThing = (`<div class="quickAction_tip" style="display:none;cursor:default"><div class="quickAction_tip_arrow"></div><div class="quickAction_tip_inner"><form><fieldset class="form-group"><label for="function">${t('project:function')}</label><select class="form-control" id="rol">${this.props.ssRoles.roleOptionsString.replace(this.state.role, `${this.state.role} selected`)}</select></fieldset><fieldset class="form-group"><button type="submit" class="form-submit">${t('common:save')}</button></fieldset></form></div></div>`)
    $theTarget.append(theQuickActionThing)
    setTimeout(function (component) {
      const quickActionMenu = $theTarget.find('.quickAction_tip')
      quickActionMenu.fadeIn(200, () => {
        this.working = false
      })
      $(window).on('click.quickActionsDisabler', (event) => {
        $(window).off('.quickActionsDisabler')
        quickActionMenu.fadeOut(200, () => {
          this.working = false
          quickActionMenu.remove()
        })
      })

      quickActionMenu.on('click', function (event) {
        event.stopPropagation()
      })
      quickActionMenu.find('form').on('submit', function (event) {
        event.preventDefault()

        $(window).off('.quickActionsDisabler')
        quickActionMenu.fadeOut(200, () => {
          this.working = false
          quickActionMenu.remove()
        })

        const userId = component.props.contact._id
        const newValue = quickActionMenu.find('select').val()

        component.setState(state => ({ error: null }))

        $.ajax({
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/project/${component.props.projectId}/editUser`,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${component.props.token.token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify({
            id: userId,
            role: newValue
          })
        }).done(function (response) {
          component.setState({
            role: newValue
          })
        }).fail((error) => {
          console.error(error)
          component.setState(state => ({
            error: t('project:update_function_error') + ': ' + error.responseJSON ? error.responseJSON.message : error.responseText
          }))
        })
      })
    }, 10, this)
  }

  mailInvite () {
    this.props.sentInvitationMail(this.props.contact.user)
  }

  render () {
    const contact = this.props.contact
    const user = contact.user || {}
    const contactRole = this.state.role
    const contactSubRole = user.ss_role_secondary
    const organisationName = (user.organisation || {}).name || ''

    return (
            <tr id={contact._id} onClick={this.gotoUserPage.bind(this, user._id)}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td onClick={this.changeContactFunction}>{`${contactRole}${contactSubRole ? `, ${contactSubRole}` : ''}${organisationName ? ` [${organisationName}]` : ''}`}</td>
                {this.state.hasCRUDPermissions
                  ? <td onClick={this.toggleQuickactions} className="quickAction_cel">
                        <a href="#" className="quickAction">
                            <span className="glyphicons glyphicons-more"></span>
                        </a>
                        <div className="quickAction_tip" style={{ display: 'none' }}>
                            <div className="quickAction_tip_arrow"></div>
                            <div className="quickAction_tip_inner">
                                <ul>
                                    <li>
                                        <a onClick={this.mailInvite} href='#'><T i18nKey="project:mail_invitation_to_contact">Mail contact uitnodiging</T></a>
                                    </li>
                                    <li>
                                        <a onClick={this.deleteContact} href='#'><T i18nKey="common:delete">Verwijderen</T></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                  : null}
            </tr>
    )
  }
}
const Contact = withTranslation()(ContactView)
