import React from 'react'
import { PassedProjectGroupTableBodyRow } from './passedProjectGroupTableBodyRow.jsx'
import { Trans as T } from 'react-i18next'

export const PassedProjectGroupTable = props => {
  const received = []
  const sent = []
  let newRow
  props.projects.forEach(project => {
    project.users.forEach((user) => {
      if (user.type === 'vc3') {
        newRow = <PassedProjectGroupTableBodyRow key={project._id} project={project} {...props} />
        if (user.user._id === props.user._id) {
          received.push(newRow)
        } else {
          sent.push(newRow)
        }
      }
    })
  })
  return (
    <section>
      {received.length ? <ReceivedTable projects={received} addClass={props.addClass} /> : null}
      {sent.length ? <SentTable projects={sent} addClass={props.addClass} /> : null}
    </section>
  )
}

const ReceivedTable = props =>
  <section>
    <h3><T i18nKey="planning:projects_received">Werven ontvangen</T></h3>
    <table className="list_table">
      <thead>
        <tr>
          <th><T i18nKey="planning:title">Titel</T></th>
          <th style={{ width: '25px' }}></th>
          <th><T i18nKey="planning:passed_by">Doorgegeven door</T></th>
          <th></th>
        </tr>
      </thead>
      <tbody className={props.addClass}>
        {props.projects}
      </tbody>
    </table>
  </section>

const SentTable = props =>
  <section>
    <h3><T i18nKey="planning:projects_passed">Werven doorgegeven</T></h3>
    <table className="list_table">
      <thead>
        <tr>
          <th><T i18nKey="planning:title">Titel</T></th>
          <th style={{ width: '25px' }}></th>
          <th><T i18nKey="planning:passed_to">Doorgegeven aan</T></th>
          <th style={{ width: '26px' }}></th>
        </tr>
      </thead>
      <tbody className={props.addClass}>
        {props.projects}
      </tbody>
    </table>
  </section>
