import { getWeekNumFromInterval, formatInterval, calcNextDateByType } from '../../projectTypeFunctions.js'
import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class MuteProjectView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showDatePicker: false,
      project: {},
      error: {},
      comment: '',
      muteDate: null
    }

    this.saveProject = this.saveProject.bind(this)
    this.changeRadio = this.changeRadio.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.changeComment = this.changeComment.bind(this)
  }

  componentDidMount () {
    // Get the project
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.match.params.id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    $.ajax(settings)
      .done((project) => {
        this.setState({
          project
        })
      })
      .fail(function (error) {
        console.error(error)
      })
  }

  hideErrorMessages () {
    $('.error').remove()
  }

  saveProject (event) {
    event.preventDefault()
    const t = this.props.t
    this.hideErrorMessages()
    const date = this.state.muteDate ? moment(this.state.muteDate) : null

    if (!date) {
      return this.setState({
        error: {
          message: t('project:invalid_date'),
          loading: false
        }
      })
    }

    const newVisit = calcNextDateByType(this.state.project.type, date)
    const newVisitDate = (newVisit || date).toISOString()

    this.setState({
      loading: true
    })
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.state.project._id}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: `{"muted": "${date}", "muteComment": "${this.state.comment}", "next_visit": "${newVisitDate}"}`
    }

    $.ajax(settings)
      .done((response) => {
        this.props.history.push('/')
      })
      .fail((error) => {
        this.setState({
          error: {
            originalError: error,
            message: t('project:error_on_server')
          },
          loading: false
        })
      })
  }

  changeRadio (number, event) {
    const showDatePicker = this.state.showDatePicker
    if (this.state.dateSet && ((number === 1 && !showDatePicker) || (number === 2 && showDatePicker))) { return }

    if (number === 1) {
      const date = moment()
      const intervalWeeks = getWeekNumFromInterval(this.state.project.type)
      date.add(intervalWeeks, 'w')
      this.setState({
        showDatePicker: false,
        muteDate: date,
        error: {},
        dateSet: true
      })
    } else {
      this.setState({
        error: {},
        muteDate: null,
        showDatePicker: true
      })
    }
  }

  changeDate (date) {
    this.setState({
      muteDate: date,
      error: {},
      dateSet: true
    })
  }

  changeComment (event) {
    const comment = event.target.value
    this.setState({ comment: comment })
  }

  render () {
    const t = this.props.t
    return (
      <section className="container form">
        <h2><T i18nKey="project:mute_project">Werf muten</T></h2>
        {this.state.error.message
          ? <div className="message">
            <div className="message_icon">
              <span className="glyphicons glyphicons-exclamation-sign"></span>
            </div>
            {this.state.error.message}
          </div>
          : null}

        <form onSubmit={this.saveProject}>
          <fieldset className="radio">
            <label htmlFor="muteRadio1">
              {this.state.project.type === 'interval_3x8months'
                ? <input disabled type="radio" name="muteRadio" id="muteRadio1" onChange={this.changeRadio.bind(this, 1)} />
                : <input type="radio" name="muteRadio" id="muteRadio1" onChange={this.changeRadio.bind(this, 1)} />
              }
              <T i18nKey="project:skip_duration" values={{ duration: formatInterval(this.state.project.type) }}>Sla {formatInterval(this.state.project.type)} over</T>
            </label>
          </fieldset>
          <fieldset className="radio">
            <label htmlFor="muteRadio2">
              <input type="radio" name="muteRadio" id="muteRadio2" onChange={this.changeRadio.bind(this, 2)} />
              <T i18nKey="project:mute_until">mute tot</T>
                            &nbsp;
                            {this.state.showDatePicker
                              ? <span className="mute-page-date-picker">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    onChange={this.changeDate}
                    className="form-control"
                    placeholderText={t('common:click_to_select_date')}
                    selected={this.state.muteDate}
                  />
                </span>
                              : <span>...</span>
              }
            </label>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="comment"><T i18nKey="project:reason">Reden</T>:</label>
            <textarea className="form-control" id="comment" onChange={this.changeComment} value={this.state.comment}></textarea>
          </fieldset>
          <fieldset className="form-group">
            <button type="submit" className="form-submit"><T i18nKey="common:save">Opslaan</T></button>
          </fieldset>
        </form>
      </section>
    )
  }
}

const MuteProject = withTranslation()(MuteProjectView)
export { MuteProject }
