import React, { Component } from 'react'
import waterfall from 'async-es/waterfall'
import moment from 'moment'
import _ from 'underscore'
import location from '../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class ToolUserCsvExport extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false
    }

    this.createActiveProjectUserCSV = this.createActiveProjectUserCSV.bind(this)
  }

  createActiveProjectUserCSV () {
    const token = this.props.token
    this.setState({
      loading: true
    })
    waterfall([
      /*
            * Get all active projects
            */
      function (callback) {
        $.ajax({
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/project?status=design,execution&limit=0&select=users,afas_id`,
          method: 'GET',
          headers: {
            authorization: `Bearer ${token}`
          }
        }).done((data) => {
          callback(null, data.results)
        }).fail((error) => {
          console.error(error)
          callback(error)
        })
      },
      /*
            * Create a collection of all the contacts and their projects
            */
      function (projects, callback) {
        let contacts
        let user
        const contactList = {}
        let selectedUser
        projects.forEach(function (project) {
          contacts = project.users
          contacts.forEach(function (contact) {
            user = contact.user || {}
            selectedUser = contactList[user.email] || { projects: [] }
            selectedUser.email = selectedUser.email || user.email
            selectedUser.firstname = selectedUser.firstname || user.firstname || ''
            selectedUser.lastname = selectedUser.lastname || user.lastname || ''
            selectedUser.projects.push(project.afas_id)
            contactList[user.email] = selectedUser
          })
        })
        callback(null, contactList)
      },
      /*
            * Create csv string from collection of contacts
            */
      function (contactList, callback) {
        let csvString = 'Email Address\tFirst Name\tLast Name\tWerven'
        _.each(contactList, function (contact) {
          if (contact.email) {
            csvString += `\n${contact.email}\t${contact.firstname}\t${contact.lastname}\t${contact.projects.join(', ')}`
          }
        })
        callback(null, csvString)
      },
      /*
            * Download csv file
            */
      function (csvString, callback) {
        const csvLink = document.createElement('a')
        csvLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString)
        csvLink.download = `${moment().format('DD_MM_YYYY_HH_mm_')}contacten_export.csv`
        document.body.appendChild(csvLink)
        csvLink.click()
        document.body.removeChild(csvLink)
        callback(null)
      }
    ], () => {
      this.setState({
        loading: false
      })
    })
  }

  render () {
    return (
      <div className="tool">
        <div className="tool-inner">
          <h2><span className="glyphicons glyphicons-newspaper"></span> <T i18nKey="tools:mailchimp_csv">CSV Mailchimp</T></h2>
          <p><T i18nKey="tools:mailchimp_message_pt1">Download een lijst van alle contacten gelinkt aan een actieve werf</T></p>
          <p><T i18nKey="tools:mailchimp_message_pt2">Deze csv is geöptimaliseerd voor Mailchimp import</T></p>
          {this.state.loading
            ? <button className="btn-green"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></button>
            : <button className="btn-green" onClick={this.createActiveProjectUserCSV}><T i18nKey="tools:download_csv">Download CSV</T></button>
          }
        </div>
      </div>
    )
  }
}
