/* eslint-disable camelcase */
import blobStream from 'blob-stream'
import moment from 'moment'
import _ from 'underscore'
import location from './location'
import i18n from './i18n.js'
const $ = window.$

let image = ''
function toDataURL (src, callback, outputFormat) {
  const img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    const canvas = document.createElement('CANVAS')
    const ctx = canvas.getContext('2d')
    canvas.height = this.naturalHeight
    canvas.width = this.naturalWidth
    ctx.drawImage(this, 0, 0)
    const dataURL = canvas.toDataURL(outputFormat)
    callback(dataURL)
  }
  img.src = src
  if (img.complete || img.complete === undefined) {
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
    img.src = src
  }
}
toDataURL(
  '/images/coordinatiedagboek_logo_vertical.png',
  function (dataUrl) {
    // console.log('RESULT:', dataUrl)
    console.info('pdf image ready')
    image = dataUrl
  }
)

let openedMenu = []
const closeOpenedMenu = function () {
  openedMenu.forEach(function (id) {
    toggle($(`#${id}`), id)
  })
  openedMenu = []
}

export function leftOrRight (element) {
  const windowWidth = window.innerWidth
  const windowMiddle = windowWidth / 2
  return (element.offsetLeft < windowMiddle) ? 'left' : 'right'
}

export function setPosition (element, position) {
  if (position === 'left') {
    element.css({
      position: 'absolute',
      left: 0,
      top: 115
    })
  } else if (position === 'right') {
    element.css({
      position: 'absolute',
      right: 0,
      top: 115
    })
  }
}

export function toggle (dropDownMenu, menu) {
  $(window).off('.dropdownExit')
  if (dropDownMenu.css('display') === 'none') {
    dropDownMenu.slideDown(500)
    closeOpenedMenu()
    openedMenu.push(menu)
    $(window).on('click.dropdownExit', function (event) {
      if (!$(event.target).closest(`.${menu}`).length && openedMenu.length) {
        toggle($(`#${menu}`))
        openedMenu = _.without(openedMenu, menu)
      }
    })
  } else {
    openedMenu = _.without(openedMenu, menu)
    dropDownMenu.slideUp(500)
  }
}

export function resetDropdowns () {
  openedMenu.forEach(function (id) {
    $(`#${id}`).hide()
  })
  openedMenu = []
}

export function resetDataCache () {
  window.dataCache = {
    newProjectsState: null,
    designProjectsState: null,
    executionProjectsState: null,
    finishedProjectsState: null,
    waitingProjectsState: null,
    filterStatus: null,
    usersState: null,
    organisationsState: null,
    sortStatus: null
  }
};

/*
accepts the userRole and the role it has permission to.
*/
export function hasPermissionFor (userRole, permissionRole) {
  let permission
  switch (userRole) {
    case 'ADMIN+':
      if (permissionRole === 'ADMIN+') {
        permission = true
        break
      }
      // falls through
    case 'ADMIN':
      if (permissionRole === 'ADMIN') {
        permission = true
        break
      }
      // falls through
    case 'EMPLOYEE':
      if (permissionRole === 'EMPLOYEE') {
        permission = true
        break
      }
      // falls through
    case 'FREELANCER':
      if (permissionRole === 'FREELANCER') {
        permission = true
        break
      }
      // falls through
    case 'PARTNER':
      if (permissionRole === 'PARTNER') {
        permission = true
        break
      }
      break
    default: permission = false
  }
  return permission
}

export function msie () { // returns true if browser is IE version older than 11
  const ua = window.navigator.userAgent

  let ieVersion
  const M = ua.match(/(msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    ieVersion = ua.match(/rv:(\d*)/i)[1]
  } else if (/msie/i.test(M[1])) {
    ieVersion = M[2]
  }
  if (ieVersion && parseInt(ieVersion) < 11) {
    return true
  }
  return false
}

export const uploadFile = function (options) {
  console.info('TOOLS => uploadFile')

  /*
    options = {
        file: ,
        file_type: '',
        token: ,
        newFile: false,
        fileId: null,
        customDate: date,
        filename: ''
    };
*/

  options.newFile = options.newFile || false
  options.fileId = options.fileId || null
  options.customDate = options.customDate || new Date().valueOf()

  const form = new FormData()
  const filename = options.file.name || (options.filename ? (options.filename + '.pdf') : 'Toevoeging aan CD.pdf')
  form.append('file', options.file, filename)
  form.append('tags', options.file_type)
  form.append('customDate', options.customDate)

  let url = `${location.origin}/v1/file`
  let method = 'POST'
  if (!options.newFile) {
    url = `${location.origin}/v1/file/${options.fileId}`
    method = 'PUT'
  }

  const settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: method,
    headers: {
      authorization: `Bearer ${options.token}`
    },
    processData: false,
    contentType: false,
    mimeType: 'multipart/form-data',
    data: form,
    xhr: function () {
      const xhr = $.ajaxSettings.xhr()
      xhr.upload.onprogress = function (e) {
        $(window).trigger('updateUploadProgressBar', (e.loaded / e.total * 100))
      }
      return xhr
    }
  }

  return $.ajax(settings)
}

export const addFileToProject = function (uploadFileResponse, file_type, token, projectId) {
  console.info('TOOLS => addFileToProject')

  try {
    let fileId
    try {
      fileId = JSON.parse(uploadFileResponse)._id || uploadFileResponse
    } catch (error) {
      fileId = uploadFileResponse
    }
    const form = new FormData()
    form.append('id', fileId)
    form.append('file_type', file_type)

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${projectId}/addFile`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`
      },
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
      data: form
    }

    return $.ajax(settings)
  } catch (error) {
    console.error(error)
    return error
  }
}

export const deleteFileFromProject = function (fileId, token, projectId) {
  console.info('TOOLS => deleteFileFromProject')

  const settings = {
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/project/${projectId}/removeFile`,
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    processData: false,
    data: `{"file_id": "${fileId}"}`
  }

  return $.ajax(settings)
}

export const logFileChange = function (options) {
  console.info('TOOLS => logFileChange')

  const payload = {
    who: {
      type: 'User',
      object: options.userId
    },
    what: {
      type: 'File',
      object: options.fileId
    },
    how: options.how,
    where: options.projectId,
    tag: options.tag
  }

  return $.ajax({
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/log`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${options.token}`,
      'content-type': 'application/json'
    },
    processData: false,
    data: JSON.stringify(payload)
  })
}

export function createPDF (text, project, name, filename, cb) {
  // create a document the same way as above
  // eslint-disable-next-line
    const doc = new window.PDFDocument
  doc.font('Helvetica')

  // pipe the document to a blob
  const stream = doc.pipe(blobStream())

  // add your content to the document here, as usual
  const date = moment().format('dddd D MMMM YYYY')
  doc.info.Title = filename

  doc.image(image, 400, 50, { fit: [150, 75] })

  const fullname = 'Project: ' + project
  const distanceAfterAuthor = 191 + (12 * Math.ceil(fullname.length / 85))

  doc.moveTo(50, 120)
    .fontSize(10)
    .lineWidth(0.5)
    .lineTo(550, 120)
    .stroke()

    .text(date, 50, 105)

    .font('Helvetica-Bold')
    .fontSize(12)
    .text('Toevoeging aan het coördinatiedagboek', 50, 140, {
      underline: true
    })
    .font('Helvetica')
    .moveDown(0.5)
    .text(fullname)
    .moveDown(0.5)
    .text('Auteur: ' + name)

    .moveTo(50, distanceAfterAuthor) // draw line between author and input text
    .lineTo(550, distanceAfterAuthor)
    .stroke()

    .moveDown(1)
    .text(text)

    // get a blob when you're done
    .end()
  stream.on('finish', function () {
    // get a blob you can do whatever you like with
    const blob = stream.toBlob('application/pdf')
    cb(blob)

    // // blob url to view in browser
    // const url = stream.toBlobURL('application/pdf')
    // console.log('url', url)
    // cb('done')
  })
}

export function xmlGetRequest (url, token) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.open('GET', url)

    req.setRequestHeader('authorization', `Bearer ${token}`)

    req.addEventListener('readystatechange', () => {
      if (req.readyState !== 4) return

      let response
      try {
        response = JSON.parse(req.response)
      } catch (error) {
        response = req.response
      }
      resolve(response)
    })

    req.send()
  })
}

export function xmlRequest (url, token, body, method = 'POST') {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.open(method, url)

    token && req.setRequestHeader('authorization', `Bearer ${token}`)
    req.setRequestHeader('content-type', 'application/json')

    req.addEventListener('readystatechange', () => {
      if (req.readyState !== 4) return

      let response
      try {
        response = JSON.parse(req.response)
      } catch (error) {
        response = req.response
      }
      resolve(response)
    })

    req.send(JSON.stringify(body))
  })
}

export function getAdministration (value = '') {
  switch (value) {
    case 2: return 'Macobo'
    case 5: return 'Vekmo'
    default: return i18n.t('common:unknown')
  }
}

export function getSystem (value = '') {
  switch (value.split('.')[1]) {
    case 'safetysnapper': return 'Safetysnapper'
    case 'archisnapper': return 'Archisnapper'
    default: return i18n.t('common:unknown_message')
  }
}
