import React, { Component } from 'react'
import _ from 'underscore'
import { deleteFileFromProject, hasPermissionFor } from '../../../tools.jsx'
import { FileUploadForm } from '../../reactUtils/fileUploadForm.jsx'

import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class RAView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      files: _.filter(this.props.project.files, function (file) { return file.file_type === 'vgp_extra' && !file.isDeleted }),
      showFileForm: false
    }

    this.showFileForm = this.showFileForm.bind(this)
    this.showNewVersionFileForm = this.showNewVersionFileForm.bind(this)
    this.closeFileForm = this.closeFileForm.bind(this)
    this.updateFiles = this.updateFiles.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    const $table = $('.list_table')
    $table.hide(0, function () {
      $table.show()
    })
  }

  showFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: true
    })
  }

  showNewVersionFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: false,
      oldFileId: $(event.currentTarget).data('id')
    })
  }

  closeFileForm (event) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileForm: false
    })
  }

  updateFiles (result, deletedFile) {
    let files = _.clone(this.state.files)
    if (!deletedFile) {
      if (Object.prototype.toString.apply(result).slice(8, -1) === 'String') {
        try {
          result = JSON.parse(result)
        } catch (error) {
          console.error(new Error(['Expected json string but got ', result]))
          result = {}
        }
      }

      let fileUpdated = false
      files.forEach(function (file) {
        if (file.file._id === result._id) {
          file.file = result
          fileUpdated = true
        }
      })
      if (!fileUpdated) {
        files.push({
          _id: new Date().valueOf(),
          file: result
        })
      }
    } else {
      const newVgpFiles = []
      files.forEach(function (file) {
        if (file._id !== deletedFile) {
          newVgpFiles.push(file)
        }
      })
      files = newVgpFiles
    }

    this.setState({
      showFileForm: false,
      files
    })
    this.props.reloadProject()
  }

  render () {
    const files = this.state.files || []
    let fileTable = (
      <table className="list_table">
        <tbody>
          <tr className="no-hover">
            <td><T i18nKey="project:there_are_no_files_yet">Er zijn nog geen bestanden geüpload.</T></td>
          </tr>
        </tbody>
      </table>
    )
    const finished = this.props.project.status === 'finished'

    const userCanUploadFiles = hasPermissionFor(this.props.user.role, 'PARTNER')

    if (files.length) {
      const fileRows = files.map((file) => {
        return (
          <FileRow
            key={file._id}
            file={file}
            user={this.props.user}
            project={this.props.project}
            token={this.props.token}
            showNewVersionFileForm={this.showNewVersionFileForm}
            updateFiles={this.updateFiles}
          />
        )
      })
      fileTable = (
        <table className="list_table">
          <thead>
            <tr>
              <th><T i18nKey="project:name">Naam</T></th>
              <th style={{ width: 110 }} ><T i18nKey="common:download">Download</T></th>
              <th style={{ width: 26 }} ></th>
            </tr>
          </thead>
          <tbody>
            {fileRows}
          </tbody>
        </table>
      )
    }

    return (
      <>
        {this.state.error ? <h1>{this.state.error}</h1> : null}
        <h1><T i18nKey="project:risk_and_safety_contractors">Risicoanalyses en veiligheidsplannen aannemers</T>
          {!finished && userCanUploadFiles &&
            <button className="btn-green btn-low right" onClick={this.showFileForm}>
              <span className="glyphicons glyphicons-cloud-upload"></span>
                        &nbsp;&nbsp;&nbsp; <T i18nKey="project:upload_document">Upload document</T>
            </button>
          }
        </h1>
        { this.state.showFileForm &&
          <FileUploadForm
            closeFileForm={this.closeFileForm}
            token={this.props.token}
            user={this.props.user}
            fileType={{ type: 'vgp_extra' }}
            project={this.props.project}
            newFile={this.state.newFileVersion}
            oldFileId={this.state.oldFileId}
            updateParentView={this.updateFiles}
          />
        }
        {fileTable}
      </>
    )
  }
}

class FileRowView extends Component {
  constructor (props) {
    super(props)

    this.toggleQuickactions = this.toggleQuickactions.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
  }

  stopPropagation (event) {
    event.stopPropagation()
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  deleteFile (event) {
    event.preventDefault()
    event.stopPropagation()
    const t = this.props.t
    const confirmed = window.confirm(t('project:confirm_delete'))
    const fileId = this.props.file._id
    const projectId = this.props.project._id
    const token = this.props.token.token
    if (confirmed) {
      deleteFileFromProject(fileId, token, projectId).done((response) => {
        this.props.updateFiles(null, fileId)
      }).fail((error) => {
        console.error(error)
        let responseMessage
        try {
          responseMessage = JSON.parse(error.responseText).message
        } catch (error) {
          console.error(error)
        }
        this.setState({
          error: responseMessage
        })
      })
    }
  }

  render () {
    const file = this.props.file.file || {}

    const projectIsFinished = this.props.project.status === 'finished'
    const uploader = file.uploaded_by || {}
    const isUploader = uploader._id === this.props.user._id
    let quickactions = <td></td>
    if ((isUploader || this.props.user.role.match('ADMIN')) && !projectIsFinished) {
      quickactions = (
        <td onClick={this.stopPropagation} className="quickAction_cel">
          <a onClick={this.toggleQuickactions} href="#" className="quickAction">
            <span className="glyphicons glyphicons-more"></span>
          </a>
          <div className="quickAction_tip" style={{ display: 'none' }}>
            <div className="quickAction_tip_arrow"></div>
            <div className="quickAction_tip_inner">
              <ul>
                <li>
                  <a data-id={file._id} onClick={this.props.showNewVersionFileForm} href="#"><T i18nKey="project:upload_new_version">Nieuwe versie uploaden</T></a>
                </li>
                <li>
                  <a href="#" onClick={this.deleteFile}><T i18nKey="project:delete_file">Bestand verwijderen</T></a>
                </li>
              </ul>
            </div>
          </div>
        </td>
      )
    }

    return (
      <tr className="no-pointer">
        <td>{file.fileName}</td>
        <td><a href={`${location.origin}/v1/file/${file._id}/-1/${file.fileName}`}><span className="glyphicons glyphicons-download-alt"></span> <T i18nKey="common:download">Download</T></a></td>
        {quickactions}
      </tr>
    )
  }
}
const FileRow = withTranslation()(FileRowView)

export default RAView
