import React from 'react'

import { VgpFileView } from './vgpFileView.jsx'
import { Trans as T } from 'react-i18next'

export const VgpView = props =>
  <section className="right_content">
    <h1><T i18nKey="project:safety_and_health_plan">Veiligheids- en Gezondheidsplan</T></h1>
    <VgpFileView {...props} />
  </section>
