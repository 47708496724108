import React, { Component } from 'react'
import { Trans as T, withTranslation } from 'react-i18next'

class DisablePlanningButtonView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      showModal: false,
      comment: '',
      date: ''
    }

    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleRemoveOnHoldClick = this.handleRemoveOnHoldClick.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      loading: false
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.showModal && this.state.showModal) {
      window.addEventListener('keydown', this.handleKeyDown)
    }
    if (prevState.showModal && !this.state.showModal) {
      window.removeEventListener('keydown', this.handleKeyDown)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown (event) {
    if (event.key === 'Escape') {
      this.closeModal()
    }
  }

  handleSaveClick () {
    const { loading, comment, date } = this.state

    if (loading) return

    this.setState({
      loading: true
    })

    const newData = {
      status: 'onHold',
      onHoldComment: comment,
      onHoldUntilAtLeastDate: date ? date.split('-').reverse().join('-') : '',
      boosted: false
    }

    this.props.updateProject(newData).done((response) => {
      this.props.reloadProject()
      this.closeModal()
    })
      .fail(function (error) {
        console.error(error)
      })
  }

  openModal () {
    this.setState(state => ({ showModal: true }))
  }

  closeModal () {
    this.setState(state => ({
      showModal: false,
      comment: '',
      date: ''
    }))
  }

  handleValueChange (name) {
    return (event) => {
      const value = event.target.value
      this.setState(state => ({
        [name]: value
      }))
    }
  }

  handleRemoveOnHoldClick () {
    this.setState({
      loading: true
    })

    const newData = {
      status: 'execution',
      onHoldComment: '',
      onHoldUntilAtLeastDate: ''
    }

    this.props.updateProject(newData).done((response) => {
      this.props.reloadProject()
      this.closeModal()
    })
      .fail(function (error) {
        console.error(error)
      })
  }

  render () {
    const t = this.props.t
    const project = this.props.project
    const { loading, showModal, comment, date } = this.state
    if (['ADMIN+', 'ADMIN', 'EMPLOYEE'].includes(this.props.userRole)) {
      const isOnHold = project.status === 'onHold'

      if (isOnHold) {
        return (
          <button className="btn-green" onClick={this.handleRemoveOnHoldClick}>
            <span className="glyphicons glyphicons-turtle"></span>
            &nbsp;
            {loading
              ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
              : t('project:unset_on_hold')
            }
          </button>
        )
      }

      return (
        <>
          <div className={`OnHold-modal ${showModal ? '' : 'OnHold-modal-hidden'}`}>
            <div className="OnHold-modal-body">
              <button className="OnHold-modal-icon" onClick={this.closeModal}><span className="glyphicons glyphicons-remove"></span></button>
              <h4 className="OnHold-modal-title"><T i18nKey="project:set_on_hold">On hold zetten</T></h4>
              <div className="form-group">
                <label htmlFor="onhold-comment">Reden</label>
                <textarea className="form-control" id="onhold-comment" type="text" onChange={this.handleValueChange('comment')} value={comment} />
              </div>
              <div className="form-group">
                <label htmlFor="onhold-date">Niet meer inplannen tot minstens</label>
                <input className="form-control" id="onhold-date" type="date" onChange={this.handleValueChange('date')} value={date} />
              </div>
              {loading
                ? <button className="btn-green"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></button>
                : <button className="btn-green" onClick={this.handleSaveClick}><T i18nKey="common:save">Opslaan</T></button>
              }
            </div>
          </div>
          <button className="btn-green" onClick={this.openModal}>
            <span className="glyphicons glyphicons-turtle"></span>
            &nbsp;
            {loading
              ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
              : t('project:set_on_hold')
            }
          </button>
        </>
      )
    }
    return null
  }
}
const DisablePlanningButton = withTranslation()(DisablePlanningButtonView)

export default DisablePlanningButton
