import React from 'react'
import './ProjectNotes.css'
import { hasPermissionFor, xmlRequest } from '../../../tools.jsx'
import { Trans as T } from 'react-i18next'
import i18n from '../../../i18n.js'
import moment from 'moment'
import location from '../../../location'

let options
function translateOptions () {
  options = {
    execution: (<>
      <option value="">---</option>
      <option value="1">{i18n.t('project:not_started_yet')}</option>
      <option value="2">{i18n.t('project:preparational_works')}</option>
      <option value="3">{i18n.t('project:project_started')}</option>
      <option value="4">{i18n.t('project:project_at_25')}</option>
      <option value="5">{i18n.t('project:project_at_50')}</option>
      <option value="6">{i18n.t('project:project_at_75')}</option>
      <option value="7">{i18n.t('project:finishing_touches')}</option>
      <option value="8">{i18n.t('project:project_finished')}</option>
    </>),
    design: (<>
      <option value="">---</option>
      <option value="p1">{i18n.t('project:advice_delivered')}</option>
      <option value="p2">{i18n.t('project:design_delivered')}</option>
      <option value="p3">{i18n.t('project:analysis_delivered')}</option>
    </>)
  }
}
translateOptions()
i18n.on('languageChanged', () => {
  translateOptions()
})

function getOptions (status) {
  /* eslint-disable */
  switch (status) {
    case 'execution':
    case 'waiting':
      return options.execution
    case 'design':
      return options.design
    default:
      return null
  }
  /* eslint-enable */
}

function handleProgressChange (e) {
  const newValue = e.target.value
  const update = { progress: newValue }
  if (newValue === '8') {
    const confirmed = window.confirm(i18n.t('project:confirm_pid_progress_change'))
    if (!confirmed) {
      return
    } else {
      update.status = 'createPid'
      update.createPidStartDate = moment().format('YYYY-MM-DD')
      xmlRequest(`${location.origin}/v1/message/send_pid_email`, this.token.token, {
        projectNumber: this.project.afas_id,
        projectId: this.project._id,
        vcName: (this.user || {}).name || ''
      }) // send mail
    }
  }

  this.updateProject(update)
    .done((response) => {
      this.reloadProject()
    })
    .fail(function (error) {
      console.error(error)
    })
}

function handlePidDateChange (name, newValue) {
  const update = {
    pidMailDates: {
      ...this.pidMailDates,
      [name]: {
        ...(this.pidMailDates[name]) || {},
        date: newValue
      }
    }
  }
  this.updateProject(update)
    .done((response) => {
      this.reloadProject()
    })
    .fail(function (error) {
      console.error(error)
    })
}

function getProgressValue (name, value) {
  /* eslint-disable */
  switch (value) {
    default:
    case '1':
      return {
        percent: 0,
        name: i18n.t('project:not_started_yet')
      }[name]
    case '2':
      return {
        percent: 5,
        name: i18n.t('project:preparational_works')
      }[name]
    case '3':
      return {
        percent: 10,
        name: i18n.t('project:project_started')
      }[name]
    case '4':
      return {
        percent: 25,
        name: i18n.t('project:project_at_25')
      }[name]
    case '5':
      return {
        percent: 50,
        name: i18n.t('project:project_at_50')
      }[name]
    case '6':
      return {
        percent: 75,
        name: i18n.t('project:project_at_75')
      }[name]
    case '7':
      return {
        percent: 90,
        name: i18n.t('project:finishing_touches')
      }[name]
    case '8':
      return {
        percent: 100,
        name: i18n.t('project:project_finished')
      }[name]
    case 'p1':
      return {
        percent: 25,
        name: i18n.t('project:advice_delivered')
      }[name]
    case 'p2':
      return {
        percent: 75,
        name: i18n.t('project:design_delivered')
      }[name]
    case 'p3':
      return {
        percent: 100,
        name: i18n.t('project:analysis_delivered')
      }[name]
  }
  /* eslint-enable */
}

export { getProgressValue }

const ProjectNotes = props => {
  const project = props.project
  // const userIsAdmin = hasPermissionFor(props.user.role, 'ADMIN')
  const userIsAdminPlus = hasPermissionFor(props.user.role, 'ADMIN+')
  let administration
  switch (props.administration) {
    case 2:
      administration = 'Macobo'
      break
    case 5:
      administration = 'Vekmo'
      break
    default:
      administration = ''
  }

  function handleCheckboxChange (e) {
    const update = { noPidRequired: e.target.checked }
    props.updateProject(update)
      .done((response) => {
        props.reloadProject()
      })
      .fail(function (error) {
        console.error(error)
      })
  }

  function handleFCodeChange (e) {
    const update = { fCode: e.target.value }
    props.updateProject(update)
      .done((response) => {
        props.reloadProject()
      })
      .fail(function (error) {
        console.error(error)
      })
  }

  return (
    <div className="ProjectNotes-info-block">
      <h3 title={props.project.description || ''}>
        <span className="glyphicons glyphicons-eye-close"></span>
        <span><T i18nKey="project:project_information">Project informatie</T></span>
      </h3>
      <div className="ProjectNotes-content">
        {userIsAdminPlus && <FCode onFCodeChange={handleFCodeChange} fCode={project.fCode} />}
        <Progress progress={props.progress || ''} projectStatus={props.projectStatus} onChange={handleProgressChange.bind(props)} />
        <PidDates projectStatus={props.projectStatus} pidMailDates={props.pidMailDates} onChange={handlePidDateChange.bind(props)} />
        {(props.projectStatus === 'createPid' || props.projectStatus === 'finished') &&
          <>
            <div className="ProjectNotes-pid-row ProjectNotes-input-checkbox">
              <div className="toggle_switch">
                <label htmlFor="nopidcheck"><T i18nKey="project:no_pid_required">Geen PID benodigd</T></label>
                <input className="toggle" id="nopidcheck" type="checkbox" onChange={handleCheckboxChange} checked={props.project.noPidRequired} />
                <label htmlFor="nopidcheck"></label>
              </div>
            </div>
            <label htmlFor="notesInput"><T i18nKey="project:pidnotes">PID-Notities</T></label>
            <div className="ProjectNotes-textarea-container">
              {props.savingInProgress.pidComment && <div className="ProjectNotes-textarea-loader"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></div>}
              <textarea
                id="notesInput"
                className="ProjectNotes-textarea"
                onBlur={props.changeCommentField('pidComment')}
                defaultValue={props.pidComment}
                disabled={props.savingInProgress.pidComment}
              ></textarea>
            </div>
          </>
        }
        <label htmlFor="notesInput"><T i18nKey="project:notes">Notities</T></label>
        <div className="ProjectNotes-textarea-container">
          {props.savingInProgress.comment && <div className="ProjectNotes-textarea-loader"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></div>}
          <textarea
            id="notesInput"
            className="ProjectNotes-textarea"
            onBlur={props.changeCommentField('comment')}
            defaultValue={props.comment}
            disabled={props.savingInProgress.comment}
          ></textarea>
        </div>
        <div className="ProjectNotes-input-container">
          {props.savingInProgress.contracted_visits && <div className="ProjectNotes-input-loader"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></div>}
          <label className="ProjectNotes-input-label" htmlFor="maxVisitsInput"><T i18nKey="project:max_visits">Max aantal werfbezoeken (incl. startverg.)</T></label>
          <input className="ProjectNotes-input" id="maxVisitsInput" disabled={props.savingInProgress.contracted_visits || !userIsAdminPlus} onBlur={props.saveMaxVisits} onChange={props.changeVisitsInput} value={props.contracted_visits} />
        </div>
        <div className="ProjectNotes-input-container">
          {props.savingInProgress.contracted_duration && <div className="ProjectNotes-input-loader"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></div>}
          <label className="ProjectNotes-input-label" htmlFor="durationInput"><T i18nKey="project:duration_contract_in_months">Uitvoeringstermijn contract (maanden)</T></label>
          <input className="ProjectNotes-input" id="durationInput" disabled={props.savingInProgress.contracted_duration || !userIsAdminPlus} onBlur={props.saveDuration} onChange={props.changeDurationInput} value={props.contracted_duration} />
        </div>
        {props.projectStatus !== 'design' && <Docs files={props.files || []} />}
        <div className="ProjectNotes-administration">
          <span><T i18nKey="project:administration">Administratie</T>:</span>
          <span>{administration}</span>
        </div>
      </div>
    </div>
  )
}

const FCode = ({ onFCodeChange, fCode }) => {
  return (
    <div className="ProjectNotes-fcode">
      <select value={fCode || ''} onChange={onFCodeChange}>
        <option value="">-</option>
        <option value="f0">F0</option>
        <option value="f1">F1</option>
        <option value="f2">F2</option>
        <option value="f3">F3</option>
      </select>
    </div>
  )
}

const Progress = ({ progress, projectStatus, onChange }) => {
  if (projectStatus !== 'design' && projectStatus !== 'execution') return null
  let width = getProgressValue('percent', progress)
  if (projectStatus !== 'design' && ~progress.indexOf('p')) {
    width = 0
  } else if (projectStatus === 'design' && !~progress.indexOf('p')) {
    width = 0
  }
  const widthString = `${width}%`
  const options = getOptions(projectStatus)
  return (
    <>
      <label htmlFor="progressInput"><T i18nKey="project:project_progress">Project voortgang</T></label>
      <div className="ProjectNotes-percent-bar">
        <div className={'ProjectNotes-percent-bar-content' + (width < 6 ? ' ProjectNotes-percent-bar-content-small' : '')} style={{ width: widthString }}><span className={width < 10 ? 'ProjectNotes-percent-bar-label-outside' : ''}>{widthString}</span></div>
      </div>
      <select id="progressInput" value={progress} onChange={onChange} >
        {options}
      </select>
    </>
  )
}

const PidDates = ({ projectStatus, pidMailDates, onChange }) => {
  if (projectStatus !== 'createPid' && projectStatus !== 'finished') return null

  const disabled = projectStatus === 'finished'

  function handleDateChange (name) {
    return e => {
      onChange(name, e.target.value)
    }
  }

  function getDate (name) {
    const date = (pidMailDates[name] || {}).date || ''
    if (date) return moment(date).format('YYYY-MM-DD')
    return ''
  }

  return (
    <div className="ProjectNotes-piddates">
      <div className="ProjectNotes-pid-row">
        <label htmlFor="pidDate1"><T i18nKey="project:1st_mail_pid">1e mail PID</T></label>
        <input type="date" id="pidDate1" onChange={handleDateChange('pidDate1')} value={getDate('pidDate1')} disabled={disabled} />
      </div>
      <div className="ProjectNotes-pid-row">
        <label htmlFor="pidDate2"><T i18nKey="project:2nd_mail_pid">2e mail PID</T></label>
        <input type="date" id="pidDate2" onChange={handleDateChange('pidDate2')} value={getDate('pidDate2')} disabled={disabled} />
      </div>
      <div className="ProjectNotes-pid-row">
        <label htmlFor="pidDate3"><T i18nKey="project:3rd_mail_pid">3e mail PID</T></label>
        <input type="date" id="pidDate3" onChange={handleDateChange('pidDate3')} value={getDate('pidDate3')} disabled={disabled} />
      </div>
      <div className="ProjectNotes-pid-row">
        <label htmlFor="pidDateSent"><T i18nKey="project:pid_sent">PID verstuurd</T></label>
        <input type="date" id="pidDateSent" onChange={handleDateChange('pidDateSent')} value={getDate('pidDateSent')} disabled={disabled} />
      </div>
    </div>
  )
}

const Docs = ({ files }) => {
  function findFile (type) {
    return files.find(file => {
      return file.deleted !== true && file.file_type === type
    }) || null
  }

  const beforedesign = findFile('beforedesign')
  const vgp = findFile('vgp')
  const analysis = findFile('analysis')

  return (
    <div>
      <span className="ProjectNotes-documents-title"><T i18nKey="project:documents_design">Documenten ontwerpfase</T>:</span>
      <FileRow file={beforedesign} type="advice_on_design" name={i18n.t('project:advice_on_design_abbreviated')} />
      <FileRow file={vgp} type="vgp" name={i18n.t('project:vgp')} />
      <FileRow file={analysis} type="analysis_quotes" name={i18n.t('project:analysis_quotes_abbreviated')} />
    </div>
  )
}

function getFileDate (file) {
  if (!file || !file.file) return '-'
  const date = moment(file.file.updated_at)
  if (!date.isValid()) return i18n.t('project:invalid_date')
  return date.format('DD-MM-YYYY')
}

const FileRow = ({ file, type, name }) => {
  const date = getFileDate(file)
  const fileObject = file ? file.file : {}
  function showNoFileWarning (e) {
    if (fileObject._id) return
    e.preventDefault()
    window.alert(i18n.t('project:file_x_not_uploaded_for_this_project', { file: i18n.t(`project:${type}`) }))
  }
  return (
    <a target="_blank" rel="noopener noreferrer" href={`${location.origin}/v1/file/${fileObject._id}/-1/${fileObject.fileName}`} onClick={showNoFileWarning}>
      <div className="ProjectNotes-documents-control">
        <span className="ProjectNotes-documents-item-name">{name}</span>
        <span className="ProjectNotes-documents-item-date">{date}</span>
      </div>
    </a>
  )
}

export default ProjectNotes
