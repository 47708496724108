import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'underscore'
import { Trans as T, withTranslation } from 'react-i18next'

export class Logs extends Component {
  constructor (props) {
    super(props)

    const newLogs = props.logs
    const sortedNewLogs = this.groupByDays(this.sortOnDate(newLogs))
    this.state = { sortedLogs: sortedNewLogs }
  }

  sortOnDate (logs) {
    return _.sortBy(logs, log => {
      return -log.when
    })
  }

  groupByDays (logs) {
    return _.groupBy(logs, function (log) {
      return moment(log.when).format('DD MMM YY')
    })
  }

  render () {
    const logs = this.state.sortedLogs
    return (
      <div>
        {_.size(logs)
          ? <ul className="logs">
            {_.map(logs, (logs, date) => {
              return (
                <DayLogs date={date} logs={logs} key={date} />
              )
            })}
          </ul>
          : <span><T i18nKey="project:no_logs_available">Geen logs beschikbaar</T></span>
        }
      </div>
    )
  }
}

const DayLogs = props =>
  <li>
    <div className='logs_date'>{props.date}</div>
    <ul>
      {
        props.logs.map((log, index) => {
          return (
            <Log log={log} key={index} />
          )
        })
      }
    </ul>
  </li>

class LogView extends Component {
  getHow () {
    const log = this.props.log
    const how = log.how || ''
    return this.props.t('project:' + how)
  }

  getWho () {
    const log = this.props.log
    if (log.who.type === 'SafetySnapper') {
      return (
        <span><T i18nKey="project:safety_coordinator">Veiligheidscoördinator</T> (SS/AS)</span>
      )
    }
    return (
      <Link to={`/gebruiker/${log.who.object._id}`}>{log.who.object.name}</Link>
    )
  }

  getWhat () {
    const t = this.props.t
    const log = this.props.log
    let what
    let type
    let fileType
    if (log.what) {
      const whatType = log.what.type
      switch (whatType) {
        case 'File':
          type = t('project:file')
          log.what.object = log.what.object || {}
          what = log.what.object.fileName
          switch (log.tag) {
            case 'vgpo': fileType = t('project:vgpo'); break
            case 'vgpu': fileType = t('project:vgpu'); break
            case 'cd': fileType = t('project:cd'); break
            case 'beforedesign': fileType = t('project:advice'); break
            case 'analysis': fileType = t('project:analysis'); break
            default: fileType = t('project:pid')
          }
          break
        case 'Planning':
          type = t('project:planning_lowercase')
          break
        case 'User':
          type = t('project:contact_lowercase')
          log.what.object = log.what.object || {}
          what = <Link to={`/gebruiker/${log.what.object._id}`}>{log.what.object.name}</Link>
          break
        default:
      }
    } else {
      what = t('project:this_project')
    }

    return (
      <span>{fileType} {type} {what}</span>
    )
  }

  render () {
    const log = this.props.log
    const when = moment(log.when).format('H:mm')
    const how = this.getHow()
    const who = this.getWho()
    const what = this.getWhat()
    return (
      <li>
        <div className='logs_time'>{when}</div>
        <div className='logs_log'>{who} <T i18nKey="project:logs_did">heeft</T> {what} {how}</div>
      </li>
    )
  }
}
const Log = withTranslation()(LogView)
