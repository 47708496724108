import React, { Component } from 'react'
import _ from 'underscore'
import { leftOrRight, setPosition, toggle } from '../../tools.jsx'
import location from '../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class UserTool extends Component {
  constructor (props) {
    super(props)

    this.triggerLogout = this.triggerLogout.bind(this)
    this.gotoPage = this.gotoPage.bind(this)
  }

  triggerLogout () {
    $(window).trigger('loggedOut')
    this.props.history.push('/login')
    localStorage.removeItem('authToken')
    sessionStorage.clear()
  }

  openDropdown (event) {
    event.preventDefault()
    const dropDownMenu = $(event.currentTarget).next()
    const menuPosition = leftOrRight(event.currentTarget)
    setPosition(dropDownMenu, menuPosition)
    toggle(dropDownMenu, 'toolbarProfileMenu')
  }

  closeDropdown (event) {
    event.preventDefault()
    toggle($(event.currentTarget), 'toolbarProfileMenu')
  }

  gotoPage (route) {
    this.props.history.push(route)
  }

  render () {
    const user = this.props.user
    if (!user) {
      return (<div className="right"></div>)
    }
    const imgUrl = user.profile_picture_icon_url || '/images/default_user_icon.png'
    const img = <img alt="icon" className="profile_pic" src={`${location.origin}${imgUrl}`} />
    return (

      <div className="right">
        <a onClick={this.openDropdown} href="#" className="toolbarProfileMenu top_bar_profile dd_menuList">
          {user.name}
          <div className="small profile_pic_round">
            {img}
          </div>
        </a>
        <ul onClick={this.closeDropdown} style={{ display: 'none' }} id="toolbarProfileMenu" className="toolbarProfileMenu dd_list">
          <li onClick={this.gotoPage.bind(this, `/gebruiker/${user._id}`)}>
            <a href="">
              <span className="glyphicons glyphicons-user"></span> <T i18nKey="header:my_profile">Mijn profiel</T>
            </a>
          </li>
          <li onClick={this.triggerLogout}>
            <a href="">
              <span className="glyphicons glyphicons-unlock"></span> <T i18nKey="header:log_out">Log uit</T>
            </a>
          </li>
        </ul>
        {user.role !== 'PARTNER' ? <MyPlanningButtonView user={this.props.user} token={this.props.token} state={this.props.state} /> : null}
      </div>

    )
  }
}

class MyPlanningButtonView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      planningCount: 0
    }

    this.getCount = this.getCount.bind(this)
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  shouldComponentUpdate (nextProps, nextState) {
    const shouldUpdate = !_.isEqual(nextState, this.state)
    return shouldUpdate
  }

  componentDidMount () {
    this.getCount()
    $(window).on('updatePlanningSlider', (event) => {
      this.getCount()
    })
    setInterval(this.getCount, 1000 * 60 * 2)
  }

  getCount () {
    const planningCountAjaxSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning/count?user=${this.props.user._id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    $.ajax(planningCountAjaxSettings).done(planningCount => {
      if (!this.isUnmounting) {
        this.setState({
          planningCount
        })
      }
    }).fail(error => {
      console.error(error)
    })
  }

  hasPermission (role) {
    switch (role) {
      case 'ADMIN+':
      case 'ADMIN':
      case 'EMPLOYEE':
      case 'FREELANCER': return true
      default: return false
    }
  }

  togglePlanningSlider (e) {
    e.preventDefault()
    e.stopPropagation()
    $(window).trigger('togglePlanningSlider')
  }

  render () {
    return (
      <span className="my_planning">
        {this.hasPermission(this.props.user.role)
          ? <a href='' onClick={this.togglePlanningSlider}>
            <span className="glyphicons glyphicons-list-alt"></span>
            <div className="label">{this.state.planningCount}</div>
          </a>
          : null
        }
      </span>
    )
  }
}
