import React, { Component } from 'react'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

const fetchOrganisations = function (options, callback) {
  const component = options.component
  const search = options.filter || ''
  const skip = options.skip || ''
  const settings = {
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/organisation?sortBy=${options.sortType || 'name'}&search=${encodeURIComponent(search)}&skip=${skip}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${component.props.token.token}`
    }
  }
  const promise = $.ajax(settings)
  promise.done((data, textStatus, jqXHR) => {
    if (!component.isUnmounting) {
      $(window).trigger('searchFinished')
      callback(data)
    }
  })
  promise.fail((jqXHR, textStatus, errorThrown) => {
    console.error(jqXHR)
    if (jqXHR.status === 401) {
      console.warn('unauthorized token, expired?')
      component.triggerLogout()
    }
  })
}

const initScrollLoad = function (component) {
  $(window).off('.scrollHandler') // lets just make sure it's off first

  const $window = $(window)
  const $organisationsSection = $('section.organisations')
  const sectionTop = $organisationsSection.position().top
  let sectionHeight
  let innerHeight
  let scrollTop
  let working = false
  $(window).on('scroll.scrollHandler', (event) => {
    if (!working) {
      innerHeight = window.innerHeight
      sectionHeight = $organisationsSection.height()
      scrollTop = $window.scrollTop()
      if (scrollTop + innerHeight > sectionTop + sectionHeight - 300) {
        working = true
        console.log('fetching 100 more organisations')
        // load next 100 and append to current list
        component.setState({
          loading: true
        })
        fetchOrganisations({
          component: component,
          sortType: component.state.sortStatus,
          filter: component.state.filterStatus,
          skip: component.state.timesLoaded * 100
        }, (organisations, organisationsCount) => {
          const newOrganisationList = component.state.organisations.concat(organisations)
          const newPageCount = component.state.timesLoaded + 1
          const state = component.state
          state.organisations = newOrganisationList
          state.timesLoaded = newPageCount
          window.dataCache.organisationsState = state
          component.setState({
            organisations: newOrganisationList,
            timesLoaded: newPageCount,
            loading: false
          })
          if (organisations.length !== 0) {
            working = false
          } else {
            console.log('no more organisations to fetch')
            $(window).off('.scrollHandler')
          }
        })
      }
    }
  })
}

class OrganisationsView extends Component {
  constructor (props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search || '')
    const intialFilter = searchParams.get('filter') || ''
    const intialSort = searchParams.get('sort') || ''

    this.state = {
      organisations: [],
      filterStatus: intialFilter,
      sortStatus: intialSort,
      timesLoaded: 1,
      loading: false
    }

    this.getFilteredList = this.getFilteredList.bind(this)
    this.sortList = this.sortList.bind(this)
    this.updateURL = this.updateURL.bind(this)
  }

  componentWillMount () {
    document.title = this.props.t('title:organisations')
  }

  componentDidMount () {
    if (this.state.organisations.length === 0) {
      this.setState({
        loading: true
      })
      fetchOrganisations({
        component: this,
        sortType: this.state.sortStatus,
        filter: this.state.filterStatus
      }, (organisations, organisationsElements) => {
        const state = this.state
        state.organisations = organisations
        window.dataCache.organisationsState = state
        this.setState({
          organisations: organisations,
          loading: false
        })
      })
    }

    setTimeout(function (comp) {
      initScrollLoad(comp)
    }, 100, this)

    $(window).on('filterTrigger', (event, filter) => {
      this.getFilteredList(filter)
    })
  }

  componentWillUnmount () {
    $(window).trigger('resetSearchbar')
    $(window).off('filterTrigger')
    $(window).off('.scrollHandler')
    this.isUnmounting = true
  }

  getFilteredList (filter) {
    fetchOrganisations({
      component: this,
      sortType: this.state.sortStatus,
      filter: filter
    }, (organisations) => {
      const state = this.state
      state.organisations = organisations
      state.filterStatus = filter
      window.dataCache.organisationsState = state
      this.updateURL('filter', filter)
      this.setState({
        organisations: organisations,
        filterStatus: filter,
        timesLoaded: 1,
        loading: false
      })
      if (filter === '') {
        initScrollLoad(this)
      }
    })
  }

  sortList () {
    let newSort = this.state.sortStatus
    if (newSort[0] === '-') {
      newSort = 'name'
    } else {
      newSort = '-name'
    }
    fetchOrganisations({
      component: this,
      sortType: newSort,
      filter: this.state.filterStatus
    }, (organisations) => {
      const state = this.state
      state.organisations = organisations
      state.sortStatus = newSort
      window.dataCache.organisationsState = state
      this.updateURL('sort', newSort)
      this.setState({
        organisations: organisations,
        sortStatus: newSort,
        timesLoaded: 1,
        loading: false
      })
    })
  }

  updateURL (name, value) {
    const newParams = new window.URLSearchParams(this.props.location.search)
    newParams.set(name, value)
    this.props.history.replace({
      search: '?' + newParams.toString()
    })
  }

  showSortIcon () {
    if (this.state.sortStatus === '-name') {
      return (
        <span className="glyphicons glyphicons-sort-by-order-alt"></span>
      )
    } else {
      return (
        <span className="glyphicons glyphicons-sort-by-order"></span>
      )
    }
  }

  render () {
    const organisations = this.state.organisations || []
    const loadingRow = (
      <tr>
        <td colSpan="3"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
      </tr>
    )

    let list = organisations.map(organisation => {
      return <OrganisationRow history={this.props.history} user={this.props.user} organisation={organisation} token={this.props.token} key={organisation._id} />
    })
    if (list.length === 0) {
      list = (
        <tr key="noResults">
          <td colSpan="3"><T i18nKey="organisation:no_search_results">Geen resultaten voor je zoekopdracht</T></td>
        </tr>
      )
    }

    return (
      <section className="container content organisations">
        <section className="list_block">
          <table className="list_table">
            <thead>
              <tr>
                <th style={{ width: '35px' }}></th>
                <th className='sortables' onClick={this.sortList}><T i18nKey="organisation:name">Naam</T> {this.showSortIcon()}</th>
                <th style={{ width: '26px' }}></th>
              </tr>
            </thead>
            <tbody>
              {list}
              {this.state.loading
                ? loadingRow
                : null}
            </tbody>
          </table>
        </section>
      </section>
    )
  }
}

const Organisations = withTranslation()(OrganisationsView)
export { Organisations }

class OrganisationRow extends Component {
  constructor (props) {
    super(props)

    this.gotoOrganisation = this.gotoOrganisation.bind(this)
    this.toggleQuickactions = this.toggleQuickactions.bind(this)
  }

  sendDeleteRequest () {
    const organisationId = this.props.organisation._id
    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/organisation/${organisationId}`,
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }).done((response) => {
      console.log(response)
      $('#' + organisationId).hide()
    }).fail(function (error) {
      console.error(error)
    })
  }

  gotoOrganisation (event) {
    this.props.history.push(`/organisatie/${event.currentTarget.id}`)
  }

  stopPropagation (event) {
    event.stopPropagation()
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  render () {
    const organisation = this.props.organisation
    const imgUrl = organisation.profile_picture_icon_url || '/images/default_organisation_icon.png'
    const img = (
      <div className="micro profile_pic_round">
        <img className="profile_pic" alt={organisation.name} src={`${location.origin}${imgUrl}`} />;
      </div>
    )
    const isAdmin = this.props.user.role.match('ADMIN')
    return (
      <tr onClick={this.gotoOrganisation} id={organisation._id}>
        <td>{img}</td>
        <td>{organisation.name}</td>
        <td onClick={this.stopPropagation} className="quickAction_cel">
          <a onClick={this.toggleQuickactions} href="#" className="quickAction">
            <span className="glyphicons glyphicons-more"></span>
          </a>
          <div className="quickAction_tip" style={{ display: 'none' }}>
            <div className="quickAction_tip_arrow"></div>
            <div className="quickAction_tip_inner">
              <ul>
                {isAdmin
                  ? <li>
                    <a onClick={this.sendDeleteRequest} href="#"><T i18nKey="common:delete">Verwijderen</T></a>
                  </li>
                  : null}
              </ul>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}
