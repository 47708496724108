import React, { Component } from 'react'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class NewPasswordView extends Component {
  constructor (props) {
    super(props)

    let token = props.match.params.token
    if (!token) {
      const searchParams = new URLSearchParams(props.location.search || '')
      token = searchParams.get('token') || ''
    }

    this.state = {
      password: '',
      password2: '',
      token: token,
      loading: false
    }

    this.changeValue = this.changeValue.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentWillMount () {
    document.title = this.props.t('title:new_password')
  }

  changeValue (fieldName, event) {
    this.setState({
      [fieldName]: event.currentTarget.value
    })
  }

  submitForm (event) {
    event.preventDefault()

    if (this.state.password === this.state.password2) {
      this.setState({
        loading: true
      })

      const newPassword = {
        password: this.state.password
      }

      const settings = {
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/user/new_password`,
        method: 'PUT',
        headers: {
          authorization: `Bearer ${this.state.token}`,
          'content-type': 'application/json'
        },
        processData: false,
        data: JSON.stringify(newPassword)
      }

      $.ajax(settings).done(response => {
        this.setState({
          loading: false
        })

        this.props.history.push('/')
      }).fail(error => {
        console.error(error)
        this.setState({
          loading: false
        })
      })
    } else {
      console.log('different passwords')
    }
  }

  render () {
    return (
      <section className="container form">
        <form onSubmit={this.submitForm}>
          <fieldset className="form-group">
            <label htmlFor="password"><T i18nKey="login:new_password_new">Nieuw wachtwoord</T></label>
            <input type="password" className="form-control" id="password" placeholder={this.props.t('login:new_password_new')} value={this.state.password} onChange={this.changeValue.bind(this, 'password')} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="password2"><T i18nKey="login:new_password_confirm">Wachtwoord bevestigen</T></label>
            <input type="password" className="form-control" id="password2" placeholder={this.props.t('login:new_password_confirm')} value={this.state.password2} onChange={this.changeValue.bind(this, 'password2')} />
          </fieldset>
          <fieldset className="form-group">
            <button type="submit" className="form-submit">
              {this.state.loading
                ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                : null}
                  &nbsp;<T i18nKey="login:send">Versturen</T>
            </button>
          </fieldset>
        </form>
      </section>
    )
  }
}

const NewPassword = withTranslation()(NewPasswordView)
export { NewPassword }
