import React, { Component } from 'react'
import { Logs } from './../../../logging/logs.jsx'
import location from '../../../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class LogsView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      logs: [],
      loading: false,
      error: false
    }
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentWillMount () {
    this.setState({
      loading: true
    })
    const project = this.props.project._id
    const populate = 'what,who'
    const limit = 100
    const sort = '-when'
    const url = `${location.origin}/v1/log?project=${project}&populate=${populate}&limit=${limit}&sort=${sort}`

    $.ajax({
      async: true,
      crossDomain: true,
      url: url,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }).done((response) => {
      if (!this.isUnmounting) {
        this.setState({
          logs: response || [],
          loading: false
        })
      }
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      this.setState({
        error: responseMessage,
        loading: false
      })
    })
  }

  render () {
    const logs = this.state.logs
    return (
      <section className="right_content">
        <h1><T i18nKey="project:logs">Logs</T></h1>
        {this.state.loading
          ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
          : <div>
            {this.state.error
              ? <p>{this.state.error}</p>
              : <Logs logs={logs}/>
          }
          </div>
        }
      </section>
    )
  }
}
