import React, { Component } from 'react'
import _ from 'underscore'
import { FileUploadForm } from '../../../../reactUtils/fileUploadForm.jsx'
import { hasPermissionFor } from '../../../../../tools.jsx'
import location from '../../../../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class VgpoView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      vgpFile: _.find(this.props.project.files, function (file) { return file.file_type === 'vgpo' && !file.isDeleted }),
      showFileForm: false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const $table = $('.list_table')
    $table.hide(0, function () {
      $table.show()
    })
  }

  showFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: true
    })
  }

  showNewVersionFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: false,
      oldFileId: $(event.currentTarget).data('id')
    })
  }

  closeFileForm (event) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileForm: false
    })
  }

  updateFiles (result) {
    console.log(result)

    if (Object.prototype.toString.apply(result).slice(8, -1) === 'String') {
      try {
        result = JSON.parse(result)
      } catch (error) {
        console.error(new Error(['Expected json string but got ', result]))
        result = {}
      }
    }
    this.setState({
      vgpFile: {
        file: result
      }
    })
  }

  render () {
    const vgpFile = this.state.vgpFile
    let fileTable
    const finished = this.props.project.status === 'finished'

    const userCanUploadVGP = hasPermissionFor(this.props.user.role, 'ADMIN')

    if (vgpFile) {
      const file = vgpFile.file || {}
      fileTable = (
                <table className="list_table">
                    <thead>
                        <tr>
                            <th><T i18nKey="project:name">Naam</T></th>
                            <th style={{ width: 110 }}><T i18nKey="common:download">Download</T></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="no-pointer">
                            <td>{file.fileName}</td>
                            <td><a href={`${location.origin}/v1/file/${file._id}/-1/${file.fileName}`}><span className="glyphicons glyphicons-download-alt"></span> <T i18nKey="common:download">Download</T></a></td>
                        </tr>
                    </tbody>
                </table>
      )
    } else {
      fileTable = (
                <table className="list_table">
                    <thead>
                        <tr>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="no-hover">
                            <td>
                            {this.state.loading
                              ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                              : <span><T i18nKey="project:no_file_yet">Nog geen bestand toegevoegd.</T></span>
                            }
                            </td>
                        </tr>
                    </tbody>
                </table>
      )
    }

    return (
            <div>
                {this.state.error ? <h1>{this.state.error}</h1> : null}
                <h4><T i18nKey="project:design">Ontwerp</T>
                    {userCanUploadVGP && !finished && vgpFile
                      ? <button className="btn-green btn-low right" data-id={vgpFile.file._id} onClick={this.showNewVersionFileForm}>
                        <span className="glyphicons glyphicons-cloud-upload"></span>
                        &nbsp;&nbsp;&nbsp; <T i18nKey="project:upload_new_version">Upload nieuwe versie</T>
                        </button>
                      : null}
                    {userCanUploadVGP && !finished && !vgpFile
                      ? <button className="btn-green btn-low right" onClick={this.showFileForm}>
                        <span className="glyphicons glyphicons-cloud-upload"></span>
                        &nbsp;&nbsp;&nbsp; <T i18nKey="project:add_file">Voeg bestand toe</T>
                        </button>
                      : null}
                </h4>
                {this.state.showFileForm
                  ? <FileUploadForm closeFileForm={this.closeFileForm} token={this.props.token} user={this.props.user} fileType={{ type: 'vgpo' }} project={this.props.project} newFile={this.state.newFileVersion} oldFileId={this.state.oldFileId} updateParentView={this.updateFiles}/>
                  : null}
                {fileTable}
            </div>
    )
  }
}
