import React, { Component, useState } from 'react'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

const saveTemplate = function (token, requestType, template, templateId) {
  const url = templateId ? `${location.origin}/v1/message/template/${templateId}` : `${location.origin}/v1/message/template`
  const settings = {
    async: true,
    crossDomain: true,
    url: url,
    method: requestType,
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    processData: false,
    data: JSON.stringify(template)
  }

  return $.ajax(settings)
}

class CreateTemplateViewComp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      template: {
        templateType: 'user'
      },
      error: false
    }

    this.typeRadioChange = this.typeRadioChange.bind(this)
    this.submitTemplate = this.submitTemplate.bind(this)
    this.isChecked = this.isChecked.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentWillMount () {
    const template = this.props.template
    if (template) {
      this.setState({
        template: {
          _id: template._id,
          name: template.name,
          messageTemplate: template.messageTemplate,
          subjectTemplate: template.subjectTemplate,
          templateType: template.templateType
        }
      })
    }
  }

  typeRadioChange (event) {
    const template = this.state.template
    template.templateType = event.currentTarget.id
    this.setState({
      template
    })
  }

  submitTemplate (event) {
    event.preventDefault()
    const that = this
    const template = this.state.template
    if (!template.name || !template.messageTemplate || !template.templateType || !template.subjectTemplate) {
      this.setState({
        error: this.props.t('settings:not_enough_info')
      })
      return console.info('not enough info')
    }

    if (template._id) {
      // put
      const templateSavePromise = saveTemplate(this.props.token.token, 'PUT', template, template._id)
      templateSavePromise.done(dbTemplate => {
        that.props.templateEdited(template)
      })
      templateSavePromise.fail(error => {
        console.error(error)
        that.setState({
          error: error.responseJSON.message
        })
      })
    } else {
      // post
      const templateSavePromise = saveTemplate(this.props.token.token, 'POST', template)
      templateSavePromise.done(template => {
        that.props.templateAdded(template)
      })
      templateSavePromise.fail(error => {
        console.error(error)
        that.setState({
          error: error.responseJSON.message
        })
      })
    }
  }

  isChecked (radioName) {
    return this.state.template.templateType === radioName
  }

  changeInput (key, event) {
    const template = this.state.template
    template[key] = event.currentTarget.value
    this.setState({
      template
    })
  }

  render () {
    let shortcodeView
    switch (this.state.template.templateType) {
      case 'user': shortcodeView = <UserShortcodeView contextValues={this.props.contextValues} />; break
      case 'organisation': shortcodeView = <OrganisationShortcodeView contextValues={this.props.contextValues} />; break
      default: shortcodeView = null
    }
    return (
      <section>
        <button onClick={this.props.gotoListView}><T i18nKey="common:back">Terug</T></button>
        <h1><T i18nKey="settings:create_template">Nieuw template maken</T></h1>
        {this.state.error ? <h1>{this.state.error}</h1> : ''}
        <form onSubmit={this.submitTemplate}>
          <fieldset className="form-group">
            <label htmlFor="user"><T i18nKey="settings:user">Gebruiker</T>&nbsp;</label>
            <input onChange={this.typeRadioChange} type="radio" name="templateTypeRadio" id="user" value="user" checked={this.isChecked('user')} />
            <br />
            <label htmlFor="organisation"><T i18nKey="settings:organisation">Organisatie</T>&nbsp;</label>
            <input onChange={this.typeRadioChange} type="radio" name="templateTypeRadio" id="organisation" value="organisation" checked={this.isChecked('organisation')} />
          </fieldset>
          {shortcodeView}
          <fieldset className="form-group">
            <label htmlFor="templateName"><T i18nKey="settings:name">Naam</T></label>
            <input className="form-control" type="text" id="templateName" value={this.state.template.name} onChange={this.changeInput.bind(this, 'name')} />
          </fieldset>
          <fieldset className="form-group">
            <label><T i18nKey="settings:subject">Onderwerp</T></label>
            <textarea className="form-control" id="subjectTemplate" value={this.state.template.subjectTemplate} onChange={this.changeInput.bind(this, 'subjectTemplate')}></textarea>
          </fieldset>
          <fieldset className="form-group">
            <label><T i18nKey="settings:message">Bericht</T></label>
            <textarea className="form-control" id="messageTemplate" value={this.state.template.messageTemplate} onChange={this.changeInput.bind(this, 'messageTemplate')}></textarea>
          </fieldset>
          <fieldset className="form-group">
            <input type="submit" value={this.props.t('common:save')} />
          </fieldset>
        </form>
      </section>
    )
  }
}

const UserShortcodeView = (props) => {
  const [showUser, setShowUser] = useState(false)
  const [showProject, setShowProject] = useState(false)

  function toggleUser () {
    if (showUser) {
      setShowUser(false)
    } else {
      setShowUser(true)
      setShowProject(false)
    }
  }

  function toggleProject () {
    if (showProject) {
      setShowProject(false)
    } else {
      setShowProject(true)
      setShowUser(false)
    }
  }

  const user = props.contextValues.user || []
  const project = props.contextValues.project
  return (
    <div style={{ marginBottom: 24 }}>
      <h4>Lijst van beschikbare variabelen</h4>
      <button type="button" onClick={toggleUser} >Toon gebruiker</button>
      <button type="button" onClick={toggleProject} >Toon werf</button>
      <p>Deze variabelen kunnen in de template gebruikt worden met <b>{'{{'}</b> ervoor en <b>{'}}'}</b> erachter.</p>
      <p>bvb: {'{{user.name}}'}</p>
      { showUser &&
        <ul>{user.map((name, i) => {
          return <li key={i}>{`user.${name}`}</li>
        })}</ul>
      }
      { showProject &&
        <ul>{project.map((name, i) => {
          return <li key={i}>{`project.${name}`}</li>
        })}</ul>
      }
    </div>
  )
}

const OrganisationShortcodeView = (contextValues) => {
  return UserShortcodeView(contextValues)
  // return (
  //     <div>
  //         <p>{"{{organisationname}}, {{...}}"}</p>
  //     </div>
  // )
}

const CreateTemplateView = withTranslation()(CreateTemplateViewComp)
export { CreateTemplateView }
