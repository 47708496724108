import React, { Component } from 'react'
import { ProjectsTable } from './tables.jsx'
import _ from 'underscore'
import Filter from '../../filter/filter.js'
import { xmlGetRequest } from '../../../tools.jsx'
import location from '../../../location'
import { Trans as T } from 'react-i18next'

export class Projects extends Component {
  constructor (props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search || '')
    const intialSort = searchParams.get('sort') || 'project_name'

    const userRole = props.user ? props.user.role : ''
    const userCanSeeVisitLabel = userRole.match('ADMIN') || userRole === 'EMPLOYEE' || userRole === 'FREELANCER'
    this.state = {
      projectStatus: (props.location.state || {}).activeTab || 'execution',
      userCanSeeVisitLabel,
      loading: true,
      projectsStore: {},
      sort: intialSort,
      projectsCount: {},
      filterCount: {}
    }

    this.getProjectsCount = this.getProjectsCount.bind(this)
    this.initScrollLoad = this.initScrollLoad.bind(this)
    this.updateProjects = this.updateProjects.bind(this)
    this.sortListener = this.sortListener.bind(this)
    this.setFilterCount = this.setFilterCount.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.removeScrollListener = this.removeScrollListener.bind(this)

    this.sectionRef = React.createRef()
    this.initialTop = 0
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      (!_.isEqual(this.state, nextState)) ||
      (this.props.location.search !== nextProps.location.search)
    ) { return true }
    return false
  }

  componentWillUpdate (nextProps, nextState) {
    const projectStatus = this.state.projectStatus
    const newProjectType = nextState.projectStatus
    if (projectStatus !== newProjectType) {
      this.initScrollLoad()
    }
    if ((this.state.projectsStore[projectStatus] || {}).amount !== (nextState.projectsStore[projectStatus] || {}).amount) { this.initScrollLoad() }
  }

  componentDidMount () {
    this.getProjectsCount()
    this.initScrollLoad()
    window.addEventListener('getSortedData', this.sortListener)
    window.addEventListener('reloadProjectCount', this.getProjectsCount)
    window.addEventListener('removeScrollListener', this.removeScrollListener)
    this.initialTop = this.sectionRef.current.getBoundingClientRect().top
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('getSortedData', this.sortListener)
    window.removeEventListener('reloadProjectCount', this.getProjectsCount)
    window.removeEventListener('removeScrollListener', this.removeScrollListener)
    this.isUnmounting = true
  }

  removeScrollListener () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  initScrollLoad () {
    window.removeEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    const working = this.state.loading
    if (!working) {
      const innerHeight = window.innerHeight
      const scrollTop = window.scrollY
      const section = this.sectionRef.current
      const sectionRect = section.getBoundingClientRect()
      const sectionHeight = sectionRect.height
      if (scrollTop + innerHeight > this.initialTop + sectionHeight - 300) { window.dispatchEvent(new CustomEvent('getNextPage')) }
    }
  }

  sortListener (event, newSortState) {
    newSortState = newSortState || event.detail
    if (this.isUnmounting) { return }

    const sortState = this.state.sort
    if (newSortState === sortState) { newSortState = `-${newSortState}` }
    if (newSortState === 'last_visit' && sortState !== 'last_visit' && sortState !== '-last_visit') { newSortState = '-last_visit' }

    const newParams = new window.URLSearchParams(this.props.location.search)
    newParams.set('sort', newSortState)
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: '?' + newParams.toString(),
      state: this.props.location.state
    })

    this.setState(state => ({
      sort: newSortState
    }))
  }

  changeProjectType (newType) {
    if (!this.isUnmounting) {
      this.setState({
        projectStatus: newType
      })
      const location = this.props.location
      this.props.history.replace({
        pathname: location.pathname,
        search: location.search,
        state: Object.assign({}, location.state, { activeTab: newType })
      })
    }
  }

  addActiveClass (type) {
    const projectStatus = this.state.projectStatus
    if (projectStatus === type) {
      return 'active'
    } else {
      return ''
    }
  }

  createButtons () {
    const userRole = this.props.user.role
    const projectsCount = this.state.projectsCount
    const filterCount = this.state.filterCount

    let buttons = [
      <button key="1" onClick={this.changeProjectType.bind(this, 'new')} className={this.addActiveClass('new')}>
        <span><T i18nKey="project:new">nieuw</T> </span>
        <span className="amount">{`${filterCount.new || 0}/${projectsCount.new || 0}`}</span>
      </button>,
      <button key="2" onClick={this.changeProjectType.bind(this, 'design')} className={this.addActiveClass('design')}>
        <span><T i18nKey="project:vc_design">vc ontwerp</T> </span>
        <span className="amount">{`${filterCount.design || 0}/${projectsCount.design || 0}`}</span>
      </button>,
      <button key="3" onClick={this.changeProjectType.bind(this, 'execution')} className={this.addActiveClass('execution')}>
        <span><T i18nKey="project:vc_execution">vc uitvoer</T> </span>
        <span className="amount">{`${filterCount.execution || 0}/${projectsCount.execution || 0}`}</span>
      </button>,
      <button key="4" onClick={this.changeProjectType.bind(this, 'waiting')} className={this.addActiveClass('waiting')}>
        <span><T i18nKey="project:waiting">in wacht</T> </span>
        <span className="amount">{`${filterCount.waiting || 0}/${projectsCount.waiting || 0}`}</span>
      </button>,
      <button key="5" onClick={this.changeProjectType.bind(this, 'onHold')} className={this.addActiveClass('onHold')}>
        <span><T i18nKey="project:on_hold">on-hold</T> </span>
        <span className="amount">{`${filterCount.onHold || 0}/${projectsCount.onHold || 0}`}</span>
      </button>,
      <button key="6" onClick={this.changeProjectType.bind(this, 'createPid')} className={this.addActiveClass('createPid')}>
        <span><T i18nKey="project:create_pid">opmaak pid</T> </span>
        <span className="amount">{`${filterCount.createPid || 0}/${projectsCount.createPid || 0}`}</span>
      </button>,
      <button key="7" onClick={this.changeProjectType.bind(this, 'finished')} className={this.addActiveClass('finished')}>
        <span><T i18nKey="project:finished">afgerond</T> </span>
        <span className="amount">{`${filterCount.finished || 0}/${projectsCount.finished || 0}`}</span>
      </button>
    ]

    if (userRole === 'FREELANCER') {
      buttons = [buttons[1], buttons[2], buttons[3]]
    }
    return buttons
  }

  getProjectsCount () {
    const token = this.props.token.token
    const url = `${location.origin}/v1/project/count?status=`
    const requests = [
      xmlGetRequest(url + 'waiting', token),
      xmlGetRequest(url + 'design', token),
      xmlGetRequest(url + 'execution', token),
      xmlGetRequest(url + 'finished', token),
      xmlGetRequest(url + 'createPid', token),
      xmlGetRequest(url + 'new', token)
    ]
    Promise.all(requests)
      .then(responses => {
        if (this.isUnmounting) { return }

        this.setState(state => ({
          projectsCount: {
            waiting: responses[0],
            design: responses[1],
            execution: responses[2],
            finished: responses[3],
            createPid: responses[4],
            new: responses[5]
          }
        }))
      }) // TODO: .catch(error => {})
  }

  updateProjects (projects, onlyLoading) {
    if (this.isUnmounting) { return }

    if (onlyLoading) {
      return this.setState(state => ({
        loading: true
      }))
    }
    if (!projects) {
      return this.setState(state => ({
        projectsStore: {},
        loading: true
      }))
    }
    this.setState(state => ({
      projectsStore: projects,
      loading: false
    }))
  }

  setFilterCount (filterCount) {
    if (this.isUnmounting) { return }
    this.setState(state => ({ filterCount }))
  }

  render () {
    const projectStatus = this.state.projectStatus
    const projects = (this.state.projectsStore[projectStatus] || {}).projects || []
    return (
      <section ref={this.sectionRef} className="content projects_toplevel">
        <div className="top_tab">
          {this.createButtons()}
        </div>
        <div className="projects-container">
          <Filter token={this.props.token} user={this.props.user} sort={this.state.sort} type="project" status={projectStatus} updateProjects={this.updateProjects} projectsCount={this.state.projectsCount} setFilterCount={this.setFilterCount} />
          <ProjectsTable history={this.props.history} loading={this.state.loading} token={this.props.token} user={this.props.user} projectStatus={this.state.projectStatus} projects={projects} sort={this.state.sort} userCanSeeVisitLabel={this.state.userCanSeeVisitLabel} />
        </div>
      </section>
    )
  }
}
