import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import nl from './languages/nl_be'
import fr from './languages/fr_be'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'nl-BE',

    ns: ['common', 'header', 'login', 'organisation', 'planning', 'project', 'settings', 'title', 'toolbar', 'tools', 'user'], // namespaces
    defaultNS: 'common',
    resources: {
      'nl-BE': nl,
      'fr-BE': fr
    },

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
