import React, { Component } from 'react'
import _ from 'underscore'
import { FileUploadForm } from '../../../reactUtils/fileUploadForm.jsx'
import { hasPermissionFor } from '../../../../tools.jsx'
import location from '../../../../location'
import './vgpFileView.css'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class VgpFileView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      vgpFile: _.find(this.props.project.files, function (file) { return file.file_type === 'vgp' && !file.isDeleted }),
      docFile: _.find(this.props.project.files, function (file) { return file.file_type === 'vgp_docx' && !file.isDeleted }),
      showFileForm: false,
      uploadingType: null,
      newFile: false,
      oldFileId: null
    }

    this.showFileForm = this.showFileForm.bind(this)
    this.closeFileForm = this.closeFileForm.bind(this)
    this.updateFiles = this.updateFiles.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    const newVgpFile = _.find(nextProps.project.files, function (file) { return file.file_type === 'vgp' && !file.isDeleted })
    const currentVgpFile = this.state.vgpFile
    if (newVgpFile.file && currentVgpFile.file && newVgpFile.file.fileId !== currentVgpFile.file.fileId) {
      this.setState(state => ({
        vgpFile: newVgpFile
      }))
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const $table = $('.list_table')
    $table.hide(0, function () {
      $table.show()
    })
  }

  showFileForm (type) {
    let file
    if (type === 'vgp') { file = this.state.vgpFile } else { file = this.state.docFile }

    const newState = {
      showFileForm: true,
      uploadingType: type,
      newFile: !file,
      oldFileId: file ? file.file._id : null
    }

    this.setState(state => newState)
  }

  closeFileForm (event) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileForm: false
    })
  }

  updateFiles (result) {
    if (Object.prototype.toString.apply(result).slice(8, -1) === 'String') {
      try {
        result = JSON.parse(result)
      } catch (error) {
        console.error(new Error(['Expected json string but got ', result]))
        result = {}
      }
    }
    this.setState(state => ({
      [this.state.uploadingType === 'vgp' ? 'vgpFile' : 'docFile']: {
        file: result
      }
    }))
    this.props.reloadProject()
  }

  render () {
    const vgpFile = this.state.vgpFile && this.state.vgpFile.file
    const docFile = this.state.docFile && this.state.docFile.file

    const userCanUploadVGP = hasPermissionFor(this.props.user.role, 'FREELANCER')
    const userCanUploadVGPDoc = hasPermissionFor(this.props.user.role, 'EMPLOYEE')

    return (
      <div className="vgpFileView-container">

        { this.state.showFileForm &&
          <>
            <span>{`${this.state.uploadingType.toUpperCase()} upload`}</span>
            <FileUploadForm
              closeFileForm={this.closeFileForm}
              token={this.props.token}
              user={this.props.user}
              fileType={{ type: this.state.uploadingType }}
              project={this.props.project}
              newFile={this.state.newFile}
              oldFileId={this.state.oldFileId}
              updateParentView={this.updateFiles}
            />
          </>
        }

        <div className="vgpFileView-row">
          <span className="vgpFileView-text">{vgpFile ? vgpFile.fileName : <T i18nKey="project:no_vgp_added">Geen VGP toegevoegd</T>}</span>
          {userCanUploadVGP
            ? <button className="vgpFileView-button" onClick={() => this.showFileForm('vgp')}><span className="glyphicons glyphicons-cloud-upload"></span><T i18nKey="project:upload_pdf">PDF uploaden</T></button>
            : <div></div>
          }
          <div className="vgpFileView-link">
            {vgpFile && <a href={`${location.origin}/v1/file/${vgpFile._id}/-1/${vgpFile.fileName}`}><span className="glyphicons glyphicons-download-alt"></span><span><T i18nKey="common:download">Download</T></span></a>}
          </div>
        </div>
        { userCanUploadVGPDoc &&
          <div className="vgpFileView-row-indent">
            <div className="vgpFileView-indent"><div className="vgpFileView-indent-lines"></div></div>
            <div className="vgpFileView-row vgpFileView-row-secret">
              <div className="vgpFileView-text">
                <div className="vgpFileView-text-icon"><span className="glyphicons glyphicons-eye-close"></span></div>
                <div>{docFile ? docFile.fileName : 'Geen docx toegevoegd'}</div>
              </div>
              <button className="vgpFileView-button" onClick={() => this.showFileForm('vgp_docx')}><span className="glyphicons glyphicons-cloud-upload"></span><T i18nKey="project:upload_docx">DOCX uploaden</T></button>
              <div className="vgpFileView-link">
                {docFile && <a href={`${location.origin}/v1/file/${docFile._id}/-1/${docFile.fileName}`}><span className="glyphicons glyphicons-download-alt"></span><span><T i18nKey="common:download_docx">DOCX downloaden</T></span></a>}
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
