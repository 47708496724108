import React, { Component } from 'react'
import _ from 'underscore'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class EditUserPictureView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: false,
      loading: false
    }

    this.uploadImage = this.uploadImage.bind(this)
  }

  componentWillMount () {
    document.title = this.props.t('title:edit_image')
  }

  uploadImage (event) {
    this.setState({
      loading: true
    })
    const form = new FormData()
    form.append('image', event.target.files[0])

    const reader = new FileReader()
    reader.onload = function (event) {
      $('#invisimage').attr('src', event.target.result)
    }
    reader.readAsDataURL(event.target.files[0])

    setTimeout(() => {
      const width = $('#invisimage').width()
      const height = $('#invisimage').height()
      let difference
      if (width < height) {
        form.append('cropWidth', width)
        form.append('cropHeight', width)
        form.append('offsetX', 0)
        difference = height - width
        form.append('offsetY', Math.round(difference / 2))
      } else if (height < width) {
        form.append('cropWidth', height)
        form.append('cropHeight', height)
        form.append('offsetY', 0)
        difference = width - height
        form.append('offsetX', Math.round(difference / 2))
      } else {
        form.append('cropWidth', width)
        form.append('cropHeight', height)
        form.append('offsetX', 0)
        form.append('offsetY', 0)
      }

      const settings = {
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/file/image`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${this.props.token.token}`
        },
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',
        data: form
      }

      const promise = $.ajax(settings)
      promise.done((response) => {
        try {
          response = JSON.parse(response)
        } catch (error) {
        }

        const newImageUrls = {
          profile_picture_id: response.id,
          profile_picture_url: `/v1/file/image/${response.id}`,
          profile_picture_icon_url: `/v1/file/image/${response.id}?size=icon`,
          profile_picture_thumbnail_url: `/v1/file/image/${response.id}?size=thumbnail`,
          profile_picture_large_url: `/v1/file/image/${response.id}?size=large`
        }

        const paramId = this.props.match.params.id
        const userId = this.props.user._id
        let url = `${location.origin}/v1/user/`
        if (paramId === userId) {
          url += 'me'
        } else {
          url += paramId
        }

        const settings = {
          async: true,
          crossDomain: true,
          url: url,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${this.props.token.token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify(newImageUrls)
        }

        const promise = $.ajax(settings)
        promise.done((response) => {
          if (window.dataCache.usersState) {
            _.find(window.dataCache.usersState.users, (user, index) => {
              if (user._id === this.props.match.params.id) {
                // let userObject = window.dataCache.usersState.users[index];
                // userObject = _.extend(userObject, newImageUrls);
                _.extend(window.dataCache.usersState.users[index], newImageUrls)
                return true
              }
            })
          }
          $(window).trigger('updateUser')
          this.setState({
            loading: false
          })
          this.props.history.goBack()
        })
        promise.fail(error => {
          console.error(error)
          this.setState({
            error,
            loading: false
          })
        })
      })
      promise.fail((error) => {
        if (error.status === 400 && ~error.responseText.indexOf('Payload content length greater than maximum allowed: ')) {
          return this.setState({
            error: {
              type: 'filesize',
              message: this.props.t('user:error_image_size', { size: '5MB' })
            },
            loading: false
          })
        }
        console.error(error)
        this.setState({
          error,
          loading: false
        })
      })
    }, 100)
  }

  render () {
    // const user = this.state.user;
    const imgUrl = /* user.profile_picture_thumbnail_url || */ '/images/default_user_thumbnail.png'
    const img = <img alt="icon" className="profile_pic" src={`${location.origin}${imgUrl}`} />
    return (
      <section className="container form">
        {this.state.error
          ? <div className="message">
            <div className="message_icon">
              <span className="glyphicons glyphicons-exclamation-sign"></span>
            </div>
            {this.state.error.type === 'filesize'
              ? <span>
                {this.state.error.message}
              </span>
              : <span>
                <T i18nKey="user:error_generic_pt1">Fout bij het uploaden van de afbeelding. Probeer het later opnieuw.</T>
                <br />
                <T i18nKey="user:error_generic_pt2">Als dit probleem zich blijft voordoen meld dit bij een beheerder.</T>
              </span>
            }
          </div>
          : ''}
        <section className="user_block">
          <div className="user_profile_pic">
            <div className="big profile_pic_round">
              {img}
            </div>
          </div>
          <div className="user_info">
            <h3><T i18nKey="user:upload_image">Upload je profiel foto</T></h3>
            <p><T i18nKey="user:image_instructions">Je foto zou minimum 300px x 300px moeten zijn en als het kan vierkant.</T></p>
            <div className="btn-green btn-file">
              <span>
                {!this.state.loading
                  ? <span className="glyphicons glyphicons-upload"></span>
                  : <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                }
                                &nbsp;<T i18nKey="user:upload_image_2">Upload je foto</T></span>
              <input onChange={this.uploadImage} id="imageLoader" type="file" accept="image/*" />
            </div>
          </div>
          <div className="clear"></div>
          <img style={{ position: 'absolute', left: '-100000%' }} id="invisimage" alt="invisible" src="" />
        </section>
      </section>
    )
  }
}

const EditUserPicture = withTranslation()(EditUserPictureView)
export { EditUserPicture }
