import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'underscore'

import { createLabel } from '../../reactUtils/labelFactory.jsx'
import { UserSearchbar } from '../userSearchbar.jsx'
import location from '../../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class ProjectGroupTableBodyRow extends Component {
  constructor (props) {
    super(props)

    this.state = {
      displayBoost: this.props.project.boosted,
      vcUserId: null
    }

    this.toggleQuickactions = this.toggleQuickactions.bind(this)
    this.addToPlanning = this.addToPlanning.bind(this)
    this.updateProject = this.updateProject.bind(this)
    this.toggleBoostProject = this.toggleBoostProject.bind(this)
    this.showAdminProjectPlanner = this.showAdminProjectPlanner.bind(this)
    this.gotoProject = this.gotoProject.bind(this)
    this.planProject = this.planProject.bind(this)
  }

  stopPropagation (event) {
    event.stopPropagation()
  }

  toggleQuickactions (event, keepPrevious) {
    if (!keepPrevious) { this.hideQuickactions() }
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  componentDidMount () {
    this.createDraggables()
  }

  componentDidUpdate (prevProps, prevState) {
    this.createDraggables()
  }

  createDraggables () {
    const project = this.props.project
    const projectName = project.ss_name || project.project_name
    // project draggable
    $('#' + project._id).draggable({
      cursor: '-webkit-grabbing',
      cursorAt: {
        left: 10,
        top: 5
      },
      appendTo: '#app_container',
      distance: 15,
      helper: function () {
        const p = `<p style="background-color: white;border: 2px solid red;border-radius: 6px; padding: 2px 5px;">${projectName}</p>`
        return p
      },
      stop: (event, ui) => {
        if (event.toElement.id === 'planning_dropbox' || $(event.toElement).parent().is('#planning_dropbox')) {
          this.addToPlanning()
        }
      }
    })
  }

  addToPlanning (userId) {
    const project = this.props.project
    const projectId = project._id
    const planningData = {
      user: userId || this.props.user._id,
      project: projectId,
      planned_date: moment().startOf('d').toISOString(),
      visit_by: project.muted || project.next_visit
    }

    const postPlanningSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(planningData)
    }

    $.ajax(postPlanningSettings).done(response => {
      $(window).trigger('updatePlanningSlider')
      this.updateProject({ planning: response._id })
    }).fail(error => {
      console.error(error)
    })
    this.props.setProjectPlanned(projectId)
  }

  expandPicture (event) {
    const $target = $(event.currentTarget)
    $target.addClass('active')
    const alt = $target.find('img')[0].alt
    const newDiv = $('<div>').html(alt).addClass('profile_pic_name')
    $(event.currentTarget).before(newDiv)
  }

  withdrawPicture (event) {
    const $target = $(event.currentTarget)
    $target.removeClass('active')
    $target.prev('.profile_pic_name').remove()
  }

  updateProject (newData, reload) {
    const projectId = this.props.project._id

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${projectId}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(newData)
    }

    return $.ajax(settings).done(response => {
      if (reload) {
        $(window).trigger('reloadProjects')
      }
    }).fail(error => {
      console.error(error)
    })
  }

  toggleBoostProject (event) {
    event.preventDefault()
    let projectBoostStatus = this.state.displayBoost
    if (projectBoostStatus) {
      projectBoostStatus = false
    } else {
      projectBoostStatus = true
    }
    this.setState({
      displayBoost: projectBoostStatus
    })
    this.updateProject({ boosted: projectBoostStatus })
    this.hideQuickactions()
  }

  gotoProject (event) {
    this.props.history.push(`/werf/${event.currentTarget.id}`)
  }

  showAdminProjectPlanner (result) {
    this.setState({
      vcUserId: result
    })
  }

  planProject (e) {
    this.addToPlanning(this.state.vcUserId)
  }

  render () {
    const t = this.props.t
    const project = this.props.project
    const isAdmin = this.props.user.role.match('ADMIN')
    const isAdminPlus = this.props.user.role === 'ADMIN+'
    let vcExecution = _.find(project.users, function (user) {
      return user.type === 'vcExecution'
    })
    vcExecution = vcExecution || { user: {} }
    const imgSrc = vcExecution.user.profile_picture_icon_url || '/images/default_user_icon.png'
    // }
    let projectName = t('project:nameless_project')
    if (project.ss_name || project.project_name) {
      projectName = project.ss_name || project.project_name
      const cleanName = projectName.search('&amp;')
      if (cleanName > 0) {
        projectName = projectName.slice(0, cleanName).trimRight()
      }
    }
    if (projectName.length > 70) {
      projectName = projectName.slice(0, 65) + '...'
    }
    // laatst bezocht
    let lastVisit = project.last_visit
    if (!lastVisit) {
      lastVisit = t('project:not_visited')
    } else {
      lastVisit = moment(lastVisit).startOf('day')
      const today = moment().startOf('day')
      lastVisit = today.diff(lastVisit, 'days')
      if (lastVisit === 0) {
        lastVisit = t('project:today')
      } else if (lastVisit !== 1) {
        lastVisit = t('project:amount_days_ago', { days: lastVisit })
      } else {
        lastVisit = t('project:1_day_ago')
      }
    }

    let projectSpecialStatusIcon = null
    if (this.state.displayBoost) {
      projectSpecialStatusIcon = <span className="glyphicons glyphicons-warning-sign red"></span>
    } else if (project.muted) {
      projectSpecialStatusIcon = <span className="label blue"><T i18nKey="planning:delayed">Uitgesteld</T></span>
    }

    let userIsVC = false
    _.each(project.users, (user) => {
      if ((user.type === 'vcExecution' || user.type === 'vcDesign') && user.user._id === this.props.user._id) {
        userIsVC = true
      }
    })
    // if (userIsVC) {
    //     const showPassButton = (this.state.project_vc_passed_status == 'false' || this.state.project_vc_passed_status === undefined) && vcExecution.user._id === this.props.user._id;
    // }

    let linkToMutePage = null
    let addToPlanningLink = null
    let linkToPassPage = null
    if (!project.planning && userIsVC) {
      if (this.props.user.role !== 'FREELANCER') {
        linkToMutePage = (
          <li>
            <Link to={`/mutewerf/${project._id}`}><T i18nKey="project:mute">Muten</T></Link>
          </li>
        )
      }

      addToPlanningLink = (
        <li>
          <a onClick={e => this.addToPlanning()} href="#"><T i18nKey="project:add_to_planning">Toevoegen aan mijn planning</T></a>
        </li>
      )

      linkToPassPage = (
        <li>
          <Link to={`/werf_doorgeven/${project._id}`}><T i18nKey="planning:pass">Doorgeven</T></Link>
        </li>
      )
    }

    return (
      <tr key={project._id} className={`draggableProject_${this.props.due}`} id={project._id} onClick={this.gotoProject}>
        <td>{projectSpecialStatusIcon} {projectName}
        </td>
        <td style={{ textAlign: 'right', paddingRight: '0' }}>{createLabel(project.type)}</td>
        <td>{vcExecution._id
          ? <div onMouseEnter={this.expandPicture} onMouseLeave={this.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${imgSrc}`} className="profile_pic" alt={vcExecution.user.name || '-'} />
          </div>
          : '-'}
        </td>
        <td>{lastVisit}</td>
        <td onClick={this.stopPropagation} className="quickAction_cel">
          <a id={`quickActionsAnchor${project._id}`} onClick={e => this.toggleQuickactions(e, false)} href="#" className="quickAction">
            <span className="glyphicons glyphicons-more"></span>
          </a>
          <div className="quickAction_tip" style={{ display: 'none' }}>
            <div className="quickAction_tip_arrow"></div>
            <div className="quickAction_tip_inner">
              <ul>
                {addToPlanningLink}
                {isAdmin
                  ? <li>
                    <a onClick={this.toggleBoostProject} href="#">{this.state.displayBoost ? t('project:remove_boost') : t('project:boost')}</a>
                  </li>
                  : null}
                {linkToMutePage}
                {linkToPassPage}
                {isAdminPlus
                  ? <li>
                    <a onClick={(e) => this.toggleQuickactions(e, true)} href="#"><T i18nKey="planning:plan_for_user">Inplannen bij</T> ...</a>
                    <div className="quickAction_tip" style={{ display: 'none' }}>
                      <div className="quickAction_tip_arrow"></div>
                      <div className="quickAction_tip_inner">
                        <UserSearchbar projectId={project._id} onChange={this.showAdminProjectPlanner} />
                        <button
                          disabled={!this.state.vcUserId}
                          className="btn-green"
                          onClick={this.planProject}
                        >
                          <T i18nKey="planning:plan">Inplannen</T>
                        </button>
                      </div>
                    </div>
                  </li>
                  : null
                }
              </ul>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}
