import React, { Component } from 'react'
import { Projects as DefaultProjects } from './default_projects/projects.jsx'
import { Projects as PartnerProjects } from './partner_projects/projects.jsx'
import { Trans as T, withTranslation } from 'react-i18next'

class ProjectsView extends Component {
  componentWillMount () {
    document.title = this.props.t('title:projects')
  }

  render () {
    const user = this.props.user
    if (!user) {
      return <p><T i18nKey="common:loading">laden</T></p>
    }
    if (user.role === 'PARTNER') {
      return <PartnerProjects {...this.props} />
    }
    return <DefaultProjects {...this.props} />
  }
}

const Projects = withTranslation()(ProjectsView)
export { Projects }
