import React, { Component } from 'react'
import moment from 'moment'
import location from '../../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export default class PlanningInfo extends Component {
  constructor (props) {
    super(props)

    this.state = {
      planning: {
        user: {}
      }
    }

    this.fetchPlanning = this.fetchPlanning.bind(this)
  }

  componentWillMount () {
    const that = this
    this.fetchPlanning()
      .done((planning) => {
        that.setState({
          planning: planning || { user: {} }
        })
      })
      .fail(function (error) {
        console.error(error)
      })
  }

  fetchPlanning () {
    return $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning?project=${this.props.project}&select=planned_date,user`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    })
  }

  render () {
    const userName = this.state.planning.user.name
    let formattedDate = this.state.planning.planned_date
    if (formattedDate) {
      formattedDate = moment(formattedDate).format('DD-MM-YYYY')
    }
    return (
      <div className="ProjectPlanning-dates">
        <div className="ProjectPlanning-date">
          <span><T i18nKey="project:planned_by">Ingepland door</T>:</span>
          <span>{userName}</span>
        </div>
        <div className="ProjectPlanning-date">
          <span><T i18nKey="project:on">Op</T>:</span>
          <span>{formattedDate}</span>
        </div>
      </div>
    )
  }
}
