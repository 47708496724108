import React, { Component } from 'react'
import './Language.css'
import i18n from '../../i18n.js'

class Language extends Component {
  constructor (props) {
    super(props)

    let currentLanguage = window.localStorage.getItem('language')
    if (!currentLanguage) {
      const oldLanguage = window.localStorage.getItem('macosafeLanguage') // TODO: remove after 06/2021
      if (oldLanguage) {
        window.localStorage.setItem('language', oldLanguage)
        i18n.changeLanguage(oldLanguage, (error, t) => {
          if (error) console.error(error)
        })
        currentLanguage = oldLanguage
      } else {
        window.localStorage.setItem('language', 'nl-BE')
        i18n.changeLanguage('nl-BE', (error, t) => {
          if (error) console.error(error)
        })
        currentLanguage = 'nl-BE'
      }
    }

    this.state = {
      open: false,
      currentLanguage
    }

    this.handleClick = this.handleClick.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.handleCloseClick = this.handleCloseClick.bind(this)
  }

  componentWillUnmount () {
    this.removeCloseEvents()
  }

  handleClick (e) {
    const newLang = e.target.dataset.lang
    i18n.changeLanguage(newLang, (error, t) => {
      if (error) console.error(error)
    })
    window.localStorage.setItem('language', newLang) // set language for the map
    this.removeCloseEvents()
    this.setState(state => ({ open: false, currentLanguage: newLang }))
  }

  toggleOpen (e) {
    if (e.target.closest('.Language-dropdown')) return

    const newState = !this.state.open

    if (newState) {
      this.createCloseEvents()
    } else {
      this.removeCloseEvents()
    }

    this.setState(state => ({
      open: !state.open
    }))
  }

  createCloseEvents () {
    window.addEventListener('click', this.handleCloseClick, true)
  }

  removeCloseEvents () {
    window.removeEventListener('click', this.handleCloseClick, true)
  }

  handleCloseClick (e) {
    const isMe = e.target.closest('.Language')
    if (isMe) return
    this.setState(state => ({ open: false }))
    this.removeCloseEvents()
  }

  render () {
    const languages = [
      {
        label: i18n.t('header:dutch'),
        value: 'nl-BE'
      },
      {
        label: i18n.t('header:french'),
        value: 'fr-BE'
      }
    ]

    const dropdownClasses = ['Language-dropdown']
    if (this.state.open) {
      dropdownClasses.push('Language-dropdown-open')
    }

    return (
      <div className="Language" onClick={this.toggleOpen}>
        <div className="Language-icon">{getLanguageShort(this.state.currentLanguage)}</div>
        <div className={dropdownClasses.join(' ')}>
          <div className="bordered">
            {
              languages.map(language => {
                return (
                  <div
                    className="Language-dropdown-item"
                    onClick={this.handleClick}
                    data-lang={language.value}
                    key={language.value}
                  >{language.label}</div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

function getLanguageShort (name) {
  switch (name) {
    case 'nl-BE': return 'NL'
    case 'fr-BE': return 'FR'
    default: return name
  }
}

export default Language
