import { ProjectGroupTable } from './projectGroupTable.jsx'
import { PassedProjectGroupTable } from './passedProjectGroupTable.jsx'
import { Dropbox } from './planningDropbox.jsx'

import { getIntervalDays } from '../../../projectTypeFunctions.js'

import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import { Trans as T, withTranslation } from 'react-i18next'

const sortProjects = function (projects, sortedProjects) {
  sortedProjects = sortedProjects || {
    passed: []
  }
  const today = moment().startOf('day')
  const thisYear = parseInt(today.format('YYYY'))
  _.each(projects, project => {
    if (project.project_vc_passed_status === 'pending') {
      return sortedProjects.passed.push(project)
    }

    if (project.boosted) {
      sortedProjects['0'] = sortedProjects['0'] || []
      return sortedProjects['0'].push(project)
    }

    let nextVisit = project.muted || project.next_visit
    if (nextVisit) {
      nextVisit = moment(nextVisit)
    } else {
      const startDate = project.start_date
      nextVisit = moment(moment(startDate))
      nextVisit.add(getIntervalDays(project.type), 'd')
    }

    const toVisitInYear = parseInt(nextVisit.format('YYYY'))
    let week = parseInt(nextVisit.format('W'))
    if (toVisitInYear < thisYear) {
      week = nextVisit.diff(moment(), 'w')
    } else if (toVisitInYear > thisYear) {
      week = week + ((toVisitInYear - thisYear) * 52)
    } else if (week === 1 && nextVisit.format('M') === '12') {
      week = 53
    }

    const deadlineWeek = parseInt(today.format('W'))
    if (week < deadlineWeek) {
      sortedProjects['-1'] = sortedProjects['-1'] || []
      sortedProjects['-1'].push(project)
    } else if (week === deadlineWeek) {
      sortedProjects['0'] = sortedProjects['0'] || []
      sortedProjects['0'].push(project)
    } else {
      // let x = 0;
      // while ( week !== deadlineWeek ) {
      //     deadlineWeek++;
      //     x++; // in case something else goes wrong this'll prevent an infinite loop
      //     if ( x > 50 ) {
      //         deadlineWeek = week;
      //     }
      // }
      const projectWeek = week - moment().format('W')
      sortedProjects[projectWeek] = sortedProjects[projectWeek] || []
      sortedProjects[projectWeek].push(project)
    }
  })
  return sortedProjects
}

class MainPlanningView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      projects: {
        passed: []
      }
    }

    this.setProjectPlanned = this.setProjectPlanned.bind(this)
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.loading !== this.props.loading) {
      const projects = nextProps.projects
      const sortedProjects = sortProjects(projects)
      if (!this.isUnmounting) {
        this.setState({
          projects: sortedProjects
        })
      }
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextProps.loading !== this.props.loading
  }

  setProjectPlanned (projectId) {
    const projects = _.flatten(_.values(this.state.projects))
    const filteredProjects = _.filter(projects, function (project) {
      return project._id !== projectId
    })
    const sortedProjects = sortProjects(filteredProjects)
    if (!this.isUnmounting) {
      this.setState({
        projects: sortedProjects
      })
      this.forceUpdate()
    }
  }

  render () {
    const t = this.props.t
    const planningTables = []
    const projects = this.state.projects

    if (this.props.loading) {
      return (
        <section className="planning-table">
          <section className="list_block">
            <table key="noProjects" className="list_table">
              <tbody>
                <tr>
                  <td className="table-loader"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      )
    }

    if (projects.passed.length) {
      planningTables.push(<PassedProjectGroupTable key='passedProjects' name={t('planning:passed')} due="passed" setProjectPlanned={this.setProjectPlanned} {...this.props} projects={projects.passed} />)
    }
    if (projects['-1']) {
      planningTables.push(<ProjectGroupTable key='-1' name={t('planning:overdue')} due="-1" setProjectPlanned={this.setProjectPlanned} {...this.props} projects={projects['-1']} addClass='warning' />)
    }
    if (projects['0']) {
      planningTables.push(<ProjectGroupTable key='0' name={t('planning:plan_within_the_week')} due="0" setProjectPlanned={this.setProjectPlanned} {...this.props} projects={this.state.projects['0']} />)
    }
    _.each(this.state.projects, (projects, key) => {
      if (key !== '-1' && key !== '0' && key !== 'passed') {
        if (key === '1') {
          planningTables.push(<ProjectGroupTable key={`${key}`} name={t('planning:plan_next_week')} due={key} setProjectPlanned={this.setProjectPlanned} {...this.props} projects={projects} />)
        } else {
          planningTables.push(<ProjectGroupTable key={`${key}`} name={t('planning:plan_in_x_weeks', { number: key })} due={key} setProjectPlanned={this.setProjectPlanned} {...this.props} projects={projects} />)
        }
      }
    })
    if (planningTables.length === 0) {
      planningTables.push(
        <table key="noProjects" className="list_table">
          <tbody>
            <tr>
              <td><T i18nKey="planning:no_projects_message">Er werden nog geen werven aan je toegewezen of je hebt al je werven ingepland.</T></td>
            </tr>
          </tbody>
        </table>
      )
    }

    return (
      <section className="planning-table">
        <section className="list_block">
          <Dropbox />
          {planningTables}
        </section>
      </section>
    )
  }
}

const MainPlanning = withTranslation()(MainPlanningView)
export { MainPlanning }
