import React, { Component } from 'react'
import { Trans as T } from 'react-i18next'

export class TemplatesTableView extends Component {
  constructor (props) {
    super(props)

    this.gotoEdit = this.gotoEdit.bind(this)
    this.gotoPreview = this.gotoPreview.bind(this)
  }

  gotoEdit (index) {
    this.props.editTemplate(index)
  }

  gotoPreview (index) {
    this.props.editTemplate(index, true)
  }

  render () {
    const templateRows = this.props.templates.map((template, index) => {
      return (
        <tr key={template._id}>
          <td>{template.name}</td>
          <td>{template.templateType}</td>
          <td><button onClick={this.gotoPreview.bind(this, index)}><T i18nKey="settings:preview">Preview</T></button></td>
          <td><button onClick={this.gotoEdit.bind(this, index)}><T i18nKey="settings:edit">Aanpassen</T></button></td>
        </tr>
      )
    })
    return (
      <table className="list_table">
        <thead>
          <tr>
            <th><T i18nKey="settings:template_name">Template naam</T></th>
            <th><T i18nKey="settings:type">Type</T></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {templateRows}
        </tbody>
      </table>
    )
  }
}
