/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import moment from 'moment'
import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class ProjectRowView extends Component {
  constructor (props) {
    super(props)

    this.gotoProject = this.gotoProject.bind(this)
  }

  componentDidMount () {
    if (this.props.userIsAdminPlus) {
      const t = this.props.t
      const planning = this.props.planning
      const project = planning.project
      const projectName = project.ss_name || project.project_name
      // planning draggable
      $(`#draggablePlanning_${planning._id}`).draggable({
        containment: '.list_table',
        cursor: '-webkit-grabbing',
        cursorAt: {
          left: 10,
          top: 5
        },
        distance: 25,
        appendTo: '#app_container',
        helper: function () {
          const p = `<p class="project_dragger">${projectName} <span class="label red">${t('planning:overdue')}</span><span class="label green">${t('planning:ok')}</span></p>`
          return p
        }
      })
    }
  }

  gotoProject (event) {
    this.props.history.push(`/werf/${event.currentTarget.dataset.id}`)
  }

  expandPicture (event) {
    const $target = $(event.currentTarget)
    $target.addClass('active')
    const alt = $target.find('img')[0].alt
    const newDiv = $('<div>').html(alt).addClass('profile_pic_name')
    $(event.currentTarget).before(newDiv)
  }

  withdrawPicture (event) {
    const $target = $(event.currentTarget)
    $target.removeClass('active')
    $target.prev('.profile_pic_name').remove()
  }

  render () {
    const planning = this.props.planning
    const project = planning.project

    const nextVDate = project.muted || project.next_visit
    const formattedNextVisit = moment(nextVDate).format('YYYY-MM-DD')

    const plannedDate = this.props.date
    const formattedNextVisitLabelColor = moment(plannedDate).startOf('d').isAfter(moment(nextVDate).startOf('d')) ? 'red' : 'green'

    let planningUser = planning.user
    planningUser = planningUser || { user: {} }
    const imgSrc = planningUser.profile_picture_icon_url || '/images/default_user_icon.png'

    return (
      <tr onClick={this.gotoProject} id={`draggablePlanning_${planning._id}`} data-date={formattedNextVisit} data-planning-id={planning._id} data-id={project._id}>
        <td>{project.ss_name || project.project_name}</td>
        <td style={{ width: 71 }}>
          <span className={`label ${formattedNextVisitLabelColor}`}>
            {formattedNextVisit}
          </span>
        </td>
        <td style={{ width: 35 }}>{planningUser._id
          ? <div onMouseEnter={this.expandPicture} onMouseLeave={this.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${imgSrc}`} className="profile_pic" alt={planningUser.name || '-'} />
          </div>
          : '-'}
        </td>
        {
          this.props.userIsAdminPlus
            ? <Dropdown
              planning={planning}
              token={this.props.token}
            />
            : null
        }
      </tr>
    )
  }
}

const ProjectRow = withTranslation()(ProjectRowView)
export { ProjectRow }

class DropdownView extends Component {
  constructor (props) {
    super(props)

    this.toggleQuickactions = this.toggleQuickactions.bind(this)
    this.hideQuickactions = this.hideQuickactions.bind(this)
    this.throwBackInPool = this.throwBackInPool.bind(this)
  }

  throwBackInPool (e) {
    e.stopPropagation()
    e.preventDefault()
    const t = this.props.t

    const confirmChange = window.confirm(t('planning:remove_from_planning_confirm'))
    if (!confirmChange) { return }

    const planning = this.props.planning
    const project = planning.project
    const token = this.props.token.token

    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning?id=${planning._id}`,
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`
      }
    }).done(response => {
      $(window).trigger('updatePlanningSlider')
      const refreshProjectsEvent = new CustomEvent('filterReloadProjects')
      window.dispatchEvent(refreshProjectsEvent)
    }).fail(error => {
      console.error(error)
    })

    const projectBody = {
      $set: {
        planning: null
      }
    }

    const users = project.users
    let type
    const newUsers = []
    users.forEach(u => {
      type = u.type
      if (type !== 'vc3' && type !== 'passingUser') { newUsers.push(u) }
    })
    if (newUsers.length !== users.length) { projectBody.$set.users = newUsers }

    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${project._id}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(projectBody)
    }).done(response => {
      // $(window).trigger('reloadProjects');
    }).fail(error => {
      console.error(error)
    })
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  render () {
    const planning = this.props.planning
    const project = planning
    return (
      <td className="quickAction_cel" style={{ width: 16 }}>
        <a id={`quickActionsAnchor${project._id}`} onClick={this.toggleQuickactions} href="#" className="quickAction">
          <span className="glyphicons glyphicons-more"></span>
        </a>
        <div className="quickAction_tip" style={{ display: 'none' }}>
          <div className="quickAction_tip_arrow"></div>
          <div className="quickAction_tip_inner">
            <ul>
              <li>
                <a onClick={this.throwBackInPool} href="#"><T i18nKey="planning:remove_from_planning">Weghalen uit planning</T></a>
              </li>
            </ul>
          </div>
        </div>
      </td>
    )
  }
}
const Dropdown = withTranslation()(DropdownView)
