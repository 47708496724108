import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { leftOrRight, setPosition, toggle, resetDropdowns } from '../../tools.jsx'
import _ from 'underscore'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class ToolbarView extends Component {
  componentWillUpdate (nextProps, nextState) {
    resetDropdowns()
  }

  openDropdown (event) {
    event.preventDefault()
    event.stopPropagation()
    const dropDownMenu = $(event.currentTarget).next()
    const menuPosition = leftOrRight(event.currentTarget)
    setPosition(dropDownMenu, menuPosition)
    toggle(dropDownMenu, $(event.currentTarget).data('toolbar'))
  }

  closeDropdown (event) {
    event.preventDefault()
    event.stopPropagation()
    if (!$(event.currentTarget).prev().hasClass('dd_menuList_onAction')) {
      toggle($(event.currentTarget), $(event.currentTarget).data('toolbar'))
    }
  }

  render () {
    const userRole = this.props.token.decodedToken.content.scope

    const path = this.props.location.pathname.split('/')[1]
    const leftContent = <ToolbarLeftContent history={this.props.history} location={this.props.location} userRole={userRole} openDropdown={this.openDropdown} closeDropdown={this.closeDropdown} currentPage={path} t={this.props.t} />
    return (
      <div className="main_bar dd_bar">
        {leftContent}
      </div>
    )
  }
}

class ToolbarLeftContent extends Component {
  constructor (props) {
    super(props)

    this.goToPreviousPage = this.goToPreviousPage.bind(this)
    this.gotoPage = this.gotoPage.bind(this)
  }

  goToPreviousPage (event) {
    console.log('goToPreviousPage')
    event.preventDefault()

    const params = new URLSearchParams(this.props.location.search)
    if (params.get('new') === 'true') {
      return this.props.history.push('/')
    }

    this.props.history.goBack()
  }

  gotoPage (route) {
    if (route === '/gotokaart') {
      window.open('https://coördinatiedagboek.be/kaart')
    } else { this.props.history.push(route) }
  }

  preventDefault (event) {
    event.preventDefault()
  }

  render () {
    const userRole = this.props.userRole
    const t = this.props.t
    const routeNames = {
      gebruikers: t('toolbar:users'),
      gebruiker: t('toolbar:user'),
      gebruiker_aanpassen: t('toolbar:user'),
      gebruiker_afbeelding: t('toolbar:user'),
      gebruiker_instellingen: t('toolbar:user'),
      gotokaart: t('toolbar:map'),
      nieuwe_gebruiker: t('toolbar:new_user'),
      organisaties: t('toolbar:organisations'),
      organisatie: t('toolbar:organisation'),
      organisatie_aanpassen: t('toolbar:organisation'),
      organisatie_afbeelding: t('toolbar:organisation'),
      // projectWizard: t('toolbar:project_wizard'),
      werven: t('toolbar:projects'),
      werf: t('toolbar:project'),
      werf_wizard: t('toolbar:project'),
      planning: t('toolbar:planning'),
      logging: t('toolbar:logging'),
      instellingen: t('toolbar:settings'),
      adminTools: t('toolbar:admin_tools')
    }

    const currentPage = this.props.currentPage

    if (currentPage === 'login') {
      return (
        <div className="left">
          <h2>
            <span className="glyphicons glyphicons-user-key"></span> {t('login:login')}
          </h2>
        </div>
      )
    } else if (currentPage === 'nieuw_wachtwoord' || currentPage === 'wachtwoord_herstellen') {
      return (
        <div className="left">
          <h2>
          </h2>
        </div>
      )
    } else {
      const dropdownIconSpan = {
        werven: <span className="glyphicons glyphicons-classic-hammer"></span>,
        planning: <span className="glyphicons glyphicons-calendar"></span>,
        organisaties: <span className="glyphicons glyphicons-building"></span>,
        gebruikers: <span className="glyphicons glyphicons-group"></span>,
        gotokaart: <span className="glyphicons glyphicons-map"></span>,
        instellingen: <span className="glyphicons glyphicons-cogwheels"></span>,
        adminTools: <span className="glyphicons glyphicons-settings"></span>
      }

      switch (userRole) {
        case 'USER':
        case 'ONEPROJECTER':
        case 'PARTNER': delete dropdownIconSpan.planning // falls through
        case 'FREELANCER': delete dropdownIconSpan.gebruikers; delete dropdownIconSpan.organisaties; delete dropdownIconSpan.gotokaart // falls through
        case 'EMPLOYEE': delete dropdownIconSpan.logging; delete dropdownIconSpan.instellingen; delete dropdownIconSpan.adminTools
          break
        default:
      }

      const currentPageName = this.props.currentPage || 'werven'
      const menuButton = (
        <a onClick={this.props.openDropdown} href="#" data-toolbar="leftMenu" className="leftMenu dd_menuList">
          {dropdownIconSpan[currentPageName]} {routeNames[currentPageName]}
        </a>
      )
      delete dropdownIconSpan[currentPageName]
      const dropdownListElements = []
      let dropdownList = null
      _.each(dropdownIconSpan, (glyphicon, key) => {
        if (key === 'werven') {
          dropdownListElements.push(
            <li key={`navDropdown${key}`} onClick={this.gotoPage.bind(this, '/')}>
              <a href="/" onClick={this.preventDefault}>{glyphicon} {t('toolbar:projects')}</a>
            </li>
          )
        } else {
          dropdownListElements.push(
            <li key={`navDropdown${key}`} onClick={this.gotoPage.bind(this, `/${key}`)}>
              <a href="#" onClick={this.preventDefault}>{glyphicon} {routeNames[key]}</a>
            </li>
          )
        }
      })

      if (dropdownListElements.length) {
        dropdownList = (
          <ul className="leftMenu dd_list" data-toolbar="leftMenu" id="leftMenu" style={{ display: 'none' }}>
            {dropdownListElements}
          </ul>
        )
      }

      let newUserButton
      if (currentPage === 'gebruikers' && userRole.match('ADMIN')) {
        newUserButton = <Link to={'nieuwe_gebruiker'} className="btn-normal"><T i18nKey="toolbar:add_user">Gebruiker toevoegen</T></Link>
      }

      const showBackButton = currentPage !== '' && currentPage !== 'planning' && currentPage !== 'organisaties' && currentPage !== 'adminTools' && currentPage !== 'gebruikers' && currentPage !== 'instellingen' && currentPage !== 'logging'
      const backButton = <a href="#" onClick={this.goToPreviousPage} className="btn-back"><T i18nKey="toolbar:back">Terug</T></a>

      return (
        <div className="left">
          <h2>
            {menuButton}
            {dropdownList}
            <span> </span>
          </h2>
          {newUserButton || null}
          {showBackButton ? backButton : null}
        </div>
      )
    }
  }
}

const Toolbar = withTranslation()(ToolbarView)
export { Toolbar }
