import React, { Component } from 'react'
import _ from 'underscore'

import { VgpoView } from './vgpo.jsx'
import { VgpuView } from './vgpu.jsx'
import RAView from '../../RAView.js'
import location from '../../../../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class VgpView extends Component {
  sendMail () {
    console.log('sendmail')
    const payload = {
      template: 'nieuwvpg',
      to: [
      ]
    }

    const users = this.props.project.users
    let user
    users.forEach(function (contact) {
      if (Object.prototype.toString.call(contact).slice(8, -1) === 'Object' && Object.prototype.toString.call(contact.user).slice(8, -1) === 'Object') {
        user = contact.user
        payload.to.push({
          email: user.email,
          name: user.name,
          type: 'bcc'
        })
      }
    })

    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/message/send_mail`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(payload)
    }).done(function (response) {
      console.log(response)
    })
  }

  render () {
    return (
            <section className="right_content">
                <h3><T i18nKey="project:safety_and_health_plan">Veiligheids- en Gezondheidsplan</T></h3>
                <GlobalVgpFilesView token={this.props.token}/>
                <VgpoView {...this.props} sendMail={this.sendMail} deleteFile={this.deleteFile}/>
                <VgpuView {...this.props} sendMail={this.sendMail} deleteFile={this.deleteFile}/>
                <div><RAView {...this.props}/></div>
            </section>
    )
  }
}

class GlobalVgpFilesView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      files: [],
      loading: true
    }
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    const that = this
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/file?tags=global,vgp`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    const filesGetPromise = $.ajax(settings)
    filesGetPromise.done(files => {
      if (!that.isUnmounting) {
        files = _.filter(files, function (file) {
          return !file.isDeleted
        })
        that.setState({
          files,
          loading: false
        })
      }
    })
    filesGetPromise.fail(error => {
      console.error(error)
      that.setState({
        error,
        loading: false
      })
    })
  }

  render () {
    let fileTable = (
            <table className="list_table">
                <thead>
                    <tr>
                        <th><T i18nKey="project:comment">Opmerking</T></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="no-hover">
                        <td>
                        {this.state.loading
                          ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                          : <span><T i18nKey="project:no_general_vgp_set">Geen algemeen veiligheids- en gezondheidsplan ingesteld.</T></span>
                        }
                        </td>
                    </tr>
                </tbody>
            </table>
    )
    if (this.state.files.length) {
      const file = this.state.files[0] || {}
      fileTable = (
                <table className="list_table">
                    <thead>
                        <tr>
                            <th><T i18nKey="project:name">Naam</T></th>
                            <th style={{ width: 110 }}><T i18nKey="common:download">Download</T></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="no-pointer">
                            <td>{file.fileName}</td>
                            <td><a href={`${location.origin}/v1/file/${file._id}/-1/${file.fileName}`}><span className="glyphicons glyphicons-download-alt"></span> <T i18nKey="common:download">Download</T></a></td>
                        </tr>
                    </tbody>
                </table>
      )
    }

    return (
            <div>
                {this.state.error ? <h1>{this.state.error}</h1> : null}
                <h4><T i18nKey="project:general">Algemeen</T></h4>
                {fileTable}
            </div>
    )
  }
}
