import React, { Component } from 'react'
import './ProjectDesign.css'
import FileRow from './FileRow.js'
import DisablePlanningButton from './DisablePlanningButton.js'
import moment from 'moment'
import { Trans as T, withTranslation } from 'react-i18next'

class ProjectDesignView extends Component {
  constructor (props) {
    super(props)

    const matchedFiles = this.findFiles(props)
    this.state = matchedFiles

    this.handleSubmitClick = this.handleSubmitClick.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    const files = this.findFiles(nextProps)
    this.setState(state => files)
  }

  findFiles (props) {
    const project = props.project || {}
    const files = project.files || []

    const results = {
      vgp: this.findFile(files, 'vgp'),
      beforedesign: this.findFile(files, 'beforedesign'),
      analyses: this.findFile(files, 'analysis')
    }

    return results
  }

  findFile (files, type) {
    return files.find(file => {
      return file.deleted !== true && file.file_type === type
    }) || null
  }

  handleSubmitClick () {
    let readyToAdvance
    if (this.state.vgp && this.state.beforedesign && this.state.analyses) { readyToAdvance = true }

    readyToAdvance = readyToAdvance || window.confirm(this.props.t('project:not_all_files_confirm'))

    if (!readyToAdvance) { return }

    let newStatus = 'execution'
    if (moment(this.props.project.start_date, 'YYYY-MM-DD').isAfter(moment().endOf('day'))) { newStatus = 'waiting' }

    this.props.updateProject({ status: newStatus })
      .done((response) => {
        this.props.reloadProject()
      })
      .fail(function (error) {
        console.error(error)
      })
  }

  render () {
    const t = this.props.t
    const submitButtonClasses = ['ProjectDesign-submit']
    if (this.state.vgp && this.state.beforedesign && this.state.analyses) { submitButtonClasses.push('active') }
    return (
      <div className="ProjectDesign-info-block">
        <h3>
          <span className="glyphicons glyphicons-eye-close"></span>
          <span><T i18nKey="project:vgp_design">VGP Ontwerp</T></span>
        </h3>
        <div className="ProjectDesign-content">
          <FileRow user={this.props.user} reloadProject={this.props.reloadProject} name={t('project:advice_on_design')} type="beforedesign" file={this.state.beforedesign} token={this.props.token} project={this.props.project} />
          <FileRow user={this.props.user} reloadProject={this.props.reloadProject} name={t('project:vgp')} type="vgp" file={this.state.vgp} token={this.props.token} project={this.props.project} />
          <FileRow user={this.props.user} reloadProject={this.props.reloadProject} name={t('project:analysis_quotes')} type="analysis" file={this.state.analyses} token={this.props.token} project={this.props.project} applicable={true} updateProject={this.props.updateProject} />
          <div className="ProjectDesign-submit-container">
            <button className={submitButtonClasses.join(' ')} onClick={this.handleSubmitClick} ><T i18nKey="project:execute">Uitvoeren</T></button>
          </div>
          <hr />
          <DisablePlanningButton
            token={this.props.token}
            reloadProject={this.props.reloadProject}
            updateProject={this.props.updateProject}
            userRole={this.props.user.role}
            project={this.props.project}
          />
        </div>
      </div>
    )
  }
}

const ProjectDesign = withTranslation()(ProjectDesignView)
export default ProjectDesign
