import React, { Component } from 'react'
import _ from 'underscore'
import moment from 'moment'
import { hasPermissionFor, xmlRequest } from './../../tools.jsx'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

const formatResult = function (client) {
  if (client.loading) return client.text
  const markup = client.name
  return markup
}
const formatSelection = function (client) {
  return client.text
}

const initiateOrganisationDropdown = function () {
  const token = localStorage.getItem('authToken')
  $('.js-remote-organisation-data').select2({
    ajax: {
      async: true,
      crossDomain: true,
      url: function (params) {
        return `${location.origin}/v1/organisation?search=${encodeURIComponent(params.term)}`
      },
      headers: {
        authorization: `Bearer ${token}`
      },
      delay: 250,
      processResults: function (data) {
        data = $.map(data, function (object) {
          object.id = object._id
          object.text = object.name
          return object
        })
        return {
          results: data
        }
      },
      cache: true
    },
    escapeMarkup: function (markup) {
      return markup
    },
    minimumInputLength: 3,
    templateResult: formatResult,
    templateSelection: formatSelection
  })
}

const getClient = function (id, token, callback) {
  const settings = {
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/organisation/${id}`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  $.ajax(settings).done((clientResponse) => {
    callback(clientResponse)
  })
}

class EditUserView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        afas_id: null,
        afas_updated: null,
        customer: null,
        name: null,
        initials: null,
        gender: null,
        email: null,
        firstname: null,
        lastname: null,
        date_of_birth: null,
        address: {},
        phone: null,
        phone_work: null,
        website: null,
        organisation: {
          organisation_id: null,
          name: null
        },
        ss_role: null,
        role: null,
        projects: null,
        created_at: null,
        updated_at: null,
        ss_updated_at: null,
        ss_id: null,
        profile_picture_id: null,
        profile_picture_icon_url: null,
        fax_number: '',
        ss_role_secondary: '',
        additional_info: '',
        ss_address: ''
      },
      location: null,
      passwordComplexity: {
        valid: null,
        complexity: null
      },
      // displayPhone: null,
      loading: true,
      loadingPass: false,
      phoneCountry: 'BE',
      deleteFromProjectsError: null
    }

    // this.updateLocation = this.updateLocation.bind(this)
    this.changePhoneValue = this.changePhoneValue.bind(this)
    this.changeValue = this.changeValue.bind(this)
    this.changeBirthdate = this.changeBirthdate.bind(this)
    this.submitChanges = this.submitChanges.bind(this)
    this.submitPassword = this.submitPassword.bind(this)
    this.changeValueBetter = this.changeValueBetter.bind(this)
    this.deleteFromProjects = this.deleteFromProjects.bind(this)

    // this.locationRef = React.createRef()
    this.oldPasswordRef = React.createRef()
    this.newPasswordRef = React.createRef()
    this.newPassword2Ref = React.createRef()
    this.delButtonRef = React.createRef()
  }

  componentWillMount () {
    document.title = this.props.t('title:edit_user')
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    const paramId = this.props.match.params.id
    const userId = this.props.user._id
    let url = `${location.origin}/v1/user/`
    if (paramId === userId) {
      url += 'me'
    } else {
      url += paramId
    }

    const settings = {
      async: true,
      crossDomain: true,
      url: url,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    $.ajax(settings).done((response) => {
      document.title = this.props.t('title:edit_user') + ` - ${response.name}`
      if (!this.isUnmounting) {
        // const address = response.address;
        // let location = null;
        // if (address) {
        //     location = `${address.street?address.street+' ':''}${address.housenumber?address.housenumber+', ':''}${address.city?address.city+', ':''}${address.country||''}`;
        //     if ( location.length < 10 ) {
        //         location = null;
        //     }
        // }
        // const locationField = this.locationRef.current;
        // if ( locationField ) {
        //     locationField.value = location;
        // }
        // const organisationSelect = $('#select2-organisation-container');
        // if ( organisationSelect.length && response.organisation ) {
        //     organisationSelect.append(response.organisation.name);
        // }
        this.setState({
          user: response,
          // location,
          // displayPhone: response.phone,
          loading: false
        })
      }
    })

    $('#organisation').on('change', (event) => {
      setTimeout(() => {
        const user = this.state.user
        const organisation = user.organisation || {}
        const newClientId = event.target.value
        organisation.organisation_id = newClientId
        getClient(newClientId, this.props.token.token, (response) => {
          organisation.name = response.name
          user.organisation = organisation
          this.setState({
            user
          })
        })
      }, 500)
    })
    // autocomplete = new window.google.maps.places.Autocomplete(
    //     this.locationRef.current,
    //     {types: ['geocode']}
    // );
    // autocomplete.addListener('place_changed', this.updateLocation);

    initiateOrganisationDropdown()

    $('#newpassword').complexify({}, (valid, complexity) => {
      this.setState({
        passwordComplexity: {
          valid,
          complexity
        }
      })
    })
  }

  // updateLocation(event) {
  //     const place = autocomplete.getPlace();
  //     const user = this.state.user;
  //     const address = {};
  //     place.address_components.forEach(component => {
  //         component.types.forEach(type => {
  //             switch (type) {
  //                 case 'route': address.street = component.long_name; break;
  //                 case 'postal_code': address.zipcode = component.long_name; break;
  //                 case 'country': address.country = component.long_name;
  //                                 address.country_short = component.short_name; break;
  //                 case 'locality': address.city = component.long_name; break;
  //                 case 'street_number': address.housenumber = component.long_name; break;
  //                 default:
  //             };
  //         });
  //     });
  //     user.address = address;
  //     this.setState({
  //         location: place.formatted_address,
  //         user
  //     });
  // }

  changePhoneValue (event) {
    const newValue = event.target.value
    this.setState(state => ({
      user: Object.assign({}, state.user, { phone: newValue })
    }))
  }

  changeValue (event) {
    const user = this.state.user
    const inputName = event.currentTarget.id
    user[inputName] = event.currentTarget.value

    if (inputName === 'lastname' || inputName === 'firstname') {
      user.name = `${user.firstname || ''} ${user.lastname || ''}`
    }

    this.setState({
      user
    })
  }

  changeBirthdate (event) {
    const user = this.state.user
    const newDate = event.currentTarget.value
    if (newDate) {
      user.date_of_birth = moment(event.currentTarget.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
    } else {
      user.date_of_birth = ''
    }
    this.setState({ user })
  }

  submitChanges (event) {
    event.preventDefault()

    if (!Object.prototype.toString.apply(this.state.user.email).slice(8, -1) === 'String' || this.state.user.email.length < 4) {
      this.setState({
        error: this.props.t('user:invalid_email')
      })
      return
    }

    const editUser = this.state.user
    const currentUser = this.props.user
    let url = `${location.origin}/v1/user/`
    if (editUser._id === currentUser._id) {
      url += 'me'
    } else {
      url += this.props.match.params.id
    }

    const settings = {
      async: true,
      crossDomain: true,
      url: url,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      },
      data: editUser
    }
    const promise = $.ajax(settings)
    promise.done((response) => {
      if (window.dataCache.usersState) {
        _.find(window.dataCache.usersState.users, (user, index) => {
          if (user._id === editUser._id) {
            window.dataCache.usersState.users[index] = editUser
            return true
          }
        })
      }
      $(window).trigger('updateUser')
      this.props.history.goBack()
    })
    promise.fail((error) => {
      console.error(error)
      this.setState({
        error: error
      })
    })
  }

  submitPassword (event) {
    event.preventDefault()
    this.setState({
      passChanged: false,
      loadingPass: true,
      errorPass: false
    })

    const oldPassword = this.oldPasswordRef.current.value
    const newPassword = this.newPasswordRef.current.value
    const newPassword2 = this.newPassword2Ref.current.value

    const role = this.props.user.role
    let errorMessage = role === 'PARTNER' ? 'Wachtwoorden moeten identiek zijn, en kijk na of het oude wachtwoord juist ingegeven is.' : 'Wachtwoorden moeten identiek zijn'

    if (newPassword && newPassword === newPassword2) {
      if (hasPermissionFor(role, 'FREELANCER') || (role === 'PARTNER' && oldPassword)) {
        const form = new FormData()
        form.append('email', this.state.user.email)
        form.append('password', oldPassword)
        form.append('newPassword', newPassword)

        const settings = {
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/user/updatePassword/${this.props.match.params.id}`,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${this.props.token.token}`
          },
          processData: false,
          contentType: false,
          mimeType: 'multipart/form-data',
          data: form
        }
        const promise = $.ajax(settings)
        promise.done((response) => {
          this.setState({
            passChanged: true,
            loadingPass: false,
            errorPass: false
          })
        }).fail(error => {
          if (error.status === 0) {
            errorMessage = this.props.t('user:save_error')
          } else if (error.status === 403) {
            errorMessage = this.props.t('user:unauthorized')
          }
          this.setState({
            loadingPass: false,
            errorPass: errorMessage
          })
        })
      } else {
        const payload = {
          id: this.state.user._id,
          password: newPassword
        }

        const settings = {
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/user/create_new_password`,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${this.props.token.token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify(payload)
        }

        $.ajax(settings).done(response => {
          this.setState({
            loadingPass: false,
            errorPass: false
          })
        }).fail(() => {
          this.setState({
            loadingPass: false,
            errorPass: errorMessage
          })
        })
      }
    } else {
      this.setState({
        errorPass: errorMessage,
        loadingPass: false
      })
    }
  }

  changeValueBetter (name, value) {
    this.setState(state => ({
      user: Object.assign({}, state.user, { [name]: value })
    }))
  }

  async deleteFromProjects () {
    const confirm = window.confirm(this.props.t('user:delete_from_projects_confirm'))
    if (!confirm) return

    const url = `${location.origin}/v1/user/removeContactFromAllProjects?id=${this.state.user._id}`
    try {
      await xmlRequest(url, this.props.token.token, null, 'DELETE')
      window.history.back()
    } catch (error) {
      this.setState({ deleteFromProjectsError: JSON.parse(error.responseText).message })
    }
  }

  render () {
    const user = this.state.user
    const t = this.props.t

    return (
      <section className="container form">
        <form onSubmit={this.submitChanges}>
          <h3>
            <T i18nKey="user:personal_information">Persoonlijke gegevens</T>
            {this.state.loading
              ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
              : null}
          </h3>
          {this.state.error
            ? <div className="message warning">
              {this.state.error}
            </div>
            : null}
          <fieldset className="form-group">
            <label htmlFor="lastname"><T i18nKey="user:surname">Naam</T></label>
            <input type="text" className="form-control" id="lastname" onChange={this.changeValue} value={user.lastname || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="firstname"><T i18nKey="user:first_name">Voornaam</T></label>
            <input type="text" className="form-control" id="firstname" onChange={this.changeValue} value={user.firstname || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="gender"><T i18nKey="user:gender">Geslacht</T></label>
            <select value={user.gender || ''} onChange={this.changeValue} className="form-control" id="gender">
              <option>--</option>
              <option value="M">{t('user:male')}</option>
              <option value="V">{t('user:female')}</option>
            </select>
          </fieldset>
          <h3><T i18nKey="user:company">Bedrijf</T></h3>
          <fieldset className="form-group">
            <select className="form-control js-remote-organisation-data" id="organisation">
            </select>
            <div className="form-info">
              <h4><T i18nKey="user:select_company">Select je bedrijf</T></h4>
            </div>
          </fieldset>
          <h3><T i18nKey="user:contact_information">Contact gegevens</T></h3>
          {/* <fieldset className="form-group">
                        <label htmlFor="location">Adres</label>
                        <input ref={this.locationRef} type="text" className="form-control" id="locatie"/>
                        <div className="form-info">
                            <h4>Adres</h4>
                            {this.state.location}<br/><br/>
                        </div>
                    </fieldset> */}
          <fieldset className="form-group">
            <label htmlFor="addressInput"><T i18nKey="user:address">Adres</T></label>
            <input type="string" className="form-control" id="addressInput" onChange={e => this.changeValueBetter('ss_address', e.target.value)} value={user.ss_address || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="email"><T i18nKey="common:email">Email</T> <span className="required"></span></label>
            <input type="email" className="form-control" id="email" onChange={this.changeValue} value={user.email || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="phone"><T i18nKey="user:mobile">GSM nr</T></label><br />
            <input type="test" className="form-control" id="phone" onChange={this.changePhoneValue} value={user.phone || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="ssRoleInput"><T i18nKey="user:safetysnapper_role">Safetysnapper rol</T></label>
            <input type="text" className="form-control" id="ssRoleInput" onChange={e => this.changeValueBetter('ss_role', e.target.value)} value={user.ss_role || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="ssSubroleInput"><T i18nKey="user:safetysnapper_subrole">Safetysnapper subrol</T></label>
            <input type="text" className="form-control" id="ssSubroleInput" onChange={e => this.changeValueBetter('ss_role_secondary', e.target.value)} value={user.ss_role_secondary || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="faxInput"><T i18nKey="user:fax">Faxnummer</T></label>
            <input type="text" className="form-control" id="faxInput" onChange={e => this.changeValueBetter('fax_number', e.target.value)} value={user.fax_number || ''} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="extraInput"><T i18nKey="user:extra_information">Extra informatie</T></label>
            <textarea className="form-control" id="extraInput" onChange={e => this.changeValueBetter('additional_info', e.target.value)} value={user.additional_info || ''}></textarea>
          </fieldset>
          <div className="clear"></div>
          <fieldset className="form-group">
            {this.state.loading
              ? <button className="form-submit"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></button>
              : <input type="submit" className="form-submit" value={t('common:save')} />
            }
          </fieldset>
        </form>
        <form onSubmit={this.submitPassword}>
          <h3><T i18nKey="common:password">Wachtwoord</T></h3>
          {this.state.passChanged
            ? <div className="message success"><T i18nKey="user:password_saved">Wachtwoord veranderd</T></div>
            : null}
          <fieldset className="form-group" style={{ display: hasPermissionFor(this.props.user.role, 'FREELANCER') ? 'none' : 'block' }}>
            <label htmlFor="oldpassword"><T i18nKey="user:old_password">Oud wachtwoord</T></label>
            <input ref={this.oldPasswordRef} type="password" className="form-control" id="oldpassword" placeholder={t('user:old_password')} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="newpassword"><T i18nKey="user:new_password">Nieuw wachtwoord</T></label>
            <input ref={this.newPasswordRef} type="password" className="form-control" id="newpassword" placeholder={t('user:new_password')} />
            <div className="form-info">
              <h4><T i18nKey="common:password">Wachtwoord</T></h4>
              <T i18nKey="user:password_description">Een wachtwoord bevat minimum 12 karakters met letters, cijfers, hoofdletters en leestekens.</T><br /><br />
            </div>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="newpassword2"><T i18nKey="user:confirm_new_password">Nieuw wachtwoord bevestigen</T></label>
            <input ref={this.newPassword2Ref} type="password" className="form-control" id="newpassword2" placeholder={t('user:confirm_new_password')} />
          </fieldset>
          <div className="pass-strenght">
            <div className="pass-strenght-color" style={{ width: `${Math.round(this.state.passwordComplexity.complexity)}%` }}></div>
            <div className="pass-strenght-percent">{`${Math.round(this.state.passwordComplexity.complexity)}%`}</div>
          </div>
          {this.state.errorPass
            ? <div className="message warning">
              {this.state.errorPass}
            </div>
            : null}
          <fieldset className="form-group">
            {this.state.loadingPass
              ? <button className="form-submit"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></button>
              : <input type="submit" className="form-submit" value={t('user:save_password')} />
            }
          </fieldset>
        </form>
        { hasPermissionFor(this.props.user.role, 'ADMIN+') &&
          <div className="ProjectWizard-status-buttons-group">
            <button ref={this.delButtonRef} className="btn-red" onClick={this.deleteFromProjects}><T i18nKey="user:delete_from_projects">Uit werven verwijderen</T></button>
            {this.state.deleteFromProjectsError && <div>{this.state.deleteFromProjectsError}</div>}
          </div>
        }
      </section>
    )
  }
}

const EditUser = withTranslation()(EditUserView)
export { EditUser }
