import React, { Component } from 'react'
import _ from 'underscore'
import moment from 'moment'
import 'moment/locale/nl'
import { resetDataCache, xmlGetRequest } from './tools.jsx'

import { Route } from 'react-router-dom'
import { Login } from './components/login/login.jsx'
import { RecoverPassword } from './components/login/recoverPassword.jsx'
import { NewPassword } from './components/login/newPassword.jsx'
import { Projects } from './components/projectList/projects.jsx'
import { Project } from './components/project/projectPage/project.jsx'
import { ProjectWizard } from './components/projectWizard/projectWizard.jsx'
import { MuteProject } from './components/project/mutePage.jsx'
import { Planning } from './components/planning/planning.jsx'
import { Users } from './components/users/users.jsx'
import { User } from './components/users/user.jsx'
import { NewUser } from './components/users/newUser.jsx'
import { EditUser } from './components/users/editUser.jsx'
import { EditUserPicture } from './components/users/editPicture.jsx'
import { Organisations } from './components/organisations/organisations.jsx'
import { Organisation } from './components/organisations/organisation.jsx'
import { EditOrganisation } from './components/organisations/editOrganisation.jsx'
import { EditOrganisationPicture } from './components/organisations/editPicture.jsx'
import { Logging } from './components/logging/logging.jsx'
import { SettingsPage } from './components/settings/settingsPage.jsx'
import { PassProjectPage } from './components/project/passProjectPage.jsx'
import { AdminToolsPage } from './components/adminTools/adminToolsPage.jsx'
import { NotFound } from './components/notFound.jsx'
import { Toolbar } from './components/toolbar/toolbar.jsx'
import { Header } from './components/header/header.jsx'
import { RightPlanningSlideView } from './components/planningSlider/planningSlider.jsx'

import location from './location'

const $ = window.$
const ga = window.ga

moment.locale('nl')

window.dataCache = {
  newProjectsState: null,
  designProjectsState: null,
  executionProjectsState: null,
  finishedProjectsState: null,
  waitingProjectsState: null,
  filterStatus: null,
  usersState: null,
  organisationsState: null,
  sortStatus: null
}

/* decode token functions */
function FormatJWT (jwt) {
  if (!jwt) {
    return null
  }
  const segments = jwt.split('.')
  if (segments.length !== 3) {
    localStorage.clear()
    window.location.reload()
    return
  }
  const header = Base64URLDecode(segments[0])
  const content = Base64URLDecode(segments[1])
  let decodedToken
  try {
    decodedToken = {
      header: JSON.parse(header),
      content: JSON.parse(content)
    }
  } catch (error) {
    console.error(error)
    console.info(jwt)
    throw new Error('Not valid JSON')
  }
  return decodedToken
}

function Base64URLDecode (base64UrlEncodedValue) {
  let result
  const newValue = base64UrlEncodedValue.replace('-', '+').replace('_', '/')
  try {
    if (!window.escape) window.escape = a => a
    result = window.decodeURIComponent(window.escape(window.atob(newValue)))
  } catch (e) {
    console.error(e)
    console.info(base64UrlEncodedValue)
    throw new Error('Base64URL decode of JWT segment failed')
  }
  return result
}
/* end decode token */

/* parse Query sting */
function parseQuery (qstr) {
  const query = {}
  const a = qstr.substr(1).split('&')
  for (let i = 0; i < a.length; i++) {
    const b = a[i].split('=')
    query[window.decodeURIComponent(b[0])] = window.decodeURIComponent(b[1] || '')
  }
  return query
}
/* */

const pageNeedsNoPermission = () => {
  const pathname = window.location.pathname
  return (pathname === '/login' || pathname.split('/')[1] === 'nieuw_wachtwoord' || pathname === '/wachtwoord_herstellen')
}

const isTouchDevice = 'ontouchstart' in document.documentElement
if (isTouchDevice) $('body').addClass('touchDevice')

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: null,
      production: !(window.location.host.includes('staging') || window.location.host.includes('localhost')),
      userHasPermission: false
    }

    this.token = {
      token: null,
      decodedToken: {
        content: {}
      }
    }

    this.getToken = this.getToken.bind(this)
  }

  componentWillMount () {
    // clearing the token makes the app render the login page without toolbar/searchbar
    $(window).on('loggedOut', (event) => {
      localStorage.removeItem('authToken')
      this.token = {}
      resetDataCache()
    })
  }

  componentDidMount () {
    this.testToken()
  }

  componentWillUnmount () {
    $(window).off('updateUser')
    $(window).off('loggedOut')
  }

  componentWillReceiveProps (nextProps) {
    this.setToken()
    if (this.token.token) {
      const tokenUserId = this.token.decodedToken.content.userId
      const user = this.state.user
      let userId
      if (user && user._id) {
        userId = user._id
      }
      if (tokenUserId !== userId) {
        this.getUser(this.token.token)
      }
    } else {
      this.setState({
        user: null
      })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      (!_.isEqual(this.state, nextState)) ||
            (nextProps.location.pathname !== this.props.location.pathname) ||
            (nextProps.location.search !== this.props.location.search)
    ) { return true }
    return false
  }

  componentDidUpdate (prevProps, prevState) {
    this.setToken()
    if (!this.token.token && !pageNeedsNoPermission()) {
      $(window).trigger('loggedOut')
      this.props.history.push('/login')
    }
  }

  async testToken () {
    await this.getToken()

    this.setToken()
    if (!pageNeedsNoPermission()) {
      this.getUser()
      $(window).on('updateUser', () => {
        this.getUser()
      })
    }
  }

  async getToken () {
    const pathname = window.location.pathname
    const localToken = localStorage.getItem('authToken')
    const newPasswordToken = (pathname.split('/')[1] === 'nieuw_wachtwoord') ? pathname.split('/')[2] : null
    const urlToken = parseQuery(window.location.search).token || newPasswordToken

    const usedToken = localToken || urlToken

    if (!(pathname === '/login' || pathname.split('/')[1] === 'nieuw_wachtwoord')) {
      if (!usedToken) {
        this.token = {
          token: null,
          decodedToken: {
            content: {}
          }
        }
        return this.props.history.replace('/login')
      } else {
        this.token = {
          token: usedToken,
          decodedToken: FormatJWT(usedToken)
        }
        const settings = {
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/auth/validate?token=${usedToken}`,
          method: 'GET',
          headers: {}
        }
        const promise = $.ajax(settings)

        let tokenIsValid
        try {
          tokenIsValid = await promise
        } catch (error) {
          return console.error(error)
        }

        if (!tokenIsValid) {
          localStorage.removeItem('authToken')

          this.token = {
            token: null,
            decodedToken: {
              content: {}
            }
          }

          if (pathname !== '/login' && pathname.split('/')[1] !== 'nieuw_wachtwoord') {
            return this.props.history.replace('/login')
          }
        } else {
          this.token = {
            token: usedToken,
            decodedToken: FormatJWT(usedToken)
          }

          if (pathname === '/login' || pathname.split('/')[1] === 'nieuw_wachtwoord') {
            return this.props.history.replace('/')
          }
        }
      }
    }
  }

  setToken () {
    const localToken = localStorage.getItem('authToken')
    const newPasswordToken = (window.location.pathname.split('/')[1] === 'nieuw_wachtwoord') ? window.location.pathname.split('/')[2] : null
    const urlToken = parseQuery(window.location.search).token || newPasswordToken

    const usedToken = localToken || urlToken
    if (usedToken) {
      this.token = {
        token: usedToken,
        decodedToken: FormatJWT(usedToken)
      }
    } else {
      this.token = {
        token: null,
        decodedToken: {
          content: {}
        }
      }
    }
  }

  userHasPermission (user) {
    const role = user.role
    const adminPages = ['logging', 'instellingen', 'organisatie_aanpassen', 'organisatie_afbeelding', 'nieuwe_gebruiker', 'adminTools']
    const employeePages = ['gebruikers', 'organisaties', 'werf_wizard', 'mutewerf']
    const freelancerPages = ['planning', 'werf_doorgeven', 'doorgegeven_werven']
    const partnerPages = ['', 'gebruiker', 'gebruiker_aanpassen', 'gebruiker_afbeelding', 'gebruiker_instellingen', 'werf', 'organisatie', 'gotokaart']

    const pathname = window.location.pathname.split('/')
    let hasPermission
    switch (role) {
      case 'ADMIN+':
      case 'ADMIN':
        if (_.includes(adminPages, pathname[1])) { hasPermission = true }
        // falls through

      case 'EMPLOYEE':
        if (_.includes(employeePages, pathname[1])) { hasPermission = true }
        // falls through

      case 'FREELANCER':
        if (_.includes(freelancerPages, pathname[1])) { hasPermission = true }
        // falls through

      case 'PARTNER':
        if (_.includes(partnerPages, pathname[1])) { hasPermission = true }
        break

      default: hasPermission = false
    }
    if (pathname[1] === 'gebruiker_aanpassen' && pathname[2] !== user._id && role !== 'ADMIN' && role !== 'ADMIN+') {
      hasPermission = false
    }
    return hasPermission
  }

  async getUser (_token) {
    let user
    try {
      user = await xmlGetRequest(`${location.origin}/v1/user/me`, this.token.token)
    } catch (error) {
      //
    }

    if (user && user._id) {
      ga('set', 'userId', user._id)
      ga('send', 'event', 'start', 'user_authenticated')
      this.setState({
        user
      })
    } else {
      this.props.history.push('/login')
      localStorage.removeItem('authToken')
      this.setState({
        user: null
      })
      console.error(user)
    }
  }

  render () {
    const user = this.state.user
    if (user || pageNeedsNoPermission()) {
      this.setToken()
      const newProps = _.clone(this.props)
      newProps.token = this.token
      if (this.token.token) {
        newProps.user = user
      }

      return (
        <div>
          <div className="fixed_header">
            <Header {...newProps} {...this.state}/>
            <div className="clear"></div>
            <Toolbar {...newProps} {...this.state} togglePlanning={this.togglePlanning} />
          </div>
          <div className="container">
            <Route path="/" exact render={props => <Projects {...newProps} {...props} />} />
            <Route path="/login" render={props => <Login {...newProps} {...props} />} />
            <Route path="/nieuw_wachtwoord/:token" render={props => <NewPassword {...newProps} {...props} />} />
            <Route exact path="/nieuw_wachtwoord" render={props => <NewPassword {...newProps} {...props} />} />
            <Route path="/wachtwoord_herstellen" render={props => <RecoverPassword {...newProps} {...props} />} />
            <Route path="/gebruikers" render={props => <Users {...newProps} {...props} />} />
            <Route path="/nieuwe_gebruiker" render={props => <NewUser {...newProps} {...props} />} />
            <Route path="/gebruiker/:id" render={props => <User {...newProps} {...props} />} />
            <Route path="/gebruiker_aanpassen/:id" render={props => <EditUser {...newProps} {...props} />} />
            <Route path="/gebruiker_afbeelding/:id" render={props => <EditUserPicture {...newProps} {...props} />} />
            <Route path="/werf/:id" render={props => <Project {...newProps} {...props} />} />
            <Route path="/werf_wizard/:id" render={props => <ProjectWizard {...newProps} {...props} />} />
            <Route path="/mutewerf/:id" render={props => <MuteProject {...newProps} {...props} />} />
            <Route path="/planning" render={props => <Planning {...newProps} {...props} />} />
            <Route path="/organisaties" render={props => <Organisations {...newProps} {...props} />} />
            <Route path="/organisatie/:id" render={props => <Organisation {...newProps} {...props} />} />
            <Route path="/organisatie_aanpassen/:id" render={props => <EditOrganisation {...newProps} {...props} />} />
            <Route path="/organisatie_afbeelding/:id" render={props => <EditOrganisationPicture {...newProps} {...props} />} />
            <Route path="/logging" render={props => <Logging {...newProps} {...props} />} />
            <Route path="/instellingen" render={props => <SettingsPage {...newProps} {...props} />} />
            <Route path="/werf_doorgeven/:id" render={props => <PassProjectPage {...newProps} {...props} />} />
            <Route path="/adminTools" render={props => <AdminToolsPage {...newProps} {...props} />} />
            <Route path="/niet_gevonden" render={props => <NotFound {...newProps} {...props} />} />
          </div>
          {user && user.role !== 'PARTNER' ? <RightPlanningSlideView {...newProps} {...this.state}/> : null}
        </div>
      )
    } else {
      return (
        <div>
                    laden
        </div>
      )
    }
  }
}

export default App
