import React, { Component } from 'react'
import { CreateTemplateView } from './templateManager_edit.jsx'
import { TemplatesTableView } from './templateManager_list.jsx'
import { TemplatePreviewView } from './templateManager_preview.jsx'
import location from '../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class TemplatesView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      templates: [],
      error: false,
      showView: 'allTemplates',
      contextValues: {}
    }

    this.changeView = this.changeView.bind(this)
    this.templateAdded = this.templateAdded.bind(this)
    this.templateEdited = this.templateEdited.bind(this)
    this.editTemplate = this.editTemplate.bind(this)
    this.gotoListView = this.gotoListView.bind(this)
  }

  componentDidMount () {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/message/template`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }
    const getTemplatesPromise = $.ajax(settings)
    getTemplatesPromise.done(templates => {
      this.setState({
        templates
      })
    })
    getTemplatesPromise.fail(error => {
      this.setState({ error })
    })

    this.getContextValues()
  }

  getContextValues () {
    const userSchemaSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/user/getSchema`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }
    const getUserSchemaPromise = $.ajax(userSchemaSettings)
    getUserSchemaPromise.done(userSchema => {
      this.setState(state => ({
        contextValues: Object.assign({}, state.contextValues, { user: userSchema })
      }))
    })
    getUserSchemaPromise.fail(error => {
      this.setState({ error })
    })

    const projectSchemaSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/getSchema`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }
    const getProjectSchemaPromise = $.ajax(projectSchemaSettings)
    getProjectSchemaPromise.done(projectSchema => {
      this.setState(state => ({
        contextValues: Object.assign({}, state.contextValues, { project: projectSchema })
      }))
    })
    getProjectSchemaPromise.fail(error => {
      this.setState({ error })
    })
  }

  changeView (view) {
    this.setState({
      showView: view
    })
  }

  templateAdded (template) {
    const templates = this.state.templates
    templates.push(template)
    this.setState({
      templates,
      showView: 'allTemplates'
    })
  }

  templateEdited (changedTemplate) {
    console.log(changedTemplate)
    let templates = this.state.templates
    templates = templates.map(template => {
      if (template._id === changedTemplate._id) {
        return changedTemplate
      } else {
        return template
      }
    })
    // console.log(templates);
    // console.log(templates.map(template => {
    //     if ( template._id === changedTemplate._id ) {
    //         return changedTemplate;
    //     } else {
    //         return template;
    //     }
    // }));
    this.setState({
      templates,
      showView: 'allTemplates'
    })
  }

  editTemplate (index, preview) {
    this.setState({
      editTemplateIndex: index,
      showView: preview ? 'previewTemplate' : 'editTemplate'
    })
  }

  gotoListView () {
    this.setState({
      showView: 'allTemplates'
    })
  }

  render () {
    let secondaryView
    switch (this.state.showView) {
      case 'allTemplates': secondaryView = <TemplatesTableView editTemplate={this.editTemplate} previewTemplate={this.previewTemplate} templates={this.state.templates} />; break
      case 'createTemplate': secondaryView = <CreateTemplateView token={this.props.token} gotoListView={this.gotoListView} templateAdded={this.templateAdded} contextValues={this.state.contextValues} />; break
      case 'editTemplate': secondaryView = <CreateTemplateView template={this.state.templates[this.state.editTemplateIndex]} gotoListView={this.gotoListView} token={this.props.token} templateEdited={this.templateEdited} contextValues={this.state.contextValues} />; break
      case 'previewTemplate': secondaryView = <TemplatePreviewView template={this.state.templates[this.state.editTemplateIndex]} gotoListView={this.gotoListView} />; break
      default:
    }
    return (
      <section className="right_content">
        {this.state.error ? <h1>{this.state.error}</h1> : ''}
        <h1><T i18nKey="settings:manage_templates">Templates beheren</T></h1>
        {this.state.showView === 'allTemplates' ? <button onClick={this.changeView.bind(this, 'createTemplate')}><T i18nKey="settings:create_template">Nieuw template maken</T></button> : ''}
        {secondaryView}
      </section>
    )
  }
}
