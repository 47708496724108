import React, { Component } from 'react'
import _ from 'underscore'
import location from '../../../location'
import i18n from '../../../i18n.js'

export class ProjectsTableRow extends Component {
  constructor (props) {
    super(props)

    this.gotoProject = this.gotoProject.bind(this)
    this.gotoProjectWizard = this.gotoProjectWizard.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return false
  }

  gotoProject (event) {
    this.props.history.push(`/werf/${event.currentTarget.id}`)
  }

  gotoProjectWizard (event) {
    this.props.history.push(`/werf_wizard/${event.currentTarget.id}?new=true`)
  }

  // hideProject() {
  //     $('#'+this.props.project._id).hide();
  // },
  render () {
    const project = this.props.project
    // const projectStatus = this.props.projectStatus;
    // let projectRow;
    // projectRow = this.prepareForOther(projectStatus);
    let projectName = i18n.t('project:nameless_project')
    if (project.ss_name || project.project_name) {
      projectName = project.ss_name || project.project_name
    }
    if (projectName.length > 63) {
      projectName = projectName.slice(0, 58) + '...'
    }
    const organisationImgSrc = `/v1/file/image/${project.client_id}_icon`
    const vcExecution = _.find(project.users, function (user) {
      return user.type === 'vcExecution'
    })
    let vc1Image
    if (vcExecution && typeof vcExecution.user === 'object') {
      vc1Image = vcExecution.user.profile_picture_icon_url || '/images/default_user_icon.png'
    } else {
      vc1Image = '/images/default_user_icon.png'
    }

    return (
      <tr className="projects_table_row" onClick={this.gotoProject} id={project._id}>
        <td>{project.afas_id}</td>
        <td>{projectName}</td>
        <td>{project.client_name
          ? <div onMouseEnter={this.props.expandPicture} onMouseLeave={this.props.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${organisationImgSrc}`} className="profile_pic" alt={project.client_name || '-'} />
          </div>
          : '-'}
        </td>
        <td>{(vcExecution && typeof vcExecution.user === 'object')
          ? <div onMouseEnter={this.props.expandPicture} onMouseLeave={this.props.withdrawPicture} className="micro profile_pic_round">
            <img src={`${location.origin}${vc1Image}`} className="profile_pic" alt={vcExecution.user.name || '-'} />
          </div>
          : '-'}
        </td>
      </tr>
    )
  }
}
