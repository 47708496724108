import React, { Component } from 'react'
import './ProjectPlanning.css'
import moment from 'moment'
import _ from 'underscore'
import PlanningInfo from './PlanningInfo.jsx'
import DisablePlanningButton from './DisablePlanningButton.js'
import location from '../../../location.js'
import { xmlRequest } from '../../../tools.jsx'
import { Trans as T, withTranslation } from 'react-i18next'

class ProjectPlanningView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false
    }

    this.addToPlanning = this.addToPlanning.bind(this)
  }

  addToPlanning () {
    const project = this.props.project

    this.setState({
      loading: true
    })

    // if project has no visits -> set new start date
    const projectUpdate = {}
    if (!(project.visits || []).length) {
      projectUpdate.start_date = moment().format('YYYY-MM-DD')
    }

    const planningData = {
      user: this.props.user._id,
      project: project._id,
      planned_date: moment().startOf('d').toISOString(),
      visit_by: project.muted || project.next_visit
    }

    xmlRequest(`${location.origin}/v1/planning`, this.props.token.token, planningData, 'POST')
      .then(response => {
        projectUpdate.planning = response._id
        this.props.updateProject(projectUpdate)
          .done((response) => {
            this.setState(state => ({ loading: false }))
          })
          .fail(function (error) {
            console.error(error)
          })

        window.dispatchEvent(new CustomEvent('updatePlanningSlider'))
        this.props.reloadProject()
      }).catch(error => {
        console.error(error)
      })
  }

  render () {
    const t = this.props.t
    const project = this.props.project
    const userRole = this.props.user.role
    const isPlanned = !!project.planning

    const nextVisit = project.next_visit ? moment(project.next_visit).format('DD-MM-YYYY') : '-'
    let lastVisit = project.last_visit
    if (lastVisit) {
      lastVisit = moment(lastVisit).format('DD-MM-YYYY')
    } else {
      lastVisit = '-'
    }

    /* add to planning button */
    const userId = this.props.user._id
    let addToPlanningButton = null
    let userIsVC = false
    _.each(project.users, function (user) {
      if (user.user && typeof user.user === 'object' && (user.user._id === userId && (user.type === 'vcExecution' || user.type === 'vcDesign' || user.type === 'vc3'))) {
        userIsVC = true
      }
    })
    let labelColor
    let extraGlypicon = null
    if (!isPlanned) {
      let enabled = userIsVC
      if (project.status === 'onHold' || project.planning_disabled || project.project_vc_passed_status === 'pending') {
        enabled = false
      }
      /* eslint-disable */
      switch (userRole) {
        case 'ADMIN+':
        case 'ADMIN':
        case 'EMPLOYEE':
        case 'FREELANCER': {
          addToPlanningButton = (
            <button disabled={!enabled} className="btn-green" onClick={enabled ? this.addToPlanning : null}>
              <span className="glyphicons glyphicons-calendar"></span>
              { this.state.loading
                ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                : <span> <T i18nKey="project:add_to_planning">Toevoegen aan mijn planning</T></span>
              }
            </button>
          )
          break
        }
        default:
      }
      /* eslint-enable */
    } else {
      const plannedDate = moment(project.planning.planned_date).startOf('d')
      const nextDate = moment(project.muted || project.next_visit).startOf('d')
      if (plannedDate.isAfter(nextDate)) {
        labelColor = 'red'
        extraGlypicon = <span className="glyphicons glyphicons-exclamation-sign"></span>
      } else {
        labelColor = 'green'
      }
      if (moment().startOf('d').isAfter(nextDate)) {
        labelColor = 'red'
        extraGlypicon = <span className="glyphicons glyphicons-exclamation-sign"></span>
      }
    }

    let snapperUrl, snapperhost
    if (typeof project.site_id === 'string') {
      snapperUrl = project.site_id
      const ss = ~snapperUrl.indexOf('safetysnapper')
      const as = ~snapperUrl.indexOf('archisnapper')
      if (!ss && !as) {
        snapperUrl = false
      }
      snapperhost = ss ? 'Safetysnapper' : 'Archisnapper'
    }

    let waitingStatusReason = false
    const now = moment().startOf('day')
    if (project.status === 'execution' || project.status === 'waiting') {
      if (moment(project.start_date, 'YYYY-MM-DD').isAfter(now)) {
        waitingStatusReason = translateReason(t, 'start_in_future', moment(project.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY'))
      } else if (project.muted && moment(project.muted).isAfter(now)) {
        waitingStatusReason = translateReason(t, 'muted_until_future', moment(project.muted).format('DD/MM/YYYY'))
      } else if (project.planning_disabled) {
        waitingStatusReason = translateReason(t, 'planning_disabled')
      }
    }

    const isOnHold = project.status === 'onHold'

    return (
      <div className="ProjectPlanning-info-block">
        <h3>
          <span className="glyphicons glyphicons-eye-close"></span>
          <span><T i18nKey="project:planning">Planning</T></span>
          {isPlanned &&
            <span className={`label ${labelColor}`}><T i18nKey="project:project_is_planned">Werf is ingepland</T> {extraGlypicon}</span>
          }
          {waitingStatusReason &&
            <span className="ProjectPlanning-title-reason">
              <span><T i18nKey="project:waiting">in wacht</T> - <T i18nKey="project:reason_lowercase">reden</T>: </span>
              <span>{waitingStatusReason}</span>
            </span>
          }
          {isOnHold &&
            <span className="ProjectPlanning-title-reason">
              <span><T i18nKey="project:on_hold">on-hold</T> - <T i18nKey="project:reason_lowercase">reden</T>: </span>
              <span>{project.onHoldComment}</span>
            </span>
          }
        </h3>
        <div className="ProjectPlanning-content">
          <div className="ProjectPlanning-buttons">
            {addToPlanningButton}
            {snapperUrl &&
              <a className="btn-green" target="_blank" rel="noopener noreferrer" href={snapperUrl}>
                <span className="glyphicons glyphicons-ok-circle"></span>{` Open in ${snapperhost}`}
              </a>
            }
            <DisablePlanningButton token={this.props.token} reloadProject={this.props.reloadProject} updateProject={this.props.updateProject} userRole={userRole} project={project} />
          </div>
          <div className="ProjectPlanning-dates">
            <div className="ProjectPlanning-date">
              <span><T i18nKey="project:visit_by">Te bezoeken tegen</T>:</span>
              <span>{nextVisit}</span>
            </div>
            {project.muted &&
              <>
                <div className="ProjectPlanning-date">
                  <span><T i18nKey="project:muted_until">Gemute tot</T>:</span>
                  <span>{moment(project.muted).format('DD-MM-YYYY')}</span>
                </div>
                {project.muteComment &&
                  <div className="ProjectPlanning-date">
                    <span><T i18nKey="project:reason">Reden</T>:</span>
                    <span>{project.muteComment}</span>
                  </div>
                }
              </>
            }
            <div className="ProjectPlanning-date">
              <span><T i18nKey="project:latest_visit">Laatst bezocht</T>:</span>
              <span>{lastVisit}</span>
            </div>
            {isOnHold && (
              <div className="ProjectPlanning-date">
                <span><T i18nKey="project:reasonasd">Niet meer inplannen tot minstens</T>:</span>
                <span>{project.onHoldUntilAtLeastDate}</span>
              </div>
            )}
          </div>
          {isPlanned && <PlanningInfo token={this.props.token} project={project._id} />}
        </div>
      </div>
    )
  }
}

const ProjectPlanning = withTranslation()(ProjectPlanningView)
export default ProjectPlanning

function translateReason (t, reason, replace) {
  /* eslint-disable */
  switch (reason) {
    case 'start_in_future': return t('project:start_date_with_date', { date: replace })
    case 'muted_until_future': return t('project:muted_until_date', { date: replace })
    case 'planning_disabled': return t('project:project_was_set_to_dont_plan')
    default: return t('project:reason_unknown')
  }
  /* eslint-enable */
}
