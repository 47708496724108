import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import location from '../../location'
import moment from 'moment'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class OrganisationView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      organisation: {
        _id: '',
        afas_id: null,
        name: null,
        address: {},
        updated_at: null,
        customer: null,
        website: null,
        phone_work: null,
        email_work: null,
        phone_mobile: null,
        ondernemersnummer: null
      },
      users: [],
      projects: [],
      loading: true
    }
  }

  componentWillMount () {
    document.title = this.props.t('title:organisation')
    const authHeader = `Bearer ${this.props.token.token}`
    const organisationAjaxSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/organisation/${this.props.match.params.id}`,
      method: 'GET',
      headers: {
        authorization: authHeader
      }
    }
    const organisationPromise = $.ajax(organisationAjaxSettings)
    organisationPromise.done((organisation) => {
      document.title = `Coördinatiedagboek  - Organisatie - ${organisation.name || ''}`
      const usersRequestSettings = {
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/user?organisationId=${organisation._id}`,
        method: 'GET',
        headers: {
          authorization: authHeader
        }
      }
      const usersPromise = $.ajax(usersRequestSettings)
      usersPromise.done((users) => {
        const projectsAjaxSettings = {
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/project?client_id=${organisation.afas_id}&select=address,project_name,afas_id,status,muted,planning_disabled`,
          method: 'GET',
          headers: {
            authorization: authHeader
          }
        }
        const projectsPromise = $.ajax(projectsAjaxSettings)
        projectsPromise.done(projects => {
          this.setState({
            organisation,
            users,
            projects: projects.results,
            loading: false
          })
        })
        projectsPromise.fail(error => {
          console.error(error)
        })
      })
      usersPromise.fail(error => {
        console.error(error)
      })
    })
    organisationPromise.fail(error => {
      console.error(error)
    })
  }

  render () {
    if (this.state.loading) {
      return (
        <section>
          <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
        </section>
      )
    }
    const organisation = this.state.organisation
    const imgUrl = organisation.profile_picture_thumbnail_url || '/images/default_organisation_thumbnail.png'
    const address = organisation.address || {}
    const location1 = `${address.street || '-'} ${address.housenumber || ''}`
    const location2 = `${address.zipcode || ''} ${address.city || '-'}`
    const userRole = this.props.user.role
    return (
      <section className="container content">
        <section className="organisation_block">
          <div className="organisation_profile_pic">
            <div className="big profile_pic_round">
              <img src={`${location.origin}${imgUrl}`} className="profile_pic" alt="profile" />
            </div>
            {userRole.match('ADMIN')
              ? <Link to={`/organisatie_afbeelding/${organisation._id}`} className="edit_profile_pic"><T i18nKey="settings:edit">Aanpassen</T></Link>
              : null}
          </div>
          <div className="organisation_info">
            <h3>{organisation.name || '-'}</h3>
            <div className="organisation_contact">
              <dl>
                <dt><span className="glyphicons glyphicons-google-maps"></span></dt>
                <dd>{location1}</dd>
                <dd>{location2}</dd>
                <dt><T i18nKey="organisation:vat">VAT</T></dt>
                <dd>{organisation.ondernemersnummer || '-'}</dd>
                <dt><span className="glyphicons glyphicons-link"></span></dt>
                <dd><a href={organisation.website}>{organisation.website || '-'}</a></dd>
                <dt><span className="glyphicons glyphicons-envelope"></span></dt>
                <dd><a href={`mailto:${organisation.email_work}`}>{organisation.email_work || '-'}</a></dd>
                <dt><span className="glyphicons glyphicons-iphone"></span></dt>
                <dd><a href={`tel:${organisation.phone_mobile}`}>{organisation.phone_mobile || '-'}</a></dd>
                <dt><span className="glyphicons glyphicons-earphone"></span></dt>
                <dd><a href={`tel:${organisation.phone_work}`}>{organisation.phone_work || '-'}</a></dd>
              </dl>
            </div>
          </div>
          {userRole.match('ADMIN')
            ? <Link to={`/organisatie_aanpassen/${organisation._id}`} className="edit">
              <span className="glyphicons glyphicons-pencil"></span>
            </Link>
            : null}
          <div className="clear"></div>
        </section>
        <ExtraView {...this.props} {...this.state} />
      </section>
    )
  }
}

const Organisation = withTranslation()(OrganisationView)
export { Organisation }

class ExtraView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      route: 'users'
    }

    this.changeTab = this.changeTab.bind(this)
  }

  changeTab (event) {
    event.preventDefault()
    this.setState({ route: event.currentTarget.id })
    const $target = $(event.currentTarget)
    $target.closest('section').find('.active').removeClass('active')
    $target.closest('li').addClass('active')
  }

  render () {
    let rightContent = <Medewerkers {...this.props} {...this.state} />
    if (this.state.route === 'projects') {
      rightContent = <Projects {...this.props} {...this.state} />
    }
    return (
      <div>
        <section className="left_menu">
          <ul>
            <li className="active">
              <a href="" onClick={this.changeTab} id="users"><T i18nKey="organisation:employees">Medewerkers</T></a>
            </li>
            <li>
              <a href="" onClick={this.changeTab} id="projects"><T i18nKey="organisation:projects">Werven</T></a>
            </li>
          </ul>
        </section>
        {rightContent}
      </div>
    )
  }
}

class Medewerkers extends Component {
  render () {
    const users = this.props.users
    const userRows = []
    users.forEach(user => {
      const ssRoleName = user.ss_role || ''
      const userImgUrl = user.profile_picture_thumbnail_url || '/images/default_user_icon.png'
      userRows.push(
        <tr key={user._id}>
          <td>
            <div className="micro profile_pic_round">
              <img src={`${location.origin}${userImgUrl}`} className="profile_pic" alt={user.name} />
            </div>
          </td>
          <td>{user.name || 'name missing'}</td>
          <td>{ssRoleName}</td>
          <td><a href={`mailto:${user.email}`}>{user.email || ''}</a></td>
          <td><a href={`tel:${user.phone}`}>{user.phone || ''}</a></td>
        </tr>
      )
    })
    return (
      <section className="right_content">
        <h3><T i18nKey="organisation:employees">Medewerkers</T></h3>
        <table className="list_table">
          <thead>
            <tr>
              <th></th>
              <th><T i18nKey="organisation:name">Naam</T></th>
              <th><T i18nKey="organisation:function">Functie</T></th>
              <th><T i18nKey="organisation:email">Email</T></th>
              <th><T i18nKey="organisation:phone">Telefoon</T></th>
            </tr>
          </thead>
          <tbody>
            {userRows}
          </tbody>
        </table>
      </section>
    )
  }
}

class ProjectsView extends Component {
  constructor (props) {
    super(props)

    this.gotoProject = this.gotoProject.bind(this)
  }

  gotoProject (event) {
    this.props.history.push(`/werf/${event.currentTarget.id}`)
  }

  render () {
    const t = this.props.t
    const projects = this.props.projects
    const projectRows = projects.map(project => {
      project.address = project.address || {}
      let gotoProjectFunction = this.gotoProject
      const cursorStyle = {}
      if (project.status === 'new') {
        gotoProjectFunction = null
        cursorStyle.cursor = 'default'
      }
      return (
        <tr key={project._id} id={project._id} onClick={gotoProjectFunction} style={cursorStyle}>
          <td>{project.afas_id}</td>
          <td>{project.ss_name || project.project_name || t('organisation:nameless')}</td>
          <td>{getStatus(t, project)}</td>
          <td>{project.address.city || ''}</td>
        </tr>
      )
    })
    return (
      <section className="right_content">
        <h3><T i18nKey="organisation:projects">Werven</T></h3>
        <table className="list_table">
          <thead>
            <tr>
              <th width="140"><T i18nKey="project:project_number_short">Project nr.</T></th>
              <th><T i18nKey="organisation:name">Naam</T></th>
              <th width="110"><T i18nKey="organisation:status">Status</T></th>
              <th><T i18nKey="organisation:location">Locatie</T></th>
            </tr>
          </thead>
          <tbody>
            {projectRows}
          </tbody>
        </table>
      </section>
    )
  }
}
const Projects = withTranslation()(ProjectsView)

function getStatus (t, project) {
  const status = project.status
  if (status === 'execution') {
    const now = moment().startOf('day')
    if (project.planning_disabled) { return t('organisation:waiting') } else if (project.muted && moment(project.muted).isAfter(now)) { return t('organisation:waiting') }
    return t('organisation:execution')
  } else {
    switch (status) {
      case 'new': return t('organisation:new')
      case 'design': return t('organisation:design')
      case 'waiting': return t('organisation:waiting')
      case 'finished': return t('organisation:finished')
      default: return t('organisation:unknown_status')
    }
  }
}
