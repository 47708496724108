import React from 'react'
import { Trans as T } from 'react-i18next'

export const TemplatePreviewView = props => {
  const template = props.template
  return (
    <div>
      <button onClick={props.gotoListView}><T i18nKey="common:back">Terug</T></button>
      <h4>{template.name}</h4>
      <h5><T i18nKey="settings:subject">Onderwerp</T></h5><p>{template.subjectTemplate}</p>
      <h5><T i18nKey="settings:message">Bericht</T></h5><div dangerouslySetInnerHTML={{ __html: template.messageTemplate }} />
    </div>
  )
}
