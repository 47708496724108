import React, { Component } from 'react'
import { uploadFile, addFileToProject, logFileChange } from '../../../tools.jsx'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class FileUploadView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      uploading: false,
      selectedDate: moment(),
      uploadProgress: 0,
      error: null
    }

    this.updateUploadProgressBar = this.updateUploadProgressBar.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)

    this.formFileRef = React.createRef()
  }

  componentDidMount () {
    $(window).on('updateUploadProgressBar', this.updateUploadProgressBar)
    window.addEventListener('updateUploadProgressBar', () => {
      console.log('addEventListener', this)
    })
  }

  componentWillUnmount () {
    $(window).off('updateUploadProgressBar')
  }

  updateUploadProgressBar (event, perc) {
    if (this.state.uploading) {
      this.setState({
        uploadProgress: perc
      })
    }
  }

  changeDate (date) {
    this.setState({
      selectedDate: moment(date)
    })
  }

  handleSubmit (event) {
    event.preventDefault()
    const t = this.props.t
    const fileDate = this.state.selectedDate
    const dateIsValid = fileDate.isValid()

    const file = this.formFileRef.current
    if (dateIsValid && file.files.length === 1) {
      this.handleFileUpload(file.files[0])
    } else {
      this.setState(state => ({
        error: t('project:file_and_date_required')
      }))
    }
  }

  handleFileUpload (file) {
    const token = this.props.token.token
    const projectId = this.props.project._id
    this.setState({
      uploading: true
    })
    const newFile = this.props.newFile
    const fileTag = this.props.type

    uploadFile({
      file: file,
      file_type: fileTag,
      token: token,
      newFile: newFile,
      fileId: this.props.oldFileId,
      customDate: this.state.selectedDate.valueOf()
    }).done((response) => {
      if (newFile) {
        addFileToProject(response, fileTag, token, projectId).done((projectAddFileResponse) => {
          this.setState({
            uploading: false,
            active: false
          })
          this.props.reloadProject()
          this.props.toggleUploadForm()
        }).fail((error) => {
          console.error(error)
          let responseMessage
          try {
            responseMessage = JSON.parse(error.responseText).message
          } catch (error) {
            console.error(error)
          }
          this.setState({
            error: responseMessage,
            uploading: false
          })
        })
      } else {
        response = JSON.parse(response)
        logFileChange({
          fileId: response._id,
          token: token,
          projectId: projectId,
          tag: fileTag,
          how: 'edited',
          userId: this.props.user._id
        })
        this.setState({
          uploading: false,
          active: false
        })
        this.props.reloadProject()
        this.props.toggleUploadForm()
      }
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      this.setState({
        error: responseMessage,
        uploading: false,
        uploadProgress: 0
      })
    })
  }

  render () {
    const t = this.props.t
    let selectedDate
    if (this.state.selectedDate.isValid()) { selectedDate = this.state.selectedDate.toDate() }

    return (
            <div className="ProjectDesign-upload-container">
                <div>
                    <button onClick={this.props.toggleUploadForm} className="btn-close ProjectDesign-close-button">
                        <span className="glyphicons glyphicons-remove"></span> <T i18nKey="common:close">Sluiten</T>
                    </button>
                </div>
                <form onSubmit={this.handleSubmit} style={{ marginTop: 20, width: '100%' }}>
                    <fieldset className="form-group">
                        <label htmlFor="file"><T i18nKey="project:select_file">Selecteer bestand</T></label>
                        <input className="form-control" type="file" id="file" ref={this.formFileRef}/>
                    </fieldset>
                    <fieldset className="form-group form-group-date-picker">
                        <label htmlFor="fileDate"><T i18nKey="project:enter_date">Geef datum in</T></label>
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={selectedDate}
                            onChange={this.changeDate}
                            className="form-control"
                            id="fileDate"
                            placeholderText={t('common:click_to_select_date')}
                            isClearable={true}
                        />
                    </fieldset>
                    { this.state.error &&
                        <div className='message warning'>
                            {this.state.error}
                        </div>
                    }
                    <button type="submit" className="btn-green" value={t('common:upload')} style={{ minWidth: `${this.state.uploadProgress}%` }}>
                        { this.state.uploading &&
                            <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                        }
                        &nbsp;<T i18nKey="common:upload">Uploaden</T>
                    </button>
                </form>
            </div>
    )
  }
}

const FileUpload = withTranslation()(FileUploadView)
export default FileUpload
