import React, { Component } from 'react'
import { uploadFile, addFileToProject, logFileChange, hasPermissionFor, createPDF } from '../../tools.jsx'
import moment from 'moment'
import _ from 'underscore'
import DatePicker from 'react-datepicker'
import location from '../../location'
import { Trans as T, Translation as Translate } from 'react-i18next'
const $ = window.$

export class FileUploadForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      uploading: false,
      selectedDate: moment(),
      newFile: this.props.newFile || false,
      uploadProgress: 0,
      showDate: !(this.props.showDate === false),
      mailen: false,
      textInsteadOfFile: false,
      textInput: '',
      pdfFileName: ''
    }

    this.updateUploadProgressBar = this.updateUploadProgressBar.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.changeMail = this.changeMail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.sendMail = this.sendMail.bind(this)
    this.mailToVC = this.mailToVC.bind(this)
    this.toggleTextOrFile = this.toggleTextOrFile.bind(this)
    this.changeText = this.changeText.bind(this)
    this.handlePdfFileNameChange = this.handlePdfFileNameChange.bind(this)

    this.mailenRef = React.createRef()
    this.formFileRef = React.createRef()
  }

  componentDidMount () {
    $(window).on('updateUploadProgressBar', this.updateUploadProgressBar)
  }

  componentWillUnmount () {
    $(window).off('updateUploadProgressBar')
  }

  updateUploadProgressBar (event, perc) {
    if (this.state.uploading) {
      this.setState({
        uploadProgress: perc
      })
    }
  }

  changeDate (date) {
    this.setState({
      selectedDate: moment(date)
    })
  }

  changeMail () {
    this.setState({
      mailen: this.mailenRef.current.checked
    })
  }

  handleSubmit (event) {
    event.preventDefault()
    const fileDate = this.state.selectedDate
    const dateIsValid = fileDate.isValid()

    if (!this.state.textInsteadOfFile) {
      const file = this.formFileRef.current
      if (dateIsValid && file.files.length === 1) {
        this.handleFileUpload(file.files[0])
      }
    } else {
      if (dateIsValid && this.state.textInput) {
        const project = this.props.project
        const projectName = project.ss_name || project.project_name || ''
        const user = this.props.user
        const name = user.name || user.firstname || user.lastname || ''
        createPDF(this.state.textInput, projectName, name, this.state.pdfFileName, pdf => {
          this.handleFileUpload(pdf, window.encodeURIComponent(this.state.pdfFileName))
        })
      }
    }
  }

  handleFileUpload (file, filename) {
    const token = this.props.token.token
    const projectId = this.props.project._id
    const that = this
    that.setState({
      uploading: true
    })
    const newFile = this.state.newFile

    const fileType = this.props.fileType.type
    let fileTag
    /* eslint-disable */
    switch (fileType) {
      case 'cd': fileTag = 'pid_type8_userupload'; break
      case 'pid': fileTag = 'pid_type' + this.props.fileType.index; break
      case 'vgpo':
      case 'vgpu':
      case 'vgp':
      case 'vgp_extra':
      case 'vgp_docx':
      case 'internal_document': fileTag = fileType; break
      default:
    }
    /* eslint-enable */

    const updateParent = this.props.updateParentView || function () { }

    uploadFile({
      file: file,
      file_type: fileType,
      token: token,
      newFile: newFile,
      fileId: this.props.oldFileId,
      customDate: this.state.selectedDate.valueOf(),
      filename
    }).done((response) => {
      if (newFile) {
        addFileToProject(response, fileTag, token, projectId).done((projectAddFileResponse) => {
          that.setState({
            uploading: false
          })

          if (this.state.mailen) {
            this.sendMail()
          } else {
            this.props.closeFileForm()
            updateParent(response)
          }
        }).fail((error) => {
          console.error(error)
          let responseMessage
          try {
            responseMessage = JSON.parse(error.responseText).message
          } catch (error) {
            console.error(error)
          }
          that.setState({
            error: responseMessage,
            uploading: false
          })
        })
      } else {
        response = JSON.parse(response)
        logFileChange({
          fileId: response._id,
          token: token,
          projectId: projectId,
          tag: fileTag,
          how: 'edited',
          userId: this.props.user._id
        })
        that.setState({
          uploading: false
        })

        if (this.state.mailen) {
          this.sendMail()
        } else {
          this.props.closeFileForm()
          updateParent(response)
        }
      }

      if (this.props.user.role === 'PARTNER') {
        // always send mail to all VCs
        this.mailToVC()
      }
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      that.setState({
        error: responseMessage,
        uploading: false,
        uploadProgress: 0
      })
    })
  }

  sendMail () {
    console.info('PID_PIDSubTypeView => sendMail')

    const updateParent = this.props.updateParentView || function () { }

    this.setState({
      error: false,
      emailing: true
    })

    const payload = {
      template: 'nieuwbestand',
      to: [
      ],
      global_merge_vars: [{
        name: 'project_name',
        content: this.props.project.ss_name || this.props.project.project_name
      }]
    }

    // Propagate payload.to with all users connected to project
    const users = this.props.project.users
    let user
    users.forEach(function (contact) {
      if (Object.prototype.toString.call(contact).slice(8, -1) === 'Object' && Object.prototype.toString.call(contact.user).slice(8, -1) === 'Object') {
        user = contact.user
        if (contact.type === 'contact') {
          payload.to.push({
            email: user.email,
            name: user.name,
            type: 'cc'
          })
        }
      }
    })

    if (hasPermissionFor(this.props.user.role, 'EMPLOYEE')) {
      payload.to.push({
        email: this.props.user.email,
        name: this.props.user.name,
        type: 'cc'
      })
    }

    if (!_.find(payload.to, function (user) {
      return user.email === 'adminveiligheid@macobo.be'
    })) {
      payload.to.push({
        email: 'adminveiligheid@macobo.be',
        name: 'adminveiligheid',
        type: 'bcc'
      })
    }

    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/message/send_mail`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(payload)
    }).done((response) => {
      console.log(response)
      this.setState({
        emailing: false
      })
      this.props.closeFileForm()
      updateParent(response)
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      this.setState({
        error: responseMessage,
        emailing: false
      })
    })
  }

  mailToVC () {
    const newpayload = {
      template: 'nieuwbestand_partner',
      to: [
      ],
      global_merge_vars: [{
        name: 'project_name',
        content: this.props.project.ss_name || this.props.project.project_name
      },
      {
        name: 'afas_id',
        content: this.props.project.afas_id
      },
      {
        name: 'user',
        content: this.props.user.firstname + ' ' + this.props.user.lastname
      }]
    }

    const users = this.props.project.users
    let projectuser,
      userType
    for (let i = 0, l = users.length; i < l; i++) {
      projectuser = users[i]
      userType = projectuser.type
      if (userType === 'vcExecution' || userType === 'vcDesign' || userType === 'vc3') {
        newpayload.to.push({
          email: projectuser.user.email,
          name: projectuser.user.name,
          type: 'cc'
        })
      }
    }

    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/message/send_mail`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(newpayload)
    }).done((response) => {
      console.log('mail VC', response)
    }).fail((error) => {
      console.error('mail VC', error)
    })
  }

  toggleTextOrFile (e) {
    this.setState(state => ({ textInsteadOfFile: !state.textInsteadOfFile }))
  }

  changeText (e) {
    this.setState({ textInput: e.target.value })
  }

  handlePdfFileNameChange (e) {
    const value = e.target.value
    this.setState(state => ({ pdfFileName: value || '' }))
  }

  render () {
    const selectedDate = this.state.selectedDate.isValid() ? this.state.selectedDate.toDate() : null
    const textInsteadOfFile = this.state.textInsteadOfFile
    const disableMails = this.props.disableMails
    const showTextButton = this.props.showTextButton
    return (
      <div className="info_block">
        <div>
          <a href="#" onClick={this.props.closeFileForm} className="btn-close">
            <span className="glyphicons glyphicons-remove"></span> <T i18nKey="common:close">Sluiten</T>
          </a>
        </div>
        <form onSubmit={this.handleSubmit} style={{ marginTop: 20 }}>
          {!textInsteadOfFile
            ? <fieldset className="form-group">
              <label htmlFor="file"><T i18nKey="settings:select_file">Selecteer bestand</T></label>
              <input className="form-control" type="file" id="file" ref={this.formFileRef} />
              {showTextButton &&
                <Translate>{t =>
                  <input type="button" className="btn-green btn-low" style={{ marginTop: 10 }} onClick={this.toggleTextOrFile} value={t('settings:input_text')} />
                }</Translate>
              }
              <hr />
            </fieldset>
            : <div>
              <fieldset className="form-group">
                <label htmlFor="titleInput"><T i18nKey="settings:filename">Bestandsnaam</T></label>
                <input id="titleInput" className="form-control" type="text" onChange={this.handlePdfFileNameChange} value={this.state.pdfFileName || ''} />
              </fieldset>
              <fieldset className="form-group">
                <label htmlFor="textInput"><T i18nKey="settings:pdf_content">PDF inhoud</T></label>
                <textarea className="form-control" id="textInput" onChange={this.changeText} value={this.state.textInput}>
                </textarea>
              </fieldset>
              <Translate>{t =>
                <input style={{ marginBottom: 15 }} type="button" className="btn-green btn-low" onClick={this.toggleTextOrFile} value={t('settings:select_file')} />
              }</Translate>
              <hr />
            </div>
          }
          {this.state.showDate
            ? <fieldset className="form-group form-group-date-picker">
              <label htmlFor="fileDate"><T i18nKey="settings:enter_date">Geef datum in</T></label>
              <Translate>{t =>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={selectedDate}
                  onChange={this.changeDate}
                  className="form-control"
                  id="fileDate"
                  placeholderText={t('common:click_to_select_date')}
                  isClearable={true}
                />
              }</Translate>
            </fieldset>
            : null
          }
          {!disableMails &&
            <fieldset className="form-group">
              <div className="toggle_switch">
                <label htmlFor="mailen"><T i18nKey="settings:mail_contacts">Contacten mailen</T></label>
                <input className="toggle" type="checkbox" id='mailen' ref={this.mailenRef} onChange={this.changeMail} checked={this.state.mailen} />
                <label htmlFor="mailen"></label>
              </div>
            </fieldset>
          }
          {this.state.error
            ? <div className='message warning'>
              {this.state.error}
            </div>
            : null
          }
          <hr />
          <Translate>{t =>
            <button type="submit" className="btn-green" value={t('settings:upload')} style={{ minWidth: `${this.state.uploadProgress}%` }}>
              {this.state.uploading
                ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                : null
              }
                            &nbsp;<T i18nKey="settings:upload">Uploaden</T>
            </button>
          }</Translate>
        </form>
      </div>
    )
  }
}
