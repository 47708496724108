import React, { Component } from 'react'
import moment from 'moment'
import location from '../../../location'
import FileUpload from './FileUpload.js'
import { deleteFileFromProject } from '../../../tools.jsx'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class FileRowView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      uploadActive: false,
      newFile: !props.file,
      currentlyApplicable: props.project.analysisApplicable !== false
    }

    this.deleteFile = this.deleteFile.bind(this)
    this.toggleUploadForm = this.toggleUploadForm.bind(this)
    this.changeApplicable = this.changeApplicable.bind(this)
  }

  deleteFile (e) {
    e.preventDefault()
    const confirmed = window.confirm(this.props.t('project:confirm_delete'))

    const fileId = this.props.file._id
    const projectId = this.props.project._id
    const token = this.props.token.token

    if (confirmed) {
      deleteFileFromProject(fileId, token, projectId).done((response) => {
        this.props.reloadProject()
      }).fail((error) => {
        console.error(error)
        let responseMessage
        try {
          responseMessage = JSON.parse(error.responseText).message
        } catch (error) {
          console.error(error)
        }
        this.setState({
          error: responseMessage
        })
      })
    }
  }

  toggleUploadForm (e) {
    e && e.preventDefault()
    this.setState(state => ({
      uploadActive: !state.uploadActive
    }))
  }

  changeApplicable (e) {
    let newValue
    this.setState(state => {
      newValue = !state.currentlyApplicable
      return {
        currentlyApplicable: newValue,
        uploadActive: false
      }
    }, () => {
      // project save change
      this.props.updateProject({ analysisApplicable: newValue })
        .done((response) => {
        })
        .fail(function (error) {
          console.error(error)
        })
    })
  }

  render () {
    const { name, file } = this.props
    let fileObject
    if (file) { fileObject = file.file }
    const applicable = this.props.applicable
    const currentlyApplicable = this.state.currentlyApplicable
    return (
      <div className="ProjectDesign-file-row">
        <div className={`ProjectDesign-tick ${fileObject ? 'ProjectDesign-tick-active' : ''}`}>
          <span className="glyphicons glyphicons-tick"></span>
        </div>
        <div className="ProjectDesign-control">
          <span className="ProjectDesign-name">
            <span>{name}</span>
            {applicable &&
              <div className="ProjectDesign-applicable-checkbox">
                <label htmlFor={`${name}-applicable`}>
                  <T i18nKey="project:applicable">van toepassing</T>:&nbsp;
                                </label>
                <input id={`${name}-applicable`} type="checkbox" checked={currentlyApplicable} onChange={this.changeApplicable} />
              </div>
            }
          </span>
          <div className="ProjectDesign-file-container">
            {applicable && !currentlyApplicable && <div className="ProjectDesign-file-disabled"></div>}
            {this.state.uploadActive
              ? <FileUpload
                newFile={this.state.newFile}
                type={this.props.type}
                token={this.props.token}
                project={this.props.project}
                reloadProject={this.props.reloadProject}
                toggleUploadForm={this.toggleUploadForm}
                oldFileId={fileObject && fileObject._id}
                user={this.props.user}
              />
              : (fileObject
                  ? <>
                  <span className="ProjectDesign-file-name">{fileObject.fileName}</span>
                  <span className="ProjectDesign-file-date">{getDate(fileObject)}</span>
                  <span className="ProjectDesign-file-user">{(fileObject.uploaded_by || {}).name || ''}</span>
                  <span className="ProjectDesign-file-download"><a href={`${location.origin}/v1/file/${fileObject._id}/-1/${fileObject.fileName}`}><T i18nKey="common:download">Download</T></a></span>
                  <span className="ProjectDesign-file-menu"><QuickActions showNewVersionFileForm={this.toggleUploadForm} deleteFile={this.deleteFile} /></span>
                </>
                  : <>
                  <span className="ProjectDesign-input-message"><T i18nKey="project:no_file_message" values={{ name: this.props.name }}>{`Geen ${this.props.name} toegevoegd`}</T></span>
                  <button className="ProjectDesign-input-button" onClick={this.toggleUploadForm}>
                    <span className="glyphicons glyphicons-cloud-upload"></span>
                    <span>{this.props.name}</span>
                  </button>
                </>
                )}
          </div>
        </div>
      </div>
    )
  }
}

class QuickActions extends Component {
  constructor (props) {
    super(props)

    this.toggleQuickactions = this.toggleQuickactions.bind(this)
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  render () {
    return (
      <>
        <a onClick={this.toggleQuickactions} href="#" className="quickAction">
          <span className="glyphicons glyphicons-more"></span>
        </a>
        <div className="quickAction_tip" style={{ display: 'none' }}>
          <div className="quickAction_tip_arrow"></div>
          <div className="quickAction_tip_inner">
            <ul>
              <li>
                <a href="#" onClick={this.props.showNewVersionFileForm}><T i18nKey="project:upload_new_version">Nieuwe versie uploaden</T></a>
              </li>
              <li>
                <a href="#" onClick={this.props.deleteFile}><T i18nKey="project:delete_file">Bestand verwijderen</T></a>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

const FileRow = withTranslation()(FileRowView)
export default FileRow

function getDate (file) {
  return moment(file.customDate || file.updated_at || file.created_at).format('DD-MM-YYYY')
}
