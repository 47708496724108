import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'underscore'
import { hasPermissionFor, getSystem } from './../../tools.jsx'
import location from '../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class QuickActions extends Component {
  constructor (props) {
    super(props)

    this.state = {
      planned: Object.prototype.hasOwnProperty.call(this.props.project, 'planning')
    }

    this.toggleQuickactions = this.toggleQuickactions.bind(this)
    this.toggleBoostProject = this.toggleBoostProject.bind(this)
    this.addToPlanning = this.addToPlanning.bind(this)
    this.unmute = this.unmute.bind(this)
  }

  stopPropagation (event) {
    event.stopPropagation()
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  toggleBoostProject (event) {
    this.props.toggleBoostProject()
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  addToPlanning () {
    const project = this.props.project
    const projectId = project._id
    const planningData = {
      user: this.props.user._id,
      project: projectId,
      planned_date: moment().startOf('d').toISOString(),
      visit_by: project.muted || project.next_visit
    }

    const postPlanningSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(planningData)
    }

    $.ajax(postPlanningSettings).done(response => {
      $(window).trigger('updatePlanningSlider')

      this.props.updateProject({ planning: response._id })
        .done((response) => {
          console.info(response)
        })
        .fail(function (error) {
          console.error(error)
        })
    }).fail(error => {
      console.error(error)
    })

    this.setState({
      planned: true
    })
  }

  unmute (e) {
    e.preventDefault()
    this.props.updateProject({
      $unset: {
        muted: true,
        muteComment: true
      }
    })
      .done((response) => {
        // console.info(response);
        window.dispatchEvent(new CustomEvent('filterClearProjects'))
        window.dispatchEvent(new CustomEvent('reloadProjectCount'))
      })
      .fail(function (error) {
        console.error(error)
      })
  }

  render () {
    const project = this.props.project
    const projectId = project._id
    const isPlanned = this.state.planned
    const isAdmin = this.props.user.role.match('ADMIN')

    let userIsVC = false
    _.each(project.users, (user) => {
      if ((user.type === 'vcExecution' || user.type === 'vcDesign') && user.user._id === this.props.user._id) {
        userIsVC = true
      }
    })

    let addToPlanningLink = null
    if (!isPlanned && userIsVC && project.project_vc_passed_status !== 'pending') {
      addToPlanningLink = (
        <li>
          <a onClick={this.addToPlanning} href="#"><T i18nKey="project:add_to_planning">Toevoegen aan mijn planning</T></a>
        </li>
      )
    }
    let linkToMutePage = null
    if (this.props.mute && hasPermissionFor(this.props.user.role, 'EMPLOYEE')) {
      linkToMutePage = (
        <li>
          <Link to={`/mutewerf/${projectId}`}><T i18nKey="project:mute">Muten</T></Link>
        </li>
      )
    }

    let hideMostButtons
    if (project.status === 'design' || project.status === 'finished' || project.status === 'onHold') { hideMostButtons = true }
    if (hideMostButtons) {
      addToPlanningLink = null
      linkToMutePage = null
    }

    let unmute
    if (project.muted) {
      unmute = <li>
        <a onClick={this.unmute} href="#"><T i18nKey="project:remove_mute">Mute weghalen</T></a>
      </li>
    }

    const ssTarget = getSystem(project.site_id)

    return (
      <td onClick={this.stopPropagation} className="quickAction_cel">
        <a id={`quickActionsAnchor${projectId}`} onClick={this.toggleQuickactions} href="#" className="quickAction">
          <span className="glyphicons glyphicons-more"></span>
        </a>
        <div className="quickAction_tip" style={{ display: 'none' }}>
          <div className="quickAction_tip_arrow"></div>
          <div className="quickAction_tip_inner">
            <ul>
              {addToPlanningLink}
              {linkToMutePage}
              {unmute}

              {!hideMostButtons && isAdmin &&
                <li key="boostLi">
                  <a onClick={this.toggleBoostProject} href="#">{this.props.boosted
                    ? <T i18nKey="project:remove_boost">Boost weghalen</T>
                    : <T i18nKey="project:boost">Boosten</T>
                  }</a>
                </li>
              }

              {ssTarget !== getSystem('') &&
                <li>
                  <a href={project.site_id} target="_blank" rel="noopener noreferrer"><T i18nKey="project:open_in_target" values={{ ssTarget }}>{`Openen in ${ssTarget}`}</T></a>
                </li>
              }
            </ul>
          </div>
        </div>
      </td>
    )
  }
}
