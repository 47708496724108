import React, { Component } from 'react'
import _ from 'underscore'
import moment from 'moment'
import location from '../../location'
import { Trans as T } from 'react-i18next'
const $ = window.$

export class PassProjectPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      newVC: '',
      newVCName: '',
      project: {},
      comment: '',
      loading: false
    }

    this.goBack = this.goBack.bind(this)
    this.submitChange = this.submitChange.bind(this)
    this.changeComment = this.changeComment.bind(this)
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    const token = this.props.token.token

    // get project
    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.match.params.id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`
      }
    }).done((projectResponse) => {
      if (!this.isUnmounting) {
        document.title = `Coördinatiedagboek  - Werf doorgeven - ${projectResponse.ss_name || projectResponse.project_name || ''}`
        this.setState({
          project: projectResponse
        })
      }
    })

    $('.veiligheidsCoordinator').select2({
      ajax: {
        async: true,
        crossDomain: true,
        url: function (params) {
          return `${location.origin}/v1/user?search=${encodeURIComponent(params.term)}&vc=true`
        },
        headers: {
          authorization: `Bearer ${token}`
        },
        delay: 250,
        processResults: function (data) {
          data = $.map(data, function (object) {
            object.id = object._id
            object.text = object.name
            return object
          })
          return {
            results: data
          }
        },
        cache: true
      },
      escapeMarkup: function (markup) {
        return markup
      },
      minimumInputLength: 3,
      templateResult: function (client) {
        if (client.loading) return client.text
        const markup = client.name
        return markup
      },
      templateSelection: function (client) {
        return client.text
      }
    })

    $('#veiligheidsCoordinator1').on('change', (event) => {
      setTimeout(() => {
        const newVC = event.currentTarget.value
        const newVCName = $(event.currentTarget).find('option').last()[0].innerText
        this.setState({
          newVC,
          newVCName
        })
      }, 500)
    })
  }

  goBack () {
    this.props.history.goBack()
  }

  submitChange (event) {
    event.preventDefault()

    this.setState({
      loading: true
    })

    const newVC = this.state.newVC
    if (newVC) {
      const token = this.props.token.token
      const project = this.state.project
      const projectId = project._id

      const projectPostData = {
        project_vc_passed_status: 'pending'
      }

      // if project_vc_passed_status === 'passed'
      // remove vc3 planning object
      // if ( project.planning ) {
      //     let url = `${location.origin}/v1/planning`;
      //     if ( project.project_vc_passed_status === 'passed' ) {
      //         const planning_id = this.props.location.query.planning_id;
      //         if ( !planning_id ) {
      //             return console.error('no planning_id in query');
      //         }
      //         url += `?id=${planning_id}`;
      //     } else {
      //         url += `?project=${project._id}`;
      //     }
      //     $.ajax({
      //         "async": true,
      //         "crossDomain": true,
      //         "url": url,
      //         "method": "DELETE",
      //         "headers": {
      //             "authorization": `Bearer ${token}`,
      //         }
      //     }).done(response => {
      //         $(window).trigger('updatePlanningSlider');
      //     }).fail(error => {
      //         console.error(error);
      //         this.setState({error});
      //     });
      //     projectPostData.planning = null;
      // }

      let successCounter = 0

      // projectPostData.users = _.clone(project.users);

      // remove any old vc3 and passingUser entries
      projectPostData.users = _.filter(project.users, function (user) {
        return user.type !== 'vc3' && user.type !== 'passingUser'
      })

      projectPostData.users.push({
        type: 'vc3',
        user: newVC
      })
      projectPostData.users.push({
        type: 'passingUser',
        user: this.props.user._id
      })

      projectPostData.passProjectComment = this.state.comment

      const preparedProjectPostData = {
        $set: projectPostData,
        $unset: {
          planning: true
        }
      }

      // set project_vc_passed_status to pending
      $.ajax({
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/project/${projectId}`,
        method: 'PUT',
        headers: {
          authorization: `Bearer ${token}`,
          'content-type': 'application/json'
        },
        processData: false,
        data: JSON.stringify(preparedProjectPostData)
      }).done((response) => {
        successCounter++
        if (successCounter === 2) {
          this.goBack()
        }
      })

      // sendmail
      const newVCAjax = $.ajax({
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/user/${newVC}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        }
      })
      newVCAjax.done(response => {
        const payload = {
          template: 'werfdoorgeven',
          to: [{
            email: response.email,
            name: response.name,
            type: 'to'
          }],
          global_merge_vars: [
            {
              name: 'project_name',
              content: this.state.project.ss_name || this.state.project.project_name
            },
            {
              name: 'afas_id',
              content: this.state.project.afas_id
            },
            {
              name: 'next_visit',
              content: moment(this.state.project.muted || this.state.project.next_visit).format('DD-MM-YYYY')
            },
            {
              name: 'from_user',
              content: this.props.user.firstname || this.props.user.name
            },
            {
              name: 'comment',
              content: this.state.comment
            }
          ]
        }

        $.ajax({
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/message/send_mail`,
          method: 'POST',
          headers: {
            authorization: `Bearer ${this.props.token.token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify(payload)
        }).done((response) => {
          successCounter++
          if (successCounter === 2) {
            this.goBack()
          }
        })
      })
      newVCAjax.fail(error => {
        console.error(error)
        this.setState({ error })
      })
    } // end newVC
  }

  changeComment (event) {
    const comment = event.target.value
    this.setState({ comment: comment })
  }

  render () {
    const project = this.state.project
    return (
      <section className="container form">
        { this.state.error
          ? <div><T i18nKey="project:project_pass_error">Er heeft zich een probleem ondervonden, propbeer opnieuw.</T></div>
          : null
        }
        <section className="info_block">
          <header>
            <h3><T i18nKey="project:project_to_pass">Werf door te geven</T></h3>
            <div>{project.afas_id} - {project.ss_name || project.project_name}</div>
          </header>
        </section>
        <T i18nKey="project:pass_project_to" values={{ name: this.state.newVCName || ': ' }}>Doorgeven aan {this.state.newVCName || ': '}.</T>
        <br />
        <T i18nKey="project:click_to_save">Klik op opslaan om toe te passen en een mail te versturen.</T>
        <form onSubmit={this.submitChange}>
          <fieldset className="form-group">
            <select className="form-control veiligheidsCoordinator" id="veiligheidsCoordinator1"></select>
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="comment"><T i18nKey="project:reason">Reden</T>:</label>
            <textarea className="form-control" id="comment" onChange={this.changeComment} value={this.state.comment}></textarea>
          </fieldset>
          {this.state.loading
            ? <button className="form-submit">
              <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
            </button>
            : <fieldset className="form-group">
              <input type="submit" value="Opslaan" className="form-submit" />
            </fieldset>
          }
        </form>
      </section>
    )
  }
}
