import React, { Component } from 'react'
import _ from 'underscore'
import location from '../../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

const formatResult = function (client) {
  if (client.loading) return client.text
  const markup = client.name
  return markup
}
const formatSelection = function (client) {
  return client.text
}

const initiateOrganisationDropdown = function () {
  const token = localStorage.getItem('authToken')
  $('.js-remote-organisation-data').select2({
    ajax: {
      async: true,
      crossDomain: true,
      url: function (params) {
        return `${location.origin}/v1/organisation?search=${encodeURIComponent(params.term)}`
      },
      headers: {
        authorization: `Bearer ${token}`
      },
      delay: 250,
      processResults: function (data) {
        data = $.map(data, function (object) {
          object.id = object._id
          object.text = object.name
          return object
        })
        return {
          results: data
        }
      },
      cache: true
    },
    escapeMarkup: function (markup) {
      return markup
    },
    minimumInputLength: 3,
    templateResult: formatResult,
    templateSelection: formatSelection
  })
}

const getClient = function (id, token, callback) {
  if (id) {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/organisation/${id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`
      }
    }

    $.ajax(settings).done((clientResponse) => {
      callback(clientResponse)
    })
  } else {
    callback()
  }
}

class CreateContactView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      contactData: {
        name: '',
        ss_role: '',
        email: '',
        phone: '',
        fax_number: '',
        organisation: {},
        ss_role_secondary: '',
        address: '',
        additional_info: ''
      },
      buttonDisabled: false,
      loading: false,
      error: '',
      // phoneCountry: 'BE',
      // faxCountry: 'BE',
      selectedRole: '--'
    }

    this.changePhoneValue = this.changePhoneValue.bind(this)
    this.changeFaxValue = this.changeFaxValue.bind(this)
    this.changeValue = this.changeValue.bind(this)
    this.changePhoneCountry = this.changePhoneCountry.bind(this)
    this.changeSSRole = this.changeSSRole.bind(this)
    this.createContact = this.createContact.bind(this)

    this.formRef = React.createRef()
  }

  componentDidMount () {
    initiateOrganisationDropdown()
    $('#organisation').on('change', (event) => {
      if (event.target.value) {
        setTimeout(() => {
          const contactData = _.clone(this.state.contactData)
          const organisation = contactData.organisation || {}
          const newClientId = event.target.value
          organisation.organisation_id = newClientId
          getClient(newClientId, this.props.token.token, (response) => {
            organisation.name = response.name
            contactData.organisation = organisation
            this.setState({
              contactData
            })
          })
        }, 500)
      }
    })
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  changePhoneValue (event) {
    this.setState({
      contactData: Object.assign({}, this.state.contactData, { phone: event.target.value })
    })
  }

  changeFaxValue (event) {
    this.setState({
      contactData: Object.assign({}, this.state.contactData, { fax_number: event.target.value })
    })
  }

  changeValue (key, event) {
    const contactData = this.state.contactData
    contactData[key] = event.currentTarget.value
    if (!this.isUnmounting) {
      this.setState({
        contactData
      })
    }
  }

  changePhoneCountry (event) {
    this.setState({
      phoneCountry: event.currentTarget.value
    })
  }

  changeSSRole (event) {
    const newRole = event.currentTarget.value || ''
    if (!this.isUnmounting) {
      this.setState(state => ({
        contactData: Object.assign({}, state.contactData, { ss_role: newRole }),
        selectedRole: newRole
      }))
    }
  }

  createContact (event) {
    event.preventDefault()
    const t = this.props.t
    this.setState({
      buttonDisabled: true,
      loading: true,
      error: null
    })
    const siteUrl = (this.props.project.site_id || '').split('/')
    const host = (siteUrl[2] || '').split('.')[1] || ''
    const siteId = siteUrl[4] ? parseInt(siteUrl[4]) : false
    const requestData = this.state.contactData
    for (const key in requestData) {
      if ((key !== 'phone' && key !== 'organisation' && key !== 'fax_number' && key !== 'ss_role_secondary' && key !== 'address' && key !== 'additional_info') && !requestData[key]) {
        this.setState({
          buttonDisabled: false,
          loading: false,
          error: t('project:add_contact_error_empty_fields')
        })
        return
      }
    }
    requestData.site_id = siteId
    requestData.archi = host === 'archisnapper'

    if (requestData.organisation) { requestData.company_name = requestData.organisation.name }

    if (!siteId) {
      this.setState(state => ({
        error: t('project:add_contact_error_unsaved_project')
      }))
      return
    }

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/user/create_ss_contact`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(requestData)
    }
    const createContactPromise = $.ajax(settings)
    createContactPromise.done(response => {
      this.props.updateProject(response, this.state.contactData.ss_role)
        .done(response => {
          this.props.reloadProject()
          $('.js-remote-organisation-data').val(null).trigger('change')

          this.formRef.current.reset()

          const mailUser = window.confirm(t('project:send_mail_to_user'))
          if (mailUser) {
            const payload = {
              template: 'nieuwepartner',
              to: [
                {
                  email: requestData.email,
                  name: requestData.name,
                  type: 'to'
                }
              ],
              global_merge_vars: [{
                name: 'afas_id',
                content: this.props.project.afas_id
              },
              {
                name: 'project_name',
                content: this.props.project.ss_name || this.props.project.project_name
              }]
            }

            $.ajax({
              async: true,
              crossDomain: true,
              url: `${location.origin}/v1/message/send_mail`,
              method: 'POST',
              headers: {
                authorization: `Bearer ${this.props.token.token}`,
                'content-type': 'application/json'
              },
              processData: false,
              data: JSON.stringify(payload)
            }).done(function (response) {
              // console.log(response);
            }).fail(error => {
              console.error(error)
              this.setState(state => ({
                buttonDisabled: false,
                loading: false,
                error: error.responseJSON ? error.responseJSON.message : error.responseText
              }))
            })
          }

          $('.js-remote-organisation-data').select2('val', '')
          this.props.goBackToListView()
        }).fail(error => {
          console.error(error)
          this.setState({
            buttonDisabled: false,
            loading: false,
            error: error.responseJSON ? error.responseJSON.message : error.responseText
          })
        })
    })
    createContactPromise.fail((error) => {
      console.error(error)
      if (error.status === 409) {
        this.props.submitContact(null, this, error.responseJSON.user)
      } else {
        this.setState({
          buttonDisabled: false,
          loading: false,
          error: error.responseJSON ? error.responseJSON.message : error.responseText
        })
      }
    })
  }

  render () {
    const contactData = this.state.contactData
    return (
      <div>
        <h3><T i18nKey="project:add_new_user">Voeg nieuwe gebruiker toe</T></h3>
          <form ref={this.formRef} onSubmit={this.createContact}>
            <fieldset className="form-group">
                <label htmlFor="name"><T i18nKey="project:name">Naam</T> <span className="required"></span></label>
                <input type="text" className="form-control" id="name" value={contactData.name} onChange={this.changeValue.bind(this, 'name')}/>
            </fieldset>
            <fieldset className="form-group">
                <label htmlFor="email"><T i18nKey="project:email">Email</T> <span className="required"></span></label>
                <input type="email" className="form-control" id="email" value={contactData.email} onChange={this.changeValue.bind(this, 'email')}/>
            </fieldset>
            <fieldset className="form-group">
                <label htmlFor="organisation"><T i18nKey="project:company">Bedrijf</T></label>
                <select className="form-control js-remote-organisation-data" id="organisation">
                </select>
            </fieldset>
            <fieldset className="form-group">
                <label htmlFor="addressInput"><T i18nKey="project:address">Adres</T></label><br/>
                <input type="test" className="form-control" id="addressInput" onChange={this.changeValue.bind(this, 'address')} value={contactData.address}/>
            </fieldset>
            <fieldset className="form-group">
                <label htmlFor="roleInputCreate"><T i18nKey="project:role_function">Rol (Functie)</T> <span className="required"></span></label>
                <select value={this.state.selectedRole} onChange={this.changeSSRole} className="form-control" id="roleInputCreate">
                    <option>--</option>
                    {this.props.ssRoles.roleOptionElements}
                </select>
            </fieldset>
            <fieldset className="form-group">
                <label htmlFor="secondaryRoleInputCreate"><T i18nKey="project:subrole">Subrol</T></label>
                <input type="text" className="form-control" id="secondaryRoleInputCreate" value={contactData.ss_role_secondary} onChange={this.changeValue.bind(this, 'ss_role_secondary')}/>
            </fieldset>
            <fieldset className="form-group">
            {/* <fieldset className="form-group" style={{width:"76%",float:'left'}}> */}
                <label htmlFor="phone"><T i18nKey="project:mobile">GSM nr</T></label><br/>
                <input type="test" className="form-control" id="phone" onChange={this.changePhoneValue} value={this.state.contactData.phone}/>
            </fieldset>
            <fieldset className="form-group">
            {/* <fieldset className="form-group" style={{width:"76%",float:'left'}}> */}
                <label htmlFor="fax"><T i18nKey="project:fax">Fax nr</T></label><br/>
                <input type="test" className="form-control" id="fax" onChange={this.changeFaxValue} value={this.state.contactData.fax_number}/>
            </fieldset>
            <fieldset className="form-group">
                <label htmlFor="additional_infoInput"><T i18nKey="project:extra_information">Extra informatie</T></label><br/>
                <textarea id="additional_infoInput" onChange={this.changeValue.bind(this, 'additional_info')} value={contactData.additional_info} style={{
                  width: '100%', minHeight: 60
                }} >
                </textarea>
            </fieldset>
              <div className="clear"></div>
              {this.state.error
                ? <span className="message warning">
                      {this.state.error}
                  </span>
                : null}
              <fieldset className="form-group">
                  <button type="submit" className="form-submit" disabled={this.state.buttonDisabled}>
                      {this.state.loading
                        ? <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                        : null}
                      &nbsp;<T i18nKey="common:save">Opslaan</T>
                  </button>
              </fieldset>
          </form>
      </div>
    )
  }
}

const CreateContact = withTranslation()(CreateContactView)
export { CreateContact }
