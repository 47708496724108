import React, { Component } from 'react'
import _ from 'underscore'
import moment from 'moment'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

let reloadProjectsInterval

export class RightPlanningSlideView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      show: false,
      planningObjects: [],
      error: false
    }

    this.getProjects = this.getProjects.bind(this)
    this.hideSideBar = this.hideSideBar.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    const shouldUpdate = !_.isEqual(nextState, this.state)
    return shouldUpdate
  }

  componentDidMount () {
    $(window).on('togglePlanningSlider', (event) => {
      if (this.state.show) {
        this.setState({
          show: false
        })
      } else {
        this.setState({
          show: true
        })
      }
    }).on('updatePlanningSlider', (event) => {
      this.getProjects()
    })

    this.getProjects()
    reloadProjectsInterval = setInterval(this.getProjects, 1000 * 60 * 2)

    $(window).click(function (e) {
      if (this.state.show && $(e.target).parents('.slider').length === 0) {
        this.hideSideBar()
      }
    }.bind(this))
  }

  componentWillUnmount () {
    $(window).off('togglePlanningSlider')
    clearInterval(reloadProjectsInterval)
    this.isUnmounting = true
  }

  getProjects () {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning?user=${this.props.user._id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    $.ajax(settings).done(planningObjects => {
      if (!this.isUnmounting) {
        this.setState({
          planningObjects: this.sortProjects(planningObjects)
        })
      }
    }).fail(error => {
      this.setState({ error })
    })
  }

  sortProjects (planningObjects) {
    const sortedPlanning = {}
    let x = 14
    const someDay = moment().startOf('d')
    let formattedDay
    while (x !== 0) {
      x--
      // if not sunday
      if (someDay.day() !== 0) {
        formattedDay = someDay.format('YYYY-MM-DD')
        sortedPlanning[formattedDay] = []
      }

      someDay.add(1, 'd')
    }

    planningObjects.forEach(planningObject => {
      let projectPlannedDate = moment(planningObject.planned_date).startOf('d')
      const today = moment().startOf('d')
      if (projectPlannedDate.isBefore(today)) { // if the project was planned in the past move it to today
        projectPlannedDate = today.format('YYYY-MM-DD')
      } else {
        projectPlannedDate = projectPlannedDate.format('YYYY-MM-DD')
      }

      sortedPlanning[projectPlannedDate] = sortedPlanning[projectPlannedDate] || []
      sortedPlanning[projectPlannedDate].push(planningObject)
    })

    return sortedPlanning
  }

  hideSideBar () {
    this.setState({
      show: false
    })
  }

  render () {
    const showClass = this.state.show ? 'slider open' : 'slider close'

    const days = _.map(this.state.planningObjects, (plannings, key, list) => {
      return <DayView key={key} refreshProjects={this.getProjects} token={this.props.token} date={key} plannings={plannings} />
    })

    return (
      <div className={showClass} >
        <div className="slider_header">
          <T i18nKey="planning:my_planning">Mijn planning</T>
          <span className="slider_close glyphicons glyphicons-remove" onClick={this.hideSideBar}></span>
        </div>
        <div className="slider_content">
          <div className="slider_content_inside">
            {days}
          </div>
        </div>
      </div>
    )
  }
}

class DayView extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    if (_.isEqual(this.props, nextProps)) {
      return false
    }
    return true
  }

  render () {
    const plannings = this.props.plannings || []

    const projectRows = plannings.map(planning => {
      return (
        <ProjectView token={this.props.token} planning={planning} key={planning._id} />
      )
    })

    const date = moment(this.props.date).format('dd, D MMM')

    return (
      <table className='planning_table'>
        <thead>
          <tr>
            <th>{date}</th>
          </tr>
        </thead>
        <tbody>
          {projectRows}
        </tbody>
      </table>
    )
  }
}

class ProjectViewComp extends Component {
  render () {
    const project = this.props.planning.project
    let projectName = this.props.t('project:nameless_project')
    if (project.ss_name || project.project_name) {
      projectName = project.ss_name || project.project_name
      const cleanName = projectName.search('&amp;')
      if (cleanName > 0) {
        projectName = projectName.slice(0, cleanName).trimRight()
      }
    }
    if (projectName.length > 60) {
      projectName = projectName.slice(0, 55) + '...'
    }

    return (
      <tr>
        <td>
          {projectName}
        </td>
      </tr>
    )
  }
}
const ProjectView = withTranslation()(ProjectViewComp)
