import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './ProjectDetails.css'
import { createLabel } from '../../reactUtils/labelFactory.jsx'
import moment from 'moment'
import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
import { hasPermissionFor } from '../../../tools.jsx'
import Mustache from 'mustache'

const $ = window.$

function getTemplates (token, cb) {
  const settings = {
    async: true,
    crossDomain: true,
    url: `${location.origin}/v1/message/template`,
    method: 'GET',
    headers: {
      authorization: `Bearer ${token.token}`
    }
  }
  const getTemplatesPromise = $.ajax(settings)
  getTemplatesPromise.done(templates => {
    cb(null, templates)
  })
  getTemplatesPromise.fail(error => {
    cb(error)
  })
}

const createServiceLabels = function (services) {
  services = services || ''
  const seperator = services.match(/[+,-]/)
  if (seperator) {
    services = services.split(seperator[0])
    services.forEach((service, index) => {
      services[index] = service.trim()
    })
  } else {
    services = [services]
  }
  const labels = []
  services.forEach(service => {
    labels.push(<span key={service} className={'label services'}>{service}</span>)
  })
  return labels
}

class ProjectDetailsView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      templatesError: null
    }

    this.mapRef = React.createRef()
    this.mailToRef = React.createRef()

    this.showQuickActions = this.showQuickActions.bind(this)
    this.handleMailClick = this.handleMailClick.bind(this)
    this.getVc = this.getVc.bind(this)

    this.map = null
    this.marker = null
  }

  componentDidMount () {
    if (this.props.project && this.props.project.geo && this.props.project.geo.lat) {
      this.initMap()
    }
  }

  initMap () {
    const map = new window.google.maps.Map(this.mapRef.current, {
      center: { lat: 51.071174, lng: 4.989230 },
      zoom: 15,
      disableDefaultUI: true,
      disbleDoubleClickZoom: true,
      keyboardShortcuts: false,
      scrollwheel: false,
      draggable: false
    })
    this.map = map
    map.setOptions({
      styles:
        [{
          stylers: [{ saturation: -100 }]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ color: '#0099dd' }]
        },
        {
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [{ color: '#aadd55' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text',
          stylers: [{ visibility: 'on' }]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text',
          stylers: [{ visibility: 'on' }]
        }]
    })

    const project = this.props.project
    const projectGeo = project.geo || { lat: 50.9303538, lng: 5.330906899999945 }

    map.setCenter({
      lat: projectGeo.lat,
      lng: projectGeo.lng
    })
    this.marker = new window.google.maps.Marker({
      position: { lat: projectGeo.lat, lng: projectGeo.lng },
      map,
      title: project.ss_name
    })
  }

  showQuickActions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  handleMailClick (name) {
    return e => {
      const handleTemplates = (error, templates) => {
        if (error) {
          console.error(error)
          this.setState({ templatesError: error })
        }
        const vc = this.getVc()
        let template
        /* eslint-disable */
        switch (name) {
          case 'new':
            template = templates.find(template => template.name === 'nieuwe_werf') || { messageTemplate: '', subjectTemplate: '' }
            break
          case 'status':
            template = templates.find(template => template.name === 'werf_stand_v_zaken') || { messageTemplate: '', subjectTemplate: '' }
            break
          default: template = { messageTemplate: '', subjectTemplate: '' }
        }
        /* eslint-enable */
        const renderedSubject = Mustache.render(template.subjectTemplate, { user: vc, project: this.props.project })
        const rendered = Mustache.render(template.messageTemplate, { user: vc, project: this.props.project })
        const mailUri = `mailto:${vc.email}?subject=${encodeURIComponent(renderedSubject)}&body=${encodeURIComponent(rendered)}`
        const mailTo = this.mailToRef.current
        if (mailTo) {
          mailTo.href = mailUri
          mailTo.click()
        }
      }
      getTemplates(this.props.token, handleTemplates)
    }
  }

  getVc () {
    const project = this.props.project
    let vcUser
    const users = project.users
    if (project.status === 'design') {
      vcUser = users.find(user => user.type === 'vcDesign')
    } else {
      vcUser = users.find(user => user.type === 'vcExecution')
    }
    if (vcUser) vcUser = vcUser.user
    return vcUser || {}
  }

  render () {
    const project = this.props.project
    const t = this.props.t

    let projectName = project.ss_name || project.project_name || t('project:nameless_project')
    const ampIndex = projectName.search('&amp;')
    if (~ampIndex) {
      projectName = projectName.slice(0, ampIndex).trimRight()
    }

    const label = createLabel(project.type)
    const serviceLabels = createServiceLabels(project.services)

    let location1 = '-'
    let location2 = '-'
    const address = project.address
    if (project.address && project.address.city) {
      location1 = `${address.street || ''} ${address.housenumber || ''} ${address.housenumber_extension || ''}`
      location2 = `${address.zipcode || ''} ${address.city || ''}`
    }
    const rsznumber = project.RSZ_n__Werf

    const startDate = moment(project.start_date).format('DD-MM-YYYY')
    const finishedDate = project.finished_date ? moment(project.finished_date).format('DD-MM-YYYY') : false
    const createPidStartDate = project.createPidStartDate ? moment(project.createPidStartDate).format('DD-MM-YYYY') : false

    const vcUser = this.getVc()

    const imgUrl = `${location.origin}${vcUser.profile_picture_thumbnail_url || '/images/default_user_thumbnail.png'}`

    const userRole = this.props.user.role
    let editButton
    /* eslint-disable */
    switch (userRole) {
      case 'ADMIN+':
      case 'ADMIN':
      case 'EMPLOYEE': editButton = (
        <Link to={`/werf_wizard/${project._id}`} className="edit">
          <span className="glyphicons glyphicons-pencil"></span>
        </Link>
      )
        break
      default: // editButton remains undefined
    }
    /* eslint-enable */

    return (

      <div className="ProjectDetails-info-block">
        <a target="_blank" rel="noreferrer noopener" href="mailto:" style={{ display: 'none' }} ref={this.mailToRef}>mailto</a>
        {editButton}
        <h3>
          <span>{projectName}</span>
          <div className="ProjectDetails-info-block-label">{label}</div>
          <span className="ProjectDetails-tags">{serviceLabels}</span>
        </h3>
        <div className="ProjectDetails-body">
          <div className="ProjectDetails-left">
            <div className="ProjectDetails-map" ref={this.mapRef}></div>
            <div className="ProjectDetails-address">
              <span>{location1}</span>
              <span>{location2}</span>
              {rsznumber &&
                <span><T i18nKey="project:rsz_number">RSZ Nr</T>: {rsznumber}</span>
              }
            </div>
          </div>
          <div className="ProjectDetails-right">
            <div className="ProjectDetails-info">
              <div>
                <div>
                  <span className="ProjectDetails-info-header"><T i18nKey="project:client">Klant</T>:</span>
                  <span className="ProjectDetails-info-text">{project.client_name}</span>
                </div>
                <div>
                  <span className="ProjectDetails-info-header"><T i18nKey="project:client_number">Bestek-Nr_Klant</T>:</span>
                  <span className="ProjectDetails-info-text">{project['Bestek-Nr_Klant'] || '-'}</span>
                </div>
              </div>
              <div>
                <div>
                  <span className="ProjectDetails-info-header"><T i18nKey="project:projectgroup">Projectgroep</T>:</span>
                  <span className="ProjectDetails-info-text">{project.project_group || '-'}</span>
                </div>
                <div>
                  <span className="ProjectDetails-info-header"><T i18nKey="project:start_date">Startdatum</T>:</span>
                  <span className="ProjectDetails-info-text">{startDate}</span>
                </div>
              </div>
              <div>
                <div>
                  <span className="ProjectDetails-info-header"><T i18nKey="project:project_number_short">Project-nr</T>:</span>
                  <span className="ProjectDetails-info-text">{project.afas_id}</span>
                </div>
                <div>
                  {finishedDate &&
                    <>
                      <span className="ProjectDetails-info-header"><T i18nKey="project:end_date">Einddatum</T>:</span>
                      <span className="ProjectDetails-info-text">{finishedDate}</span>
                    </>
                  }
                </div>
              </div>
              <div>
                <div>
                  {createPidStartDate &&
                    <>
                      <span className="ProjectDetails-info-header"><T i18nKey="project:create_pid_start_date">Begin opmaak PID</T>:</span>
                      <span className="ProjectDetails-info-text">{createPidStartDate}</span>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="ProjectDetails-vc">
              <div className="ProjectDetails-vc-header">
                <T i18nKey="project:safety_coordinator">Veiligheidsco&ouml;rdinator</T>
                {hasPermissionFor(this.props.user.role, 'ADMIN+') &&
                  <div style={{ position: 'relative' }}>
                    <button onClick={this.showQuickActions} className="quickAction btn-green btn-low">
                      <T i18nKey="project:mail_vc">Mail VC</T>
                    </button>
                    <div className="quickAction_tip" style={{ display: 'none' }}>
                      <div className="quickAction_tip_arrow"></div>
                      <div className="quickAction_tip_inner ProjectDetails-mail-buttons">
                        <button onClick={this.handleMailClick('new')}><T i18nKey="project:new_project">Nieuwe werf</T></button>
                        <button onClick={this.handleMailClick('status')}><T i18nKey="project:project_status">Stand van zaken</T></button>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="ProjectDetails-vc-details-container">
                <div className="ProjectDetails-vc-details-image">
                  <img src={imgUrl} alt="Veiligheidsco&ouml;rdinator" />
                </div>
                <div className="ProjectDetails-vc-details">
                  <span>{vcUser.name || ''}</span>
                  <span>{vcUser.email || ''}</span>
                  <span>{vcUser.phone || ''}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

const ProjectDetails = withTranslation()(ProjectDetailsView)
export default ProjectDetails
