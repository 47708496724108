import React from 'react'
import { ProjectGroupTableBodyRow } from './projectGroupTableBodyRow.jsx'
import { Trans as T, withTranslation } from 'react-i18next'

export const ProjectGroupTableView = props => {
  const titel = props.t('planning:projects') + ` ${props.name}`
  const projectList = props.projects.map(project => {
    return <ProjectGroupTableBodyRow due={props.due} key={project._id} project={project} {...props} />
  })
  return (
    <section>
      <h3 className="planning-table-title">{titel}</h3>
      <table className="list_table">
        <thead>
          <tr>
            <th><T i18nKey="project:title">Titel</T></th>
            <th style={{ width: '25px' }}></th>
            <th style={{ width: '30px' }}><T i18nKey="project:vc">VC</T></th>
            <th style={{ width: '150px' }}><T i18nKey="project:latest_visit">Laatst bezocht</T></th>
            <th style={{ width: '26px' }}></th>
          </tr>
        </thead>
        <tbody className={props.addClass}>
          {projectList}
        </tbody>
      </table>
    </section>
  )
}

const ProjectGroupTable = withTranslation()(ProjectGroupTableView)
export { ProjectGroupTable }
