import React from 'react'
import i18n from './i18n.js'

export function getIntervalDays (type) {
  switch (type) {
    case 'interval_1week': return 7
    case 'interval_2weeks': return 2 * 7
    case 'interval_3weeks': return 3 * 7
    case 'interval_1month': return 4 * 7
    case 'interval_6weeks': return 6 * 7
    case 'interval_3x8months': return 8 * 7
    default:
      console.warn(`unknown type in getIntervalDays: ${type}`)
      return 0
  }
}

export function getWeekNumFromInterval (type) {
  switch (type) {
    case 'interval_1week': return 1
    case 'interval_2weeks': return 2
    case 'interval_3weeks': return 3
    case 'interval_1month': return 4
    case 'interval_6weeks': return 6
    default:
      console.warn(`unknown type in getWeekNumFromInterval: ${type}`)
      return ''
  }
}

export function formatInterval (type) {
  switch (type) {
    case 'interval_1week': return i18n.t('common:1_week')
    case 'interval_2weeks': return i18n.t('common:2_weeks')
    case 'interval_3weeks': return i18n.t('common:3_weeks')
    case 'interval_1month': return i18n.t('common:1_month')
    case 'interval_6weeks': return i18n.t('common:6_weeks')
    case 'interval_3x8months': return i18n.t('common:3_per_8_months')
    default:
      console.warn(`unknown type in formatInterval: ${type}`)
      return ''
  }
}

export function calcNextDateByType (type, prevDate) {
  switch (type) {
    case 'interval_1week': return new Date(prevDate + 7 * 24 * 60 * 60 * 1000)
    case 'interval_2weeks': return new Date(prevDate + 2 * 7 * 24 * 60 * 60 * 1000)
    case 'interval_3weeks': return new Date(prevDate + 3 * 7 * 24 * 60 * 60 * 1000)
    case 'interval_1month': return new Date(prevDate + 4 * 7 * 24 * 60 * 60 * 1000)
    case 'interval_6weeks': return new Date(prevDate + 6 * 7 * 24 * 60 * 60 * 1000)
    case 'interval_3x8months': return new Date(prevDate + 8 * 7 * 24 * 60 * 60 * 1000)
    default:
      console.warn(`unknown type in calcNextDateByType: ${type}`)
  }
}

export function getIntervalOptions () {
  return [
    <option key="1" value="interval_1week">{i18n.t('common:1_week')}</option>,
    <option key="2" value="interval_2weeks">{i18n.t('common:2_weeks')}</option>,
    <option key="3" value="interval_3weeks">{i18n.t('common:3_weeks')}</option>,
    <option key="4" value="interval_1month">{i18n.t('common:1_month')}</option>,
    <option key="5" value="interval_6weeks">{i18n.t('common:6_weeks')}</option>,
    <option key="6" value="interval_3x8months">{i18n.t('common:3_per_8_months')}</option>
  ]
}
