import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
import { xmlRequest } from '../../../tools.jsx'
const $ = window.$

export class MyPlanning extends Component {
  constructor (props) {
    super(props)

    this.state = {
      show: false,
      planningObjects: {},
      error: false
    }

    this.sortProjects = this.sortProjects.bind(this)
  }

  componentWillMount () {
    const sortedProjects = this.sortProjects(this.props.projects)
    this.setState(state => ({
      planningObjects: sortedProjects
    }))
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.projects.length !== this.props.projects.length) {
      const sortedProjects = this.sortProjects(nextProps.projects)
      this.setState(state => ({
        planningObjects: sortedProjects
      }))
    }
  }

  sortProjects (planningObjects) {
    const sortedPlanning = {}
    let x = 14
    const someDay = moment().startOf('d')
    let formattedDay
    while (x !== 0) {
      x--
      // if not sunday
      if (someDay.day() !== 0) {
        formattedDay = someDay.format('YYYY-MM-DD')
        sortedPlanning[formattedDay] = []
      }

      someDay.add(1, 'd')
    }

    planningObjects.forEach(planningObject => {
      let projectPlannedDate = moment(planningObject.planned_date).startOf('d')
      const today = moment().startOf('d')
      if (projectPlannedDate.isBefore(today)) { // if the project was planned in the past move it to today
        projectPlannedDate = today.format('YYYY-MM-DD')
      } else {
        projectPlannedDate = projectPlannedDate.format('YYYY-MM-DD')
      }

      sortedPlanning[projectPlannedDate] = sortedPlanning[projectPlannedDate] || []
      sortedPlanning[projectPlannedDate].push(planningObject)

      // if (planningObject.muted) {
      //     sortedPlanning.muted = sortedPlanning.muted || []
      //     sortedPlanning.muted.push(planningObject)
      // } else if (difference < 0) {
      //     sortedPlanning.late = sortedPlanning.late || []
      //     sortedPlanning.late.push(planningObject)
      // } else {
      //     sortedPlanning[difference] = sortedPlanning[difference] || []
      //     sortedPlanning[difference].push(planningObject)
      // }
    })

    return sortedPlanning
  }

  refreshProjects () {
    const refreshProjectsEvent = new CustomEvent('filterReloadProjects')
    window.dispatchEvent(refreshProjectsEvent)
  }

  render () {
    if (this.props.loading) {
      return <section className="planning-table my-planning">
        <table className="list_table">
          <tbody>
            <tr>
              <td><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
            </tr>
          </tbody>
        </table>
      </section>
    }

    /* single table
            special tr for datesk;lsdj;alsjkdATESDATESDATES
        */
    const days = _.map(this.state.planningObjects, (plannings, key, list) => {
      const tableRows = []
      tableRows.push(<DateTR key={key + '_head'} plannings={this.props.projects} refreshProjects={this.refreshProjects} token={this.props.token} date={key} />)
      plannings.forEach((planning) => {
        tableRows.push(<ProjectView history={this.props.history} date={key} token={this.props.token} planning={planning} key={planning._id} />)
      })
      return tableRows
    })
    return (
      <section className="planning-table my-planning">
        <table className="list_table">
          <tbody>
            {days}
          </tbody>
        </table>
      </section>
    )
  }
}

class ProjectViewComp extends Component {
  constructor (props) {
    super(props)

    this.throwBackInPool = this.throwBackInPool.bind(this)
    this.toggleQuickactions = this.toggleQuickactions.bind(this)
    this.gotoProject = this.gotoProject.bind(this)
    this.passProject = this.passProject.bind(this)
  }

  componentDidMount () {
    const t = this.props.t
    const planning = this.props.planning
    const project = planning.project
    const projectName = project.ss_name || project.project_name
    // planning draggable
    $(`#draggablePlanning_${planning._id}`).draggable({
      containment: '.list_table',
      cursor: '-webkit-grabbing',
      cursorAt: {
        left: 10,
        top: 5
      },
      distance: 25,
      appendTo: '#app_container',
      helper: function () {
        const p = `<p class="project_dragger">${projectName} <span class="label red">${t('planning:overdue')}</span><span class="label green">${t('planning:ok')}</span></p>`
        return p
      }
    })
  }

  throwBackInPool (e) {
    e.stopPropagation()
    e.preventDefault()

    const planning = this.props.planning
    const project = planning.project
    const token = this.props.token.token

    if (project.project_vc_passed_status === 'passed') {
      this.props.history.push(`/werf_doorgeven/${project._id}?planning_id=${planning._id}`)
      return
    }

    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning?id=${planning._id}`,
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`
      }
    }).done(response => {
      $(window).trigger('updatePlanningSlider')
    }).fail(error => {
      console.error(error)
    })

    const projectUpdateSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${project._id}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify({ planning: null })
    }

    $.ajax(projectUpdateSettings).done(response => {
      window.dispatchEvent(new CustomEvent('filterReloadProjects'))
    }).fail(error => {
      console.error(error)
    })
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200/* , () => {
            working = false
        } */)
  }

  gotoProject (event) {
    this.props.history.push(`/werf/${this.props.planning.project._id}`)
  }

  passProject (event) {
    event.stopPropagation()
    event.preventDefault()
    this.props.history.push(`/werf_doorgeven/${this.props.planning.project._id}?planning_id=${this.props.planning._id}`)
  }

  render () {
    const t = this.props.t
    const project = this.props.planning.project
    let projectName = t('project:nameless_project')
    if (project.ss_name || project.project_name) {
      projectName = project.ss_name || project.project_name
      const cleanName = projectName.search('&amp;')
      if (cleanName > 0) {
        projectName = projectName.slice(0, cleanName).trimRight()
      }
    }
    if (projectName.length > 80) {
      projectName = projectName.slice(0, 75) + '...'
    }

    // class for droppable to compare to
    const nextVDate = project.muted || project.next_visit
    const formattedNextVisit = moment(nextVDate).format('YYYY-MM-DD')

    const plannedDate = this.props.date
    const formattedNextVisitLabelColor = moment(plannedDate).startOf('d').isAfter(moment(nextVDate).startOf('d')) ? 'red' : 'green'

    const passedProject = (project.project_vc_passed_status === 'passed')

    return (
      <tr id={`draggablePlanning_${this.props.planning._id}`} onClick={this.gotoProject} data-date={formattedNextVisit} data-id={this.props.planning._id}>
        <td>
          {projectName}
        </td>
        <td>
          <span className={`label ${formattedNextVisitLabelColor}`}>
            {formattedNextVisit}
          </span>
        </td>
        <td className="quickAction_cel" style={{ width: 16 }}>
          <a id={`quickActionsAnchor${project._id}`} onClick={this.toggleQuickactions} href="#" className="quickAction">
            <span className="glyphicons glyphicons-more"></span>
          </a>
          <div className="quickAction_tip" style={{ display: 'none' }}>
            <div className="quickAction_tip_arrow"></div>
            <div className="quickAction_tip_inner">
              <ul>
                {!passedProject
                  ? <li>
                    <a onClick={this.throwBackInPool} href="#"><T i18nKey="planning:remove_from_my_planning">Weghalen uit mijn planning</T></a>
                  </li>
                  : null
                }
                <li>
                  <a onClick={this.passProject} href="#"><T i18nKey="planning:pass">Doorgeven</T></a>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}
const ProjectView = withTranslation()(ProjectViewComp)

class DateTR extends Component {
  constructor (props) {
    super(props)

    this.rowRef = React.createRef()

    this.dragChange = this.dragChange.bind(this)
  }

  componentDidMount () {
    const $planningRow = $(this.rowRef.current)
    // planning droppable
    $planningRow.droppable({
      // accept: '.draggablePlanning',
      tolerance: 'pointer',
      activate: (event, ui) => {
        // $planningRow.addClass('reveal-droppable')
      },
      deactivate: (event, ui) => {
        $('.project_dragger').removeClass('ok')
        $('.project_dragger').removeClass('notok')
      },
      over: (event, ui) => {
        $('.project_dragger').removeClass('ok')
        $('.project_dragger').removeClass('notok')
        const myDate = moment(this.props.date, 'YYYY-MM-DD')
        const yourDate = moment($(ui.draggable).data('date'), 'YYYY-MM-DD')
        const isBeforeMyDate = yourDate.isBefore(myDate)

        let newClass = ''
        if (!isBeforeMyDate) {
          newClass = 'ok'
        } else {
          newClass = 'notok'
        }
        $('.project_dragger').addClass(newClass)
      },
      // out: (event, ui) => {
      //     $('.project_dragger').removeClass('ok')
      //     $('.project_dragger').removeClass('notok')
      // },
      drop: (event, ui) => {
        this.dragChange($(ui.draggable).data('id'))
      },
      hoverClass: 'dropOnMe'
    })
  }

  dragChange (planningId) {
    const thisPlanning = this.props.plannings.find(({ _id }) => _id === planningId)
    const thisProject = thisPlanning.project || {}

    const newDate = {
      planned_date: new Date(this.props.date)
    }

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning?id=${planningId}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(newDate)
    }

    const updateWhenDone = () => {
      this.props.refreshProjects()
      // trigger reload of planning in de planning slider
      $(window).trigger('updatePlanningSlider')
    }

    $.ajax(settings).done(response => {
      // if project has no visits -> set new start date
      if (!(thisProject.visits || []).length) {
        xmlRequest(`${location.origin}/v1/project/${thisProject._id}`, this.props.token.token, { start_date: moment(newDate.planned_date).format('YYYY-MM-DD') }, 'PUT')
          .then(response => {
            updateWhenDone()
          }).catch(error => {
            console.error(error)
          })
      } else {
        updateWhenDone()
      }
    }).fail(error => {
      console.error(error)
    })
  }

  render () {
    const date = moment(this.props.date).format('dd, D MMM')
    return (
      <tr ref={this.rowRef} className="planning_date">
        <td colSpan="3">
          {date}
        </td>
        {/* <td colSpan="2">
                    Te bezoeken tegen
                </td> */}
      </tr>
    )
  }
}
