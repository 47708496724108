import 'url-search-params-polyfill'
import './customEventPolyfill.js'
// import './logger.js'

import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import './i18n.js'

import './index.css'
import './css/logs.css'
import './css/main.css'
import './css/tools.css'
import './css/variables.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'select2/dist/css/select2.min.css'

import App from './app.jsx'

const RouterApp = withRouter(App)

const AppRouter = () => (
  <BrowserRouter>
    <RouterApp />
  </BrowserRouter>
)

ReactDOM.render(<AppRouter />, document.getElementById('app_container'))
