import React from 'react'
import { ProjectsMain } from './projectsMain.jsx'

export const PlannedProjectsOverview = props => {
  if (props.loading) {
    return <section className="planning-table planned-table">
      <table className="list_table">
        <tbody>
          <tr>
            <td className="table-loader"><span className="glyphicons glyphicons-restart glyphicons-spin"></span></td>
          </tr>
        </tbody>
      </table>
    </section>
  }
  return (
    <ProjectsMain history={props.history} planningObjects={props.projects} user={props.user} token={props.token} loading={props.loading} />
  )
}
