import React, { Component } from 'react'
import location from '../../location'
import { Trans as T } from 'react-i18next'
import { xmlGetRequest, xmlRequest } from '../../tools.jsx'

export class PidMailView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      name: '',
      error: null,
      saved: false
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    this.getCurrentEmail()
  }

  async getCurrentEmail () {
    try {
      const resp = await xmlGetRequest(`${location.origin}/v1/message/pid_email`, this.props.token.token)
      this.setState(state => resp)
    } catch (error) {
      return this.setState(state => ({ error }))
    }
  }

  handleInputChange (name) {
    return e => {
      const newValue = e.target.value
      this.setState(state => ({ [name]: newValue, saved: false, error: null }))
    }
  }

  async handleSubmit (e) {
    e.preventDefault()
    const toSave = {
      email: this.state.email,
      name: this.state.name
    }
    try {
      await xmlRequest(`${location.origin}/v1/message/pid_email`, this.props.token.token, toSave)
      this.setState(state => ({ saved: true }))
    } catch (error) {
      this.setState(state => ({ error }))
    }
  }

  render () {
    return (
      <section className="right_content">
        {this.state.error ? <h1>{this.state.error}</h1> : ''}
        <h1><T i18nKey="settings:pid_email_input_description">Email van de persoon naar wie een mail wordt gestuurd wanneer een project naar de 'PID aanmaken' status gezet is.</T></h1>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="email"><T i18nKey="common:email">Email</T></label>
            <input id="email" type="email" onChange={this.handleInputChange('email')} value={this.state.email} />
          </div>
          <div>
            <label htmlFor="name"><T i18nKey="settings:name">Naam</T></label>
            <input id="name" type="name" onChange={this.handleInputChange('name')} value={this.state.name} />
          </div>
          <button type="submit"><T i18nKey="common:save">Opslaan</T></button>
        </form>
        {this.state.saved ? <p><T i18nKey="common:saved">Opgeslagen</T></p> : ''}
      </section>
    )
  }
}
