import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import { deleteFileFromProject, hasPermissionFor } from './../../../tools.jsx'
import { FileUploadForm } from '../../reactUtils/fileUploadForm.jsx'

import DatePicker from 'react-datepicker'
import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

export class CDView extends Component {
  constructor (props) {
    super(props)

    const files = _.filter(this.props.project.files, function (file) {
      return !file.isDeleted
    })
    this.state = {
      files: files,
      fileLength: files.length,
      finished: this.props.project.status === 'finished',
      loading: false,
      showFileForm: false
    }

    this.updateFiles = this.updateFiles.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
    this.showFileForm = this.showFileForm.bind(this)
    this.showNewVersionFileForm = this.showNewVersionFileForm.bind(this)
    this.closeFileForm = this.closeFileForm.bind(this)

    this.cdTableRef = React.createRef()
  }

  componentWillReceiveProps (nextProps) {
    const files = _.filter(nextProps.project.files, function (file) {
      return !file.isDeleted
    })
    this.setState({
      files: files,
      fileLength: files.length
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const $table = $(this.cdTableRef.current)
    $table.hide(0, function () {
      $table.show()
    })
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  updateFiles () {
    this.setState({
      loading: true,
      showFileForm: false
    })
    $.ajax({
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.project._id}?select=files`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }).done((projectResponse) => {
      if (!this.isUnmounting) {
        const files = _.filter(projectResponse.files, function (file) {
          return !file.isDeleted
        })
        this.setState({
          files: files,
          fileLength: files.length,
          loading: false
        })
        this.props.reloadProject()
      }
    }).fail((error) => {
      console.error(error)
      let responseMessage
      try {
        responseMessage = JSON.parse(error.responseText).message
      } catch (error) {
        console.error(error)
      }
      this.setState({
        error: responseMessage,
        loading: false
      })
    })
  }

  deleteFile (event) {
    event.preventDefault()
    event.stopPropagation()

    const confirmed = window.confirm(this.props.t('project:confirm_delete'))
    const fileId = $(event.currentTarget).data('id')
    const projectId = this.props.project._id
    const token = this.props.token.token

    if (confirmed) {
      deleteFileFromProject(fileId, token, projectId).done((response) => {
        console.log('done')
        this.updateFiles()
      }).fail((error) => {
        console.error(error)
        let responseMessage
        try {
          responseMessage = JSON.parse(error.responseText).message
        } catch (error) {
          console.error(error)
        }
        this.setState({
          error: responseMessage
        })
      })
    }
  }

  showFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: true
    })
  }

  showNewVersionFileForm (event) {
    event.preventDefault()
    this.setState({
      showFileForm: true,
      newFileVersion: false,
      oldFileId: $(event.currentTarget).data('id')
    })
  }

  closeFileForm (event) {
    if (event) {
      event.preventDefault()
    }
    this.setState({
      showFileForm: false
    })
  }

  handleFactToggle (visit) {
    return event => {
      const project = this.props.project
      let visitInvoicesStatus
      if (visit === 'all') {
        const allVisitsAreInvoiced = project.visitInvoicesStatus && project.visitInvoicesStatus.length && project.visitInvoicesStatus.every(item => item.invoiced)
        visitInvoicesStatus = project.visits.map(visit => ({
          visit_id: visit.visit_id.toString(),
          invoiced: !allVisitsAreInvoiced
        }))
      } else {
        visitInvoicesStatus = new Map(project.visitInvoicesStatus.map(item => [item.visit_id.toString(), item]))
        visitInvoicesStatus.set(visit.visit_id.toString(), {
          visit_id: visit.visit_id.toString(),
          invoiced: event.target.checked
        })
        visitInvoicesStatus = [...visitInvoicesStatus.values()]
      }

      this.setState(state => ({ savingInvoiceStatus: true }))
      this.props.updateProject({ visitInvoicesStatus })
        .done(async () => {
          await this.props.reloadProject()
          this.setState(state => ({ savingInvoiceStatus: false }))
        })
        .fail((error) => {
          console.error(error)
          this.setState(state => ({ savingInvoiceStatus: false }))
        })
    }
  }

  render () {
    const t = this.props.t
    const project = this.props.project
    const files = this.state.files
    const finished = this.state.finished
    const loading = this.state.loading
    const isAdminPlus = hasPermissionFor(this.props.user.role, 'ADMIN+')
    const hasInvoicedVisits = project.hasInvoicedVisits
    const visitInvoicesStatus = new Map(project.visitInvoicesStatus.map(item => [item.visit_id, item]))
    const savingInvoiceStatus = this.state.savingInvoiceStatus

    const reportsList = project.visits.map((report, index, array) => {
      let reportHasBeenSent = report.report_mailings || []
      reportHasBeenSent = reportHasBeenSent.slice(-1)[0]
      let url
      let fileName = t('project:no_filename')
      if (reportHasBeenSent) {
        let file = _.find(files, function (file) {
          return parseInt(file.visit_id) === parseInt(report.visit_id)
        })
        if (file) {
          try {
            file = file.file
            fileName = file.fileName
            url = <td><a target="_blank" rel="noopener noreferrer" href={`${location.origin}/v1/file/${file._id}/-1/${fileName}`}><T i18nKey="common:download">Download</T></a></td>
          } catch (error) {
            url = <td><T i18nKey="project:not_available_yet">Nog niet beschikbaar</T></td>
          }
        } else {
          url = <td><T i18nKey="project:not_available_yet">Nog niet beschikbaar</T></td>
        }
      } else {
        url = <td><T i18nKey="project:no_file_available_yet">Nog geen bestand beschikbaar</T></td>
      }
      // format date
      let visitDate = report.date
      if (visitDate) {
        visitDate = moment(visitDate).format('DD-MM-YYYY')
      }

      let filename = fileName
      if (filename.length > 55) { filename = filename.slice(0, 55) + '...' }

      return ({
        date: moment(report.date),
        row:
                    <tr key={report.visit_id}>
                        <td>{filename}</td>
                        <td>{visitDate}</td>
                        <UserTD user={report.creator} token={this.props.token} source={report.__source || 'safetysnapper'}/>
                        {url}
                        { isAdminPlus && hasInvoicedVisits &&
                          <td><input disabled={savingInvoiceStatus || finished} type="checkbox" checked={(visitInvoicesStatus.get(report.visit_id + '') || {}).invoiced || false} onChange={this.handleFactToggle(report)} /></td>
                        }
                        <td></td>
                    </tr>
      })
    })

    // Get a list of all user uploaded reports
    const remainingFiles = _.filter(files, function (file) {
      return file.file_type === 'pid_type8_userupload' || (file.file_type === 'pid_type8' && file.deleted)
    })

    // Combine reports and uploaded user reports in one array and sort on date
    const reportsAndFilesList = _.sortBy(reportsList.concat(remainingFiles), function (item) {
      const date = (item.date) ? item.date.valueOf() : (item.file.customDate || item.file.updated_at)
      return -date
    })

    // Generate the react components for all the files en reports
    const completeFilesList = []
    const that = this
    _.each(reportsAndFilesList, (item) => {
      if (item.row) {
        completeFilesList.push(item.row)
      } else {
        const file = item
        completeFilesList.push(
                    <FileView
                        key={file._id}
                        showNewVersionFileForm={that.showNewVersionFileForm}
                        user={that.props.user}
                        file={file}
                        deleteFile={that.deleteFile}
                        finished={that.state.finished}
                        token={this.props.token}
                        updateFiles={this.updateFiles}
                        hasInvoicedVisits={hasInvoicedVisits}
                    />
        )
      }
    })

    let allVisitsAreInvoiced = false
    if (hasInvoicedVisits) {
      allVisitsAreInvoiced = project.visitInvoicesStatus && project.visitInvoicesStatus.length && project.visitInvoicesStatus.every(item => item.invoiced)
    }

    return (
            <section className="right_content">
                <h1>
                    <T i18nKey="project:coordination_diary">Coördinatiedagboek</T>
                    {!finished
                      ? <button className="btn-green btn-low right" onClick={this.showFileForm}>
                        <span className="glyphicons glyphicons-cloud-upload"></span>
                        &nbsp;&nbsp;&nbsp; <T i18nKey="project:upload_document">Upload document</T>
                        </button>
                      : null}
                </h1>

                {this.state.showFileForm
                  ? <FileUploadForm closeFileForm={this.closeFileForm} token={this.props.token} user={this.props.user} fileType={{ type: 'cd' }} project={this.props.project} newFile={this.state.newFileVersion} oldFileId={this.state.oldFileId} updateParentView={this.updateFiles} showTextButton={true} />
                  : null
                }

                <table ref={this.cdTableRef} className="list_table">
                    {completeFilesList.length !== 0 && !loading
                      ? [
                        <thead key="thead">
                            <tr>
                                <th><T i18nKey="project:filename">Bestandsnaam</T></th>
                                <th style={{ minWidth: 100 }}><T i18nKey="project:date">Datum</T></th>
                                <th style={{ minWidth: 150 }}><T i18nKey="project:uploader">Uploader</T></th>
                                <th style={{ minWidth: 80 }}><T i18nKey="common:download">Download</T></th>
                                {isAdminPlus && hasInvoicedVisits &&
                                  <th style={{ minWidth: 32 }} className="Project-info-cd-fact-toggle">
                                    Fact
                                    <input disabled={savingInvoiceStatus || finished} type="checkbox" checked={allVisitsAreInvoiced} onChange={this.handleFactToggle('all')} />
                                  </th>
                                }
                                <th style={{ minWidth: 32 }}></th>
                            </tr>
                        </thead>,
                        <tbody key="tbody">
                            {completeFilesList}
                        </tbody>
                        ]
                      : <tbody>
                        <tr className="no-hover">
                            {loading
                              ? <td>
                                    <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
                                </td>
                              : <td><T i18nKey="project:there_are_no_reports_yet">Er zijn nog geen verslagen.</T></td>
                            }
                        </tr>
                    </tbody>}
                </table>
            </section>
    )
  }
}

class UserTDView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: []
    }
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentWillMount () {
    if (this.props.user) {
      $.ajax({
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/user/small?email=${this.props.user}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.props.token.token}`
        }
      }).done((user) => {
        if (!this.isUnmounting) {
          this.setState({ user })
        }
      }).fail((error) => {
        console.error(error)
      })
    }
  }

  render () {
    const t = this.props.t
    let ss = '(SS)'
    if (this.props.source === 'safetysnapper') {
      ss = '(SS)'
    } else if (this.props.source === 'archisnapper') {
      ss = '(AS)'
    }
    return (
            <td>
                {this.state.user.length ? `${this.state.user[0].name} ${ss}` : (t('project:safety_coordinator') + ' ' + ss)}
            </td>
    )
  }
}
const UserTD = withTranslation()(UserTDView)

class FileView extends Component {
  componentDidMount () {
    window.addEventListener('closeDateChangers', this.setDateChangeStatusHidden)
  }

  componentWillUnmount () {
    window.removeEventListener('closeDateChangers', this.setDateChangeStatusHidden)
  }

  constructor (props) {
    super(props)

    this.state = {
      dateChangeOpen: false
    }

    this.toggleQuickactions = this.toggleQuickactions.bind(this)
    this.setDateChangeStatusVisible = this.setDateChangeStatusVisible.bind(this)
    this.setDateChangeStatusHidden = this.setDateChangeStatusHidden.bind(this)
  }

  stopPropagation (event) {
    event.stopPropagation()
  }

  toggleQuickactions (event) {
    this.hideQuickactions()
    event.stopPropagation()
    event.preventDefault()
    const next = $(event.currentTarget).next()
    let working = false
    if (!working) {
      working = true
      if (next.css('display') === 'none') {
        next.fadeIn(200, () => {
          working = false
        })
        $(window).on('click.quickActionsDisabler', (event) => {
          $(window).off('.quickActionsDisabler')
          next.fadeOut(200, () => {
            working = false
          })
        })
      } else {
        $(window).off('.quickActionsDisabler')
        next.fadeOut(200, () => {
          working = false
        })
      }
    }
  }

  hideQuickactions () {
    $(window).off('.quickActionsDisabler')
    $('.quickAction_tip').fadeOut(200)
  }

  setDateChangeStatusVisible (e) {
    e.stopPropagation()
    this.setState({ dateChangeOpen: true })
  }

  setDateChangeStatusHidden (e) {
    if (!e.detail || e.detail.toString() !== this.props.file.file._id.toString()) {
      this.setState({ dateChangeOpen: false })
    }
  }

  render () {
    const file = this.props.file.file
    const deleted = this.props.file.deleted
    const fileDate = file.customDate || file.updated_at
    const visitDate = moment(fileDate).format('DD-MM-YYYY')
    const uploader = file.uploaded_by || {}
    const isUploader = uploader._id === this.props.user._id
    let quickactions = <td></td>
    const isAdminPlus = hasPermissionFor(this.props.user.role, 'ADMIN+')
    const hasInvoicedVisits = this.props.hasInvoicedVisits

    let filename
    try {
      filename = window.decodeURIComponent(file.fileName)
    } catch (error) {
      filename = file.fileName
    }
    if (filename.length > 55) { filename = filename.slice(0, 55) + '...' }

    if ((isUploader || this.props.user.role.match('ADMIN')) && !this.props.finished) {
      quickactions = (
                <td onClick={this.stopPropagation} className="quickAction_cel">
                    <a onClick={this.toggleQuickactions} href="#" className="quickAction">
                        <span className="glyphicons glyphicons-more"></span>
                    </a>
                    <div className="quickAction_tip" style={{ display: 'none' }}>
                        <div className="quickAction_tip_arrow"></div>
                        <div className="quickAction_tip_inner">
                            <ul>
                                <li>
                                    <a data-id={file._id} onClick={this.props.showNewVersionFileForm} href="#"><T i18nKey="project:upload_new_version">Nieuwe versie uploaden</T></a>
                                </li>
                                <li>
                                    <a data-id={this.props.file._id} href="#" onClick={this.props.deleteFile}><T i18nKey="project:delete_file">Bestand verwijderen</T></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </td>
      )
    }
    return (
            <tr className="no-pointer" style={deleted ? { color: '#e45a5a', textDecoration: 'line-through', display: hasPermissionFor(this.props.user.role, 'FREELANCER') ? '' : 'none' } : {}}>
                <td>{filename}</td>
                <td onClick={this.props.user.role === 'ADMIN+' && !deleted ? this.setDateChangeStatusVisible : null}>
                    {visitDate}
                    {this.state.dateChangeOpen
                      ? <DateChanger
                            file={file}
                            updateFiles={this.props.updateFiles}
                            token={this.props.token}
                        />
                      : null}
                </td>
                <td>
                    {uploader.name}
                </td>
                { !deleted
                  ? <td><a target="_blank" rel="noopener noreferrer" href={`${location.origin}/v1/file/${file._id}/-1/${file.fileName}`}><T i18nKey="common:download">Download</T></a></td>
                  : <td></td>
                }
                { isAdminPlus && hasInvoicedVisits && // fact column is empty for files and only present for admin+ users
                  <td></td>
                }
                { !deleted
                  ? quickactions
                  : <td></td>
                }
            </tr>
    )
  }
}

class DateChanger extends Component {
  constructor (props) {
    super(props)

    this.state = {
      myId: `quickAction_tip_${this.props.file._id}`,
      date: moment(this.props.file.customDate || this.props.file.updated_at)
    }

    this.dispatchCloseEvent = this.dispatchCloseEvent.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount () {
    this.dispatchCloseEvent()
    // window.addEventListener('click', this.closeDateChangerIfOutside);
    // this.fadeMeIn();
  }

  // closeDateChangerIfOutside (e) {
  //     const clickedOutsideDateChanger = e.target.closest('quickAction_tip') ? false : true;
  //     const clickedOutsideDatePicker = e.target.closest('datepicker') ? false : true;
  //     if ( clickedOutsideDateChanger && clickedOutsideDatePicker ) {
  //         const customEvent = new CustomEvent('closeDateChangers');
  //         window.dispatchEvent(customEvent);
  //     }
  // },
  // componentWillUnmount () {
  //     window.removeEventListener('click', this.closeDateChangerIfOutside);
  // //     this.fadeMeOut();
  // },
  dispatchCloseEvent () {
    const customEvent = new CustomEvent('closeDateChangers', { detail: this.props.file._id })
    window.dispatchEvent(customEvent)
  }

  // fadeMeIn () {
  //     console.log('fade in');
  //     const myView = $(this.state.myId);
  //     myView.fadeIn(200, () => {
  //         working = false;
  //     });
  // },
  // fadeMeOut () {
  //     console.log('gotta close!');
  //     const myView = $(this.state.myId);
  //     myView.fadeOut(200, () => {
  //         working = false;
  //     });
  // },
  handleSubmit (e) {
    e.preventDefault()

    const file = this.props.file

    this.dispatchCloseEvent()

    const newValue = this.state.date
    const date = moment(file.customDate || file.updated_at).startOf('d')

    if (!date.isSame(newValue.startOf('d'))) {
      const settings = {
        async: true,
        crossDomain: true,
        url: `${location.origin}/v1/file/edit/${file._id}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.props.token.token}`
        },
        processData: false,
        contentType: false,
        data: JSON.stringify({ customDate: newValue.format('YYYY-MM-DD') })
      }

      const promise = $.ajax(settings)

      promise.done((response) => {
        this.props.updateFiles()
        const customEvent = new CustomEvent('closeDateChangers')
        window.dispatchEvent(customEvent)
      })
      promise.fail(function (error) {
        console.error(error)
        const customEvent = new CustomEvent('closeDateChangers')
        window.dispatchEvent(customEvent)
      })
    }
  }

  render () {
    const startDate = this.state.date.isValid() ? this.state.date.toDate() : null
    return (
            <div className={`${this.state.myId} quickAction_tip`}>
            {/* <div className={this.state.myId} style={{display:"none"}}> */}
                <div className="quickAction_tip_arrow"></div>
                <div className="quickAction_tip_inner">
                    <form onSubmit={this.handleSubmit}>
                        <fieldset className="form-group form-group-date-picker">
                            <label htmlFor="date"><T i18nKey="project:date">Datum</T></label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                id="date"
                                selected={startDate}
                                onChange={date => this.setState({ date })}
                                className="form-control"
                                placeholderText="Klik om een datum te selecteren"
                                isClearable={true}
                            />
                        </fieldset>
                        <fieldset className="form-group">
                            <button type="submit" className="form-submit"><T i18nKey="common:save">Opslaan</T></button>
                        </fieldset>
                    </form>
                </div>
            </div>
    )
  }
}
