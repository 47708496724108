import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import i18n from '../../i18n.js'
import './header.css'
import { version } from '../../../package.json'

import { SearchBar } from './searchbar.jsx'
import { UserTool } from './usertool.jsx'
import Language from './Language.js'

const $ = window.$

export class Header extends Component {
  constructor (props) {
    super(props)

    this.state = {
      forceShow: false
    }
  }

  componentWillMount () {
    $(window).on('showSearchbar', () => this.setState({ forceShow: true }))
    $(window).on('hideSearchbar', () => this.setState({ forceShow: false }))
  }

  componentWillUnmount () {
    $(window).off('showSearchbar')
    $(window).off('hideSearchbar')
  }

  render () {
    const path = this.props.location.pathname.split('/')[1]
    let showSearchBar = this.state.forceShow
    if (path === 'gebruikers' || path === 'organisaties' || path === 'projects_ref') {
      showSearchBar = true
    }

    let headerLink = '/'
    if (this.props.user) {
      const role = this.props.user.role
      switch (role) {
        case 'EMPLOYEE':
        case 'FREELANCER': headerLink = '/planning'
          break
        default:
      }
    }

    return (
      <header>
        <div className="top_bar dd_bar">
          <div className="left">
            <Link className="top_bar_brand" to={headerLink}>
              <img src="/images/coordinatiedagboek_logo.png" alt={i18n.t('header:logo')} />
              <span className="version">{`V${version}`}</span>
              {!this.props.production &&
                <span className="version" style={{ color: '#FF2000' }}><b>{window.location.hostname === 'localhost' ? 'DEV' : 'STAGING'}</b></span>
              }
            </Link>
          </div>
          <div className="header_language">
            <Language />
          </div>
          {this.props.user
            ? <UserTool history={this.props.history} token={this.props.token} user={this.props.user} />
            : ''}
          {this.props.user && showSearchBar
            ? <SearchBar location={this.props.location} />
            : ''}
        </div>
      </header>
    )
  }
}
