import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import waterfall from 'async-es/waterfall'

import { createLabel } from '../../reactUtils/labelFactory.jsx'

import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

export class PassedProjectGroupTableBodyRow extends Component {
  constructor (props) {
    super(props)

    this.gotoProject = this.gotoProject.bind(this)
  }

  gotoProject (event) {
    this.props.history.push(`/werf/${event.currentTarget.id}`)
  }

  expandPicture (event) {
    const $target = $(event.currentTarget)
    $target.addClass('active')
    const alt = $target.find('img')[0].alt
    const newDiv = $('<div>').html(alt).addClass('profile_pic_name')
    $(event.currentTarget).before(newDiv)
  }

  withdrawPicture (event) {
    const $target = $(event.currentTarget)
    $target.removeClass('active')
    $target.prev('.profile_pic_name').remove()
  }

  render () {
    const project = this.props.project
    let projectSpecialStatusIcon = null
    if (project.boosted) {
      projectSpecialStatusIcon = <span className="glyphicons glyphicons-warning-sign red"></span>
    } else if (project.muted) {
      projectSpecialStatusIcon = <span className="label blue"><T i18nKey="planning:delayed">Uitgesteld</T></span>
    }
    let projectName = this.props.t('project:nameless_project')
    if (project.ss_name || project.project_name) {
      projectName = project.ss_name || project.project_name
      const cleanName = projectName.search('&amp;')
      if (cleanName > 0) {
        projectName = projectName.slice(0, cleanName).trimRight()
      }
    }
    if (projectName.length > 78) {
      projectName = projectName.slice(0, 73) + '...'
    }

    let passedProject
    let isPassedToCurrentUser = false
    project.users.forEach((user) => {
      if (user.type === 'vc3') {
        if (user.user._id === this.props.user._id) {
          isPassedToCurrentUser = true
          passedProject = _.find(project.users, function (user) {
            return user.type === 'passingUser'
          })
          if (passedProject) {
            passedProject = passedProject.user.name
          }
        } else {
          passedProject = user.user.name
        }
      }
    })

    passedProject = (
      <td>
        {passedProject}
      </td>
    )

    let specialAction
    const pvpassedStatus = project.project_vc_passed_status
    if (pvpassedStatus === 'passed') {
      specialAction = <td><T i18nKey="planning:accepted">Aangenomen</T></td>
    } else if (pvpassedStatus === 'pending') {
      if (isPassedToCurrentUser) {
        specialAction = <PassProjectButtons project={project} token={this.props.token} />
      } else {
        specialAction = <td><T i18nKey="planning:sent">Verstuurd</T></td>
      }
    }

    return (
      <tr key={project._id} id={project._id} onClick={this.gotoProject}>
        <td>{projectSpecialStatusIcon} {projectName}
        </td>
        <td style={{ textAlign: 'right', paddingRight: '0' }}>{createLabel(project.type)}</td>
        {passedProject}
        {specialAction}
      </tr>
    )
  }
}

class PassProjectButtonsView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentStatus: 'pending'
    }

    this.handleResponse = this.handleResponse.bind(this)
    this.changeProjectStatus = this.changeProjectStatus.bind(this)
  }

  handleResponse (response, e) {
    e.stopPropagation()
    const project = this.props.project
    const projectId = project._id
    const token = this.props.token.token
    this.setState({
      currentStatus: 'mail'
    })
    const projectData = {}

    if (response === 'yes') {
      projectData.project_vc_passed_status = 'passed'
    } else {
      projectData.project_vc_passed_status = null
    }

    waterfall([
      function (callback) {
        if (response === 'yes') {
          const vc3 = _.find(project.users, function (user) {
            return user.type === 'vc3'
          })
          // add to planning
          const postPlanningSettings = {
            async: true,
            crossDomain: true,
            url: `${location.origin}/v1/planning`,
            method: 'POST',
            headers: {
              authorization: `Bearer ${token}`,
              'content-type': 'application/json'
            },
            processData: false,
            data: JSON.stringify({
              user: vc3.user._id,
              project: projectId,
              planned_date: moment().startOf('d').toISOString(),
              visit_by: project.muted || project.next_visit,
              project_was_passed: true
            })
          }
          $.ajax(postPlanningSettings)
            .done(response => {
              callback(null, response)
            }).fail(error => {
              callback(error)
            })
        } else {
          // response = 'no'
          Promise.all([
            new Promise((resolve, reject) => {
              $.ajax({
                async: true,
                crossDomain: true,
                url: `${location.origin}/v1/project/${projectId}/removeUser`,
                method: 'PUT',
                headers: {
                  authorization: `Bearer ${token}`,
                  'content-type': 'application/json'
                },
                processData: false,
                data: JSON.stringify({
                  type: 'vc3'
                })
              }).done(response => {
                resolve()
              }).fail(error => {
                reject(error)
              })
            }),
            new Promise((resolve, reject) => {
              $.ajax({
                async: true,
                crossDomain: true,
                url: `${location.origin}/v1/project/${projectId}/removeUser`,
                method: 'PUT',
                headers: {
                  authorization: `Bearer ${token}`,
                  'content-type': 'application/json'
                },
                processData: false,
                data: JSON.stringify({
                  type: 'passingUser'
                })
              }).done(response => {
                resolve()
              }).fail(error => {
                reject(error)
              })
            })
          ]).then(callback(null, null)).catch(callback)
        } // end if 'yes' or 'no'
      }, // waterfall 1
      function (result, callback) {
        if (result) {
          projectData.planning = result._id
        }
        // set project_vc_passed_status to new value
        $.ajax({
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/project/${projectId}`,
          method: 'PUT',
          headers: {
            authorization: `Bearer ${token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify(projectData)
        }).always(function () {
          callback()
        })
      }, // waterfall 2
      function (callback) {
        // sendmail

        const passingUser = _.find(project.users, function (user) {
          return user.type === 'passingUser'
        })
        const vc3 = _.find(project.users, function (user) {
          return user.type === 'vc3'
        })

        const payload = {
          template: 'werfaangenomen',
          to: [{
            email: passingUser.user.email || '',
            name: passingUser.user.name || '',
            type: 'to'
          }],
          global_merge_vars: [
            {
              name: 'status',
              content: response === 'yes' ? 'aangenomen' : 'niet aangenomen'
            },
            {
              name: 'project_name',
              content: project.ss_name || project.project_name
            },
            {
              name: 'afas_id',
              content: project.afas_id
            },
            {
              name: 'to_user',
              content: vc3.user.firstname || vc3.user.name
            }
          ]
        }

        $.ajax({
          async: true,
          crossDomain: true,
          url: `${location.origin}/v1/message/send_mail`,
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
            'content-type': 'application/json'
          },
          processData: false,
          data: JSON.stringify(payload)
        }).always(function () {
          callback()
        })
      } // waterfall 3
    ], (error) => {
      if (error) {
        console.error(error)
        this.setState({
          error
        })
        return
      }
      this.changeProjectStatus(response)
    })
  }

  changeProjectStatus (result) {
    this.setState({
      currentStatus: result
    })
  }

  render () {
    const t = this.props.t
    if (this.state.error) {
      return (
        <td className="btnAction_cel">
          <T i18nKey="planning:generic_error">Er is iets misgelopen</T>
        </td>
      )
    }
    const currentStatus = this.state.currentStatus
    let view
    if (currentStatus === 'pending') {
      view = [
        <button className="btn-green" key="yes" onClick={this.handleResponse.bind(this, 'yes')}><T i18nKey="common:yes">ja</T></button>,
        <button className="btn-red" key="no" onClick={this.handleResponse.bind(this, 'no')}><T i18nKey="common:no">nee</T></button>
      ]
    } else if (currentStatus === 'mail') {
      view = t('planning:mailing')
    } else if (currentStatus === 'yes') {
      view = t('planning:project_accepted')
    } else {
      view = t('planning:project_not_accepted')
    }
    return (
      <td className="btnAction_cel">
        {view}
      </td>
    )
  }
}
const PassProjectButtons = withTranslation()(PassProjectButtonsView)
