import { SecondaryInfo } from '../projectSecondaryInfo/secondary_info.jsx'

import ProjectDetails from './ProjectDetails.js'
import ProjectDesign from './ProjectDesign.js'
import ProjectPlanning from './ProjectPlanning.js'
import ProjectNotes from './ProjectNotes.js'

import React, { Component } from 'react'
import moment from 'moment'
import { hasPermissionFor } from '../../../tools.jsx'
import location from '../../../location'
import { Trans as T, withTranslation } from 'react-i18next'
const $ = window.$

class ProjectView extends Component {
  constructor (props) {
    super(props)

    let hasCRUDPermissions = false
    /* eslint-disable */
    switch (this.props.user.role) {
      case 'ADMIN+':
      case 'ADMIN':
      case 'EMPLOYEE':
      case 'FREELANCER': hasCRUDPermissions = true
        break
      default:
        hasCRUDPermissions = false
    }
    /* eslint-enable */
    this.state = {
      project: {
        _id: '',
        client_id: null,
        client_name: null,
        afas_id: null,
        project_name: null,
        ss_name: null,
        visits: [],
        type: null,
        services: null,
        status: null,
        start_date: null,
        address: {},
        geo: {},
        users: [],
        files: []
      },
      planned: false,
      error: {},
      loading: true,
      hasCRUDPermissions,
      comment: '',
      pidComment: '',
      contracted_visits: '',
      contracted_duration: '',
      savingInProgress: {
        comment: false,
        pidComment: false,
        contracted_visits: false,
        contracted_duration: false
      }
    }

    this.reloadProject = this.reloadProject.bind(this)
    this.fetchProject = this.fetchProject.bind(this)
    this.addToPlanning = this.addToPlanning.bind(this)
    this.updateProject = this.updateProject.bind(this)
    this.changeCommentField = this.changeCommentField.bind(this)
  }

  componentWillMount () {
    document.title = this.props.t('title:project')
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    this.fetchProject()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.reloadProject()
    }
  }

  reloadProject () {
    return this.fetchProject()
  }

  fetchProject () {
    if (!this.props.match.params.id) {
      return
    }
    const t = this.props.t

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${this.props.match.params.id}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    return new Promise((resolve, reject) => {
      $.ajax(settings).done((projectResponse) => {
        if (!this.isUnmounting) {
          document.title = t('title:project') + ` - ${projectResponse.ss_name || projectResponse.project_name || ''}`
          this.setState({
            project: projectResponse,
            planned: Object.prototype.hasOwnProperty.call(projectResponse, 'planning'),
            loading: false,
            comment: projectResponse ? projectResponse.comment : '',
            pidComment: projectResponse ? projectResponse.pidComment : '',
            contracted_visits: projectResponse ? projectResponse.contracted_visits : '',
            contracted_duration: projectResponse ? projectResponse.contracted_duration : ''
          })
        }
        resolve(null)
      }).fail(error => {
        if (error.status === 403) {
          this.setState({
            error,
            loading: false
          })
        } else {
          this.setState({
            error: { status: 404 },
            loading: false
          })
        }
        console.error(error)
        resolve(error)
      })
    })
  }

  addToPlanning () {
    const project = this.state.project
    const planningData = {
      user: this.props.user._id,
      project: project._id,
      planned_date: moment().startOf('d').toISOString(),
      visit_by: project.muted || project.next_visit
    }

    const postPlanningSettings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/planning`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(planningData)
    }

    $.ajax(postPlanningSettings).done(response => {
      this.updateProject({ planning: response._id })
        .done((response) => {
          // console.info(response)
        })
        .fail(function (error) {
          console.error(error)
        })

      $(window).trigger('updatePlanningSlider')
      this.reloadProject()
    }).fail(error => {
      console.error(error)
    })
  }

  updateProject (newData) {
    const projectId = this.state.project._id

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/project/${projectId}`,
      method: 'PUT',
      headers: {
        authorization: `Bearer ${this.props.token.token}`,
        'content-type': 'application/json'
      },
      processData: false,
      data: JSON.stringify(newData)
    }
    return $.ajax(settings)
  }

  changeCommentField (name) {
    return e => {
      const newValue = e.target.value
      if (newValue !== this.state[name]) {
        this.setState(state => ({
          [name]: newValue,
          savingInProgress: Object.assign({}, state.savingInProgress, {
            [name]: true
          })
        }))
        this.updateProject({ [name]: newValue }).then(() => {
          this.setState(state => ({
            savingInProgress: Object.assign({}, state.savingInProgress, {
              [name]: false
            })
          }))
        })
      }
    }
  }

  changeInputField (name, value) {
    this.setState(state => ({ [name]: value }))
  }

  saveInput (name) {
    const newValue = this.state[name]
    if (newValue !== this.state.project[name]) {
      this.setState(state => ({
        savingInProgress: Object.assign({}, state.savingInProgress, {
          [name]: true
        })
      }))
      this.updateProject({ [name]: newValue }).then(() => {
        this.setState(state => ({
          savingInProgress: Object.assign({}, state.savingInProgress, {
            [name]: false
          })
        }))
      })
    }
  }

  render () {
    if (this.state.error.status === 403) {
      return <h1><T i18nKey="project:unauthorized">Geen toegang</T></h1>
    } else if (this.state.error.status === 404) {
      return <h1><T i18nKey="project:project_doesnt_exist">Deze werf bestaat niet</T></h1>
    }

    if (this.state.loading) {
      return (
        <section className="container content">
          <span className="glyphicons glyphicons-restart glyphicons-spin"></span>
        </section>
      )
    }

    const userRole = this.props.user.role
    const project = this.state.project

    const isDesign = project.status === 'design'
    let userCanSeeThis = false

    if (hasPermissionFor(userRole, 'EMPLOYEE')) {
      userCanSeeThis = true
    } else if (hasPermissionFor(userRole, 'FREELANCER')) {
      const vcType = project.status === 'design' ? 'vcDesign' : 'vcExecution'
      if (project.users.find(user => user.type === vcType)) {
        userCanSeeThis = true
      }
    }

    return (
      <section className="container content">

        <div className="ProjectDetails-space">
          <ProjectDetails project={project} user={this.props.user} token={this.props.token} />
          {hasPermissionFor(userRole, 'FREELANCER') &&
            <ProjectNotes
              projectStatus={project.status}
              changeCommentField={this.changeCommentField}
              changeVisitsInput={e => this.changeInputField('contracted_visits', e.target.value)}
              changeDurationInput={e => this.changeInputField('contracted_duration', e.target.value)}
              saveMaxVisits={e => this.saveInput('contracted_visits')}
              saveDuration={e => this.saveInput('contracted_duration')}
              comment={this.state.comment || ''}
              pidComment={this.state.pidComment || ''}
              contracted_visits={this.state.contracted_visits || ''}
              contracted_duration={this.state.contracted_duration || ''}
              progress={project.progress}
              reloadProject={this.reloadProject}
              updateProject={this.updateProject}
              savingInProgress={this.state.savingInProgress}
              user={this.props.user}
              administration={project.administration}
              files={project.files}
              pidMailDates={project.pidMailDates || {}}
              token={this.props.token}
              project={project}
            />
          }
        </div>
        { userCanSeeThis &&
          (isDesign
            ? <ProjectDesign project={project} user={this.props.user} token={this.props.token} reloadProject={this.reloadProject} updateProject={this.updateProject} />
            : <ProjectPlanning project={project} user={this.props.user} token={this.props.token} reloadProject={this.reloadProject} updateProject={this.updateProject} />
          )}

        { project &&
          <SecondaryInfo
            {...this.props}
            {...this.state}
            updateProject={this.updateProject}
            reloadProject={this.reloadProject}
          />
        }
      </section>
    )
  }
}

const Project = withTranslation()(ProjectView)
export { Project }
