import React, { Component } from 'react'
import { ProjectsTable } from './tables.jsx'
import location from '../../../location'
const $ = window.$

export class Projects extends Component {
  constructor (props) {
    super(props)

    this.state = {
      projects: [],
      sortBy: 'project_name',
      error: {},
      projectsCount: 0,
      totalProjectsCount: 0,
      projectIndex: 0,
      searchParameter: '',
      loading: true
    }

    this.fetchProjects = this.fetchProjects.bind(this)
    this.initScrollLoad = this.initScrollLoad.bind(this)
    this.initSearchListener = this.initSearchListener.bind(this)
    this.initSortListener = this.initSortListener.bind(this)
  }

  componentDidMount () {
    const that = this
    this.fetchProjects()
      .done((result) => {
        if (!that.isUnmounting) {
          that.setState({
            projects: result.results,
            projectIndex: result.indexStop,
            projectsCount: result.amount,
            loading: false
          })
        }
      })
      .fail((error) => {
        console.error(error)
      })

    this.initSearchListener()
    this.initScrollLoad()
    this.initSortListener()
    $(window).trigger('showSearchBar')
  }

  componentWillUnmount () {
    $(window).off('filterTrigger')
    $(window).off('.projectsScroll')
    $(window).off('getSortedData')
    $(window).trigger('hideSearchBar')
    this.isUnmounting = true
  }

  fetchProjects (options) {
    options = options || {}

    let url = `${location.origin}/v1/project?status=execution,waiting&limit=30`

    let keepIndex = true
    if (options.sortBy) {
      keepIndex = false
    }
    if (options.keepIndex === false) {
      keepIndex = false
    }
    options.sortBy = options.sortBy || this.state.sortBy
    url += `&sortBy=${options.sortBy}`

    if (keepIndex) {
      url += `&skip=${this.state.projectIndex || 0}`
    }

    let searchParameter = options.searchParameter
    if (searchParameter || searchParameter === '') {
      url += `&search=${encodeURIComponent(searchParameter)}`
    } else if (this.state.searchParameter) {
      url += `&search=${encodeURIComponent(this.state.searchParameter)}`
    }
    searchParameter = searchParameter || this.state.searchParameter

    url += `&user=${this.props.user._id}&user_type=contact`

    return $.ajax({
      async: true,
      crossDomain: true,
      url: url,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    })
  }

  initScrollLoad () {
    $(window).off('.projectsScroll')

    const $section = $('.projects_toplevel')
    const sectionTop = $section.position().top
    let sectionHeight
    const $window = $(window)
    let innerHeight
    let scrollTop
    let working
    $(window).on('scroll.projectsScroll', (event) => {
      working = this.state.loading
      if (!working) {
        innerHeight = window.innerHeight
        scrollTop = $window.scrollTop()
        sectionHeight = $section.height()
        if (scrollTop + innerHeight > sectionTop + sectionHeight - 300) {
          this.setState({
            loading: true
          })
          this.fetchProjects()
            .done((result) => {
              if (result.results.length === 0) {
                $(window).off('.projectsScroll')
              }
              if (!this.isUnmounting) {
                const newProjects = this.state.projects.concat(result.results)
                this.setState({
                  projects: newProjects,
                  projectIndex: result.indexStop,
                  projectsCount: result.amount,
                  loading: false
                })
              }
            })
            .fail((error) => {
              console.log(error)
            })
        }
      }
    })
  }

  initSearchListener () {
    const that = this
    $(window).on('filterTrigger', (event, searchParameter) => {
      this.setState({
        loading: true,
        searchParameter: searchParameter
      })
      this.fetchProjects({ keepIndex: false })
        .done((result) => {
          if (!that.isUnmounting) {
            that.setState({
              projects: result.results,
              projectIndex: result.indexStop,
              projectsCount: result.amount,
              loading: false
            })
          }
        })
        .fail((error) => {
          console.error(error)
        })
    })
  }

  initSortListener () {
    const that = this
    $(window).on('getSortedData', (event, sortType) => {
      if (sortType === this.state.sortBy) {
        sortType = `-${sortType}`
      }
      this.setState({ sortBy: sortType })
      this.fetchProjects({ keepIndex: false, sortBy: sortType })
        .done((result) => {
          if (!that.isUnmounting) {
            that.setState({
              projects: result.results,
              projectIndex: result.indexStop,
              projectsCount: result.amount,
              loading: false
            })
          }
        })
        .fail((error) => {
          console.error(error)
        })
    })
  }

  render () {
    const projects = this.state.projects
    // console.warn('scroll load');
    // if ( this.state.loading ) {
    //     return <p>laden</p>;
    // }
    return (
      <section className="container content projects_toplevel">
        <ProjectsTable history={this.props.history} loading={this.state.loading} token={this.props.token} user={this.props.user} projectStatus={this.state.projectStatus} projects={projects} sortState={this.state.sortBy} projectsCount={this.state.projectsCount} totalProjectsCount={this.state.totalProjectsCount} />
      </section>
    )
  }
}
