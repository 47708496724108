import React, { Component } from 'react'
import location from '../../location'
import { Trans as T, Translation as Translate } from 'react-i18next'
const $ = window.$

export class FilesView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      files: [],
      error: false
    }

    this.handleFileUpload = this.handleFileUpload.bind(this)
  }

  componentWillUnmount () {
    this.isUnmounting = true
  }

  componentDidMount () {
    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/file?tags=global`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      }
    }

    const fileGetPromise = $.ajax(settings)
    fileGetPromise.done(files => {
      if (!this.isUnmounting) {
        this.setState({
          files
        })
      }
    })
    fileGetPromise.fail(error => {
      this.setState({
        error
      })
    })
  }

  addFile () {
    $('#fileInput').click()
  }

  handleFileUpload (event) {
    event.preventDefault()

    let tags = ['global']
    $('#tagsSelect option').each((index, element) => {
      if (element.selected) {
        tags.push(element.value)
      }
    })
    tags = tags.join(',')

    const files = $('#fileInput')[0].files
    if (!files.length) {
      return console.info('no file')
    }

    const form = new FormData()
    form.append('file', files[0])
    form.append('tags', tags)

    const settings = {
      async: true,
      crossDomain: true,
      url: `${location.origin}/v1/file`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${this.props.token.token}`
      },
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
      data: form
    }

    const fileSavePromise = $.ajax(settings)
    fileSavePromise.done(response => {
      const files = this.state.files
      try {
        response = JSON.parse(response)
        files.push(response)
      } catch (error) { }
      this.setState({
        files
      })
    })
    fileSavePromise.fail(error => {
      console.error(error)
      this.setState({
        error
      })
    })
  }

  showFileName (event) {
    $('#fileName').val(event.currentTarget.files[0].name)
  }

  render () {
    return (
      <section className="right_content">
        {this.state.error ? <h1>{this.state.error}</h1> : ''}
        <form onSubmit={this.handleFileUpload}>
          <fieldset className="form-group">
            <button onClick={this.addFile}><T i18nKey="settings:choose_file">Bestand kiezen</T></button>
            <input id="fileName" disabled></input>
            <input id="fileInput" type="file" style={{ display: 'none' }} onChange={this.showFileName} />
          </fieldset>
          <fieldset>
            <label htmlFor="tagsSelect"><T i18nKey="settings:tags">Tags</T></label>
            <select className="form-control" id="tagsSelect" multiple>
              <Translate>{t =>
                <option value="vgp">{t('settings:general_vgp')}</option>
              }</Translate>
            </select>
          </fieldset>
          <fieldset>
            <Translate>{t =>
              <input type="submit" value={t('settings:upload')} />
            }</Translate>
          </fieldset>
        </form>
        <FilesTableView files={this.state.files} />
      </section>
    )
  }
}

const FilesTableView = props => {
  const fileRows = props.files.map(file => {
    return (
      <tr key={file._id}>
        <td>{file.fileName}</td>
        <td>{file.tags.join(' ')}</td>
        <td><a href={`${location.origin}/v1/file/${file._id}/-1/${file.fileName}`}><T i18nKey="settings:download">Download</T></a></td>
      </tr>
    )
  })
  return (
    <table className="list_table">
      <thead>
        <tr>
          <th><T i18nKey="settings:name">Naam</T></th>
          <th><T i18nKey="settings:tags">Tags</T></th>
          <th><T i18nKey="settings:download">Download</T></th>
        </tr>
      </thead>
      <tbody>
        {fileRows}
      </tbody>
    </table>
  )
}
