import React, { Component } from 'react'
import location from '../../location'
const $ = window.$

export class UserSearchbar extends Component {
  constructor (props) {
    super(props)

    let myId = 'js-remote-user-data'
    let divId = 'vcFilterDD'
    if (this.props.projectId) {
      myId += `-${this.props.projectId}`
      divId = `div-${this.props.projectId}`
    }
    this.state = {
      select2: {},
      selected: false,
      myId,
      divId
    }

    this.searchConfirmed = this.searchConfirmed.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount () {
    const select2 = initiateUserDropdown(this.state.myId, this.state.divId)
    this.setState({
      select2: select2
    })
    $(`#${this.state.myId}`).on('change', this.searchConfirmed)
  }

  componentWillUnmount () {
    $(`#${this.state.myId}`).off('change')
  }

  searchConfirmed (event) {
    const userId = event.target.value
    if (this.props.onChange) {
      this.props.onChange(userId)
    } else {
      $(window).trigger('filterTrigger', userId)
      this.setState({
        selected: true
      })
    }
  }

  clearSearch (event) {
    this.state.select2.val(null).trigger('change')
    this.setState({
      selected: false
    })
  }

  render () {
    return (
      <form>
        <select className="form-control" id={this.state.myId} style={{ width: 250 }}></select>
        <div id={this.state.divId} ></div>
        {this.state.selected
          ? <span style={{ marginLeft: 10, padding: '2px 12px' }} className='btn-green' onClick={this.clearSearch}>x</span>
          : null}
      </form>
    )
  }
}

const initiateUserDropdown = function (id, divId) {
  const token = localStorage.getItem('authToken')
  return $(`#${id}`).select2({
    ajax: {
      async: true,
      crossDomain: true,
      url: function (params) {
        return `${location.origin}/v1/user?search=${encodeURIComponent(params.term)}&vc=true`
      },
      headers: {
        authorization: `Bearer ${token}`
      },
      delay: 250,
      processResults: function (data) {
        data = $.map(data, function (object) {
          object.id = object._id
          object.text = object.name
          return object
        })
        return {
          results: data
        }
      },
      cache: true
    },
    escapeMarkup: function (markup) {
      return markup
    },
    minimumInputLength: 3,
    placeholder: 'Filter op VC',
    dropdownParent: $(`#${divId}`),
    templateResult: function (client) {
      if (client.loading) return client.text
      const markup = client.name
      return markup
    },
    templateSelection: function (client) {
      return client.text
    }
  })
}
