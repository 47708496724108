import React, { Component } from 'react'
let searchTimeout
const $ = window.$

export class SearchBar extends Component {
  constructor (props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search || '')
    const intialFilter = searchParams.get('filter') || ''

    this.state = {
      searchValue: intialFilter
    }

    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.triggerFilterEvent = this.triggerFilterEvent.bind(this)
    this.startSearch = this.startSearch.bind(this)
    this.setIconToSearch = this.setIconToSearch.bind(this)
    this.setIconToRestart = this.setIconToRestart.bind(this)

    this.searchFieldRef = React.createRef()
    this.searchIconRef = React.createRef()
  }

  componentDidMount () {
    $(window).on('resetSearchbar', (event) => {
      if (this.state.searchValue !== '' && !this.isUnmounting) {
        this.setState({
          searchValue: ''
        })
      }
    })
    $(window).on('searchFinished', (event) => {
      this.setIconToSearch()
    })
    $(window).on('searchCanceled', (event) => {
      this.setIconToSearch()
      setTimeout(function (component) {
        component.setIconToSearch()
      }, 250, this)
    })

    $(window).on('displaySearch', (event, searchParameter) => {
      this.setState({
        searchValue: searchParameter
      })
    })
  }

  componentWillUnmount () {
    $(window).off('resetSearchbar')
    $(window).off('searchFinished')
    $(window).off('displaySearch')
    this.isUnmounting = true
  }

  handleSearchInput (event) {
    const inputValue = this.searchFieldRef.current.value
    this.setState({
      searchValue: inputValue
    })
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(function (component) {
      component.triggerFilterEvent(inputValue)
    }, 250, this)
  }

  triggerFilterEvent (inputValue) {
    $(window).trigger('filterTrigger', inputValue)
    this.setIconToRestart()
  }

  startSearch (event) {
    event.preventDefault()
    const inputValue = this.searchFieldRef.current.value
    this.triggerFilterEvent(inputValue)
  }

  setIconToSearch () {
    $(this.searchIconRef.current).addClass('glyphicons-search')
    $(this.searchIconRef.current).removeClass('glyphicons-restart glyphicons-spin')
  }

  setIconToRestart () {
    $(this.searchIconRef.current).removeClass('glyphicons-search')
    $(this.searchIconRef.current).addClass('glyphicons-restart glyphicons-spin')
  }

  render () {
    return (
      <div className="top_bar_search">
        <form onSubmit={this.startSearch}>
          <input value={this.state.searchValue} onChange={this.handleSearchInput} ref={this.searchFieldRef} type="search" />
          <button><span ref={this.searchIconRef} className="glyphicons glyphicons-search"></span></button>
        </form>
      </div>
    )
  }
}
