import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { msie, xmlRequest } from '../../tools.jsx'
import location from '../../location'
import { Trans as T, withTranslation } from 'react-i18next'

function getParameterByName (name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

class LoginView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loginField: '',
      showIENotification: msie()
    }

    this.submitLogin = this.submitLogin.bind(this)

    this.emailRef = React.createRef()
    this.passwordRef = React.createRef()
  }

  componentWillMount () {
    document.title = this.props.t('title:login')
  }

  async submitLogin (event) {
    event.preventDefault()

    const url = `${location.origin}/v1/auth/login`
    const body = {
      email: this.emailRef.current.value.toLowerCase(),
      password: this.passwordRef.current.value
    }

    let response
    try {
      response = await xmlRequest(url, null, body)
    } catch (error) {
      return console.error(error)
    }

    if (response.error) {
      const errorMessage = response.error.message || ''
      return this.setState({ loginField: errorMessage.split(' ')[1] })
    }

    localStorage.setItem('authToken', response.token)
    const redirect = getParameterByName('redirect') ? getParameterByName('redirect') : ''
    if (redirect) {
      location.href = location.origin + '/' + redirect
    }
    this.props.history.push('/')
  }

  render () {
    return (
      <section className="container form">
        {this.state.showIENotification
          ? <p className="message">
            <T i18nKey="login:ie_message">IE 10 of ouder wordt niet ondersteund. Gelieve Chrome, firefox, IE 11, safari te gebruiken voor een optimaal gebruik van Coördinatiedagboek .</T>
          </p>
          : null}
        <h3>
          <T i18nKey="login:welcome">Welkom in het coördinatiedagboek van <a href="https://www.vekmo.be/" target="_blank" rel="noopener noreferrer">Vekmo</a>.</T>
        </h3>
        <p className="message">
          <T i18nKey="login:new_password_message">Indien u nog niet over een login beschikt kan u best even op <b><Link to={'/wachtwoord_herstellen'}>hier</Link></b> klikken, om een nieuw paswoord aan te vragen.</T>
        </p>
        {this.state.loginField
          ? <div className="message warning">
            <div className="message_icon">
              <span className="glyphicons glyphicons-exclamation-sign"></span>
            </div>
            <T i18nKey="login:invalid_credentials">E-mail en/of paswoord zijn foutief of komen niet overeen. Indien u het paswoord niet meer weet ga naar <Link to={'/wachtwoord_herstellen'}>paswoord herstellen</Link>.</T>
          </div>
          : ''}
        <form onSubmit={this.submitLogin}>
          <fieldset className="form-group">
            <label htmlFor="email"><T i18nKey="common:email">Email</T></label>
            <input ref={this.emailRef} type="email" className="form-control" id="email" placeholder={this.props.t('common:email')} />
          </fieldset>
          <fieldset className="form-group">
            <label htmlFor="password"><T i18nKey="common:password">Paswoord</T></label>
            <input ref={this.passwordRef} type="password" className="form-control" id="password" placeholder={this.props.t('common:password')} />
          </fieldset>
          <fieldset className="form-group">
            <button type="submit" className="form-submit"><T i18nKey="login:login">Login</T></button>
          </fieldset>
          <div className="text_right">
            <Link to={'/wachtwoord_herstellen'}><T i18nKey="login:forgot_password">Paswoord vergeten</T></Link>
          </div>
        </form>
      </section>
    )
  }
}

const Login = withTranslation()(LoginView)
export { Login }
